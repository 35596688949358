import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";

export default class CarsfastIncomeLength extends GenSet {
    title = CoreTools.emit("panelTitle", "How long have you been earning this income?");
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <div className="form-row">
                <div className="col-lg-6 mb-2">
                    <label className="btn btn-option">
                        <AppInput.Radio name="income_length" value="Less than 3 months" onClick={this.onClick} />
                        <span className="btn-option-text">Less than 3 months</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-2">
                    <label className="btn btn-option">
                        <AppInput.Radio name="income_length" value="3 - 6 Months" onClick={this.onClick} />
                        <span className="btn-option-text">3 - 6 Months</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-2">
                    <label className="btn btn-option">
                        <AppInput.Radio name="income_length" value="6 - 12 Months" onClick={this.onClick} />
                        <span className="btn-option-text">6 - 12 Months</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-2">
                    <label className="btn btn-option">
                        <AppInput.Radio name="income_length" value="12 Months - 2 Years" onClick={this.onClick} />
                        <span className="btn-option-text">12 Months - 2 Years</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-2">
                    <label className="btn btn-option">
                        <AppInput.Radio name="income_length" value="2 Years +" onClick={this.onClick} />
                        <span className="btn-option-text">2 Years +</span>
                    </label>
                </div>
            </div>
        </div>
    </div>;

}