import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools"

export default class SurexOwnHome extends GenSet {
    continue = () => setTimeout(() => { 
        this.navigate(CoreTools.switch(
            [this.applicant.field("home_owner") === "No", "#Married"],
            [true, this.props.continue]
        ));
    });
    
    content = (state, props) => <SurexComponents.Card
        title="Do you own your home?"
    >
        <div className="form-row justify-content-center">
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="home_owner" value="Yes" onClick={this.continue} />
                    <span>Yes</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="home_owner" value="No" onClick={this.continue} />
                    <span>No</span>
                </label>
            </div>
        </div>
    </SurexComponents.Card>;
}
