import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";

export default class CLRGender extends GenSet {
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <div className="form-panel">
    <div className="row">
        <div className="col-md-12">
            <span className="radio-button-title">Which of the following would you like to cover?</span>                
            <span className="input-group"> 
                <label className="btn btn-option">
                    <AppInput.Radio name="insurance_cover" value="My Family" onClick={this.onClick} />
                    <span className="btn-option-text">My Family</span>
                </label>    
                <label className="btn btn-option">
                    <AppInput.Radio name="insurance_cover" value="My Mortgage" onClick={this.onClick} />
                    <span className="btn-option-text">My Mortgage</span>
                </label>
                <label className="btn btn-option">
                    <AppInput.Radio name="insurance_cover" value="My Family & Mortgage" onClick={this.onClick} />
                    <span className="btn-option-text">My Family & Mortgage</span>
                </label>
                <label className="btn btn-option">
                    <AppInput.Radio name="insurance_cover" value="End of Life Coverage" onClick={this.onClick} />
                    <span className="btn-option-text">End of Life Coverage</span>
                </label>       
            </span>
        </div>
    </div>
</div>;
}

