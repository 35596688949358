import CoreTools from "../../../services/CoreTools";
import GenSet from "../../../services/GenSet";

// import FieldValidate from "../../../services/FieldValidate";

export default class HlProcessing extends GenSet {
    onApplicant = (state, props, applicant) => CoreTools.switch(
        [!applicant.field("first_name"),                        () => this.navigate("#Name")],
        [!applicant.field("last_name"),                         () => this.navigate("#Name")],
        // [!applicant.field("company_name"),                      () => this.navigate("#Contact")],
        [!applicant.field("phone"),                             () => this.navigate("#Contact")],
        [!applicant.field("email"),                             () => this.navigate("#Contact")],
        [true,                                                  () => applicant.post("hearing-ca", "submit", {})]
    );

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <div className="day-fld-box">
                <h3 className="text-center">We are processing your submission...</h3>
            </div>
            <div className="row">
                <img className="col-12" src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" />
            </div>
        </div>
    </div>;
}

