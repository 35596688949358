import React from "react";
import Applicant from "./Applicant";
import CoreTools from "./CoreTools";

export default class GenSet extends React.Component {
    location = CoreTools.state.location;
    
    setVal = CoreTools.stateHandler(this);

    wHolder = CoreTools.watchHolder();

    applicant = Applicant();

    componentDidMount = () => {
        this.setVal("mount");
        if (typeof(this.onMount) === "function") {this.onMount(this.state, this.props);}
        if (typeof(this.onApplicant) === "function") {
            if (CoreTools.state.applicantLoaded) {
                this.onApplicant(this.state, this.props, this.applicant);
            } else {
                this.wHolder(CoreTools.on("applicantLoaded", () => this.onApplicant(this.state, this.props, this.applicant)));
            }
        }
    };

    componentWillUnmount = () => {
        this.setVal("unmount");
        this.end = true;
        if (typeof(this.onUnmount) === "function") {this.onUnmount(this.state, this.props);}
        this.wHolder("end");
    };

    componentDidUpdate = () => typeof(this.onUpdate) === "function" && this.onUpdate(this.state, this.props);

    navigate = (url, delay) => url && setTimeout(() => window.location.href = url, delay);
    onNavigate = cb => this.wHolder(CoreTools.on("locationChange", location => cb(location)));
    
    importStyle = (localPath, onLoad) => CoreTools.importStyle(CoreTools.state.isLocal ? localPath : "https://form.consumergenius.com" + localPath, onLoad);

    render = () => typeof(this.content) === "function" ? this.content(this.state, this.props, this.applicant) : null;
}