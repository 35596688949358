import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import HeComponents from "../inserts/HeComponents";
import FormValidate from "../../../services/FormValidate";

export default class HeEmail extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };
    
    content = (state, props) => <HeComponents.Card
        title="Great News! We have matched you with a home equity lender in your area."
        text="Enter your contact info so we can personalize your results."
        controls={<HeComponents.Controls continue={this.continue} buttonText={props.buttonText} disclaimer={props.disclaimer} />}
    >
        <div className="form-row">
            <div className="col-12 mb-2">
                <AppInput.Input type="email" name="email" className="form-control text-field" placeholder="Email Address" onValidate={FormValidate.isEmail} failClass="invalid" />
            </div>
        </div>
    </HeComponents.Card>;
}