import Icon from "../../directives/Icon";

const LifeInsuranceFormData = ({
  amount: ["$0 - $24,999", "$25,000 - $49,999", "$50,000 - $99,999", "100,000 - $249,999", "$250,000+"],
  gender: ["Male", "Female"],
  genderV2: [
    {
      label: "Male", 
      value: "male", 
      image: <Icon name="lifeInsuranceMale" 
      className="family-ic" 
      style={{width: '75px'}} 
      alt="male" />
    }, 
    {
      label: "Female", 
      value: "female", 
      image: <Icon name="lifeInsuranceFemale" 
      className="family-ic" 
      style={{width: '75px'}} 
      alt="female" />
    }
  ],
  insurance: ["Term", "Permanent", "Not Sure"],
  location: ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland & Labrador", "Nova Scotia", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Northwest Territories", "Nunavut", "Yukon"],
  smoking: ["Smoking", "Non-Smoking"],
  smokingV2: [
    {
      label: "Smoking", 
      value: "Smoking", 
      image: <Icon name="lifeInsuranceSmoking" 
      className="family-ic" 
      style={{width: '75px'}} alt="smoking" />
    }, 
    {
      label: "Non-Smoking", 
      value: "Non-Smoking", 
      image: <Icon name="lifeInsuranceNonSmoking" 
      className="family-ic" 
      style={{width: '75px'}} alt="non-smoking" />
    }
  ],
  type: ["My Family", "My Mortgage", "My Family & Mortgage", "End of Life Coverage"],
  typeV2: [
    {
      label: "My Family", 
      value: "My Family", 
      image: <Icon name="lifeInsuranceFamily" 
      className="family-ic" 
      style={{width: '75px'}} 
      alt="my family" />
    }, 
    {
      label: "My Mortgage", 
      value: "My Mortgage", 
      image: <Icon name="lifeInsuranceMortgage" 
      className="family-ic" 
      style={{width: '75px'}} 
      alt="my mortgage" />
    },
    {
      label: "My Family & Mortgage", 
      value: "My Family & Mortgage", 
      image: <Icon name="lifeInsuranceFamilyMortgage" 
      className="family-ic" 
      style={{width: '75px'}} 
      alt="my family and mortgage" />
    }, 
    {
      label: "End of Life Coverage", 
      value: "End of Life Coverage", 
      image: <Icon name="lifeInsuranceEnd" 
      className="family-ic" 
      style={{width: '75px'}} 
      alt="end of life coverage" />
    }
  ],
})

export default LifeInsuranceFormData;
