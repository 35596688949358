import CoreTools from "../../../services/CoreTools";
import GenSet from "../../../services/GenSet";
import SurexComponents from "../inserts/SurexComponents";
// import FieldValidate from "../../../services/FieldValidate";

export default class SurexProcessing extends GenSet {
    // onMount = (state, props) => this.applicant.post("surex", "submit", {});

    onApplicant = (state, props, applicant) => {
        console.log("Surex Data", applicant.fields);
        if (applicant.flow() === "EverQuote")
            applicant.post("everquote", "submit", {});
        else
            applicant.post("surex", "submit", {});
    };

    content = (state, props) => <SurexComponents.Card
        title="We are processing your submission..."
    >
        <div className="row">
            <img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" className="col" />
        </div>
    </SurexComponents.Card>;
}
