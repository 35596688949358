import Builder from './Builder';
import Funnels from './Funnels';
import Support from './Support';
import CoreTools from './services/CoreTools';
import SecureCookie from './services/SecureCookie';
import Applicant from './services/Applicant';

CoreTools.state.location = {
  href: window.location.href,
  host: window.location.host || "",
  pathname: window.location.pathname || "",
  hash: window.location.hash || "",
  search: window.location.search
};

CoreTools.state.isLocal = CoreTools.state.location.host.split(":")[0] === "localhost";

(() => {
  let lastNav = false;
  window.addEventListener("popstate", event => {  
    CoreTools.state.location.href = window.location.href;
    if (lastNav !== CoreTools.state.location.href) {
      lastNav = CoreTools.state.location.href;
      CoreTools.state.location.pathname = window.location.pathname || "";
      CoreTools.state.location.hash = window.location.hash || "";
      CoreTools.emit("locationChange", CoreTools.state.location);
    }
  });
})();

window.addEventListener("resize", event => CoreTools.emit("resize", event));
window.addEventListener("beforeunload", event => typeof(CoreTools.state.beforeExit) === "function" && CoreTools.state.beforeExit(event));

SecureCookie();

(() => {
  const start = () => [
    ["funnelComp", Funnels],
    ["funnelBuilder", Builder],
    ["funnelSupport", Support]
  ].filter(e => !CoreTools.state[e[0]]).forEach(e => {
      CoreTools.state[e[0]] = document.getElementById(e[0]);
      if (CoreTools.state[e[0]]) {
        e[1](CoreTools.state[e[0]]);
      }
  });
  start();
  if (document.readyState === "loading") {window.addEventListener("load", start);}
})();

window.addEventListener("load", () => {
  if (typeof(window.funnelData) === "function" && typeof(window.flow) === "string") {
    const applicant = Applicant();
    applicant.setFlow(window.flow);
    if (CoreTools.state.applicantLoaded) {
      setTimeout(() => window.funnelData(applicant));
    } else {
      CoreTools.on("applicantLoaded", () => setTimeout(() => window.funnelData(applicant)));
    }
  }
});