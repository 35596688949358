import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";

export default class NhpusFirstTime extends GenSet {
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <fieldset className="card card-options">
        <div className="card-body">
            <h5 className="card-title">Are you a First-Time Home Buyer?</h5>
            <div className="card-fields row">
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio name="first_time_home_buyer" value="Yes" onClick={this.onClick} />
                        <span>Yes</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio name="first_time_home_buyer" value="No" onClick={this.onClick} />
                        <span>No</span>
                    </label>
                </div>
            </div>
        </div>
    </fieldset>;

}