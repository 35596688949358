import GenSet from "../../../services/GenSet";

export default class CarsfastFlatFormForward extends GenSet {
    continue = () => {

    };
    
    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <p className="card-title text-center mt-3 mb-3"><b>Thank you for your application</b></p>
            <p className="card-title text-center mt-3 mb-3">Unfortunately, we didn't find a match for you in our system.</p>
            <div className="forward-page-disclaimer">
                <h5>
                    <b className="text-center">Next Step: There may be issues with your credit score and report that you were unaware of. Your credit score impacts what type of interest rate you may qualify for, so it’s important to ensure that the information in your credit profile is accurate to get the best available rates.</b>
                </h5>
                <div className="continue-button mt-4"><a href="http://track.lendingarch.ca/?c=1335&lp=64&&sub1=CarsFast&sub2=Step3" target="_blank" rel="noreferrer" style={{color: "white"}}>Get My Credit Score</a></div>
            </div>    
        </div>
    </div>;

}
