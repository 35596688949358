import GenSet from "../../../services/GenSet";

export default class Warning extends GenSet {
    init = this.setVal({count: 60});

    timer = () => {
        this.timeOut = setTimeout(() => {
            this.setVal({count: --this.state.count}, () => {
                if(this.state.count === 0) {
                    this.props.cancel(this.props.url);
                } else {
                    this.timer();
                }
            });
        }, 1000);
    };

    continue = () => {
        clearTimeout(this.timeOut);
        this.props.continue();
    };

    cancel = () => {
        clearTimeout(this.timeOut);
        this.props.cancel(this.props.url);
    };

    onMount = (state, props) => {
        this.timer();
    };

    onUnmount = () => clearTimeout(this.timeOut);

    content = (state) => <div className="warning-message">
        <div>This form will time out in {state.count} seconds.</div>
        <div>
            <button className="btn-success p-2 pl-4 pr-4" style={{marginRight: "30px"}} onClick={this.continue}>Continue My Form</button>
            <button className="btn-danger p-2 pl-4 pr-4" onClick={this.cancel}>Start New Form</button>
        </div>
    </div>;
}
