import PanelEffects from "../../directives/PanelEffects";
import CoreTools from "../../services/CoreTools";
import GenSet from "../../services/GenSet";
import HairJusticeGenPanel from "./panels/HairJusticeGenPanel";
import HairJusticeFormData from "./HairJusticeFormData";
import GoogleTagManager from "../../services/GoogleTagManager";
import FormValidate from "../../services/FormValidate";

const yearOptions = (() => {let year = new Date().getFullYear(); 
    let years = [{value: "", caption: "Year"}]; 
    for (let i = year; i > year - 25; i--) {
        if (i === 1999) {
          years.push({ value: "1999 or earlier", caption: "1999 or earlier" });
        } else {
          years.push({ value: i.toString(), caption: i.toString() });
        }
      }  return years;
})();

export default class HairJusticeIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (!CoreTools.state.appOptions.includes("nocss")) {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
            this.importStyle("/css/HairJustice.css");
            this.importStyle("/css/animate.css");
            this.importStyle("/css/rangeslider2.css");

        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider2.css");
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}, () => GoogleTagManager.event({event: "first_page", pageName: this.state.hash || "#Residence"})));
        this.applicant.setField("roof_shade", "Full Sun");
        this.applicant.setField("credit", "Good");
    };

    structure = {
        age: {
            title: "What is your age?",
            horizontalLine: true,
            fields: [
                {type: "select", name: "age", options: HairJusticeFormData.age, onValidate: v => v}
            ],
            continue: {caption: "Continue"} 
        },
        chemical: {
            title: "Did the affected person frequently use chemical-based hair straightening/relaxing products?",
            horizontalLine: true,
            fields: [
                {type: "radio", name: "chemical", values: HairJusticeFormData.yesNo }
            ],
        },
        contact: {
            title: "Congratulations your pre-approval is ready!",
            horizontalLine: true,
            fields: [
                {type: "input", label: "First Name", name: "first_name", placeholder: "First Name", onValidate: FormValidate.isName},
                {type: "input", label: "Last Name", name: "last_name", placeholder: "Last Name", onValidate: FormValidate.isName},
                {type: "input", label: "Email", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail },
                {type: "phone", label: "Phone", name: "phone", placeholder: "(123) 456 7890", onValidate: FormValidate.isPhone, onChange: FormValidate.phoneFormat},
            ],
            continue: {caption: "Continue"},
        },
        discovery: {
            title: "What age was the diagnosis given?",
            horizontalLine: true,
            fields: [
                {type: "select", name: "discovery_age", options: HairJusticeFormData.age, onValidate: v => v}
            ],
            continue: {caption: "Continue"} 
        },
        diagnosis: {
            title: "Were you (or a loved one) diagnosed with any of the following?",
            subTitle: "Select all improvements that apply",
            horizontalLine: true,
            fields: [
                {type: "multiSelect", name: "projects", 
                options: [
                    "Endometrial cancer",
                    "Ovarian cancer",
                    "Uterine cancer",
                    "Uterine fibroids",
                    "None of these",
                ]},
            ], 
            continue: {caption: "Continue"} 
        },
        illness: {
            title: "Did you develop any of the following illnesses or injuries while or after residing or working near Camp Lejeune?",
            horizontalLine: true,
            fields: [
                {type: "select", name: "illness", options: HairJusticeFormData.illnesses, onValidate: v => v}
            ],
            continue: {caption: "Continue"} 
        },
        processing: {
            processing: true,
        },
        year: {
            title: "Year of diagnosis?",
            horizontalLine: true,
            fields: [
                {type: "select", name: "year_diagnosis", options: yearOptions, onValidate: v => v}
            ],
            continue: {caption: "Continue"}
        },
    };

    panels = {
        "#Diagnosis":         <HairJusticeGenPanel options={this.structure.diagnosis} />,
        "#Chemical":          <HairJusticeGenPanel options={this.structure.chemical} />,
        "#Discovery":         <HairJusticeGenPanel options={this.structure.discovery} />,
        "#Year":              <HairJusticeGenPanel options={this.structure.year} />,
        "#Contact":           <HairJusticeGenPanel options={this.structure.contact} />, 
        "#Processing":        <HairJusticeGenPanel options={this.structure.processing} />,
    };

    content = (state, props) => <>
        <PanelEffects.ProgressBar
            barColor="#000080"
            className="progress-bar"
            textColorSecondary="#000"
            style={{
                borderStyle: "solid",
                borderWidth: "2px", 
                borderColor: "#000",
                backgroundColor: "#fff",
                borderRadius: "5px", 
                padding: "2px", 
                fontSize: "13px",
                margin: "0 10px"
            }}
        />
        <PanelEffects.Transition1 className={["#Loading"].includes(state.hash) ? "loading-panel" : "panel-container"}>
            <div id="appForm" style={{marginTop: "15px"}} key={state.hash}>
                <div className="appForm_inner">
                    <PanelEffects.Selector
                        selector={state.hash}
                        panels={this.panels} 
                    />
                </div>
            </div>
        </PanelEffects.Transition1>
    </>;
}
