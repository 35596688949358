import Applicant from "./Applicant";
import CoreTools from "./CoreTools";

const PhoneVerify = msg => {
    let phoneNumber = "";
    let onVerified = null;
    let onGetPin = null;
    let onError = null;
    const endHook = Applicant().on("phoneVerified", data => {
        console.log("phoneVerified", data);
        CoreTools.switch(
            [data.status === "internalError", () => {if (typeof(onError) === "function") {onError("Oops, something broke on our end.");}}],
            [data.status === "recordError", () => {if (typeof(onError) === "function") {onError("Oops, something broke on our end.");}}],
            [data.status === "badNumber", () => {if (typeof(onError) === "function") {onError("Please enter a valid 10 digit phone number.");}}],
            [data.status === "noIp", () => {if (typeof(onError) === "function") {onError("We failed to validate your systems identity.");}}],
            [data.status === "badMsg", () => {if (typeof(onError) === "function") {onError("Oops, something broke on our end.");}}],
            [data.status === "antiHammer", () => {if (typeof(onError) === "function") {onError("Sorry, you've attempted too many phone numbers.");}}],
            [data.status === "exceededAttempts", () => {if (typeof(onError) === "function") {onError("Sorry, you've made to many invalid pin attempts.");}}],
            [data.status === "getPin", () => {if (typeof(onGetPin) === "function") {onGetPin();}}],
            [data.status === "invalidPin", () => {if (typeof(onError) === "function") {onError("The pin you have entered is invalid. Check your texts for the pin.");}}],
            [data.status === "verified", () => {if (typeof(onVerified) === "function") {onVerified();}}]
        );
    });

    return {
        submitNumber: (number, voice) => {
            phoneNumber = number;
            console.log("submitNumber", phoneNumber, voice);
            Applicant().post("phone", "number", {number: phoneNumber, msg: msg, voice: voice});
        },
        submitPin: pin => {
            console.log("submitPin", phoneNumber, pin);
            Applicant().post("phone", "pin", {number: phoneNumber, pin: pin});
        },
        onVerified: cb => onVerified = cb,
        onGetPin: cb => onGetPin = cb,
        onError: cb => onError = cb,
        end: () => {
            endHook();
        }
    };
};

export default PhoneVerify;