import CoreTools from "../../services/CoreTools";
import Socket from "../../services/Socket";
import SecureCookie from "../../services/SecureCookie";

let connection = false;
const BuildSocket = () => connection || (() => {
    CoreTools.log("BuildSocket Loaded");
    let socket = Socket("wss://form.consumergenius.com/builder");
    socket.reconnect(true);
    const socketCache = [];
    const state = {
        cookie: null,
        admin: false
    };
    const send = (action, data) => {
        if (socket.state() === 1) {socket.send(action, data);}
        else {socketCache.push({action: action, data: data});}
    };
    socket.on("open", () => {
        CoreTools.log("Build Socket Open...");
        send("resume", {cookie: state.cookie});
        while (socketCache.length && socket.state() === 1) {
            const sCache = socketCache.shift();
            send(sCache.action, sCache.data);
        }

    });
    socket.on("admin", data => state.admin = data.admin);
    SecureCookie().onReady(cookie => {
        state.cookie = cookie;
        socket.connect();
    });
    
    connection = {
        on: socket.on,
        send: send,
        state: state
    };
    return connection;
})();
export default BuildSocket;