

import PanelEffects from "../../directives/PanelEffects";
import CoreTools from "../../services/CoreTools";
import GenSet from "../../services/GenSet";
import LoanzBirthdate from "./panels/LoanzBirthdate";
import LoanzInstitution from "./panels/LoanzInstitution";
import LoanzEmployer from "./panels/LoanzEmployer";
import LoanzGenPanel from "./panels/LoanzGenPanel";
import LoanzProcessingUK from "./panels/LoanzProcessingUK";
import LoanzProcessingUS from "./panels/LoanzProcessingUS";
import LoanzStructureCA from "./LoanzStructureCA";
import LoanzStructureUK from "./LoanzStructureUK";
import LoanzStructureUS from "./LoanzStructureUS";
import LoanzDebtRelief from "./panels/LoanzDebtRelief";
import LoanzFlatFormUS from "./LoanzFlatFormUS";
import LoanzStructureUSFlatForm from "./LoanzStructureUSFlatForm";

export default class LoanzIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (CoreTools.state.appOptions.includes("nocss")) {  
            this.setVal({
                cssBootstrap: true,
                cssMain: true
            });
        } else {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css", () => this.setVal({cssBootstrap: true}));
            this.importStyle("/css/Loanz.css", () => this.setVal({cssMain: true}));
            this.importStyle("/css/animate.css");
        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider3.css");
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}, () => setTimeout(CoreTools.centerForm, 50)));
    };

    onApplicant = (state, props, applicant) => {
        if (!applicant.field("loan_amount")) {
            applicant.setField("loan_amount", 500);
        }
    }
    
    versions = {
        ca: {
            "#Amount":              <LoanzGenPanel options={LoanzStructureCA.amount} />,
            "#Reason":              <LoanzGenPanel options={LoanzStructureCA.reason} />,
            "#Birthdate":           <LoanzBirthdate />,
            // "#ResidenceType":       <LoanzGenPanel options={LoanzStructureCA.residenceType} />,
            "#Address":             <LoanzGenPanel options={LoanzStructureCA.addressCA} />,
            "#RentAmount":          <LoanzGenPanel options={LoanzStructureCA.rentAmount} />,
            "#Name":                <LoanzGenPanel options={LoanzStructureCA.name} />,
            "#Email":               <LoanzGenPanel options={LoanzStructureCA.email} />,
            "#Phone":               <LoanzGenPanel options={LoanzStructureCA.phone} />,
            "#Institution":         <LoanzInstitution />,
            // "#Verification":        <LoanzGenPanel options={LoanzStructureCA.verification} />,
            "#Employer":            <LoanzEmployer />,
            "#SIN":                 <LoanzGenPanel options={LoanzStructureCA.sin} />,
            "#CreditCard":          <LoanzGenPanel options={LoanzStructureCA.creditCard} />,
            "#UnsecuredDebt":       <LoanzGenPanel options={LoanzStructureCA.unsecuredDebt} />,
            "#CurrentStatus":       <LoanzGenPanel options={LoanzStructureCA.currentStatus} />,
            "#DebtRelief":          <LoanzGenPanel options={LoanzStructureCA.debtRelief} />,
            "#DebtCall":            <LoanzGenPanel options={LoanzStructureCA.debtCall} />,
            "#Mogo":                <LoanzGenPanel options={LoanzStructureCA.mogo} />,
            "#Consent":             <LoanzGenPanel options={LoanzStructureCA.consent} />,
            "#Processing":          <LoanzGenPanel options={LoanzStructureCA.processingCA} />,
        },
        uk: {
            "#Amount":              <LoanzGenPanel options={LoanzStructureUK.amountUK} />,
            "#LoanDuration":        <LoanzGenPanel options={LoanzStructureUK.loanDuration} />,
            "#Reason":              <LoanzGenPanel options={LoanzStructureUK.reasonUK} />,
            // "#Title":               <LoanzGenPanel options={LoanzStructureUK.title} />,
            "#Name":                <LoanzGenPanel options={LoanzStructureUK.name} />,
            "#Birthdate":           <LoanzGenPanel options={LoanzStructureUK.birthdate} />,
            "#MaritalStatus":       <LoanzGenPanel options={LoanzStructureUK.maritalStatus} />,
            "#Dependents":          <LoanzGenPanel options={LoanzStructureUK.dependents} />,
            "#Address":             <LoanzGenPanel options={LoanzStructureUK.addressUK} />,
            "#ResidentialStatus":   <LoanzGenPanel options={LoanzStructureUK.residentialStatus} />,
            "#TimeAtAddress":       <LoanzGenPanel options={LoanzStructureUK.timeAtAddress} />,
            "#Phone":               <LoanzGenPanel options={LoanzStructureUK.phoneUK} />,
            "#Email":               <LoanzGenPanel options={LoanzStructureUK.email} />,
            "#WorkIndustry":        <LoanzGenPanel options={LoanzStructureUK.workIndustry} />,
            "#WorkType":            <LoanzGenPanel options={LoanzStructureUK.workType} />,
            "#WorkExperience":      <LoanzGenPanel options={LoanzStructureUK.workExperienceUK} />,
            "#EmployerName":        <LoanzGenPanel options={LoanzStructureUK.employerName} />,
            "#JobTitle":            <LoanzGenPanel options={LoanzStructureUK.jobTitle} />,
            "#PayPeriod":           <LoanzGenPanel options={LoanzStructureUK.payPeriodUK} />,
            "#NetPay":              <LoanzGenPanel options={LoanzStructureUK.netPayUK} />,
            "#PayNextDate":         <LoanzGenPanel options={LoanzStructureUK.nextPayDate} />,
            "#PayFollowingDate":    <LoanzGenPanel options={LoanzStructureUK.followingPayDate} />,
            // "#Expenses":            <LoanzGenPanel options={LoanzStructureUK.expenses} />,
            // "#BankInformation":     <LoanzGenPanel options={LoanzStructureUK.bankInformationUK} />,
            "#Summary":             <LoanzGenPanel options={LoanzStructureUK.applicationSummary} />,
            "#Processing":          <LoanzProcessingUK />,
        },
        uk2: {
            "#Amount":              <LoanzGenPanel options={LoanzStructureUK.amountUK} />,
            // "#LoanDuration":        <LoanzGenPanel options={LoanzStructureUK.loanDuration} />,
            // "#Reason":              <LoanzGenPanel options={LoanzStructureUK.reasonUK} />,
            "#Title":               <LoanzGenPanel options={LoanzStructureUK.title} />,
            "#Name":                <LoanzGenPanel options={LoanzStructureUK.name} />,
            "#Birthdate":           <LoanzGenPanel options={LoanzStructureUK.birthdate} />,
            // "#MaritalStatus":       <LoanzGenPanel options={LoanzStructureUK.maritalStatus} />,
            // "#Dependents":          <LoanzGenPanel options={LoanzStructureUK.dependents} />,
            "#Address":             <LoanzGenPanel options={LoanzStructureUK.addressUK} />,
            // "#ResidentialStatus":   <LoanzGenPanel options={LoanzStructureUK.residentialStatus} />,
            // "#TimeAtAddress":       <LoanzGenPanel options={LoanzStructureUK.timeAtAddress} />,
            "#Phone":               <LoanzGenPanel options={LoanzStructureUK.phoneUK} />,
            "#Email":               <LoanzGenPanel options={LoanzStructureUK.email} />,
            // "#WorkIndustry":        <LoanzGenPanel options={LoanzStructureUK.workIndustry} />,
            // "#WorkType":            <LoanzGenPanel options={LoanzStructureUK.workType} />,
            // "#WorkExperience":      <LoanzGenPanel options={LoanzStructureUK.workExperienceUK} />,
            // "#EmployerName":        <LoanzGenPanel options={LoanzStructureUK.employerName} />,
            // "#JobTitle":            <LoanzGenPanel options={LoanzStructureUK.jobTitle} />,
            // "#PayPeriod":           <LoanzGenPanel options={LoanzStructureUK.payPeriodUK} />,
            "#NetPay":              <LoanzGenPanel options={LoanzStructureUK.netPayUK} />,
            // "#PayNextDate":         <LoanzGenPanel options={LoanzStructureUK.nextPayDate} />,
            // "#PayFollowingDate":    <LoanzGenPanel options={LoanzStructureUK.followingPayDate} />,
            // "#Expenses":            <LoanzGenPanel options={LoanzStructureUK.expenses} />,
            // "#BankInformation":     <LoanzGenPanel options={LoanzStructureUK.bankInformationUK} />,
            "#Summary":             <LoanzGenPanel options={LoanzStructureUK.applicationSummary2} />,
            "#Processing":          <LoanzProcessingUK />,
        },
        uk3: {
            "#Amount":              <LoanzGenPanel options={LoanzStructureUK.amountUK} />,
            "#LoanDuration":        <LoanzGenPanel options={LoanzStructureUK.loanDuration} />,
            "#Reason":              <LoanzGenPanel options={LoanzStructureUK.reasonUK} />,
            "#Title":               <LoanzGenPanel options={LoanzStructureUK.title} />,
            "#Name":                <LoanzGenPanel options={LoanzStructureUK.name} />,
            "#Birthdate":           <LoanzGenPanel options={LoanzStructureUK.birthdate} />,
            "#MaritalStatus":       <LoanzGenPanel options={LoanzStructureUK.maritalStatus} />,
            "#Dependents":          <LoanzGenPanel options={LoanzStructureUK.dependents} />,
            "#Address":             <LoanzGenPanel options={LoanzStructureUK.addressUK} />,
            "#ResidentialStatus":   <LoanzGenPanel options={LoanzStructureUK.residentialStatus} />,
            "#TimeAtAddress":       <LoanzGenPanel options={LoanzStructureUK.timeAtAddress} />,
            "#Phone":               <LoanzGenPanel options={LoanzStructureUK.phoneUK} />,
            "#Email":               <LoanzGenPanel options={LoanzStructureUK.email} />,
            "#WorkIndustry":        <LoanzGenPanel options={LoanzStructureUK.workIndustry} />,
            "#WorkType":            <LoanzGenPanel options={LoanzStructureUK.workType} />,
            "#WorkExperience":      <LoanzGenPanel options={LoanzStructureUK.workExperienceUK} />,
            "#EmployerName":        <LoanzGenPanel options={LoanzStructureUK.employerName} />,
            "#JobTitle":            <LoanzGenPanel options={LoanzStructureUK.jobTitle} />,
            // "#PayPeriod":           <LoanzGenPanel options={LoanzStructureUK.payPeriodUK} />,
            "#NetPay":              <LoanzGenPanel options={LoanzStructureUK.netPayUK} />,
            "#PayNextDate":         <LoanzGenPanel options={LoanzStructureUK.nextPayDate} />,
            "#PayFollowingDate":    <LoanzGenPanel options={LoanzStructureUK.followingPayDate} />,
            "#BankInformation":     <LoanzGenPanel options={LoanzStructureUK.bankInformationUK} />,
            "#Summary":             <LoanzGenPanel options={LoanzStructureUK.applicationSummary3} />,
            "#Processing":          <LoanzProcessingUK />,
        }, 
        us: {         
            "#Amount":              <LoanzGenPanel options={LoanzStructureUS.amountUSRange} />,
            "#Reason":              <LoanzGenPanel options={LoanzStructureUS.reasonUS} />,
            "#Name":                <LoanzGenPanel options={LoanzStructureUS.name} />,
            "#Birthdate":           <LoanzBirthdate />, 
            "#Email":               <LoanzGenPanel options={LoanzStructureUS.email} />,
            "#Phone":               <LoanzGenPanel options={LoanzStructureUS.phoneUS} />,
            "#DriversLicense":      <LoanzGenPanel options={LoanzStructureUS.driversLicense} />,
            "#SSN":                 <LoanzGenPanel options={LoanzStructureUS.ssn} />,
            "#ResidentialStatus":   <LoanzGenPanel options={LoanzStructureUS.residentialStatus} />,
            "#Address":             <LoanzGenPanel options={LoanzStructureUS.addressUS} />,
            "#TimeAtAddress":       <LoanzGenPanel options={LoanzStructureUS.timeAtAddress} />,
            "#PayPeriod":           <LoanzGenPanel options={LoanzStructureUS.payPeriodUS} />,
            "#NextPayDate":         <LoanzGenPanel options={LoanzStructureUS.nextPayDate} />,
            "#FollowingPayDate":    <LoanzGenPanel options={LoanzStructureUS.followingPayDate} />,
            "#BankAccountType":     <LoanzGenPanel options={LoanzStructureUS.bankAccountType} />,
            "#BankDirectDeposit":   <LoanzGenPanel options={LoanzStructureUS.bankDirectDeposit} />,
            "#IncomeType":          <LoanzGenPanel options={LoanzStructureUS.incomeType} />,
            "#ActiveMilitary":      <LoanzGenPanel options={LoanzStructureUS.activeMilitary} />,
            "#EmployerName":        <LoanzGenPanel options={LoanzStructureUS.employerName} />,
            "#EmployerPhone":       <LoanzGenPanel options={LoanzStructureUS.employerPhone} />,
            "#WorkExperience":      <LoanzGenPanel options={LoanzStructureUS.workExperience} />,
            "#NetPay":              <LoanzGenPanel options={LoanzStructureUS.netPayUS} />,
            "#UnsecuredDebt":       <LoanzGenPanel options={LoanzStructureUS.unsecuredDebtUS} />,
            "#Credit":              <LoanzGenPanel options={LoanzStructureUS.credit} />,
            "#AutoTitle":           <LoanzGenPanel options={LoanzStructureUS.autoTitle} />,
            "#BankAba":             <LoanzGenPanel options={LoanzStructureUS.bankAba} />,
            "#BankAccountNumber":   <LoanzGenPanel options={LoanzStructureUS.bankAccountNumber} />,
            "#Consent":             <LoanzGenPanel options={LoanzStructureUS.consent} />,
            "#Processing":          <LoanzProcessingUS />,
        },
        us2: {         
            "#Amount":              <LoanzGenPanel options={LoanzStructureUS.amountUSRange} />,
            "#Reason":              <LoanzGenPanel options={LoanzStructureUS.reasonUS2} />,
            "#Name":                <LoanzGenPanel options={LoanzStructureUS.name2} />,
            "#Birthdate":           <LoanzBirthdate />, 
            "#Email":               <LoanzGenPanel options={LoanzStructureUS.email} />,
            "#Phone":               <LoanzGenPanel options={LoanzStructureUS.phoneUS} />,
            "#DriversLicense":      <LoanzGenPanel options={LoanzStructureUS.driversLicense} />,
            "#SSN":                 <LoanzGenPanel options={LoanzStructureUS.ssn} />,
            "#ResidentialStatus":   <LoanzGenPanel options={LoanzStructureUS.residentialStatus} />,
            "#Address":             <LoanzGenPanel options={LoanzStructureUS.addressUS} />,
            "#TimeAtAddress":       <LoanzGenPanel options={LoanzStructureUS.timeAtAddress} />,
            "#PayPeriod":           <LoanzGenPanel options={LoanzStructureUS.payPeriodUS} />,
            "#NextPayDate":         <LoanzGenPanel options={LoanzStructureUS.nextPayDate} />,
            "#FollowingPayDate":    <LoanzGenPanel options={LoanzStructureUS.followingPayDate} />,
            "#BankAccountType":     <LoanzGenPanel options={LoanzStructureUS.bankAccountType} />,
            "#BankDirectDeposit":   <LoanzGenPanel options={LoanzStructureUS.bankDirectDeposit} />,
            "#IncomeType":          <LoanzGenPanel options={LoanzStructureUS.incomeType2} />,
            "#ActiveMilitary":      <LoanzGenPanel options={LoanzStructureUS.activeMilitary} />,
            "#EmployerName":        <LoanzGenPanel options={LoanzStructureUS.employerName} />,
            "#EmployerPhone":       <LoanzGenPanel options={LoanzStructureUS.employerPhone} />,
            "#WorkExperience":      <LoanzGenPanel options={LoanzStructureUS.workExperience} />,
            "#NetPay":              <LoanzGenPanel options={LoanzStructureUS.netPayUS} />,
            "#UnsecuredDebt":       <LoanzGenPanel options={LoanzStructureUS.unsecuredDebtUS} />,
            "#Credit":              <LoanzGenPanel options={LoanzStructureUS.credit} />,
            "#AutoTitle":           <LoanzGenPanel options={LoanzStructureUS.autoTitle} />,
            "#BankAba":             <LoanzGenPanel options={LoanzStructureUS.bankAba} />,
            "#BankAccountNumber":   <LoanzGenPanel options={LoanzStructureUS.bankAccountNumber} />,
            "#Consent":             <LoanzGenPanel options={LoanzStructureUS.consent} />,
            "#Processing":          <LoanzProcessingUS />,
        },
        usFlat: {
            "#Amount":              <LoanzGenPanel options={LoanzStructureUSFlatForm.amountUS} />,
            "#Email":               <LoanzGenPanel options={LoanzStructureUSFlatForm.email} />,
            "#Flatform":            <LoanzFlatFormUS />,
            "#BankAba":             <LoanzGenPanel options={LoanzStructureUSFlatForm.bankAba} />,
            "#Summary":             <LoanzGenPanel options={LoanzStructureUSFlatForm.consent} />,
            "#Processing":          <LoanzProcessingUS />,
        }
    };

    content = (state, props) => state.cssBootstrap && state.cssMain && <>
        <div className="main-content">
            {!["#DebtRelief"].includes(state.hash) && <PanelEffects.ProgressBar
                caption=" "
                barColor="#40AD87"
                className="progress-bar"
                style={{
                    borderStyle: "solid",
                    borderWidth: "2px", 
                    borderColor: "#ffffff",
                    backgroundColor: "rgb(233, 236, 239)",
                    borderRadius: "5px", 
                    padding: "2px", 
                    fontSize: "13px",
                    margin: "0 10px"
                }}
            />}
            <PanelEffects.Transition1 className={["#Flatform"].includes(state.hash) ? "container-md" : "panel-container"}>
                <div id="appForm" style={{marginTop: "15px"}} key={state.hash}>
                    <div className="appForm_inner">
                        <PanelEffects.Selector
                            selector={state.hash}
                            panels={CoreTools.switch(
                                [state.hash === "#DebtRelief", () => ({"#DebtRelief": <LoanzDebtRelief />})],
                                [props.version === "ca",          () => this.versions.ca],
                                [props.version === "uk",          () => this.versions.uk],
                                [props.version === "uk2",          () => this.versions.uk2],
                                [props.version === "uk3",          () => this.versions.uk3],
                                [props.version === "us",          () => this.versions.us],
                                [props.version === "us2",         () => this.versions.us2],
                                [props.version === "usFlat",      () => this.versions.usFlat],
                            )} 
                        />
                    </div>
                </div>
            </PanelEffects.Transition1>
        </div>
    </>;
}
