import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";
import FormValidate from "../../../services/FormValidate";
import Icon from "../../../directives/Icon";

let subValidate = null;
export default class LifeInsuranceGenPanel extends GenSet {
    continue = () => this.navigate(this.props.continue);
    validate = () => this.applicant.validate(isValid => isValid && (typeof(subValidate) === "function" ? subValidate() : true) ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    setOutput = event => {
        if (event.target.value === "0") {
            this.setVal({output: "0"});
        } else if (event.target.value === "5000") {
            this.setVal({output: "5,000+"});
        } else {
            this.setVal({output: event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")});
        }
    };
    phoneFormat = event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };
    yearOptions = (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year - 15; i > year - 100; i--) {years.push(i.toString());} return years;})();
    monthOptions = (() => {let months = []; for (let i = 1; i <= 12; i++) {months.push(i);} return [{value: "", caption: "Month"}, ...months.map(m => ({value: m < 10 ? "0" + m : m.toString(), caption: m}))];})();
    dayOptions = (() => {let days = []; for (let i = 1; i <= 31; i++) {days.push(i);} return [{value: "", caption: "Day"}, ...days.map(m => ({value: m < 10 ? "0" + m : m.toString(), caption: m}))];})();
    
    content = (state, props) => <div className="question-Box" data-wdth="10">
        <div className="step-inrBox">
            {props.options.title && <p className="step-hdng">{props.options.title}</p>}
            {props.options.personaltitle && <p className="step-hdng">{this.applicant.field("first_name")}, where can we send your free life quote?</p>}
                {Array.isArray(props.options.fields) && <div className="option-box1">
                    {props.options.fields.map((f, i) => CoreTools.switch(
                        f.type,      
                        ["input", () => <div className="plnfrmfield"><label>{f.placeholder}</label><AppInput.Input name={f.name} placeholder={f.placeholder} onValidate={f.onValidate} className="plninput" failClass="invalid" /></div>],
                        ["radio", () => (f.values || []).map(o => <label className="option-col next-btn" key={o}><AppInput.Radio name={f.name} value={o} onClick={this.continue} /><span className="opt-rad"></span><div className="desc-opt">{o}</div></label>)],
                        ["phone", () => <div className="plnfrmfield"><label>{f.placeholder}</label><AppInput.Input type="text" name={f.name} className="plninput" placeholder={f.placeholder} onValidate={f.onValidate} onChange={FormValidate.phoneFormat} failClass="invalid" /></div>]
                    ))}
                </div>}
                {Array.isArray(props.options.fields) && <div className="option-box2">
                    {props.options.fields.map((f, i) => CoreTools.switch(
                        f.type,      
                        ["birthdate", () => <div><div className="plnfrmfield dob-field dateMargin" style={{marginRight: "2%"}}><label>Month</label><AppInput.Select name="dobMonth" className="plninput datefld dwn-arw" options={this.monthOptions} onValidate={v => v} failClass="invalid" /></div><div className="plnfrmfield dob-field dateMargin" style={{marginRight: "2%"}}><label>Day</label><AppInput.Select name="dobDay" className="plninput datefld dwn-arw" options={this.dayOptions} onValidate={v => v} failClass="invalid" /></div><div className="plnfrmfield dob-field"><label>Year</label><AppInput.Select name="dobYear" className="plninput datefld dwn-arw" options={this.yearOptions} onValidate={v => v} failClass="invalid" /></div></div>]
                    ))}
                </div>}
                {Array.isArray(props.options.fields) && <div className="option-box3">
                    {props.options.fields.map((f, i) => CoreTools.switch(
                        f.type,      
                        ["image", () => (f.values || []).map(o => <label class="option-col next-btn" key={o.value}><AppInput.Radio name={f.name} value={o.value} onClick={this.continue} /><span className="opt-rad"></span><div className="desc-opt"><div>{o.image}{o.label && <p>{o.label}</p>}</div></div></label>)]
                    ))}
                </div>}
            {props.options.continue && <button className="pg-btn next-btn" onClick={this.validate}>{props.options.continue}</button>}
            {props.options.back && <div><p className="btn-back" onClick={() => this.navigate(this.props.previous)}><span><Icon name="back" />{props.options.back}</span></p></div>}
            {props.options.consent && <div className="term-text"><p className="text-center widest">By clicking "Continue", I have read and accept the <a id="privacy-anchor-tag" href="https://loanz.com/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>. I also acknowledge having read the <a id="privacy-anchor-tag" href="https://loanz.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and agree to the terms herein.</p></div>}
            {props.options.processingAnimation && <div className="card card-options"><div className="card-body"><div><img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{display: "block", margin: "20px auto", width: "100%"}} /></div></div></div>}
        </div>
    </div>;
}
