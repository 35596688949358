import Icon from "../../directives/Icon";
import Applicant from "../../services/Applicant";
import Lang from "../../services/Lang";
import ClrLanguage from "./ClrLanguage";
import ClrGenPanel from "./panels/ClrGenPanel";
import FormValidate from "../../services/FormValidate";

const ClrPanels = () => {
    ClrLanguage();
    return {
        province: time => <ClrGenPanel content={{
            title: Lang("titles").province,
            time: time,
            elements: [
                {type: "split", name: "province", options: Lang("provinceOptions")}
            ]
        }} />,

        gender: time => <ClrGenPanel content={{
            title: Lang("titles").gender,
            time: time,
            elements: [
                {type: "2Image", name: "gender", options: [
                    {value: "Male", image: <Icon name="male" />, caption: Lang("gender").male},
                    {value: "Female", image: <Icon name="female" />, caption: Lang("gender").female}
                ]}
            ]
        }} />,

        age: time => <ClrGenPanel content={{
            title: Lang("titles").age,
            time: time,
            elements: [
                {
                    type: "radio",
                    name: "age_range",
                    options: ["18 - 25", "26 - 45", "46 - 65", "66 - 74", "75+"].map(age => ({value: age, caption: age}))
                }
            ]
        }} />,

        dob: time => <ClrGenPanel content={{
            title: Lang("titles").dob,
            time: time,
            elements: [
                {
                    type: "select",
                    name: "dobDay",
                    options: (() => {let days = []; for (let i = 1; i <= 31; i++) {days.push(i);} return [{value: "", caption: "Day"}, ...days.map(m => ({value: m < 10 ? "0" + m : m.toString(), caption: m}))];})(),
                    onValidate: v => v
                },
                {
                    type: "select",
                    name: "dobMonth",
                    options: (() => {let months = []; for (let i = 1; i <= 12; i++) {months.push(i);} return [{value: "", caption: "Month"}, ...months.map(m => ({value: m < 10 ? "0" + m : m.toString(), caption: m}))];})(),
                    onValidate: v => v
                },
                {
                    type: "select",
                    name: "dobYear",
                    options: (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year - 15; i > year - 100; i--) {years.push(i.toString());} return years;})(),
                    onValidate: v => v
                },
                {
                    type: "continue",
                    caption: Lang("continue")
                }
            ],
            inValidate: () => {
                Applicant().setField("dob", [Applicant().field("dobYear"), Applicant().field("dobMonth"), Applicant().field("dobDay")].join("-"));
            }
        }} />,

        dob2: time => <ClrGenPanel content={{
            title: Lang("titles").dob,
            time: time,
            elements: [
                {
                    type: "dobLegacy",
                    caption: "Continue"
                }
            ]
        }} />,

        insuranceAmount: time => <ClrGenPanel content={{
            title: Lang("titles").amount,
            subTitle: Lang("titles").amountSub,
            time: time,
            elements: [
                {
                    type: "radio",
                    name: "insurance_amount",
                    options: [
                        {value: "1500000", caption: "$1 - $250,000"},
                        {value: "500000", caption: "$250,001 - $500,000"},
                        {value: "750000", caption: "$500,001 - $750,000"},
                        {value: "1000000", caption: "$750,001 - $1,000,000"},
                        {value: "1500000", caption: "$1,000,001 - $1,500,000"}
                    ]
                }
            ]
        }} />,

        insuranceType: time => <ClrGenPanel content={{
            title: Lang("titles").type,
            time: time,
            elements: [
                {
                    type: "radio",
                    name: "insurance_type",
                    options: [
                        {value: "Term", caption: Lang("insuranceTypes").term},
                        {value: "Permanent", caption: Lang("insuranceTypes").permanent},
                        {value: "Not Sure", caption: Lang("insuranceTypes").notSure}
                    ]
                }
            ]
        }} />,

        smoking: time => <ClrGenPanel content={{
            title: Lang("titles").smoke,
            time: time,
            elements: [
                {type: "2Image", name: "smoking_status", options: [
                    {value: "No", image: <Icon name="non-smoking" />, caption: Lang("no")},
                    {value: "Yes", image: <Icon name="smoking" />, caption: Lang("yes")}
                ]}
            ]
        }} />,

        contactInformation: time => <ClrGenPanel content={{
            title: Lang("titles").contact,
            subTitle: Lang("titles").contactSub,
            time: time,
            elements: [
                {type: "textInput", name: "first_name", placeholder: Lang("contact").firstName, onValidate: FormValidate.isName},
                {type: "textInput", name: "last_name", placeholder: Lang("contact").lastName, onValidate: FormValidate.isName},
                {type: "textInput", name: "email", placeholder: Lang("contact").email, onValidate: FormValidate.isEmail},
                {type: "textInput", name: "phone", placeholder: Lang("contact").phone, onValidate: FormValidate.isPhone, onChange: FormValidate.phoneFormat},
                {type: "continue", caption: Lang("completeMyApplication")},
                {type: "content", content: Lang("agreement")}
            ]
        }} />,

        contactName: time => <ClrGenPanel content={{
            title: Lang("titles").contact,
            subTitle: Lang("titles").contactSub,
            time: time,
            elements: [
                {type: "textInput", name: "first_name", placeholder: Lang("contact").firstName, onValidate: FormValidate.isName},
                {type: "textInput", name: "last_name", placeholder: Lang("contact").lastName, onValidate: FormValidate.isName},
                {type: "continue", caption: Lang("continue")}
            ]
        }} />,

        contactEmail: time => <ClrGenPanel content={{
            title: Lang("titles").contact,
            subTitle: Lang("titles").contactSub,
            time: time,
            elements: [
                {type: "textInput", name: "email", placeholder: Lang("contact").email, onValidate: FormValidate.isEmail},
                {type: "continue", caption: Lang("continue")}
            ]
        }} />,

        contactPhone: time => <ClrGenPanel content={{
            title: Lang("titles").contact,
            subTitle: Lang("titles").contactSub,
            time: time,
            elements: [
                {type: "textInput", name: "phone", placeholder: Lang("contact").phone, onValidate: FormValidate.isPhone, onChange: FormValidate.phoneFormat},
                {type: "continue", caption: Lang("completeMyApplication")},
                {type: "content", content: Lang("agreement")}
            ]
        }} />,

        nameAndGender: time => <ClrGenPanel content={{
            title: "What is your Name and Gender",
            time: time,
            elements: [
                {type: "textInput", name: "first_name", placeholder: Lang("contact").firstName, onValidate: FormValidate.isName},
                {type: "textInput", name: "last_name", placeholder: Lang("contact").lastName, onValidate: FormValidate.isName},
                {
                    type: "split",
                    name: "insurance_type",
                    options: [
                        {value: "Male", caption: <><Icon name="male" style={{height: "40px"}} /> {Lang("gender").male}</>},
                        {value: "Female", caption: <><Icon name="female" style={{height: "40px"}} /> {Lang("gender").female}</>}
                    ]
                },
                {type: "continue", caption: Lang("continue")}
            ]
        }} />
    };
};
export default ClrPanels;