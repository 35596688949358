import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import JugglingDebtComponents from "../inserts/JugglingDebtComponents";

export default class JugglingDebtMonthlyIncome extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };
    setOutput = event => {
        this.setVal({
            dollar:{
                val: "$" + event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            }
        });
    };
    content = (state, props) => <JugglingDebtComponents.Card
        title={props.title || "What is your current Monthly Gross Income?"}
        controls={<JugglingDebtComponents.Controls continue={this.continue} buttonText={props.slider ? props.buttonText : false} disclaimer={props.disclaimer} />}
        counter={props.counter}
    >
        <div className="form-row">
            {props.greaterThan
                ?<>
                    <div className="col-12">
                        <label className="btn btn-option small">
                            <AppInput.Radio name="monthly_income" value={"Above $"+props.greaterThan} onClick={this.continue} />
                            <span>Yes</span>
                        </label>
                        <label className="btn btn-option small">
                            <AppInput.Radio name="monthly_income" value={"$0 - $"+props.greaterThan} onClick={this.continue} />
                            <span>No</span>
                        </label>
                    </div>
                </>
                :props.slider
                    ? <div className="col-12">
                        <div className="form-slider">
                            <span className="form-value two-value">
                                <div className="dollar">{state.dollar?.val}</div>
                            </span>
                            <AppInput.Range
                                style={{sidplay: "block"}}
                                name="monthly_income_range"
                                onValidate={v => Number(v)}
                                failClass="invalid"
                                onChange={this.setOutput}
                                onLoad={this.setOutput}
                                min={props?.min || 500}
                                max="10000"
                                step="500"
                                defaultValue="2000"
                            />
                            <div className="slider-values">
                                <span>${props?.min || 0}</span>
                                <span>$10,000+</span>
                            </div>
                        </div>
                    </div>
                    : <>
                        <div className="col-12">
                            <label className="btn btn-option">
                                <AppInput.Radio name="monthly_income" value="Above $5000" onClick={this.continue} />
                                <span>Above $5000</span>
                            </label>
                        </div>
                        <div className="col-12">
                            <label className="btn btn-option">
                                <AppInput.Radio name="monthly_income" value="$3501 - $5000" onClick={this.continue} />
                                <span>$3501 - $5000</span>
                            </label>
                        </div>
                        <div className="col-12">
                            <label className="btn btn-option">
                                <AppInput.Radio name="monthly_income" value="$2801 - $3500" onClick={this.continue} />
                                <span>$2801 - $3500</span>
                            </label>
                        </div>
                        <div className="col-12">
                            <label className="btn btn-option">
                                <AppInput.Radio name="monthly_income" value="$2301 - $2800" onClick={this.continue} />
                                <span>$2301 - $2800</span>
                            </label>
                        </div>
                        <div className="col-12">
                            <label className="btn btn-option">
                                <AppInput.Radio name="monthly_income" value="$1801 - $2300" onClick={this.continue} />
                                <span>$1801 - $2300</span>
                            </label>
                        </div>
                        <div className="col-12">
                            <label className="btn btn-option">
                                <AppInput.Radio name="monthly_income" value="$1001 - $1800" onClick={this.continue} />
                                <span>$1001 - $1800</span>
                            </label>
                        </div>
                        <div className="col-12">
                            <label className="btn btn-option">
                                <AppInput.Radio name="monthly_income" value="Below $1000" onClick={this.continue} />
                                <span>Below $1000</span>
                            </label>
                        </div>
                    </>
            }
        </div>
    </JugglingDebtComponents.Card>;
}