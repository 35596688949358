import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import JugglingDebtComponents from "../inserts/JugglingDebtComponents";

export default class JugglingDebtUnsecuredDebtAmount extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };
    
    setOutput = event => {
        this.setVal({
            dollar:{
                val: "$" + event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            }
        });
    };

    content = (state, props) => <JugglingDebtComponents.Card
        title="How Much Total Unsecured Debt Do You Have?"
        text="Enter any other unsecured debt you may have (Credit Card, Payday Loans, Personal Loans, Line of Credit (unsecured), Student Loans, CERB, Any Government Taxes (GST/HST), Payroll Taxes, Personal Taxes to CRA, etc.)"
        controls={<JugglingDebtComponents.Controls continue={this.continue} buttonText={props.buttonText} disclaimer={props.disclaimer} />}
    >
        <div className="form-row">
            <div className="col-12">
                <div className="form-slider">
                    <span className="form-value two-value">
                        <div className="dollar">{state.dollar?.val}</div>
                    </span>
                    <AppInput.Range
                        style={{sidplay: "block"}}
                        name="unsecured_debt_amount_range"
                        onValidate={v => Number(v)}
                        failClass="invalid"
                        onChange={this.setOutput}
                        onLoad={this.setOutput}
                        min="1000"
                        max="100000"
                        step="1000"
                        defaultValue="50000"
                    />
                    <div className="slider-values">
                        <span>$0</span>
                        <span>$100,000+</span>
                    </div>
                </div>
            </div>
        </div>
    </JugglingDebtComponents.Card>;
}