import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import GoogleAddress from "../../../directives/GoogleAddress";
import CoreTools from "../../../services/CoreTools";
import OptionLists from "../../../services/OptionLists";

export default class CarsfastAddress extends GenSet {
    title = CoreTools.emit("panelTitle", "What is your address?");
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };

    stateOptions = [
        {value: "", caption: "Select State"},
        ...OptionLists.States.map(i => ({value: i.short, caption: i.long}))
    ];

    zipCodeFormat = event => {
        if (typeof(event.target.value) === "string") {
            const proper = event.target.value.replace(/[^0-9]/gi, "").substring(0, 5);
            if (event.target.value !== proper) {
                const cPos = event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = cPos;
                event.target.selectionEnd = cPos;
            }
        }
    };

    zipCodeValidate = value => (
        typeof(value) === "string" &&
        value.length === 5 &&
        CoreTools.match(value.substring(0, 5), /[0-9]/g)
    );

    content = (state, props, applicant) => <div className="card card-options">
        <div className="card-body">
        <div className="form-row text-center mb-3">
                <div className="col-12">
                    <small className="addTip">Enter your physical address in the box below to quickly autocomplete all required fields.</small>
                    <div id="gm-autocomplete-wrap" className="form-field mb-3">
                        <GoogleAddress
                            country="us"
                            className="form-control text-center"
                            placeholder="Enter Address Here to Search"
                            onSelect={address => {
                                applicant.setField("google_address", address.full);
                                applicant.setField("street_number", address.number);
                                applicant.setField("street_name", address.street);
                                applicant.setField("city", address.city);
                                applicant.setField("state", address.stateShort);
                                applicant.setField("zip_code", address.zipcode);
                            }}
                        />
                    </div>
                    <small className="addTip">or manually enter below:</small>
                </div>
            </div>
            <div className="card-fields" id="addressFields" >
                <div className="form-row">
                    <div className="col-lg-6 mb-1">
                        <AppInput.Input type="text" name="street_number" placeholder="Street Number" onValidate={v => v} className="form-control text-field" failClass="invalid" />
                    </div>
                    <div className="col-lg-6 mb-1">
                        <AppInput.Input type="text" name="street_name" placeholder="Street Name" onValidate={v => v} className="form-control text-field" failClass="invalid" />
                    </div>
                    <div className="col-lg-6 mb-1">
                        <AppInput.Input type="text" name="unit" placeholder="Unit/Apt/Suite" className="form-control" />
                    </div>
                    <div className="col-lg-6 mb-1">
                        <AppInput.Input type="text" name="city" placeholder="City" onValidate={v => v} className="form-control text-field" failClass="invalid" />
                    </div>
                    <div className="col-lg-6 mb-1">
                        <AppInput.Select type="text" name="state" options={this.stateOptions} className="form-control" onValidate={v => v} failClass="invalid" />			
                    </div>
                    <div className="col-lg-6 mb-1">
                        <AppInput.Input type="text" name="zip_code" placeholder="Postal Code" className="form-control" onValidate={this.zipCodeValidate} failClass="invalid" onChange={this.zipCodeFormat} />
                    </div>
                </div>
            </div>
            <div className="card-footer text-center mt-4">
                <button onClick={this.continue} className="mx-auto btn btn-navigate">Continue</button>
            </div>
        </div>
    </div>;

}