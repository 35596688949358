import GenSet from "../../../services/GenSet";
import CoreTools from "../../../services/CoreTools";
import React from "react";
import SurexComponents from "../inserts/SurexComponents";
import Api from "../../../services/Api";

class YearSelector extends GenSet {
    init = this.setVal({years: []});
    onMount = (state, props) => {
        Api("https://form.consumergenius.com/api/surexVehicles/years").json({}).fetch(res => this.setVal({years: res.data.years}, () => setTimeout(CoreTools.centerForm, 50)));
    };
    content = (state, props) => <div>
        {state.years.map(year => <button key={year} type="button" className="btn btn-md btn-primary m-1" onClick={() => props.onSelect(year)}><b>{year}</b></button>)}
    </div>;
}

class MakeSelector extends GenSet {
    init = this.setVal({makes: [], other: false});
    primaryMakes = ['BUICK', 'CHEVROLET', 'CHRYSLER', 'DODGE/RAM', 'FORD', 'GMC', 'HONDA', 'HYUNDAI', 'JEEP', 'KIA', 'NISSAN', 'TOYOTA'];

    onMount = (state, props) => {
        Api("https://form.consumergenius.com/api/surexVehicles/makes").json({year: props.year}).fetch(res => {
            let apiData = res.data.makes;
            if (Array.isArray(apiData) && !apiData.map(m => typeof(m) === "string").includes(false) && apiData.length) {
                this.setVal({makes: apiData}, () => setTimeout(CoreTools.centerForm, 50));
            } else {
                props.onReset();
            }
        });
    };

    content = (state, props) => <div>
        <div className="two-columns">
            {state.makes.filter(m => state.other ? !this.primaryMakes.includes(m) : this.primaryMakes.includes(m)).map(make => <button key={make} type="button" className="btn btn-md btn-primary m-1 custom-width" onClick={() => props.onSelect(make)}><b>{make}</b></button>)}
        </div>
        <div className="two-columns">
            <button className="btn btn-md btn-secondary m-1 custom-width" onClick={() => this.setVal({other: !state.other})}>OTHER</button>
        </div>
    </div>;
}

class ModelSelector extends GenSet {
    init = this.setVal({models: [], reduced: false});
    onMount = (state, props) => {
        Api("https://form.consumergenius.com/api/surexVehicles/models").json({year: props.year, make: props.make}).fetch(res => {
            if (Array.isArray(res.data.models) && !res.data.models.map(m => typeof(m) === "string").includes(false) && res.data.models.length) {
                this.setVal({models: res.data.models});
            } else {
                props.onReset();
            }
        });
    };

    content = (state, props) => state.reduced || state.models.length < 15 ? <div className="two-columns">
        {Array.isArray(state.models) && state.models.map(model => <button key={model} type="button" className="btn btn-md btn-primary m-1 custom-width" onClick={() => props.onSelect(model)}><b>{model}</b></button>)}
    </div> : <div className="two-columns">
        {Array.isArray(state.models) && state.models.map(m => m.split(" ")[0]).reduce((a, c) => {
            if (!a.includes(c)) {a.push(c);}
            return a;
        }, []).map(model => <button key={model} type="button" className="btn btn-md btn-primary m-1 custom-width" onClick={() => this.setVal({models: state.models.filter(m => m.split(" ")[0] === model), reduced: true})}><b>{model}</b></button>)}
    </div>;
}

class FinishPanel extends GenSet {
    onMount = () => setTimeout(CoreTools.centerForm, 50);
    content = (state, props) => <div>
        <div className="form-row justify-content-center">
            <h1 className="text-primary text-center">{props.year} {props.make} {props.model}</h1>
        </div>
        <div className="text-center mt-4">
            <button type="button" onClick={props.onReset} className="btn btn-md text-primary">Select a different vehicle</button>
        </div>
    </div>;
}

export default class SurexVehicleOptionsV2 extends GenSet {
    init = this.setVal({year: true, make: true, model: true});

    onApplicant = (state, props, applicant) => {
        this.setVal({
            year: applicant.field("vehicle_year"),
            make: applicant.field("vehicle_make"),
            model: applicant.field("vehicle_model")
        });
    };
    
    continue = () => this.navigate(this.props.continue);

    content = (state, props) => <SurexComponents.Card
        title={CoreTools.switch(
            [!state.year, "What year is your vehicle?"],
            [!state.make, "What make is your vehicle?"],
            [!state.model, "What model is your vehicle?"],
            [true, "Your vehicle is a:"]
        )}
        controls={<SurexComponents.Controls continue={this.continue} buttonText="Continue" disabled={!state.year || !state.make} />}
    >
        <div style={{textAlign: 'center'}}>
            {CoreTools.switch(
                [() => !state.year, () => <YearSelector
                    title="test"
                    onSelect={year => this.setVal({year: year}, () => this.applicant.setField("year", year))}
                />],
                [() => !state.make, () => <MakeSelector
                    year={state.year}
                    onSelect={make => this.setVal({make: make}, () => {this.applicant.setField("make", make); })}                    
                    onReset={() => this.setVal({year: false, make: false, model: false}, () => setTimeout(CoreTools.centerForm, 50))}
                />],
                [() => !state.model, () => <ModelSelector
                    year={state.year}
                    make={state.make}
                    onSelect={model => this.setVal({model: model}, () => this.applicant.setField("model", model))}
                    onReset={() => this.setVal({year: false, make: false, model: false})}
                />],
                [true, () => <FinishPanel
                    year={state.year}
                    make={state.make}
                    model={state.model}
                    onReset={() => this.setVal({year: false, make: false, model: false}, () => setTimeout(CoreTools.centerForm, 50))}
                />]
            )}
        </div>
    </SurexComponents.Card>;
}

