import GenSet from "../../../services/GenSet";
import SurexComponents from "../inserts/SurexComponents";
import Icon from "../../../directives/Icon";

export default class SurexVehicleNumber extends GenSet {
    
    continue = () => this.navigate(this.props.continue);
    
    content = (state, props) => <SurexComponents.Card
        title="How many vehicles do you want to insure?"
    >
        <div className="form-row">
                <div className="col-md-4 mb-2">
                    <button className="numButton" name="residence_type" value="1" onClick={this.continue}><Icon name="1car-blue" /><span>1</span></button>
                </div>
                <div className="col-md-4 mb-2">
                    <button className="numButton" name="residence_type" value="2" onClick={this.continue}><Icon name="2cars-blue" /><span>2</span></button>
                </div>
                <div className="col-md-4 mb-2">
                    <button className="numButton" name="residence_type" value="3" onClick={this.continue}><Icon name="3cars-blue" /><span>3+</span></button>
                </div>
        </div>
    </SurexComponents.Card>;
}