import React, { useState, useEffect } from "react";
import CoreTools from "../../../services/CoreTools";

export default function LoadingPanel(props) {
    const [loadingState, setLoadingState] = useState({});

    useEffect(() => {
        const timeoutIds = [
            { key: 'load1', timeout: 1000 },
            { key: 'load2', timeout: 1500 },
            { key: 'load3', timeout: 3000 },
            { key: 'load4', timeout: 3500 },
            { key: 'load5', timeout: 5000 },
            { key: 'load6', timeout: 5500 }
            ].map(({ key, timeout }) => setTimeout(
            () => setLoadingState(prevState => ({...prevState, [key]: true})),
            timeout
        ));
        return () => timeoutIds.forEach(clearTimeout);
    }, []);
    

    useEffect(() => {
        const next = () => window.location.href = props.continue;

        if (loadingState.load1 && loadingState.load2 && loadingState.load3 && loadingState.load4 && loadingState.load5 && loadingState.load6) {
            setTimeout(() => {
                next();
            }, 1000); 
        }
    });

    return <div style={{margin: '50px 0'}}>
        <img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading5.gif"} alt="Processing" className="loader-img" />
        <ul className="loader_list">
            <li>
                <h3 style={{ display: loadingState.load1 ? 'block' : 'none' }}>Submitting your response</h3>
                <p style={{ display: loadingState.load2 ? 'block' : 'none' }}><img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/check.png"} alt="Processing"/>Submitted </p>
            </li>
            <li>
                <h3 style={{ display: loadingState.load3 ? 'block' : 'none' }}>Checking for providers in your area </h3>
                <p style={{ display: loadingState.load4 ? 'block' : 'none' }}><img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/check.png"} alt="Processing"/><strong>(3)</strong> certified providers found </p>
            </li>
            <li>
                <h3 style={{ display: loadingState.load5 ? 'block' : 'none' }}>Verifying customer ratings & reviews </h3>
                <p style={{ display: loadingState.load6 ? 'block' : 'none' }}><img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/check.png"} alt="Processing"/>Verified reviews found</p>
            </li>
        </ul>
    </div>;
}
