import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";

export default class SurexLicenseStatus extends GenSet {
    continue = (state, props) => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };
    
    content = (state, props) => <SurexComponents.Card
        title="What's your driver's license status?"
    >
        <div className="form-row justify-content-center">
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="license_status" value="Active" onClick={this.continue}/>
                    <span>Active</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="license_status" value="Expired" onClick={this.continue}/>
                    <span>Expired</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="license_status" value="Revoked" onClick={this.continue}/>
                    <span>Revoked</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="license_status" value="Suspended" onClick={this.continue}/>
                    <span>Suspended</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="license_status" value="Unlicensed" onClick={this.continue}/>
                    <span>Unlicensed</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="license_status" value="Learner" onClick={this.continue}/>
                    <span>Learner</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="license_status" value="International" onClick={this.continue}/>
                    <span>International</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="license_status" value="Unknown" onClick={this.continue}/>
                    <span>Unknown</span>
                </label>
            </div>
        </div>
    </SurexComponents.Card>;
}
