import React from "react";
import CoreTools from "../services/CoreTools";

const percent = (base, total, current) => {
  if (typeof(total) === "number" && typeof(current) === "number" && total > 0) {
    const p = Math.ceil(base / total * current);
    return p > base ? base : p;
  } else {
    return 0;
  }
};

const CircleBar = props => {
  const [percentage, setPercentage] = React.useState(0);
  const renderPercent = (total, current) => {
    setPercentage(percent(100, total - 1, current - 1));
    const degree = percent(360, total - 1, current - 1);
    const progressRight = (degree < 180 ? degree : 180) + "deg";
    const progressLeft = (degree > 180 ? degree - 180 : 0) + "deg";
    CoreTools.setTheme({"--progressRight": progressRight, "--progressLeft": progressLeft});
  };

  React.useEffect(() => {
    CoreTools.setTheme({"--progressBarColor": props.color || "red"});
    CoreTools.importStyle((CoreTools.state.isLocal ? "" : "https://form.consumergenius.com") + "/css/CircleBar.css");
    const next = () => CoreTools.state.currentPageNumber ? renderPercent(CoreTools.state.totalPages, CoreTools.state.currentPageNumber) : setTimeout(next);
    next();
  }, [props.color]);
  
  React.useEffect(() => CoreTools.on("pageSelect", (name, current, total) => {
    setTimeout(() => {renderPercent(total, current)});
  }), [setPercentage]);

  return (
    <div id="circleBar">
      <div className="row d-flex justify-content-center">
        <div className="col-md-6" style={{display: "flex", justifyContent: "center"}}>
          <div className="progress blue">
            <span className="progress-left">
              <span className="progress-bar"></span>
            </span>
            <span className="progress-right">
              <span className="progress-bar"></span>
            </span>
              <div className="progress-value">{percentage + "%"}</div> 
              <div className="text-value">COMPLETED</div> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircleBar;
