import CoreTools from "../../../services/CoreTools";
import GenSet from "../../../services/GenSet";
import SurexComponents from "../inserts/SurexComponents";
// import FieldValidate from "../../../services/FieldValidate";

export default class SurexProcessing extends GenSet {
    onMount = (state, props) => this.applicant.post("surex", "submit", {});

    content = (state, props) => <SurexComponents.Card
        title="We are processing your submission..."
    >
        <div>
            <img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{display: "block", margin: "20px auto"}} />
        </div>
    </SurexComponents.Card>;
}
