import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";
import TrustedFormCert from "../../../directives/TrustedFormCert";
import FormValidate from "../../../services/FormValidate";

export default class NhpusContactInformation extends GenSet {
    continue = () => {
        this.applicant.setField("consent", true);
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };
    phoneOptions = [{caption: "Mobile", value: "mobile"}, {caption: "Landline", value: "landline"}];
    phoneFormat = event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };

    consentCompany = "Best Lenders For USA Inc.";

    content = (state, props) => <fieldset className="card card-inputs last">
        <div className="card-body">
            <h5 className="card-title">Great News! We have matched you with a mortgage lender.</h5>
            <p className="card-text text-center">Enter your contact info so we can personalize your results.</p>
            <div className="card-fields">
                <TrustedFormCert>
                    <div className="form-row">
                        <div className="col-12 mb-2">
                            <AppInput.Input type="text" name="first_name" className="form-control text-field" placeholder="First Name" onValidate={FormValidate.isName} failClass="invalid" />
                        </div>
                        <div className="col-12 mb-2">
                            <AppInput.Input type="text" name="last_name" className="form-control text-field" placeholder="Last Name" onValidate={FormValidate.isName} failClass="invalid" />
                        </div>
                        <div className="col-12 mb-2">
                            <AppInput.Input type="email" name="email" className="form-control text-field" placeholder="Email Address" onValidate={FormValidate.isEmail} failClass="invalid" />
                        </div>
                        <div className="col-12 mb-2">
                            <AppInput.Input type="tel" name="phone" className="form-control text-field" placeholder="Mobile Phone Number" onChange={this.phoneFormat} onValidate={FormValidate.isPhone} failClass="invalid" />
                        </div>
                    </div>
                </TrustedFormCert>
            </div>
            <div className="card-footer text-center mt-4">
                <button type="submit" className="btn btn-complete" onClick={this.continue}><span>Complete My Application</span></button>
                <p className="term-txt text-center mt-1"><b>By clicking "Complete My Application", I have read and accept the <a href="https://lendingarch.ca/terms-and-conditions/" target="_blank" rel="noreferrer">Terms of Use</a>. I also acknowledge having read the <a href="https://lendingarch.ca/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> and agree to the terms herein.</b></p>
                
                <label htmlFor="leadid_tcpa_disclosure">
                    <ul className="term-txt mt-2 text-left">
                        <li>I consent to be contacted and receive informational and marketing communications and messages by {this.consentCompany} and one or more of its <a href="/service-providers" target="_blank">service providers</a> about mortgage services by a live agent, artificial, automated, or pre-recorded voice, by email and by SMS text at my residence, mobile, or other phone number, using any number or address I have provided, or that I will provide in the future (of which I hereby represent I am the owner) (collectively, “Contact Info”). Those communications may be dialed manually or by a predictive dialer. For SMS messages, I acknowledge that standard message and data rates may apply.</li>
                        <li>I consent to be contacted even if my Contact Info appears on a {this.consentCompany} or its <a href="/service-providers" target="_blank">service providers</a> Do Not Call / Do Not Email List, a State or National Do Not Call Registry, or any other Do Not Contact List; I will promptly notify {this.consentCompany} in the event that I stop using a particular number or address that I have provided to {this.consentCompany}. Consent to the contact in this paragraph is not a condition to purchase services and I acknowledge that I may revoke this consent at any time, by emailing us at <a href="/cdn-cgi/l/email-protection#34415a4741564757465d56517458515a505d5a535546575c1a575b59" target="_blank"><span className="__cf_email__" data-cfemail="d8adb6abadbaabbbaab1babd98b4bdb6bcb1b6bfb9aabbb0f6bbb7b5">[email&#160;protected]</span></a> , or by any reasonable means.</li>
                        <li>I consent to and acknowledge that automated messages may be played when the telephone is answered whether by me or someone else.</li>
                        <li>I consent to and acknowledge that {this.consentCompany} and one or more of it's <a href="/service-providers" target="_blank">service providers</a> may listen and/or record and retain recordings of calls between {this.consentCompany} and one or more service providers and me for quality assurance or other purposes as permitted by applicable laws without additional notice.</li>
                        <li>I agree and acknowledge that {this.consentCompany} may disclose my personal data to one or more third parties, such as mortgage partners, <a href="/service-providers" target="_blank">service providers</a> and other affiliates, and that {this.consentCompany} can use this data for various purposes, including, but not limited to, marketing, analytics, product and service improvement, and to enhance user experience. Disclosures may include third party lenders, their brokers and service providers and I expressly consent to be contacted by {this.consentCompany}, its agents, representatives, affiliates, third parties, or anyone calling for any and all purposes arising out of or relating to my inquiry with {this.consentCompany}, whether for the product or service I am applying for, or for any other product or service that {this.consentCompany} may deem a potential fit for me, based on the information I have submitted.</li>
                        <li>Our services may include a Short Message Service (“SMS”) which may deliver up to five messages per day to your wireless device. We may use your information to contact you about your info, purchases, Website updates, conduct surveys, or informational and service-related communications, including important security updates. You may remove your information at any time by replying “STOP” to the SMS text message you received. After you send the SMS message “STOP” to us, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from us. If you want to join again, just sign up as you did the first time and we will start sending SMS messages to you again. For help, please reply to a text with HELP. Message and data rates may apply, depending on your cell phone plan. Carriers are not liable for delayed or undelivered messages. Alternatively, you may submit your request by email to us, including the email address and phone number you registered with us, or by any reasonable means.</li>
                        <li>I consent to receive disclosures and communications about my loan inquiry in electronic form.</li>
                        <li>I consent to {this.consentCompany} and one or more of its <a href="/service-providers" target="_blank">service providers</a> performing a credit check on me based on the information I have provided.</li>
                        <li>I agree and consent to be bound and governed by the {this.consentCompany} <a href="https://lendingarch.com/privacy-policy/" rel="noreferrer" target="_blank">Privacy Policy</a> and <a href="https://lendingarch.com/terms-of-use/" rel="noreferrer" target="_blank">Terms of Use</a> (as currently presented the linked website pages, and as amended from time to time).</li>
                    </ul>
                </label>
            </div>
        </div>
    </fieldset>;

}