import React from "react";
import Applicant from "../services/Applicant";
import CoreTools from "../services/CoreTools";
import OptionLists from "../services/OptionLists";
import AppInput from "./AppInput";
import GoogleAddress from "./GoogleAddress";

const navigate = (url, delay) => url && setTimeout(() => window.location.href = url, delay);

const RadioButtons = props => {
    const options = props.options.map(
        o => CoreTools.isObject(o) ? {
            caption: o.caption !== undefined ? o.caption : o.value,
            key: o.key !== undefined ? o.key : o.value,
            value: o.value
        } : {
            caption: o,
            value: o,
            key: o
        }
    );
    const onClick = () => navigate(props.continue);
    return <div className="card card-options">
        <div className="card-body">
            {props.title && <h5 className="card-title">{props.title}</h5>}
            {props.subtitle && <p className="card-text text-center">{props.subtitle}</p>}
            {options.map(o => <label key={o.key} className="btn btn-option">
                <AppInput.Radio name={props.name} value={o.value} onClick={onClick} />
                <span className="btn-option-text">{o.caption}</span>
            </label>)}
        </div>
    </div>;
};

const ImageButtons = props => {
    const options = props.options.map(
        o => ({
            image: o.image,
            caption: o.caption !== undefined ? o.caption : o.value,
            key: o.key !== undefined ? o.key : o.value,
            value: o.value
        })
    );
    const onClick = () => navigate(props.continue);
    return <div className="card card-options">
        <div className="card-body">
            <div className="icon-picker">
                {props.title && <h5 className="card-title">{props.title}</h5>}
                {props.subtitle && <p className="card-text text-center">{props.subtitle}</p>}
                {options.map(o => <label key={o.key} className="btn-icon">
                    <AppInput.Radio name={props.name} value={o.value} onClick={onClick} />
                    <span>
                        {o.image}
                        <span className="btn-option-text">{o.caption}</span>
                    </span>
                </label>)}
            </div>
        </div>
    </div>;
};

const Address = props => {
    const applicant = Applicant();
    const canSelect = ga => (
        ga && 
        applicant.field("street_number") && 
        applicant.field("street_name") && 
        applicant.field("city") && 
        applicant.field("state") && 
        applicant.field("zip_code") ?
        true : 
        false
    );
    const [googleAddress, setGoogleAddress] = React.useState(canSelect(applicant.field("google_address")) ? applicant.field("google_address") : "");
    const [selected, setSelected] = React.useState(googleAddress || props.override ? true : false);
    React.useEffect(() => {
        const watch = applicant.onFieldUpdate("google_address", newValue => {
            setGoogleAddress(canSelect(newValue) ? newValue : "");
            setSelected(canSelect(newValue));
        });
        return watch;
    });
    const onNext = () => {
        applicant.validate(isValid => selected && isValid ? navigate(props.continue) : window.scrollTo(0, 0));
    };

    const provinceOptions = [
        {value: "", caption: "Select Province"},
        ...OptionLists.Provinces.map(i => ({value: i.short, caption: i.long}))
    ];

    const stateOptions = [
        {value: "", caption: "Select State"},
        ...OptionLists.States.map(i => ({value: i.short, caption: i.long}))
    ];

    const zipCodeFormat = event => {
        if (typeof(event.target.value) === "string") {
            const proper = event.target.value.replace(/[^0-9]/gi, "").substring(0, 5);
            if (event.target.value !== proper) {
                const cPos = event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = cPos;
                event.target.selectionEnd = cPos;
            }
        }
    };

    const zipCodeValidate = value => (
        typeof(value) === "string" &&
        value.length === 5 &&
        CoreTools.match(value.substring(0, 5), /[0-9]/g)
    );

    const postalCodeFormat = event => {
        if (typeof(event.target.value) === "string") {
            const proper = event.target.value.replace(/[^a-z0-9]/gi, "").toUpperCase().substring(0, 6);
            if (event.target.value !== proper) {
                const cPos = event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = cPos;
                event.target.selectionEnd = cPos;
            }
        }
    };

    const postalCodeValidate = value => (
        typeof(value) === "string" &&
        value.length === 6 &&
        CoreTools.match(value.substring(0, 1), /[A-Z]/) &&
        CoreTools.match(value.substring(1, 2), /[0-9]/) &&
        CoreTools.match(value.substring(2, 3), /[A-Z]/) &&
        CoreTools.match(value.substring(3, 4), /[0-9]/) &&
        CoreTools.match(value.substring(4, 5), /[A-Z]/) &&
        CoreTools.match(value.substring(5, 6), /[0-9]/)
    );

    return <div className="card card-options">
        <div className="card-body">
            {props.title && <h5 className="card-title">{props.title}</h5>}
            {props.subtitle && <p className="card-text text-center">{props.subtitle}</p>}
            <div className="form-row text-center mb-3">
                <div className="col-12">
                    <div id="gm-autocomplete-wrap" className="form-field mb-3">                    
                        <GoogleAddress
                            country="ca"
                            className="form-control text-center"
                            placeholder="Type Here to Search For Your Address"
                            defaultValue={applicant.field("google_address") || ""}
                            onSelect={address => {
                                applicant.setField("google_address", address.full);
                                applicant.setField("street_number", address.number);
                                applicant.setField("street_name", address.street);
                                applicant.setField("city", address.city);
                                applicant.setField("state", address.provinceShort);
                                applicant.setField("zip_code", address.postalcode.replace(" ", ""));
                                setSelected(true);
                            }}
                        />
                    </div>
                </div>
            </div>
            {selected && <div className="card-fields" id="addressFields" >
                <div className="form-row">
                    <div className="col-12 mb-1">
                        <AppInput.Input type="text" name="street_number" placeholder="Street Number" onValidate={v => v} className="form-control text-field" failClass="invalid" />
                    </div>
                    <div className="col-12 mb-1">
                        <AppInput.Input type="text" name="street_name" placeholder="Street Name" onValidate={v => v} className="form-control text-field" failClass="invalid" />
                    </div>
                    <div className="col-12 mb-1">
                        <AppInput.Input type="text" name="unit" placeholder="Unit/Apt/Suite" className="form-control" />
                    </div>
                    <div className="col-12 mb-1">
                        <AppInput.Input type="text" name="city" placeholder="City" onValidate={v => v} className="form-control text-field" failClass="invalid" />
                    </div>
                    <div className="col-12 mb-1">
                        <AppInput.Select
                            type="text"
                            name="state"
                            options={props.country === "ca" ? provinceOptions : stateOptions}
                            className="form-control"
                            onValidate={v => v}
                            failClass="invalid"
                        />			
                    </div>
                    <div className="col-12 mb-1">
                        <AppInput.Input 
                            type="text"
                            name="zip_code"
                            placeholder={props.country === "ca" ? "Postal Code" : "Zip Code"}
                            className="form-control"
                            onChange={props.country === "ca" ? postalCodeFormat : zipCodeFormat}
                            onValidate={props.country === "ca" ? postalCodeValidate : zipCodeValidate}
                            failClass="invalid"
                        />
                    </div>
                </div>
            </div>}
            <div className="card-footer text-center mt-4">
                <button disabled={!selected} onClick={onNext} className="mx-auto btn btn-navigate">Next</button>
            </div>
        </div>
    </div>;
};

const GlobalPanels = {
    RadioButtons: RadioButtons,
    ImageButtons: ImageButtons,
    Address: Address
};

export default GlobalPanels;