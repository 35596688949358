import CoreTools from "../../../services/CoreTools";
import GenSet from "../../../services/GenSet";

export default class LoanzProcessingUK extends GenSet {
    onApplicant = (state, props, applicant) => {
        console.log("applicant", applicant.fields);
        CoreTools.switch(
            // [applicant.field("loan_amount"),                   () => this.navigate("#Amount")],
            // [applicant.field("loan_reason"),                   () => this.navigate("#Reason")],
            // [!FieldValidate.isDob(),                           () => this.navigate("#Birthdate")],
            // [applicant.field("residential_status"),            () => this.navigate("#ResidentialStatus")],
            // [!FieldValidate.isAddress("ca"),                   () => this.navigate("#Address")],
            // [!FieldValidate.isName(),                          () => this.navigate("#Name")],
            // [!FieldValidate.isEmail(),                         () => this.navigate("#Email")],
            // [!FieldValidate.isPhone(),                         () => this.navigate("#Phone")],
            [true,                                                () => applicant.post("loanz-uk", "submit", {})]
        );
    };
    
    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <h5 className="card-title text-center widest">We are processing your application</h5>
            <div className="row">
                <img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" className="col" />
            </div>
        </div>
    </div>;
}
