import Lang from "../../services/Lang";
import OptionLists from "../../services/OptionLists";

const ClrLanguage = () => Lang({
    en: {
        titles: {
            province: "Where Are You Located?",
            gender: "What Is Your Gender?",
            age: "Please select your age:",
            dob: "What Is Your Date of Birth?",
            amount: "How Much Insurance Coverage Do You Need?",
            amountSub: "It's okay to estimate",
            type: "What Type of Life Insurance Are You Looking For?",
            smoke: "Do You Smoke?",
            contact: "A life insurance specialist will call you to go over your free assessment regarding the best coverage for your needs.",
            contactSub: "Enter your information for personalized results",
            name: "Enter your name:"
        },
        provinceOptions: OptionLists.Provinces.map(p => ({caption: p.long, value: p.short})),
        previous: "Previous",
        continue: "Continue",
        secondsLeft: "Seconds Left",
        progress: "Progress: ",
        gender: {
            male: "Male",
            female: "Female"
        },
        insuranceTypes: {
            term: "Term",
            permanent: "Permanent",
            notSure: "I'm not sure yet"
        },
        yes: "Yes",
        no: "No",
        contact: {
            firstName: "First Name",
            lastName: "Last Name",
            email: "Email Address",
            phone: "Primary Phone"
        },
        completeMyApplication: "Get My Quotes",
        agreement: <p className="term-txt text-center mt-3"><input type="hidden" id="leadid_tcpa_disclosure"/>By clicking "Get My Quotes", you agree to a human or virtual assistant from Cover Direct contacting you by phone and electronic message about your quote. For more information see our <a href="https://canadianliferates.ca/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://canadianliferates.ca/terms-and-conditions/" target="_blank" rel="noreferrer">Terms of Use</a>.</p>,
        secondsToGo: "Seconds To Go!"
    },
    fr: {
        titles: {
            province: "Où vous situez-vous?",
            gender: "Quel est votre sexe?",
            age: "Veuillez sélectionner votre âge :",
            dob: "Quelle est votre date de naissance?",
            amount: "De quelle couverture d'assurance avez-vous besoin?",
            amountSub: "C'est bien d'estimer",
            type: "Quel type d'assurance-vie recherchez-vous?",
            smoke: "Fumez-vous?",
            contact: "Un spécialiste de l’assurance vie vous appellera pour passer en revue votre évaluation gratuite concernant la meilleure couverture pour vos besoins.",
            contactSub: "Entrez vos informations pour des résultats personnalisés",
            name: "Entrez votre nom:"
        },
        provinceOptions: OptionLists.Provinces.map(p => ({caption: p.frLong, value: p.short})),
        previous: "Retour",
        continue: "Continuer",
        secondsLeft: "Secondes Restantes",
        progress: "Progrès: ",
        gender: {
            male: "Homme",
            female: "Femme"
        },
        insuranceTypes: {
            term: "Terme",
            permanent: "Permanent",
            notSure: "Je ne suis pas encore sûr"
        },
        yes: "Oui",
        no: "Non",
        contact: {
            firstName: "Prénom",
            lastName: "Nom de famille",
            email: "Adresse e-mail",
            phone: "Téléphone primaire"
        },
        completeMyApplication: "Remplir Ma Candidature",
        agreement: <p className="term-txt text-center mt-3"><input type="hidden" id="leadid_tcpa_disclosure"/>En cliquant sur "Compléter ma candidature", j'ai lu et j'accepte les <a href="https://canadianliferates.ca/terms-and-conditions/" target="_blank" rel="noreferrer">conditions d'utilisation</a>. Je reconnais également avoir lu la <a href="https://canadianliferates.ca/privacy-policy/" target="_blank" rel="noreferrer">politique de confidentialité</a> et j'accepte les termes des présentes.</p>,
        secondsToGo: "secondes pour y aller!"
    }
});
export default ClrLanguage;
