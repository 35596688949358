import GenSet from "../../services/GenSet";
import CoreTools from "../../services/CoreTools";
import React from "react";
import Applicant from "../../services/Applicant";
import AppInput from "../../directives/AppInput";
import GoogleAddress from "../../directives/GoogleAddress";
import OptionLists from "../../services/OptionLists";
import FormValidate from "../../services/FormValidate";

const PanelLoan = props => {
    const amountOptions = [
        {value: "", caption: "Please Select"},
        {value: "1", caption: "£100"},
        {value: "2", caption: "£200"},
        {value: "3", caption: "£300"},
        {value: "4", caption: "£400"},
        {value: "5", caption: "£500"},
        {value: "6", caption: "£600"},
        {value: "7", caption: "£700"},
        {value: "8", caption: "£800"},
        {value: "9", caption: "£900"},
        {value: "10", caption: "£1000"},
        {value: "11", caption: "£1100"},
        {value: "12", caption: "£1200"},
        {value: "13", caption: "£1300"},
        {value: "14", caption: "£1400"},
        {value: "15", caption: "£1500"},
        {value: "16", caption: "£1600"},
        {value: "17", caption: "£1700"},
        {value: "18", caption: "£1800"},
        {value: "19", caption: "£1900"},
        {value: "20", caption: "£2000"},
        {value: "21", caption: "£2100"},
        {value: "22", caption: "£2200"},
        {value: "23", caption: "£2300"},
        {value: "24", caption: "£2400"},
        {value: "25", caption: "£2500"},
        {value: "26", caption: "£3000"},
        {value: "27", caption: "£3500"},
        {value: "28", caption: "£4000"},
        {value: "29", caption: "£4500"},
        {value: "30", caption: "£5000 and more"},
    ];
    const lengthOptions = [
        {value: "", caption: "Please Select"},
        {value: "1", caption: "1 Month"},
        {value: "2", caption: "2 Months"},
        {value: "3", caption: "3 Months"},
        {value: "4", caption: "4 Months"},
        {value: "5", caption: "5 Months"},
        {value: "6", caption: "6 Months"},
        {value: "7", caption: "9 Months"},
        {value: "8", caption: "12 Months"},
        {value: "9", caption: "15 Months"},
        {value: "10", caption: "18 Months"},
        {value: "11", caption: "24 Months"},
        {value: "12", caption: "36 Months"},
    ];
    const purposeOptions = [
        {value: "", caption: "Please Select"},
        {value: "1", caption: "Utility Bill"},
        {value: "2", caption: "Rent or Mortgage"},
        {value: "3", caption: "Debt Consolidation"},
        {value: "4", caption: "Temporary Income Reduction"},
        {value: "5", caption: "Vehicle"},
        {value: "6", caption: "Special Occasion"},
        {value: "7", caption: "Household Goods"},
        {value: "8", caption: "Home Improvement"},
        {value: "9", caption: "Emergency"},
        {value: "10", caption: "Living Expense"},
        {value: "11", caption: "Other"},
    ];
    return <div className="form-panel">
        <div className="section-header">
            <span className="section-header-text">About Your Loan</span>
            {/* <span className="section-header-number"><b>1</b> of 6</span> */}
        </div>
        <hr className="horizontal-line"/>
        <div className="customer-message">
            <span className="customer-message-text">Help us narrow down which lending partners can help you.</span>
        </div>
        <div className="form-center">
            <div>
                <span className="label width-3">Loan Amount</span>
                <span className="label width-3">Repayment Length</span>
            </div>
            <div className="input-group">
                <AppInput.Select name="loan_amount" options={amountOptions} onValidate={v => v} failClass="invalid" className="width-3" />
                <AppInput.Select name="loan_length" options={lengthOptions} onValidate={v => v} failClass="invalid" className="width-3" />
            </div>
        </div>
        <div className="form-center">
            <div>
                <span className="label width-6">Loan Purpose</span>
            </div>
            <div className="input-group">
                <AppInput.Select name="loan_purpose" options={purposeOptions} onValidate={v => v} failClass="invalid" className="width-6" />
            </div>
        </div>
    </div>;
};

const PanelYou = props => {
    const titleOptions = [
        {value: "", caption: "Please Select"},
        {value: "1", caption: "Mr"},
        {value: "2", caption: "Ms"},
        {value: "3", caption: "Mrs"},
        {value: "4", caption: "Miss"},
    ]
    const maritalOptions = [
        {value: "", caption: "Please Select"},
        {value: "1", caption: "Single"},
        {value: "2", caption: "Married"},
        {value: "3", caption: "Living with Partner"},
        {value: "4", caption: "Separated"},
        {value: "5", caption: "Divorced"},
        {value: "6", caption: "Widowed"},
        {value: "7", caption: "Other"},
    ]
    const dependentOptions = [
        {value: "", caption: "Please Select"},
        {value: "1", caption: "0"},
        {value: "2", caption: "1"},
        {value: "3", caption: "2"},
        {value: "4", caption: "3"},
        {value: "5", caption: "4"},
        {value: "6", caption: "5+"},
    ]
    // const phoneTypeOptions = [{value: "1", caption: "Mobile"}, {value: "2", caption: "Land-Line"}];
    const phoneFormat = event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };
    const yearOptions = (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year - 17; i > year - 100; i--) {years.push(i.toString());} return years;})();
    const monthOptions = (() => {let months = [{value: "", caption: "Month"}]; for (let i = 1; i <= 12; i++) {months.push(i.toString());} return months;})();
    const dayOptions = (() => {let days = [{value: "", caption: "Day"}]; for (let i = 1; i <= 31; i++) {days.push(i.toString());} return days;})();
    
    return <div className="form-panel">
                <div className="section-header">
            <span className="section-header-text">Your Personal Details</span>
            {/* <span className="section-header-number"><b>2</b> of 6</span> */}
        </div>
        <hr className="horizontal-line"/>
        <div className="customer-message">
            <span className="customer-message-text">We take security very seriously. Your details will remain secure.</span>
        </div>
        <div className="form-center">
            <div>
                <span className="label width-6">Title</span>
            </div>
            <div className="input-group">
                <AppInput.Select name="loan_length" options={titleOptions} onValidate={v => v} failClass="invalid" className="width-6" />
            </div>
            <div>
                <span className="label width-6">Your Name</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="first_name" placeholder="First Name" onValidate={FormValidate.isName} failClass="invalid" className="width-3" />
                <AppInput.Input name="last_name" placeholder="Last Name" onValidate={FormValidate.isName} failClass="invalid" className="width-3" />
            </div>
            <div>
                <span className="label width-6">Date Of Birth</span>
            </div>
            <div className="input-group">
                <AppInput.Select name="dob_day" options={dayOptions} onValidate={v => v} failClass="invalid" className="width-2" />
                <AppInput.Select name="dob_month" options={monthOptions} onValidate={v => v} failClass="invalid" className="width-2" />
                <AppInput.Select name="dob_year" options={yearOptions} onValidate={v => v} failClass="invalid" className="width-2" />
            </div>
            <div>
                <span className="label width-3">Marital Status</span>
                <span className="label width-3">Dependants</span>
            </div>
            <div className="input-group">
                <AppInput.Select name="marital_status" options={maritalOptions} onValidate={v => v} failClass="invalid" className="width-3" />
                <AppInput.Select name="dependents" options={dependentOptions} onValidate={v => v} failClass="invalid" className="width-3" />
            </div>
            <div>
                <span className="label width-6">Home Phone Number</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="phone" onValidate={FormValidate.isPhone} failClass="invalid" className="width-6" onChange={phoneFormat} />
            </div>
            <div>
                <span className="label width-6">Mobile Phone Number</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="phone" onValidate={FormValidate.isPhone} failClass="invalid" className="width-6" onChange={phoneFormat} />
            </div>
            <div>
                <span className="label width-6">E-mail</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="email" onValidate={FormValidate.isEmail} failClass="invalid" className="width-6" />
            </div>
        </div>
    </div>;
};

const PanelAddress = props => {
    const province = {
        options: [
            {value: "", caption: "Select Province"},
            ...OptionLists.Provinces.map(ol => ({value: ol.short, caption: ol.long}))
        ]
    };
    const postalCode = {
        format: event => {
            if (typeof(event.target.value) === "string") {
                const proper = event.target.value.replace(/[^a-z0-9]/gi, "").toUpperCase().substring(0, 6);
                if (event.target.value !== proper) {
                    const cPos = event.target.selectionStart;
                    event.target.value = proper;
                    event.target.selectionStart = cPos;
                    event.target.selectionEnd = cPos;
                }
            }
        },
        validate: value => (
            typeof(value) === "string" &&
            value.length === 6 &&
            CoreTools.match(value.substring(0, 1), /[A-Z]/) &&
            CoreTools.match(value.substring(1, 2), /[0-9]/) &&
            CoreTools.match(value.substring(2, 3), /[A-Z]/) &&
            CoreTools.match(value.substring(3, 4), /[0-9]/) &&
            CoreTools.match(value.substring(4, 5), /[A-Z]/) &&
            CoreTools.match(value.substring(5, 6), /[0-9]/)
        )
    };
    return <div className="form-panel">
        <div className="section-header">
            <span className="section-header-text">Your Address Details</span>
            {/* <span className="section-header-number"><b>3</b> of 6</span> */}
        </div>
        <hr className="horizontal-line"/>
        <div className="customer-message">
            <span className="customer-message-text">Tell us a little about your current living situation.</span>
        </div>
        <div className="form-center">
            <div><span className="label width-6">Search Address</span></div>
            <div className="input-group"><GoogleAddress
                country="ca"
                className="width-6"
                placeholder="Type Here to Search For Your Address"
                onSelect={address => {
                    Applicant().setField("google_address", address.full);
                    Applicant().setField("street_number", address.number);
                    Applicant().setField("street_name", address.street);
                    Applicant().setField("city", address.city);
                    Applicant().setField("state", address.provinceShort);
                    Applicant().setField("zip_code", address.postalcode.split(" ").join("").toUpperCase());
                }}
            /></div>
            <div>
                <span className="label width-2">Street Number</span>
                <span className="label width-4">Street Name</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="street_number" placeholder="Street Number" onValidate={v => v} className="width-2" failClass="invalid" />
                <AppInput.Input name="street_name" placeholder="Street Name" onValidate={v => v} className="width-4" failClass="invalid" />
            </div>
            <div>
                <span className="label width-2">Unit / Apt / Suite</span>
                <span className="label width-4">City</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="unit" placeholder="U / A / S" className="width-2" />
                <AppInput.Input name="city" placeholder="City" onValidate={v => v} className="width-4" failClass="invalid" />
            </div>
            <div>
                <span className="label width-2">Postal Code</span>
                <span className="label width-4">Province</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="postal_code" placeholder="Postal Code" className="width-2" onValidate={postalCode.validate} failClass="invalid" onChange={postalCode.format} />
                <AppInput.Select name="province" options={province.options} className="width-4" onValidate={v => v} failClass="invalid" />
            </div>
        </div>
    </div>;
};

const PanelIncome = props => {
    const employmentOptions = [
        {value: "", caption: "Please Select"},
        {value: "1", caption: "Full Time Employment"},
        {value: "2", caption: "Part Time Employment"},
        {value: "3", caption: "Temporary Employment"},
        {value: "4", caption: "Self Employed"},
        {value: "5", caption: "Unemployed"},
        {value: "6", caption: "Pension"},
        {value: "7", caption: "Disability Benefits"},
        {value: "8", caption: "Student"},
    ]
    const industryOptions = [
        {value: "", caption: "Please Select"}, 
        {value: "1", caption: "Accountancy"}, 
        {value: "2", caption: "Administration Secretarial"},
        {value: "3", caption: "Advertising Media"},
        {value: "4", caption: "Business Consultancy"},
        {value: "5", caption: "Call Centre Operations"},
        {value: "6", caption: "Cleaning Services"},
        {value: "7", caption: "Construction"},
        {value: "8", caption: "Driver"},
        {value: "9", caption: "Education"},
        {value: "10", caption: "Electricity Gas water"},
        {value: "11", caption: "Leisure Culture"},
        {value: "12", caption: "Manager Mid Level"},
        {value: "13", caption: "Manager Senior Level"},
        {value: "14", caption: "Manufacturing"},
        {value: "15", caption: "Military"},
        {value: "16", caption: "Mining Quarrying"},
        {value: "17", caption: "Other"},
        {value: "18", caption: "Professional"},
        {value: "19", caption: "Public Administration"},
        {value: "20", caption: "Publishing"},
        {value: "21", caption: "Real Estate Property"},
        {value: "22", caption: "Research Development"},
        {value: "23", caption: "Retail"},
        {value: "24", caption: "Sales and Customer Service"},
        {value: "25", caption: "Skilled Trade"},
        {value: "26", caption: "Telecoms Internet"},
        {value: "27", caption: "Transportation"},
        {value: "28", caption: "Healthcare NHS"}
    ]
    const employmentTimeOptions = [
        {value: "", caption: "Please Select"},
        {value: "1", caption: "< 3 Months"},
        {value: "2", caption: "4 - 6 Months"},
        {value: "3", caption: "7 - 9 Months"},
        {value: "4", caption: "10 - 12 Months"},
        {value: "5", caption: "1 - 2 Years"},
        {value: "6", caption: "2 - 3 Years"},
        {value: "7", caption: "4 - 8 Years"},
        {value: "8", caption: "Over 8 Years"},
    ]
    const payFrequencyOptions = [
        {value: "", caption: "Please Select"},
        {value: "1", caption: "Last Working Day of Month"},
        {value: "2", caption: "Four Weekly"},
        {value: "3", caption: "A Specific Day of Month"},
        {value: "4", caption: "Last Monday of Month"},
        {value: "5", caption: "Last Tuesday of Month"},
        {value: "6", caption: "Last Wednesday of Month"},
        {value: "7", caption: "Last Thursday of Month"},
        {value: "8", caption: "Last Friday of Month"},
        {value: "9", caption: "Weekly"},
        {value: "10", caption: "Fortnightly"},
        {value: "11", caption: "Semi Monthly"},
    ]
    const dayOptions = (() => {let days = [{value: "", caption: "DD"}]; for (let i = 1; i <= 31; i++) {days.push(i.toString());} return days;})();
    const monthOptions = (() => {let months = [{value: "", caption: "MM"}]; for (let i = 1; i <= 12; i++) {months.push(i.toString());} return months;})();


    return <div className="form-panel">
        <div className="section-header">
            <span className="section-header-text">Your Income</span>
            {/* <span className="section-header-number"><b>4</b> of 6</span> */}
        </div>
        <hr className="horizontal-line"/>
        <div className="customer-message">
            <span className="customer-message-text">We will never contact your employer. Lenders just need to understand how you generate an income.</span>
        </div>
        <div className="form-center">
            <div>
                <span className="label width-6">Employment Status</span>
            </div>
            <div className="input-group">
                <AppInput.Select name="employment_status" options={employmentOptions} onValidate={v => v} failClass="invalid" className="width-6" />
            </div>
            <div>
                <span className="label width-6">Monthly Income (After Tax)</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="monthly_income" placeholder="£" className="width-6" failClass="invalid" />
            </div>
            <div>
                <span className="label width-6">Name of Employer</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="employer_name" className="width-6" failClass="invalid" />
            </div>
            <div>
                <span className="label width-6">Employer Industry</span>
            </div>
            <div className="input-group">
                <AppInput.Select name="employer_industry" options={industryOptions} onValidate={v => v} failClass="invalid" className="width-6" />
            </div>
            <div>
                <span className="label width-6">Time At Current Employer</span>
            </div>
            <div className="input-group">
                <AppInput.Select name="loan_amount" options={employmentTimeOptions} onValidate={v => v} failClass="invalid" className="width-6" />
            </div>
            <div>
                <span className="label width-6">Pay Frequency</span>
            </div>
            <div className="input-group">
                <AppInput.Select name="loan_amount" options={payFrequencyOptions} onValidate={v => v} failClass="invalid" className="width-6" />
            </div>
            <div>
                <span className="label width-6">Next Paydate</span>
            </div>
            <div className="input-group">
                <AppInput.Select name="loan_amount" options={dayOptions} onValidate={v => v} failClass="invalid" className="width-3" />
                <AppInput.Select name="loan_amount" options={monthOptions} onValidate={v => v} failClass="invalid" className="width-3" />
            </div>
            <div>
                <span className="label width-6">Paydate After Next</span>
            </div>
            <div className="input-group">
                <AppInput.Select name="loan_amount" options={dayOptions} onValidate={v => v} failClass="invalid" className="width-3" />
                <AppInput.Select name="loan_amount" options={monthOptions} onValidate={v => v} failClass="invalid" className="width-3" />
            </div>
            <div>
                <span className="label width-6">Paid Direct Into Bank</span>
            </div>
            <div>
                <span className="label width-6">
                    <AppInput.Radio name="direct_deposit" value="Yes" />
                    <span>Yes</span>
                    <AppInput.Radio name="direct_deposit" value="No" />
                    <span>No</span>
                </span>
            </div>
        </div>
    </div>;
};

const PanelExpenses = props => {
    return <div className="form-panel">
        <div className="section-header">
            <span className="section-header-text">Your Expenses</span>
            {/* <span className="section-header-number"><b>5</b> of 6</span> */}
        </div>
        <hr className="horizontal-line"/>
        <div className="customer-message">
            <span className="customer-message-text">Please enter accurate information. It will improve your chances of being approved.</span>
        </div>
        <div className="form-center">
            <div>
                <span className="label width-6">Rental/Mortgage Payment</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="first_name" placeholder="£" onValidate={v => v} failClass="invalid" className="width-6" />
            </div>
            <div>
                <span className="label width-6">Existing Loan Payments</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="first_name" placeholder="£" onValidate={v => v} failClass="invalid" className="width-6" />
            </div>
            <div>
                <span className="label width-6">Utility Bills</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="first_name" placeholder="£" onValidate={v => v} failClass="invalid" className="width-6" />
            </div>
            <div>
                <span className="label width-6">Transportation Costs</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="first_name" placeholder="£" onValidate={v => v} failClass="invalid" className="width-6" />
            </div>
            <div>
                <span className="label width-6">Food Costs</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="first_name" placeholder="£" onValidate={v => v} failClass="invalid" className="width-6" />
            </div>
            <div>
                <span className="label width-6">Other Monthly Outgoings</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="first_name" placeholder="£" onValidate={v => v} failClass="invalid" className="width-6" />
            </div>
        </div>
    </div>;
};

const PanelLast = props => {
    return <div className="form-panel">
        <div className="section-header">
            <span className="section-header-text">The Small Print</span>
            {/* <span className="section-header-number"><b>6</b> of 6</span> */}
        </div>
        <hr className="horizontal-line"/>
        <div className="customer-message">
            <span className="customer-message-text">Please complete this final step to see your personalised loan offers.</span>
        </div>
        <div className="form-left">
            <div className="inline-display">
                <span><input type="checkbox"></input>I agree to the <a className="terms-anchor-tag" href="https://lendingarch.ca/terms-and-conditions/" target="_blank" rel="noreferrer">Terms and Conditions</a> and I have read and understood the <a className="terms-anchor-tag" href="https://lendingarch.ca/terms-and-conditions/" target="_blank" rel="noreferrer">Privacy Policy</a> and I understand as part of my application my data may be shared with <a className="terms-anchor-tag" href="https://www.transunion.com/" target="_blank" rel="noreferrer">TransUnion</a> and Credit Providers.</span>
            </div>
            <div className="inline-display">
                <span><input type="checkbox"></input>Please indicate if you would like to receive your credit application results by email.</span>
            </div>
        </div>
    </div>;
};

export default class LoanzFlatFormUK extends GenSet {
    onMount = () => {
        if (!CoreTools.state.appOptions.includes("nocss")) {
            if (CoreTools.state.isLocal) {
                CoreTools.importStyle("/css/loanz-flatform-uk.css");
            } else {
                CoreTools.importStyle("https://form.consumergenius.com/css/loanz-flatform-uk.css");
            }
        }
    };
    content = state => <div className="form-body">
        <PanelLoan />
        <PanelYou />
        <PanelAddress />
        <PanelIncome showFlinks={v => this.setVal({flinks: v})}/>
        <PanelExpenses />
        <PanelLast />
    </div>;
}
