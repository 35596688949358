import Applicant from "./Applicant";
import CoreTools from "./CoreTools";
import FormValidate from "./FormValidate";

const FieldValidate = {
    isPhone: () => {
        let phone = Applicant().field("phone");
        if (typeof(phone) !== "string") {return false;}
        phone = phone.replace(/\D/g, "").slice(-10);
        if (Applicant().field("phone") !== phone) {Applicant().setField("phone", phone);}
        return phone.length === 10;
    },
    isEmail: () => FormValidate.isEmail(Applicant().field("email")),
    isName: () => FormValidate.isName(Applicant().field("first_name")) && FormValidate.isName(Applicant().field("last_name")),
    isAddress: country => {
        if (!Applicant().field("street_number") || typeof(Applicant().field("street_number")) !== "string") {return false;}
        if (!Applicant().field("street_name") || typeof(Applicant().field("street_name")) !== "string") {return false;}
        if (!Applicant().field("city") || typeof(Applicant().field("city")) !== "string") {return false;}
        if (!Applicant().field("state") || typeof(Applicant().field("state")) !== "string") {return false;}
        let zipCode = Applicant().field("zip_code");
        if (!zipCode || typeof(zipCode) !== "string") {return false;}
        zipCode = zipCode.replace(/[^a-z0-9]/gi, "").toUpperCase();
        if (zipCode !== Applicant().field("zip_code")) {Applicant().setField("zip_code", zipCode);}
        if (
            country === "ca" && (
                zipCode.length !== 6 ||
                !CoreTools.match(zipCode.substring(0, 1), /[A-Z]/) ||
                !CoreTools.match(zipCode.substring(1, 2), /[0-9]/) ||
                !CoreTools.match(zipCode.substring(2, 3), /[A-Z]/) ||
                !CoreTools.match(zipCode.substring(3, 4), /[0-9]/) ||
                !CoreTools.match(zipCode.substring(4, 5), /[A-Z]/) ||
                !CoreTools.match(zipCode.substring(5, 6), /[0-9]/)
            )
        ) {return false;}
        if (
            country === "us" && (
                zipCode.length !== 5 ||
                !CoreTools.match(zipCode.substring(0, 5), /[0-9]/g)
            )
        ) {return false;}
        return true;
    },
    isDob: () => {
        let dob = Applicant().field("dob");
        if (!dob || typeof(dob) !== "string") {return false;}
        let dobSplit = dob.split("-");
        if (
            dobSplit.length === 3 && 
            (Number(dobSplit[0]) || 0) > 1900 &&
            (Number(dobSplit[0]) || 0) <= new Date().getFullYear() &&
            (Number(dobSplit[1]) || 0) >= 1 &&
            (Number(dobSplit[1]) || 0) <= 12 &&
            (Number(dobSplit[2]) || 0) >= 1 &&
            (Number(dobSplit[2]) || 0) <= 31
        ) {return true;}
        return false;
    }
};
export default FieldValidate;
