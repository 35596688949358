import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";

export default class RVFastMonthlyRent extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };

    setOutput = event => {
        console.log("Value:", event.target.value);
        if (event.target.value === "0") {
            this.setVal({output: "$0"});
        } else if (event.target.value === "5000") {
            this.setVal({output: "$5,000+"});
        } else {
            this.setVal({output: "$" + event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")});
        }
    };


    content = (state, props) => <div className="card card-options">
        <div className="card-body">
        <h5 className="card-title">What Are Your Monthly (Rent Or Mortgage) Payments?</h5>
            <div className="card-fields">
                <div className="form-row">
                    <div className="form-slider text-center">
                        <div className="form-value">{state.output}</div>
                        <AppInput.Range
                            style={{sidplay: "block"}}
                            name="monthly_residence_cost"
                            onValidate={v => Number(v)}
                            failClass="invalid"
                            onChange={this.setOutput}
                            onLoad={this.setOutput}
                            min="0"
                            max="5000"
                            step="100"
                            defaultValue="2500"
                        />
                    </div>
                </div>
            </div>
            <div className="card-footer text-center mt-4">
                <button onClick={this.continue} className="mx-auto btn btn-navigate">Next</button>
            </div>
        </div>
    </div>;

}
