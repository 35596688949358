import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";

export default class SurexMaritalStatus extends GenSet {
    continue = (state, props) => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };
    
    content = (state, props) => <SurexComponents.Card
        title="What's your marital status?"
    >
        <div className="form-row justify-content-center">
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="marital_status" value="CivilUnion" onClick={this.continue}/>
                    <span>Civil Union</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="marital_status" value="DomesticPartner" onClick={this.continue}/>
                    <span>Domestic Partner</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="marital_status" value="Divorced" onClick={this.continue}/>
                    <span>Divorced</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="marital_status" value="Married" onClick={this.continue}/>
                    <span>Married</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="marital_status" value="Single" onClick={this.continue}/>
                    <span>Single</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="marital_status" value="Separated" onClick={this.continue}/>
                    <span>Separated</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="marital_status" value="Widowed" onClick={this.continue}/>
                    <span>Widowed</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="marital_status" value="Unknown" onClick={this.continue}/>
                    <span>Unknown</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="marital_status" value="Other" onClick={this.continue}/>
                    <span>Other</span>
                </label>
            </div>
        </div>
    </SurexComponents.Card>;
}
