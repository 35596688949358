import GenSet from "../../../services/GenSet";
import JugglingDebtComponents from "../inserts/JugglingDebtComponents";

export default class JugglingDebtComments extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };
    content = (state, props, applicant) => <JugglingDebtComponents.Card
        title="Do you have any extra comments regarding your situation?"
        controls={<JugglingDebtComponents.Controls continue={this.continue} buttonText={props.buttonText} disclaimer={props.disclaimer} />}
    >
        <div className="form-row colored">
            <div className="col-12">
                <textarea resizeable="false" onBlur={e => applicant.setField("coments", e.target.value)} />
            </div>
        </div>
    </JugglingDebtComponents.Card>;
}