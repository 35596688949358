import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import HeFunctions from "../inserts/HeFunctions";
import HeComponents from "../inserts/HeComponents";

export default class HeMortgageBalance extends GenSet {

    continue = () => {
        if ((this.applicant.field("mortgage_balance") / this.applicant.field("property_value_range") * 100) >= 85 && !this.state.popup) {
            this.setVal({
                popup: true,
                property_value_range: "$" + this.applicant.field("property_value_range").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                mortgage_balance: "$" + this.applicant.field("mortgage_balance").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                property_value_range_low: "$" + (this.applicant.field("property_value_range") - 5000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                mortgage_balance_low: "$" + (this.applicant.field("mortgage_balance") - 5000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                ltvpercent: (this.applicant.field("mortgage_balance") / this.applicant.field("property_value_range") * 100).toFixed(2) + "%",
                ltvbalance: "$" + (this.applicant.field("property_value_range") - this.applicant.field("mortgage_balance")).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            });
        }else{
            this.navigate(this.props.continue);
        }
    };

    setOutput = event => {
        this.setVal({
            dollar:{
                val: event.target.value,
                min: "$" + (event.target.value - event.target.min + 1 ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                max: "$" + event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            }
        });
    };

    setRange = (value,bool) => {
        if(value){
            this.setVal({options: [
                ...HeFunctions.createDropdowns(0,200000,5000),
                ...HeFunctions.createDropdowns(200000,400000,10000),
                ...HeFunctions.createDropdowns(400000,1000000,20000),
                {value: "1500000", caption: "$1.0M - $1.5M"},
                {value: "2000000", caption: "$1.51M - $2.0M"},
                {value: "2000001", caption: "$2.0M or more"},
            ].filter(e => e.value <= parseInt(value))});
            this.applicant.setField('mortage_balance',value?.toString());
        }
    }

    onApplicant = (state, props, applicant) => {
        this.setRange(applicant.field("property_value_range"),false);
        this.wHolder(applicant.onFieldUpdate("property_value_range", value => this.setRange(value,true)));
    }
    
    content = (state, props) => <HeComponents.Card
        title={!state.popup?"What is the remaining mortgage balance on this property?":"You've selected the following:"}
        controls={<HeComponents.Controls continue={this.continue} buttonText={!state.popup?props.buttonText:false} disclaimer={props.disclaimer} />}
    >
        {!state.popup
            ?<div className="form-row">
                <div className="col-12">
                    {state.options && <AppInput.Select 
                        type="text" 
                        name="mortgage_balance" 
                        options={state.options} 
                        className="form-control" 
                        onValidate={v => v} 
                        failClass="invalid" 
                    />	}
                </div>
            </div>
            :<div className="form-row">
                <div className="infoboxes">
                    <div>
                        <span>{state.property_value_range_low} - {state.property_value_range}</span>
                        <label>Property Value</label>
                    </div>
                    <div>
                        <span>{state.mortgage_balance_low} - {state.mortgage_balance}</span>
                        <label>Remaining Mortgage Balance</label>
                    </div>
                </div>
                <span className="info">Your Loan to Value ratio is <b>{state.ltvpercent}</b>. This means you have <b>{state.ltvbalance}</b> in equity in your home.</span>
                <span className="info">Is this correct?</span>
                <div className="col-12">
                    <label className="btn btn-option small">
                        <span onClick={this.continue}>Yes</span>
                    </label>
                    <label className="btn btn-option small highlight">
                        <span onClick={() => this.navigate("#PropertyValue")}>No</span>
                    </label>
                </div>
            </div>
        }
    </HeComponents.Card>;
}