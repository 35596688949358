import ReactDOM from 'react-dom/client';
import CoreTools from './services/CoreTools';
import GenSet from './services/GenSet';

class NoteElem extends GenSet {
    content = (state, props) => <div style={{position: "absolute", left: "10px", top: "10px", zIndex: 50}}>Notes</div>;
}

const Notes = () => {
    if (CoreTools.getQueryParam("notes")) {
        let ne = document.createElement("div");
        ne.id = "noteElement";
        document.body.appendChild(ne);
        const root = ReactDOM.createRoot(ne);
        root.render(<NoteElem />);
        console.log("Notes");
    }
};

export default Notes;