import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import JugglingDebtComponents from "../inserts/JugglingDebtComponents";

export default class JugglingDebtCCDebtAmount extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };
    setOutput = event => {
        this.setVal({
            dollar:{
                val: "$" + event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            }
        });
    };
    content = (state, props) => <JugglingDebtComponents.Card
        title="Settle your debt with low monthly payments!"
        text="Select the current credit card amount you owe for a free, no-obligation consultation."
        controls={<JugglingDebtComponents.Controls continue={this.continue} buttonText={props.slider ? props.buttonText : false} disclaimer={props.disclaimer} />}
        counter={props.counter}
    >
        <div className="form-row">
            {props.slider
                ? <div className="col-12">
                    <div className="form-slider">
                        <span className="form-value two-value">
                            <div className="dollar">{state.dollar?.val}</div>
                        </span>
                        <AppInput.Range
                            style={{sidplay: "block"}}
                            name="cc_debt_amount_range"
                            onValidate={v => Number(v)}
                            failClass="invalid"
                            onChange={this.setOutput}
                            onLoad={this.setOutput}
                            min="1000"
                            max="1000000"
                            step="1000"
                            defaultValue="50000"
                        />
                        <div className="slider-values">
                            <span>$0</span>
                            <span>$1,000,000+</span>
                        </div>
                    </div>
                </div>
                : <>
                    <div className="col-12">
                        <label className="btn btn-option">
                            <AppInput.Radio name="cc_debt_amount" value="9999" onClick={this.continue} />
                            <span>0 - $9,999</span>
                        </label>
                    </div>
                    <div className="col-12">
                        <label className="btn btn-option">
                            <AppInput.Radio name="cc_debt_amount" value="15000" onClick={this.continue} />
                            <span>$10,000 - $15,000</span>
                        </label>
                    </div>
                    <div className="col-12">
                        <label className="btn btn-option">
                            <AppInput.Radio name="cc_debt_amount" value="20000" onClick={this.continue} />
                            <span>$15,001 - $24,999</span>
                        </label>
                    </div>
                    <div className="col-12">
                        <label className="btn btn-option">
                            <AppInput.Radio name="cc_debt_amount" value="25000" onClick={this.continue} />
                            <span>$25,000+</span>
                        </label>
                    </div>
                </>
            }   
        </div>
    </JugglingDebtComponents.Card>;
}