import PanelEffects from "../../directives/PanelEffects";
import CoreTools from "../../services/CoreTools";
import GenSet from "../../services/GenSet";
import ClrFlatFormProcessing from "./panels/ClrFlatFormProcessing";
import ClrFlatForm from "./panels/ClrFlatFormv2";

export default class ClrIndexFlatForm extends GenSet {
  init = (() => {
    this.setVal({ hash: this.location.hash });
    if (CoreTools.state.appOptions.includes("nocss")) {
      this.setVal({
        cssBootstrap: true,
        cssMain: true,
      });
    } else {
      CoreTools.importStyle(
        "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css",
        () => this.setVal({ cssBootstrap: true })
      );
      this.importStyle("/css/loanz-flatform-us.css", () =>
        this.setVal({ cssMain: true })
      );
      this.importStyle("/css/animate.css");
    }
    this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
    this.importStyle("/css/rangeslider3.css");
  })();

  onMount = (state, props) => {
    this.onNavigate((location) =>
      this.setVal({ hash: location.hash }, () =>
        setTimeout(CoreTools.centerForm, 50)
      )
    );
  };

  panels = {
    "#Flatform":    <ClrFlatForm />,
    "#Processing":  <ClrFlatFormProcessing />,
  };

  content = (state, props) => 
    state.cssBootstrap &&
    state.cssMain && 
    <>
      <div className="main-content">
        <PanelEffects.Transition1 className="container-lg">
          <div id="appForm" style={{ marginTop: "15px" }} key={state.hash}>
            <div className="appForm_inner">
              <PanelEffects.Selector
                selector={state.hash}
                panels={this.panels}
              />
            </div>
          </div>
        </PanelEffects.Transition1>
      </div>
    </>
}
