import AppInput from "../../directives/AppInput";
import CoreTools from "../../services/CoreTools";
import GenSet from "../../services/GenSet"
import PanelEffects from "../../directives/PanelEffects";
import FormValidate from "../../services/FormValidate";
import LegacyDOBPicker from "../../directives/LegacyDOBPicker";
import OptionLists from "../../services/OptionLists";
import GoogleAddress from "../../directives/GoogleAddress";

const VisualPanelFrame = props => <div className="card card-options" style={props.style}>
    <div className="card-body">
        <p className="card-title text-center mb-4">{props.title}</p>
        {props.children}
    </div>
</div>;

const VisualLoading = props => <VisualPanelFrame title={props.title}>
    <div><img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{display: "block", margin: "20px auto", width: "100%"}} /></div>
</VisualPanelFrame>;

const VisualConsent = () => <div className="term-text mt-3">
    <p className="text-center widest"><input type="hidden" id="leadid_tcpa_disclosure"/>
        By clicking "Continue", I have read and accept the <a id="privacy-anchor-tag" href="/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>. 
        I also acknowledge having read the <a id="privacy-anchor-tag" href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and agree to the terms herein.
    </p>
</div>;

const VisualContinue = props => <div className="text-center continue-button-container">
    <button type="submit" className="continue-button mt-3" onClick={props.onClick} style={{display: "block"}}>{props.text || "Continue"}</button>
</div>;

class PanelLoading extends GenSet {
    onApplicant = (state, props, applicant) => {
        const injectId = CoreTools.getQueryParam("injectId");
        if (injectId) {
            applicant.post("loanconnect-ca", "inject", {injectId: injectId});
        } else {
            this.navigate("#Amount");
        }
    };
    content = (state, props) => <VisualLoading title="We are rendering your options." />;
}

class PanelAmount extends GenSet {
    continue = () => this.navigate(this.props.continue);
    setOutput = event => {if (event.target.value === "60") {this.setVal({output: event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "+"});}else {this.setVal({output: event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")});}};
    content = (state, props) => <VisualPanelFrame title={<>How much are you looking for?</>}>
        <div className="card-fields row mt-1"><div className="col-12 mb-3">
            <div className="col-12 text-center"><div className="form-slider">
                <span className="form-value two-value"><div className="dollar"><span id="dollar-sign">$</span>{state.output}</div></span>
                <AppInput.Range className="mb-5" name="amount" style={{width: "100%"}} onChange={this.setOutput} onLoad={this.setOutput} min={500} max={15000} step={500} defaultValue={1000} />
            </div></div>
        </div></div>
        <VisualContinue onClick={this.continue} />
    </VisualPanelFrame>;
}

class PanelReason extends GenSet {
    continue = () => this.navigate(this.props.continue);
    content = (state, props, applicant) => <VisualPanelFrame title={<>What Do You Need The Money For?</>}>
        <div className="form-row">
            {
                [
                    "Debt Consolidation",
                    "Pay off Credit Cards",
                    "Home Improvement",
                    "Purchase an Item",
                    "Grow Your Business",
                    "Cover Medical Expenses",
                    "Pay for a Wedding",
                    "Buy a Car",
                    "Take a Vacation",
                    "Bills and Expenses",
                    "Rebuild Your Credit",
                    "Other"
                ].map((e, i) => <div key={i} className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio name="reason" value={e} onClick={this.continue} />
                        <span>{e}</span>
                    </label>
                </div>)
            }
        </div>
    </VisualPanelFrame>;
}

class PanelName extends GenSet {
    continue = () => this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    content = (state, props) => <VisualPanelFrame title={<>What is your <b>Name</b>?</>}>
        <div className="card-fields row mt-1"><div className="col-12 mb-3">
            <AppInput.Input name="first_name" placeholder="First Name (Formal)" title="First Name (Formal)" className="form-control text-field special-field" failClass="invalid" onValidate={v => v} />
        </div></div>
        <div className="card-fields row mt-1"><div className="col-12 mb-3">
            <AppInput.Input name="last_name" placeholder="Last Name" title="Last Name" className="form-control text-field special-field" failClass="invalid" onValidate={v => v} />
        </div></div>
        <VisualContinue onClick={this.continue} />
    </VisualPanelFrame>;
}

class PanelEmail extends GenSet {
    continue = () => this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    content = (state, props) => <VisualPanelFrame title={<>What is your <b>Email</b>?</>}>
        <div className="card-fields row mt-1"><div className="col-12 mb-3">
            <AppInput.Input name="email" placeholder="Email Address" title="Email Address" className="form-control text-field special-field" failClass="invalid" onValidate={FormValidate.isEmail} />
        </div></div>
        <VisualContinue onClick={this.continue} />
        <VisualConsent />
    </VisualPanelFrame>;
}

class PanelPhone extends GenSet {
    continue = () => this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    content = (state, props) => <VisualPanelFrame title={<>What is your <b>Phone</b> number?</>}>
        <div className="card-fields row mt-1"><div className="col-12 mb-3">
            <AppInput.Input name="phone" placeholder="Phone Number" title="Phone Number" className="form-control text-field special-field" failClass="invalid" onChange={FormValidate.phoneFormat} onValidate={FormValidate.isPhone} />
        </div></div>
        <VisualContinue onClick={this.continue} />
    </VisualPanelFrame>;
}

class PanelDob extends GenSet {
    continue = () => this.navigate(this.props.continue);
    content = (state, props) => <VisualPanelFrame title={<>What is your <b>Date Of Birth</b>?</>}>
        <div className="card-fields row mt-1"><div className="col-12 mb-3">
            <LegacyDOBPicker onSelect={v => this.setVal({ready: v})} />
        </div></div>
        <div className="text-center continue-button-container">
            <button type="submit" className={state.ready ? 'continue-button mt-3' : 'disabled-button mt-3'} disabled={!state.ready} onClick={this.continue}>Continue</button>
        </div>
    </VisualPanelFrame>;
}

class PanelAddress extends GenSet {
    continue = () => this.applicant.validate(isValid => this.state.addressSet && isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    provinceOptions = [{value: "", caption: "Select Province"}, ...OptionLists.Provinces.map(ol => ({value: ol.short, caption: ol.long}))];
    postalCodeFormat = event => {
        if (typeof(event.target.value) === "string") {
            const proper = event.target.value.replace(/[^a-z0-9]/gi, "").toUpperCase().substring(0, 6);
            if (event.target.value !== proper) {
                const cPos = event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = cPos;
                event.target.selectionEnd = cPos;
            }
        }
    };
    postalCodeValidate = value => (
        typeof(value) === "string" &&
        value.length === 6 &&
        CoreTools.match(value.substring(0, 1), /[A-Z]/) &&
        CoreTools.match(value.substring(1, 2), /[0-9]/) &&
        CoreTools.match(value.substring(2, 3), /[A-Z]/) &&
        CoreTools.match(value.substring(3, 4), /[0-9]/) &&
        CoreTools.match(value.substring(4, 5), /[A-Z]/) &&
        CoreTools.match(value.substring(5, 6), /[0-9]/)
    );
    onApplicant = (state, props, applicant) => this.setVal({
        addressSet: applicant.field("street_number") && applicant.field("street_name") && applicant.field("city") && applicant.field("state") && applicant.field("zip_code") ? true : false
    });
    content = (state, props, applicant) => <div className="card-body-address">
        <p className="card-title text-center mb-4">Where do you live?</p>
        <div className="form-row text-center mt-2 mb-3">
            <div className="col-12">
                <div id="gm-autocomplete-wrap" className="form-field mb-3 address-form">                    
                    <GoogleAddress
                        country="ca"
                        className="form-control text-center google-address-input"
                        placeholder="Enter Address Here To Lookup"
                        onSelect={address => {
                            applicant.setField("google_address", address.full);
                            applicant.setField("street_number", address.number);
                            applicant.setField("street_name", address.street);
                            applicant.setField("city", address.city);
                            applicant.setField("state", address.provinceShort);
                            applicant.setField("zip_code", address.postalcode.split(" ").join("").toUpperCase());
                            this.setVal({addressSet: true}, () => console.log(this.state.addressSet));
                        }}
                    />
                </div>
            </div>
            <div className="col-12">
                <span onClick={() => {this.setVal({addressSet: true})}} className="manual-address">My Address Is Not Listed</span>
            </div>
        </div>
        {state.addressSet && <div className="card-fields" id="address">
            <div className="form-row">
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="street_number" placeholder="Street Number" title="Street Number" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="street_name" placeholder="Street Name" title="Street Name" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="unit" placeholder="Unit/Apt/Suite" title="Unit/Apt/Suite" className="form-control special-field" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="city" placeholder="City" title="City" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Select type="text" name="state" options={this.provinceOptions} className="form-control province-selector special-field" onValidate={v => v} failClass="invalid" />			
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="zip_code" placeholder="Postal Code" title="Postal Code" className="form-control special-field" onValidate={this.postalCodeValidate} failClass="invalid" onChange={this.postalCodeFormat} />
                </div>
            </div>
        </div>}
        <div className="text-center continue-button-container">
            <button type="submit" className={state.addressSet ? 'continue-button mt-3' : 'disabled-button mt-3'} disabled={!state.addressSet} onClick={this.continue}>Continue</button>
        </div>
    </div>;
}

class PanelHousing extends GenSet {
    continue = () => this.navigate(this.props.continue);
    content = (state, props, applicant) => <VisualPanelFrame title={<>Do you <b>Own/Rent/Neither</b>?</>}>
        <div className="form-row">
            {
                [
                    "Own",
                    "Rent",
                    "Neither"
                ].map((e, i) => <div key={i} className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio name="residence_type" value={e} onClick={this.continue} />
                        <span>{e}</span>
                    </label>
                </div>)
            }
        </div>
    </VisualPanelFrame>;
}

class PanelRent extends GenSet {
    continue = () => this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    content = (state, props) => <VisualPanelFrame title={<>What is your monthly Rent/Mortgage?</>}>
        <div className="card-fields row mt-1"><div className="col-12 mb-3">
            <AppInput.Input name="rent" placeholder="Rent/Mortgage Value" title="Rent/Mortgage Value" onChange={FormValidate.onlyNumbers} className="form-control text-field special-field" onValidate={v => typeof(v) === "string" && v.length} failClass="invalid" />
        </div></div>
        <VisualContinue onClick={this.continue} />
    </VisualPanelFrame>;
}

class PanelEmployment extends GenSet {
    continue = () => this.navigate(this.props.continue);
    content = (state, props, applicant) => <VisualPanelFrame title={<>Select Your Income Source</>}>
        <div className="form-row">
            {
                [
                    "Full Time Employment",
                    "Part Time Employment",
                    "Self Employed",
                    "Unemployed",
                    "Retired",
                    "Disabled",
                    "Social Assistance",
                    "Other"
                ].map((e, i) => <div key={i} className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio name="employment_status" value={e} onClick={this.continue} />
                        <span>{e}</span>
                    </label>
                </div>)
            }
        </div>
    </VisualPanelFrame>;
}

class PanelAnnualIncome extends GenSet {
    continue = () => this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    content = (state, props) => <VisualPanelFrame title={<>What is your <b>Gross Annual Income</b>?</>}>
        <div className="card-fields row mt-1"><div className="col-12 mb-3">
            <AppInput.Input name="income_annual" placeholder="Annual Income, Ex: 10000" title="Annual Income, Ex: 10000" className="form-control text-field special-field" onChange={FormValidate.onlyNumbers} failClass="invalid" onValidate={v => v && (parseInt(v) || 0) > 1000} />
        </div></div>
        <VisualContinue onClick={this.continue} />
    </VisualPanelFrame>;
}

class PanelExpenses extends GenSet {
    continue = () => this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    content = (state, props) => <VisualPanelFrame title={<>What are your <b>Monthly Expenses</b>?</>}>
        {[
            ["Phone", "expense_phone"],
            ["Internet", "expense_internet"],
            ["Electric", "expense_electric"],
            ["Gas", "expense_gas"],
            ["Water", "expense_water"],
            ["Other", "expense_other"]
        ].map((n, i) => <div key={i} className="card-fields row mt-1"><div className="col-12 mb-3">
            <AppInput.Input name={n[1]} placeholder={n[0]} title={n[0]} className="form-control text-field special-field" onChange={FormValidate.onlyNumbers} onValidate={v => typeof(v) === "string" && v.length} failClass="invalid" />    
        </div></div>)}
        <VisualContinue onClick={this.continue} />
    </VisualPanelFrame>;
}

class PanelCredit extends GenSet {
    continue = () => this.navigate(this.props.continue);
    content = (state, props) => <VisualPanelFrame title={<>How's your credit?</>}>
        <div className="form-row colored">
            {[
                ["Excellent", "720+"],
                ["Good", "719-660"],
                ["Fair", "659-600"],
                ["Poor", "< 599"]
            ].map((e, i) => <div key={i} className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="credit_score" value={e[0]} onClick={this.continue} />
                    <span id={"btn-option-" + e[0].toLowerCase()}>{e[1]} {e[0]}</span>
                </label>
            </div>)}
        </div>
    </VisualPanelFrame>;
}

class PanelBankruptcy extends GenSet {
    continue = () => this.navigate(this.props.continue);
    content = (state, props) => <VisualPanelFrame title={<>Any Past Bankruptcy or Consumer Proposals?</>}>
        <div className="form-row colored">
            {[
                "No",
                "Yes"
            ].map((e, i) => <div key={i} className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="has_bankruptcy" value={e} onClick={this.continue} />
                    <span>{e}</span>
                </label>
            </div>)}
        </div>
    </VisualPanelFrame>;
}

class PanelCitizenshipStatus extends GenSet {
    continue = () => this.navigate(this.props.continue);
    content = (state, props, applicant) => <VisualPanelFrame title={<>What's your Citizenship Status?</>}>
        <div className="form-row">
            {
                [
                    "Canadian Citizen",
                    "Permanent Resident",
                    "Work Permit",
                    "International Student",
                    "Visitor",
                    "Other"
                ].map((e, i) => <div key={i} className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio name="citizenship_status" value={e} onClick={this.continue} />
                        <span>{e}</span>
                    </label>
                </div>)
            }
        </div>
    </VisualPanelFrame>;
}

class PanelProcessing extends GenSet {
    onApplicant = (state, props, applicant) => applicant.post("loanconnect-ca", "apply", {});
    content = (state, props) => <VisualLoading title="Your Application is being Processed" />;
}

class PanelOptions extends GenSet {
    init = this.setVal({wall: this.applicant.field("wall") || []});
    onApplicant = (state, props, applicant) => {
        if (!state.wall.length) {this.setVal({wall: applicant.field("wall") || []});}
        this.wHolder(applicant.onFieldUpdate("wall", value => this.setVal({wall: value})));
        CoreTools.log("Wall:", this.applicant.field("wall"));
        // setTimeout(() => CoreTools.log("Wall:", state.wall), 2000);
    };
    content = (state, props, applicant) => <>
        <VisualPanelFrame title="Your Offers"><div className="horizontal-line mt-4 mb-4"></div></VisualPanelFrame>
        {state.wall.map((opt, index) => <VisualPanelFrame key={index} title={opt.lender_name} style={{marginTop: "30px"}}>
            <div>
                <img src={opt.lender_logo} alt={opt.lender_name} style={{width: "80%", maxWidth: "500px", maxHeight: "250px"}} />
            </div>
            <div>
                {opt.amount && <div>{opt.preapproved && <span className="text-success">PreApproved </span>}Offer: {opt.amount}</div>}
                {opt.term && <div>Loan Term: {opt.term} Months</div>}
                {CoreTools.switch(
                    [opt.monthly_payment_range_high && opt.monthly_payment_range_low, () => <div>Monthly Payments: ${opt.monthly_payment_range_low} - ${opt.monthly_payment_range_high}</div>],
                    [opt.monthly_payment_range_low, () => <div>Monthly Payments: ${opt.monthly_payment_range_low}</div>],
                    [opt.monthly_payment_range_high, () => <div>Monthly Payments: ${opt.monthly_payment_range_high}</div>]
                )}
                {CoreTools.switch(
                    [opt.apr_range_high && opt.apr_range_low, () => <div>APR: {opt.apr_range_low} - {opt.apr_range_high}</div>],
                    [opt.apr_range_low, () => <div>APR: {opt.apr_range_low}</div>],
                    [opt.apr_range_high, () => <div>APR: {opt.apr_range_high}</div>]
                )}
                {opt.has_applied && <div className="text-danger">You have already applied for this offer.</div>}
            </div>
            <a key={index} href={opt.select_url} target="_blank" rel="noreferrer" style={{cursor: "pointer", width: "100%"}}>
                <VisualContinue text={"Apply - " + opt.lender_name} />
            </a>
            <div className="horizontal-line mt-4 mb-4"></div>
        </VisualPanelFrame>)}
    </>;
}

const panelGroups = {
    main: {
        "#Loading":     <PanelLoading />,
        "#Amount":      <PanelAmount />,
        "#Reason":      <PanelReason />,
        "#Name":        <PanelName />,
        "#Email":       <PanelEmail />,
        "#Phone":       <PanelPhone />,
        "#DOB":         <PanelDob />,
        "#Address":     <PanelAddress />,
        "#Housing":     <PanelHousing />,
        "#Rent":        <PanelRent />,
        "#Employment":  <PanelEmployment />,
        "#Income":      <PanelAnnualIncome />,
        "#Expenses":    <PanelExpenses />,
        "#Credit":      <PanelCredit />,
        "#Bankruptcy":  <PanelBankruptcy />,
        "#Citizenship": <PanelCitizenshipStatus />,
        "#Processing":  <PanelProcessing />
    },
    options: {
        "#Options": <PanelOptions />
    },
    noOptions: {}
};

export default class LoanConnectCanadaIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (CoreTools.state.appOptions.includes("nocss")) {
            this.setVal({cssBootstrap: true, cssMain: true});
        } else {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css", () => this.setVal({cssBootstrap: true}));
            this.importStyle("/css/Loanz.css", () => this.setVal({cssMain: true}));
            this.importStyle("/css/animate.css");
        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider3.css");
        this.wHolder(this.applicant.on("lcWall", resp => {
            console.log("lcWall", resp);
            if (CoreTools.isObject(resp) && resp.statusCode === 200 && CoreTools.isObject(resp.data) && resp.data.status && Array.isArray(resp.data.result)) {
                this.applicant.setField("wall", resp.data.result);
                if (resp.data.result.length) {this.navigate("#Options");}
                else {this.navigate("https://loanz.ca/apply/f2/processing");}
            } else {
                this.navigate("https://loanz.ca/apply/f2/processing");
            }
        }));
        this.wHolder(this.applicant.on("inject", data => {
            console.log("inject", data);
            if (CoreTools.isObject(data)) {
                Object.keys(data).forEach(key => this.applicant.setField(key, data[key]));
            }
        }));
    })();
    onMount = (state, props) => this.onNavigate(location => this.setVal({hash: location.hash}, () => setTimeout(CoreTools.centerForm, 50)));
    content = (state, props) => state.cssBootstrap && state.cssMain && <>
        <div className="main-content">
            {!["#Options", "#NoOptions"].includes(state.hash) && <PanelEffects.ProgressBar
                caption=" "
                barColor="#40AD87"
                className="progress-bar"
                style={{
                    borderStyle: "solid",
                    borderWidth: "2px", 
                    borderColor: "#ffffff",
                    backgroundColor: "rgb(233, 236, 239)",
                    borderRadius: "5px", 
                    padding: "2px", 
                    fontSize: "13px",
                    margin: "0 10px"
                }}
            />}
            <PanelEffects.Transition1 className="panel-container">
                <div id="appForm" style={{marginTop: "15px"}} key={state.hash}>
                    <div className="appForm_inner">
                        <PanelEffects.Selector
                            selector={state.hash}
                            panels={CoreTools.switch(
                                [Object.keys(panelGroups.options).includes(state.hash), () => panelGroups.options],
                                [Object.keys(panelGroups.noOptions).includes(state.hash), () => panelGroups.noOptions],
                                [true, () => panelGroups.main]
                            )}
                        />
                    </div>
                </div>
            </PanelEffects.Transition1>
        </div>
    </>;
}
