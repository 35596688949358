import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";
import SubForm from "../../../services/SubForm";

// const isDate = dob => {
//     if (!dob || typeof(dob) !== "string") {return false;}
//     let dobSplit = dob.split("-");
//     if (
//         dobSplit.length === 3 && 
//         (Number(dobSplit[0]) || 0) > 1900 &&
//         (Number(dobSplit[0]) || 0) <= new Date().getFullYear() &&
//         (Number(dobSplit[1]) || 0) >= 1 &&
//         (Number(dobSplit[1]) || 0) <= 12 &&
//         (Number(dobSplit[2]) || 0) >= 1 &&
//         (Number(dobSplit[2]) || 0) <= 31
//     ) {return true;}
//     return false;
// };

class EmployerName extends GenSet {
    continue = () => {
        this.props.employment.validate(isValid => isValid ? this.props.next() : window.scrollTo(0, 0));
    };

    handleChange = (event) => {
            return event.target.value.length
    };

    content = (state, props) => <div className="card card-inputs last">
        <div className="card-body">
            <p className="card-title text-center">Where do you work?</p>
            <div className="horizontal-line mt-4 mb-4"></div>
            <div className="card-fields row">
                <div className="col-12 mt-2 mb-3">
                    <AppInput.Input subForm={props.employment} type="text" name="employer_name" className="form-control text-field special-field short" placeholder="Employer Name" onValidate={v => v} failClass="invalid" onChange={() => this.setVal({display: true})} />
                </div>
            </div>
            <div className="text-center continue-button-container">
                <button type="submit" className={state.display ? 'continue-button mt-3' : 'disappear-button'} onClick={this.continue}>Continue</button>
            </div>
        </div>
    </div>;
};

class EmploymentStatus extends GenSet {
    init = console.log(this.props.employment.fields, 'employment')

    continue = () => {
        this.props.employment.validate(isValid => isValid ? this.props.next() : window.scrollTo(0, 0));
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <p className="card-title text-center">What Is Your Employment Status?</p>
            <div className="horizontal-line mt-4 mb-4"></div>
            <div className="card-fields row mt-1">
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="employment_status" value="Full-Time Employed" onClick={() => this.setVal({display: true})}/>
                        <span>Full-Time Employed</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="employment_status" value="Part-Time Employed" onClick={() => this.setVal({display: true})}/>
                        <span>Part-Time Employed</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="employment_status" value="Pension" onClick={() => this.setVal({display: true})}/>
                        <span>Pension</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="employment_status" value="Canada Pension Plan (CPP)" onClick={() => this.setVal({display: true})}/>
                        <span>Canada Pension Plan (CPP)</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="employment_status" value="Old Age Security (OAS)" onClick={() => this.setVal({display: true})}/>
                        <span>Old Age Security (OAS)</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="employment_status" value="Other" onClick={() => this.setVal({display: true})}/>
                        <span>Other</span>
                    </label>
                </div>
                <div className="text-center continue-button-container">
                    <button type="submit" className={state.display ? 'continue-button mt-3' : 'disappear-button'} onClick={this.continue}>Continue</button>
                </div>
            </div>
        </div>
    </div>;
};

class NetPay extends GenSet {
    continue = () => {
        this.props.employment.validate(isValid => isValid ? this.props.next() : window.scrollTo(0, 0));
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <p className="card-title text-center">What Was Your Last Net* Pay?</p>
            <div className="horizontal-line mt-4 mb-3"></div>
            <div className="card-fields row">
                <div className="form-subtext">
                    <p className="text-center">For your last pay period.
                    <br />
                    <b>*take home pay after taxes and deductions</b></p>
                </div>
                <div className="col-12 adjusted-col">
                    <span className="currency"><b>$</b></span>
                    <AppInput.Input subForm={props.employment} type="text" name="net_pay" className="form-control text-field special-field no-margins short mb-3" placeholder="Enter Dollar Amount" onValidate={v => v} failClass="invalid" onChange={() => this.setVal({display: true})}/>
                </div>
            </div>
            <div className="text-center continue-button-container">
                <button type="submit" className={state.display ? 'continue-button mt-3' : 'disappear-button'} onClick={this.continue}>Continue</button>
            </div>
        </div>
    </div>;
};

class DirectDeposit extends GenSet {
    continue = () => {
        this.props.employment.validate(isValid => isValid ? this.props.next() : window.scrollTo(0, 0));
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <p className="card-title text-center mb-1">Was This Through Direct Deposit?</p>
            <div className="horizontal-line mt-4 mb-4"></div>
            <div className="card-fields row">
                <div className="col-12 mt-1">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="direct_deposit" value="Yes Direct Deposit" onClick={() => this.setVal({display: true})}/>
                        <span>Yes</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="direct_deposit" value="No Direct Deposit" onClick={() => this.setVal({display: true})}/>
                        <span>No</span>
                    </label>
                </div>
                <div className="text-center continue-button-container">
                    <button type="submit" className={state.display ? 'continue-button mt-3' : 'disappear-button'} onClick={this.continue}>Continue</button>
                </div>
            </div>
        </div>
    </div>;
};

class PayType extends GenSet {
    continue = () => {
        this.props.employment.validate(isValid => isValid ? this.props.next() : window.scrollTo(0, 0));
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <p className="card-title text-center mb-1">What Is Your Pay Type?</p>
            <div className="horizontal-line mt-4 mb-4"></div>
            <div className="card-fields row">
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="pay_type" value="Hourly Pay Type" onClick={() => this.setVal({display: true})}/>
                        <span>Hourly</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="pay_type" value="Salary Pay Type" onClick={() => this.setVal({display: true})}/>
                        <span>Salary</span>
                    </label>
                </div>
            </div>
            <div className="text-center continue-button-container">
                <button type="submit" className={state.display ? 'continue-button mt-3' : 'disappear-button'} onClick={this.continue}>Continue</button>
            </div>
        </div>
    </div>;
};

class WorkExperience extends GenSet {
    continue = () => {
        this.props.employment.validate(isValid => isValid ? this.props.next() : window.scrollTo(0, 0));
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <p className="card-title text-center mb-1">How Long Have You Been Working Here?</p>
            <div className="horizontal-line mt-4 mb-4"></div>
            <div className="card-fields row">
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="work_experience" value="Less Than 2 Months" onClick={() => this.setVal({display: true})}/>
                        <span>Less Than 2 Months</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="work_experience" value="2 - 12 Months" onClick={() => this.setVal({display: true})}/>
                        <span>2 - 12 Months</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="work_experience" value="1 - 2 Years" onClick={() => this.setVal({display: true})}/>
                        <span>1 - 2 Years</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="work_experience" value="2 - 3 Years" onClick={() => this.setVal({display: true})}/>
                        <span>2 - 3 Years</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="work_experience" value="3 - 5 Years" onClick={() => this.setVal({display: true})}/>
                        <span>3 - 5 Years</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="work_experience" value="More Than 5 Years" onClick={() => this.setVal({display: true})}/>
                        <span>More Than 5 Years</span>
                    </label>
                </div>
                <div className="text-center continue-button-container">
                    <button type="submit" className={state.display ? 'continue-button mt-3' : 'disappear-button'} onClick={this.continue}>Continue</button>
                </div>
            </div>
        </div>
    </div>;
};

class PayPeriod extends GenSet {
    continue = () => {
        this.props.employment.validate(isValid => isValid ? this.props.next() : window.scrollTo(0, 0));
    };
    
    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <p className="card-title text-center">How Often Do You Get Paid This?</p>
            <div className="horizontal-line mt-4 mb-4"></div>
            <div className="card-fields row">
                <div className="col-12">
                    <label className="btn btn-option stacked">
                        <AppInput.Radio subForm={props.employment} name="pay_period" value="Monthly" onClick={() => this.setVal({display: true})}/>
                        <span>
                            <div className="payment-frequency-text">Monthly</div>
                            <div className="payment-frequency-subtext">Once a Month</div>
                        </span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option stacked">
                        <AppInput.Radio subForm={props.employment} name="pay_period" value="Bi-Monthly" onClick={() => this.setVal({display: true})}/>
                        <span>
                            <div className="payment-frequency-text">Bi-Monthly</div>
                            <div className="payment-frequency-subtext">Twice a Month</div>
                        </span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option stacked">
                        <AppInput.Radio subForm={props.employment} name="pay_period" value="Weekly" onClick={() => this.setVal({display: true})}/>
                        <span>
                            <div className="payment-frequency-text">Weekly</div>
                            <div className="payment-frequency-subtext">Once a Week</div>
                        </span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option stacked">
                        <AppInput.Radio subForm={props.employment} name="pay_period" value="Bi-Weekly" onClick={() => this.setVal({display: true})}/>
                        <span>
                            <div className="payment-frequency-text">Bi-Weekly</div>
                            <div className="payment-frequency-subtext">Every Two Weeks</div>
                        </span>
                    </label>
                </div>
                <div className="text-center mt-4 continue-button-container">
                    <button type="submit" className={state.display ? 'continue-button mt-3' : 'disappear-button'} onClick={this.continue}>Continue</button>
                </div>
            </div>
        </div>
    </div>;
};

class PaySchedule extends GenSet {
    continue = () => {
        this.props.employment.validate(isValid => isValid ? this.props.next() : window.scrollTo(0, 0));
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <p className="card-title text-center">What Is Your Payment Schedule?</p>
            <div className="horizontal-line mt-4 mb-4"></div>
            <div className="card-fields row">
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="pay_schedule" value="Same Day of the Month" onClick={() => this.setVal({display: true})}/>
                        <span>Same Day of the Month</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="pay_schedule" value="Specific Date of the Month" onClick={() => this.setVal({display: true})}/>
                        <span>Specific Date of the Month</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option">
                        <AppInput.Radio subForm={props.employment} name="pay_schedule" value="Last Day of the Month" onClick={() => this.setVal({display: true})}/>
                        <span>Last Day of the Month</span>
                    </label>
                </div>
            </div>
            <div className="text-center mt-4 continue-button-container">
                <button type="submit" className={state.display ? 'continue-button mt-3' : 'disappear-button'} onClick={this.continue}>Continue</button>
            </div>
        </div>
    </div>;
};

class PayLastDate extends GenSet {
    continue = () => {
        this.props.employment.validate(isValid => isValid ? this.props.next() : window.scrollTo(0, 0));
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <p className="card-title text-center">When Was Your Last Pay Date?</p>
            <div className="horizontal-line mt-4 mb-3"></div>
            <div className="form-subtext widest no-margins mb-3">
                <p className="text-center no-margins">Please select today's date if you are getting paid today.
                <br />
                <b>*Last Pay Date may not exceed Today.</b></p>
            </div>
            <div className="card-fields row">
                <div className="col-12">
                    <AppInput.Input subForm={props.employment} type="text" name="last_pay_date" className="form-control text-field special-field mb-1" placeholder="YYYY/MM/DD" onValidate={v => v} failClass="invalid" onChange={() => this.setVal({display: true})}/>
                </div>
            </div>
            <div className="text-center continue-button-container">
                <button type="submit" className={state.display ? 'continue-button mt-4' : 'disappear-button'} onClick={this.continue}>Continue</button>
            </div>
        </div>
    </div>;
};

class PayNextDate extends GenSet {
    continue = () => {
        this.props.employment.validate(isValid => isValid ? this.props.next() : window.scrollTo(0, 0));
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <p className="card-title text-center">When Is Your Next Pay Date?</p>
            <div className="horizontal-line mt-4 mb-3"></div>
            <div className="form-subtext widest no-margins mb-3">
                <p className="text-center no-margins">
                <br />
                <b>*Next Pay Date must be greater than Today.</b></p>
            </div>
            <div className="card-fields row">
                <div className="col-12">
                    <AppInput.Input subForm={props.employment} type="text" name="next_pay_date" className="form-control text-field special-field mb-1" placeholder="YYYY/MM/DD" onValidate={v => v} failClass="invalid" onChange={() => this.setVal({display: true})}/>
                </div>
            </div>
            <div className="text-center continue-button-container">
                <button type="submit" className={state.display ? 'continue-button mt-4' : 'disappear-button'} onClick={this.continue}>Continue</button>
            </div>
        </div>
    </div>;
};

class PayFollowingDate extends GenSet {
    continue = () => {
        this.props.employment.validate(isValid => isValid ? this.props.next() : window.scrollTo(0, 0));
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <p className="card-title text-center">When Is Your Next Following Monthly Payment?</p>
            <div className="horizontal-line mt-4 mb-3"></div>
            <div className="form-subtext widest no-margins mb-3">
                <p className="text-center no-margins">
                <br />
                <b>*Following Pay Date must be greater than Next Pay Date.</b></p>
            </div>
            <div className="card-fields row">
                <div className="col-12">
                    <AppInput.Input subForm={props.employment} type="text" name="following_pay_date" className="form-control text-field special-field mb-1" placeholder="YYYY/MM/DD" onValidate={v => v} failClass="invalid" onChange={() => this.setVal({display: true})}/>
                </div>
            </div>
            <div className="text-center continue-button-container">
                <button type="submit" className={state.display ? 'continue-button mt-4' : 'disappear-button'} onClick={this.continue}>Continue</button>
            </div>
        </div>
    </div>;
};

export default class LoanzEmployer extends GenSet {
    init = (() => {
        this.setVal({
            employments: Array.isArray(this.applicant.field("employments")) ? this.applicant.field("employments") : [],
            employment: false,
            panel: "EmploymentOverview"
        });
    })();


    continue = () => {
        this.navigate(this.props.continue);
    };

    onMount = (state, props) => {
        this.wHolder(this.applicant.onFieldUpdate("employments", newValue => this.setVal({employments: Array.isArray(newValue) ? newValue : []})));
    };

    addEmployment = () => this.setVal({employment: SubForm(), panel: "EmployerName"});
    saveEmployment = () => this.setVal({
        employments: [...this.state.employments, this.state.employment.fields],
        employment: false
    }, () => this.applicant.setField("employments", this.state.employments));
    empSelect = panel => () => this.setVal({panel: panel});

    employmentItem = (emp, index) => <table key={index} className="display-employment">
        <tbody>
            <tr>
                <td colSpan="2">
                    <span><b>{emp.employer_name}</b></span>
                    <span onClick={() => this.removeEmploymentItem(emp, index)} className="delete"></span>
                </td>
            </tr>
            <tr><td>Status:</td><td className="employment-item" onClick={this.empSelect("EmploymentStatus", emp)}><li>{emp.employment_status}</li></td></tr>
            
            {/* {this.minimumNetPay(emp) < 1500 && <tr><td colSpan="2" className="table-error-message employment-item" onClick={this.empSelect("NetPay")}><span>Please confirm your Net Pay is accurate.</span></td></tr>} */}
           
            <tr><td>Net Pay:</td><td className="employment-item" onClick={() => console.log(emp.net_pay)}><li>{emp.net_pay}</li></td></tr>
            <tr><td>Direct Deposit:</td><td className="employment-item" onClick={this.empSelect("DirectDeposit", emp)}><li>{emp.direct_deposit}</li></td></tr>
            <tr><td>Pay Type:</td><td className="employment-item" onClick={this.empSelect("PayType", emp)}><li>{emp.pay_type}</li></td></tr>
            <tr><td>Months Employed:</td><td className="employment-item" onClick={this.empSelect("WorkExperience", emp)}><li>{emp.work_experience}</li></td></tr>
            <tr><td>Payment Frequency:</td><td className="employment-item" onClick={this.empSelect("PayPeriod", emp)}><li>{emp.pay_period}</li></td></tr>
            <tr><td>Monthly Payment Schedule:</td><td className="employment-item" onClick={this.empSelect("PaySchedule", emp)}><li>{emp.pay_schedule}</li></td></tr>

            <tr><td colSpan="2" className="table-error-message employment-item" onClick={this.empSelect("LastPayDate", emp)}><span >Last Pay Date is out of range.</span></td></tr>
            <tr><td>Last Pay Date:</td><td className="table-error-highlight employment-item" onClick={this.empSelect("LastPayDate", emp)}><li>{emp.last_pay_date}</li></td></tr>

            <tr><td colSpan="2" className="table-error-message employment-item" onClick={this.empSelect("NextPayDate", emp)}><span>Next Pay Date is out of range of Last Pay Date.</span></td></tr>
            <tr><td>Next Pay Date:</td><td className="table-error-highlight employment-item" onClick={this.empSelect("NextPayDate", emp)}><li>{emp.next_pay_date}</li></td></tr>
            
            <tr><td colSpan="2" className="table-error-message employment-item" onClick={this.empSelect("FollowingPayDate", emp)}><span>Following Pay Date is out of range of Last Pay Date.</span></td></tr>
            <tr><td>Following Pay Date:</td><td className="table-error-highlight employment-item" onClick={this.empSelect("FollowingPayDate", emp)}><li>{emp.following_pay_date}</li></td></tr>
        </tbody>
    </table>;

    removeEmploymentItem = (emp, index) => {
        console.log('delete', index);
        console.log('delete', emp);
    
        let currentEmployment = this.applicant.fields.employments;
        console.log('currentEmployment', currentEmployment);
        
        return currentEmployment.filter((employment, index) => employment !== index);
        // reload the page to update the state?
    };
    
    employmentOverview = () => <div className="card card-inputs last">
        <div className="card-body">
            <p className="card-title text-center mb-2">Great - Enter Your Employer And Income Details</p>
            <div className="form-subtext">
                <p className="text-center mt-4">Include payroll, pension, commissions, etc.</p>
            </div>
            {/* 
            if an error is present, display this message:
            <div>
                <span>If any of the fields within the listed employer are incorrect you can click the value to edit.</span>
            </div> 
            */}
            <div className="horizontal-line-two"></div>
            {this.state.employments.map(this.employmentItem)}

            {/* {this.test()} */}
            
            <div className="text-center add-employer-button-container mt-3 mb-4">
                <button type="submit" onClick={this.addEmployment}>Add Employer</button>
            </div>
            <div className="text-center mt-2 continue-button-container-red">
            </div>
                <span type="submit" onClick={this.continue} disabled={!this.state.employments.length} className={this.state.employments.length ? "continue-button" : "red-add-employer-continue-button"}>{this.state.employments.length ? "Continue" : "Add Employer to Continue" }</span>
            {/* if error exists = "Complete above to continue"
            if everything is good it is not a red button but a colorful one */}
        </div>
    </div>;

    content = (state, props) => <div className="card card-inputs last">{
        state.employment ? CoreTools.switch(
            state.panel,
            ['EmployerName',        <EmployerName           employment={state.employment} next={this.empSelect("EmploymentStatus")} />],
            ['EmploymentStatus',    <EmploymentStatus       employment={state.employment} next={this.empSelect("NetPay")} />],
            ['NetPay',              <NetPay                 employment={state.employment} next={this.empSelect("DirectDeposit")} />],
            ['DirectDeposit',       <DirectDeposit          employment={state.employment} next={this.empSelect("PayType")} />],
            ['PayType',             <PayType                employment={state.employment} next={this.empSelect("WorkExperience")} />],
            ['WorkExperience',      <WorkExperience         employment={state.employment} next={this.empSelect("PayPeriod")} />],
            ['PayPeriod',           <PayPeriod              employment={state.employment} next={this.empSelect("PaySchedule")} />],
            ['PaySchedule',         <PaySchedule            employment={state.employment} next={this.empSelect("PayLastDate")} />],
            ['PayLastDate',         <PayLastDate            employment={state.employment} next={this.empSelect("PayNextDate")} />],
            ['PayNextDate',         <PayNextDate            employment={state.employment} next={this.empSelect("PayFollowingDate")} />],
            ['PayFollowingDate',    <PayFollowingDate       employment={state.employment} next={this.saveEmployment} />],
        ) : this.employmentOverview()
    }</div>;
}
