import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";

export default class SurexEducation extends GenSet {
    continue = (state, props) => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };
    
    content = (state, props) => <SurexComponents.Card
        title="What is your current level of education?"
    >
        <div className="form-row justify-content-center">
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="education" value="LessThanHighSchoolDiploma" onClick={this.continue}/>
                    <span>Less Than High School Diploma</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="education" value="HighSchool" onClick={this.continue}/>
                    <span>HighSchool</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="education" value="GED" onClick={this.continue}/>
                    <span>GED</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="education" value="AssociateDegree" onClick={this.continue}/>
                    <span>Associate Degree</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="education" value="BachelorsDegree" onClick={this.continue}/>
                    <span>Bachelors Degree</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="education" value="MastersDegree" onClick={this.continue}/>
                    <span>Masters Degree</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="education" value="OtherProfessionalDegree" onClick={this.continue}/>
                    <span>Other Professional Degree</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="education" value="OtherNonProfessionalDegree" onClick={this.continue}/>
                    <span>Other Non-Professional Degree</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="education" value="SomeCollegeNoDegree" onClick={this.continue}/>
                    <span>Some College, No Degree</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="education" value="VocationalTechnical" onClick={this.continue}/>
                    <span>Vocational Technical</span>
                </label>
            </div>
        </div>
    </SurexComponents.Card>;
}
