import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";
import FormValidate from "../../../services/FormValidate";
import OptionLists from "../../../services/OptionLists";
import GoogleAddress from "../../../directives/GoogleAddress";

let subValidate = null;

class PanelContact extends GenSet {
    phoneFormat = event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };
    
    yearOptions = (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year - 17; i > year - 100; i--) {years.push(i.toString());} return years;})();
    monthOptions = (() => {let months = [{value: "", caption: "Month"}]; for (let i = 1; i <= 12; i++) {months.push(i.toString());} return months;})();
    dayOptions = (() => {let days = [{value: "", caption: "Day"}]; for (let i = 1; i <= 31; i++) {days.push(i.toString());} return days;})();

    content = (state) => <div className="form-panel">
        <hr className="horizontal-line"/>
        <p className="customer-message-text text-center">Tell us a little about yourself.</p>
        <div className="row">
            <div className="col-md-6">
                <span className="label">First Name</span>
                <span className="input-group">                    
                    <AppInput.Input name="first_name" placeholder="First Name" onValidate={FormValidate.isName} onChange={FormValidate.onlyLetters} failClass="invalid" />
                </span>
            </div>
            <div className="col-md-6">
                <span className="label">Last Name</span>   
                <span className="input-group">                    
                    <AppInput.Input name="last_name" placeholder="Last Name" onValidate={FormValidate.isName} onChange={FormValidate.onlyLetters} failClass="invalid" />
                </span>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <span className=" label">Email</span>
                <span className="input-group">                    
                    <AppInput.Input name="email" onValidate={FormValidate.isEmail} placeholder="email@email.com" failClass="invalid" />
                </span>
            </div>
            <div className="col-md-6">
                <span className="label">Phone Number</span>
                <span className="input-group">                    
                    <AppInput.Input name="phone" onValidate={FormValidate.isPhone} placeholder="(XXX) XXX-XXXX" onChange={this.phoneFormat} onKeyDown={FormValidate.maxLength(10)} type="tel" failClass="invalid" />
                </span>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <span className="label">Birthdate</span>
                <div className="row">
                    <div className="col-4">
                        <span className="input-group">                    
                        <AppInput.Select name="dob_month" options={this.monthOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                    <div className="col-4">
                        <span className="input-group">                    
                        <AppInput.Select name="dob_day" options={this.dayOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                    <div className="col-4">
                        <span className="input-group">                    
                        <AppInput.Select name="dob_year" options={this.yearOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

class PanelAddress extends GenSet {
    province = {
        options: [
            {value: "", caption: "Select Province"},
            ...OptionLists.Provinces.map(ol => ({value: ol.short, caption: ol.long}))
        ]
    };

    residenceLength = [
        {caption: "Please Select", value: ""},
        {caption: "Less Than 3 Months", value: "Less Than 3 Months"},
        {caption: "3 - 6 Months", value: "3 - 6 Months"},
        {caption: "6 - 12 Months", value: "6 - 12 Months"},
        {caption: "12 Months - 2 Years", value: "12 Months - 2 Years"},
        {caption: "2 Years +", value: "2 Years +"},
    ];

    residentialStatus = [
        {short: "", caption: "Please Select"},
        {short: "Rent", caption: "Rent"},
        {short: "Own", caption: "Own"},
    ];

    residenceCost = (() => {
        let cost = []; 
        for(let i = 0; i <= 49; i++){cost.push(i*100);}
        return[{value: "", caption: "Please Select"},...cost.map(x => ({value: x.toString(), caption: `$${x}`})), {value: "5000", caption: "$5000+"}];})();

    postalCode = {
        format: event => {
            if (typeof(event.target.value) === "string") {
                const proper = event.target.value.replace(/[^a-z0-9]/gi, "").toUpperCase().substring(0, 6);
                if (event.target.value !== proper) {
                    const cPos = event.target.selectionStart;
                    event.target.value = proper;
                    event.target.selectionStart = cPos;
                    event.target.selectionEnd = cPos;
                }
            }
        },
        validate: value => (
            typeof(value) === "string" &&
            value.length === 6 &&
            CoreTools.match(value.substring(0, 1), /[A-Z]/) &&
            CoreTools.match(value.substring(1, 2), /[0-9]/) &&
            CoreTools.match(value.substring(2, 3), /[A-Z]/) &&
            CoreTools.match(value.substring(3, 4), /[0-9]/) &&
            CoreTools.match(value.substring(4, 5), /[A-Z]/) &&
            CoreTools.match(value.substring(5, 6), /[0-9]/)
        )
    };

    content = (state, props, applicant) => <div className="form-panel">
        <hr className="horizontal-line"/>
        <p className="customer-message-text text-center">Tell us a little about your current living situation.</p>
        <div>
            <span className="label">Search Address</span>
                <div className="input-group-large">
                <GoogleAddress
                country="ca"
                className="width-6"
                placeholder="Type Here to Search For Your Address"
                onSelect={address => {
                    applicant.setField("google_address", address.full);
                    applicant.setField("street_number", address.number);
                    applicant.setField("street_name", address.street);
                    applicant.setField("city", address.city);
                    applicant.setField("state", address.provinceShort);
                    applicant.setField("zip_code", address.postalcode.split(" ").join("").toUpperCase());
                }}
            /></div>
           </div>
        <div className="row">
            <div className="col col-sm-4 col-12">
                <span className="label">Street Number</span>
                <span className="input-group">                    
                    <AppInput.Input name="street_number" placeholder="Street Number" onValidate={v => v} failClass="invalid" />
                </span>
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">Street Name</span>   
                <span className="input-group">                    
                    <AppInput.Input name="street_name" placeholder="Street Name" onValidate={v => v} failClass="invalid" />
                </span>
            </div>   
            <div className="col col-sm-4 col-12">
                <span className="label">Unit / Apt / Suite</span>
                <span className="input-group">                    
                    <AppInput.Input name="unit" placeholder="U / A / S" />
                </span>
            </div>      
        </div>
        <div className="row">
            <div className="col col-sm-4 col-12">
                <span className="label">City</span>   
                <span className="input-group">                    
                    <AppInput.Input name="city" placeholder="City" onValidate={v => v} failClass="invalid" />
                </span>
            </div>         
            <div className="col col-sm-4 col-12">
                <span className="label">Postal Code</span>
                <span className="input-group">                    
                    <AppInput.Input name="zip_code" placeholder="Postal Code" className="width-2" onValidate={this.postalCode.validate} failClass="invalid" onChange={this.postalCode.format} />
                </span>
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">Province</span>   
                <span className="input-group">                   
                    <AppInput.Select name="state" options={this.province.options} className="width-4" onValidate={v => v} failClass="invalid" />
                </span>
            </div>         
        </div>
        <div className="row">
            <div className="col col-sm-4 col-12">
                <span className="label">Time at Address</span>   
                <span className="input-group">                   
                    <AppInput.Select name="residence_duration" options={this.residenceLength} onValidate={v => v} failClass="invalid" />
                </span>
            </div>  
            <div className="col col-sm-4 col-12">
                <span className="label">Rent/Own</span>   
                <span className="input-group">                   
                    <AppInput.Select name="residence_type" options={this.residentialStatus} onValidate={v => v} failClass="invalid" />
                </span>
            </div>           
            <div className="col col-sm-4 col-12">
                <span className="label">Rent/Mortgage Cost</span>   
                <span className="input-group">                   
                    <AppInput.Select name="monthly_residence_cost" options={this.residenceCost} onValidate={v => v} failClass="invalid" />
                </span>
            </div>         
        </div>
    </div>
};

class PanelEmployment extends GenSet {
    employmentTimeOptions = [
        {caption: "Please Select", value: ""},
        {caption: "<3 months", value: "less_than_3_months"},
        {caption: "4 - 6 months", value: "4_to_6_months"},
        {caption: "7 - 9 months", value: "7_to_9_months"},
        {caption: "10 - 12 months", value: "10_to_12_months"},
        {caption: "1 - 2 years", value: "1_to_2_years"},
        {caption: "2 - 3 years", value: "2_to_3_years"},
        {caption: "4 - 8 years", value: "4_to_8_years"},
        {caption: "Over 8 years", value: "over_8_years"},
    ];

    employmentStatus = [
        {caption: "Please Select", value: ""},
        {caption: "Employed", value: "Employed"}, 
        {caption: "Unemployed", value: "Unemployed"},
        {caption: "Self-Employed", value: "Self Employed"},
        {caption: "Retired", value: "Retired"},
        {caption: "Disabled", value: "Disabled"},
        {caption: "Other", value: "Other"},
    ];

    monthlyIncome = [
        {caption: "Please Select", value: ""},
        {caption: "Under $1800", value: "1799"},
        {caption: "$1800 to $2200", value: "2200"},
        {caption: "$2200 to $3000", value: "3000"},
        {caption: "$3000 to $4500", value: "4500"},
        {caption: "Over $4500", value: "5000"},
    ];

    employmentDuration = [
        {caption: "Please Select", value: ""},
        {caption: "Less Than 3 Months", value: "Less Than 3 Months"},
        {caption: "3 - 6 Months", value: "3 - 6 Months"},
        {caption: "6 - 12 Months", value: "6 - 12 Months"},
        {caption: "12 Months - 2 Years", value: "12 Months - 2 Years"},
        {caption: "2 Years +", value: "2 Years +"},
    ];
    
    content = () => <div className="form-panel">
        <hr className="horizontal-line"/>
        <p className="customer-message-text text-center">Tell us a little about your current work situation.</p>
        <div className="row">
            <div className="col col-sm-4 col-12">
                <span className="label">Employment Status</span>   
                <span className="input-group">                    
                    <AppInput.Select name="employment_status" options={this.employmentStatus} onValidate={v => v} failClass="invalid" />
                </span>
            </div>     
            <div className="col col-sm-4 col-12">
                <span className="label">Monthly Income</span>
                <span className="input-group">   
                    <p className="currency-holder">$</p>
                    <AppInput.Select name="gross_monthly_income" className="special-input" options={this.monthlyIncome} onValidate={v => v} failClass="invalid" />
                </span>
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">Employment Duration</span>
                <span className="input-group">   
                    <AppInput.Select name="employment_duration" options={this.employmentDuration} onValidate={v => v} failClass="invalid" />
                </span>
            </div>
        </div>
        <span className="customer-message-text">We will never contact your employer. Lenders just need to understand how you generate an income.</span>
        <div className="row">
            <div className="col col-sm-4 col-12">
                <span className="label">Name of Company</span>
                <span className="input-group">                    
                    <AppInput.Input name="company_name" onValidate={v => v} onChange={FormValidate.onlyLetters} failClass="invalid" />
                </span>
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">Job Title</span>
                <span className="input-group">                    
                    <AppInput.Input name="job_title" onValidate={v => v} onChange={FormValidate.onlyLetters} failClass="invalid" />
                </span>
            </div>
        </div>
    </div>
};

class PanelVehicle extends GenSet {
    vehicleType = [
        {caption: "Please Select", value: ""},
        {caption: "Car", value: "Car"},
        {caption: "Truck", value: "Truck"},
        {caption: "Van", value: "Van"},
        {caption: "SUV", value: "Suv"},
    ];

    monthlyBudget = [
        {value: "", caption: "Please Select"},
        {caption: "Under $250/month", value: "Under 250"}, 
        {caption: "$251-$375/month", value: "251 to 375"}, 
        {caption: "$376-$500/month", value: "376 to 500"}, 
        {caption: "+$500/month", value: "500+"}, 
        
    ];

    existingVehicle = [
        {caption: "Please Select", value: ""},
        {caption: "No", value: "No"},
        {caption: "Yes, I want to trade", value: "Trade"},
        {caption: "Yes, I'm keeping it", value: "Keep"},
    ];
    
    content = () => <div className="form-panel">
        <hr className="horizontal-line"/>
        <p className="customer-message-text text-center">Tell us a little about your current vehicle situation.</p>
        <div className="row">
            <div className="col col-sm-4 col-12">
                <span className="label">Desired Vehicle</span>   
                <span className="input-group">                    
                    <AppInput.Select name="desired_vehicle" options={this.vehicleType} onValidate={v => v} failClass="invalid" />
                </span>
            </div>     
            <div className="col col-sm-4 col-12">
                <span className="label">Monthly Budget</span>
                <span className="input-group">   
                    <AppInput.Select name="monthly_budget" options={this.monthlyBudget} onValidate={v => v} failClass="invalid" />
                </span>
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">Existing Vehicle</span>
                <span className="input-group">   
                    <AppInput.Select name="existing_vehicle" options={this.existingVehicle} onValidate={v => v} failClass="invalid" />
                </span>
            </div>
        </div>
    </div>
};

export default class CarsFastFlatformGenPanel extends GenSet {
    validate = () => {
        this.applicant.setField("dob", `${this.applicant.field("dob_month")}-${this.applicant.field("dob_day")}-${this.applicant.field("dob_year")}`);
        this.applicant.validate(isValid => isValid && (typeof(subValidate) === "function" ? subValidate() : true) ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };
    setOutput = event => {if (event.target.value === "60") {this.setVal({output: event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "+"});}else {this.setVal({output: event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")});}};

    onMount = (state, props) => {
        this.wHolder(CoreTools.on("setErrorMessage", (name, msg) => {
            const msgs = state.errorMsgs || {};
            msgs[name] = msg;
            this.setVal({errorMsgs: msgs});
        }));
    };

    radioFormat = elem => CoreTools.switch(
        [CoreTools.isObject(elem), () => ({caption: typeof(elem.caption) === "string" ? elem.caption : elem.value, value: elem.value})],
        [typeof(elem) === "string", () => ({caption: elem, value: elem})],
        [typeof(elem) === "number", () => ({caption: elem.toString(), value: elem.toString()})],
        [typeof(elem) === "boolean", () => ({caption: elem ? "Yes" : "No", value: elem ? "Yes" : "No"})],
        [true, () => ({caption: "", value: ""})]
    );

    content = (state, props) => <div className="card card-inputs last">
        <div className="card-body">
            <div className="custom-background">{props.options.counter}</div>
            <p className="card-title text-center">{props.options.title}</p>
            {props.options.subttitle && <h3 className="card-title text-center mt-3">{props.options.subtitle.caption}</h3>}
            {props.options.panelContact && <PanelContact {...this.props}/> }
            {props.options.panelAddress && <PanelAddress {...this.props}/> }
            {props.options.panelEmployment && <PanelEmployment {...this.props}/> }
            {props.options.panelVehicle && <PanelVehicle {...this.props}/> }
            {props.options.processingAnimation && <div className="card card-options"><div className="card-body"><div><img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{display: "block", margin: "20px auto"}} /></div></div></div>}
            {props.options.continue && <div className="continue-button mb-3" onClick={() => this.validate(props.options.continue.disabled)}>{props.options.continue.caption}</div>}
        </div>
    </div>;
};


