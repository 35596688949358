import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";

export default class CLRAmount extends GenSet {
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <div className="form-panel">
    <div className="row">
        <div className="col-md-12">
            <span className="radio-button-title">Which of the following would you like to cover?</span>                
            <span className="input-group"> 
                <label className="btn btn-option">
                    <AppInput.Radio name="insurance_amount" value="250000" onClick={this.onClick} />
                    <span className="btn-option-text">$1 - $250,000</span>
                </label>
                <label className="btn btn-option">
                    <AppInput.Radio name="insurance_amount" value="500000" onClick={this.onClick} />
                    <span className="btn-option-text">$250,001 - $500,000</span>
                </label>
                <label className="btn btn-option">
                    <AppInput.Radio name="insurance_amount" value="750000" onClick={this.onClick} />
                    <span className="btn-option-text">$500,001 - $750,000</span>
                </label>
                <label className="btn btn-option">
                    <AppInput.Radio name="insurance_amount" value="1000000" onClick={this.onClick} />
                    <span className="btn-option-text">$750,001 - $1,000,000</span>
                </label>
                <label className="btn btn-option">
                    <AppInput.Radio name="insurance_amount" value="1500000" onClick={this.onClick} />
                    <span className="btn-option-text">$1,000,001 - $1,500,000</span>
                </label>    
            </span>
        </div>
    </div>
</div>;
}

