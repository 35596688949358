import CoreTools from "./CoreTools";

const GeoCode = (country, postalCode, cb) => {
    const getAddress = () => {
        const geoCoder = new window.google.maps.Geocoder();
        geoCoder.geocode({
            "address": postalCode,
            componentRestrictions: {
                country: country || "CA"
            }
        }, (results, status) => cb(status === "OK" && results.length ? {
            success: true,
            status: status,
            full: results[0].formatted_address || "",
            zipcode: (results[0].address_components.find(ac => ac.types.includes("postal_code")) || {long_name: ""}).long_name,
            city: (
                results[0].address_components.find(ac => ac.types.includes("locality")) ||
                results[0].address_components.find(ac => ac.types.includes("neighborhood")) ||
                results[0].address_components.find(ac => ac.types.includes("administrative_area_level_2")) ||
                {long_name: ""}
            ).long_name,
            state: (results[0].address_components.find(ac => ac.types.includes("administrative_area_level_1")) || {long_name: ""}).long_name,
            stateShort: (results[0].address_components.find(ac => ac.types.includes("administrative_area_level_1")) || {short_name: ""}).short_name,
            country: (results[0].address_components.find(ac => ac.types.includes("country")) || {long_name: ""}).long_name,
            countryShort: (results[0].address_components.find(ac => ac.types.includes("country")) || {short_name: ""}).short_name,
            source: results[0]
        } : {
            success: false,
            status: status,
            full: "",
            zipcode: "",
            city: "",
            state: "",
            stateShort: "",
            country: "",
            countryShort: ""
        }));
    };

    if (!CoreTools.state.googleLocationApi) {
        CoreTools.state.googleLocationApi = CoreTools.importScript(
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyD-KMFZXB4lVhte9gDoL86ceR8tP_kRFFE&libraries=places",
          () => setTimeout(getAddress)
        );
    } else {
        getAddress();
    }
};
export default GeoCode;