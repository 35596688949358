import Icon from "../../directives/Icon";

const ClrFormData = ({
   gender: [
      {label: "Male", value: "male", image: <Icon name="male" style={{width: '100px'}} alt="male" />},
      {label: "Female", value: "female", image: <Icon name="female" style={{width: '100px'}} alt="female" />}
   ],
   finalExpense: ["$5,000 - $9,999", "$10,000 - $19,999", "$20,000 - $29,999", "$30,000 - $39,999", "$40,000 - $50,000"],
   insurance: ["$1 - $250,000", "$250,001 - $500,000", "$500,001 - $750,000", "$750,001 - $1,000,000", "$1,000,001 - $1,500,000"],
   insuranceFiftyPlus: ["$1 - $50,000", "$50,001 - $100,000", "$100,001 - $150,000", "$150,001 - $250,000", "$250,001 - $500,000"],
   insuranceCover: ["My Family", "My Mortgage", "My Family & Mortgage", "End of Life Coverage"],
   insuranceTypes: ["Term", "Permanent", "Not Sure"],
   insuranceWho: ["Just Me", "Me & My Partner"],
   province: ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland & Labrador", "Nova Scotia", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Northwest Territories", "Nunavut", "Yukon"],
   smoke: [
      {label: "Smoking", value: "yes", image: <Icon name="smoking" style={{width: '100px'}} alt="smoking" />}, 
      {label: "Non-Smoking", value: "no", image: <Icon name="non-smoking" style={{width: '100px'}} alt="non-smoking" />}
   ],
});

export default ClrFormData;
