import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";

export default class SurexAnnualMileage extends GenSet {
    continue = (state, props) => {this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());};
    
    content = (state, props) => <SurexComponents.Card
        title="What's your annual mileage?"
    >
        <div className="form-row justify-content-center">
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="annual_mileage" value="4999" onClick={this.continue}/>
                    <span>Less than 5,000</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="annual_mileage" value="10000" onClick={this.continue}/>
                    <span>5,000 - 10,000</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="annual_mileage" value="15000" onClick={this.continue}/>
                    <span>10,000 - 15,000</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="annual_mileage" value="20000" onClick={this.continue}/>
                    <span>15,000 - 20,000</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="annual_mileage" value="20001" onClick={this.continue}/>
                    <span>More than 20,000</span>
                </label>
            </div>
        </div>
    </SurexComponents.Card>;
}
