import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";

export default class SurexResidenceLength extends GenSet {
  continue = () => {
        this.navigate(this.props.continue);
    };
    
  setOutput = event => {if (event.target.value === "100") {this.setVal({output: event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "+ months"});}else if (event.target.value === "1") {this.setVal({output: event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " month"});}else {this.setVal({output: event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " months"});}};

  content = (state, props) => <SurexComponents.Card
      title="How many months have you lived at that residence?"
      text=""
      controls={<SurexComponents.Controls continue={this.continue} buttonText={props.buttonText} disclaimer={props.disclaimer} />}
  >
      <div className="form-row justify-content-center">
          <div className="col-8 text-center">
            <div className="form-slider">
              <span className="form-value two-value">
                <div className="output">{state.output}</div>
              </span>
              <AppInput.Range 
                className="mb-5" 
                name="residence_length"
                onValidate={v => v}
                failClass="invalid" 
                onChange={this.setOutput} 
                onLoad={this.setOutput} 
                min="1"
                max="100"
                step="1"
                defaultValue="50"
              />
            </div>
          </div>
      </div>
  </SurexComponents.Card>;
}
