import CoreTools from "../../../services/CoreTools";
import FieldValidate from "../../../services/FieldValidate";
import GenSet from "../../../services/GenSet";
import ClrObjects from "../inserts/ClrObjects";

export default class ClrProcessing extends GenSet {
    onApplicant = (state, props, applicant) => CoreTools.switch(
        [!applicant.field("province"), () => this.navigate("#Province")],
        [!applicant.field("gender"), () => this.navigate("#Gender")],
        // [!this.applicant.field("age_range"), () => this.navigate("#Age")],
        [!applicant.field("insurance_amount"), () => this.navigate("#InsuranceAmount")],
        [!applicant.field("insurance_type"), () => this.navigate("#InsuranceType")],
        [!applicant.field("smoking_status"), () => this.navigate("#InsuranceSmokingStatus")],
        [!FieldValidate.isName(), () => this.navigate("#ContactInformation")],
        [!FieldValidate.isEmail(), () => this.navigate("#ContactInformation")],
        [!FieldValidate.isPhone(), () => this.navigate("#ContactInformation")],
        [true, () => applicant.post("canadianliferates", "submit", {})]
    );

    content = (state, props) => <fieldset className="card card-options">
        <div className="card-body">
            <h5 className="card-title">We are processing your application.</h5>
            <div>
                <img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{display: "block", margin: "20px auto"}} />
            </div>
        </div>
        <ClrObjects.NavOption previous={false} time="" msg="Your Request Is Processing..." />
    </fieldset>;
}