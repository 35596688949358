import GenSet from "../../services/GenSet";
import CoreTools from "../../services/CoreTools";
import PanelEffects from "../../directives/PanelEffects";
import ClrObjects from "./inserts/ClrObjects";
import Lang from "../../services/Lang";
import ClrPanels from "./ClrPanels";
import ClrProcessing from "./panels/ClrProcessing";

export default class ClrIndex extends GenSet {
    panels = ClrPanels();

    init = (() => {
        this.setVal({hash: this.location.hash});
        // this.wHolder(this.applicant.on("hasBuyer", data => {
        //     console.log("hasBuyer", data);
        //     if (data.isSold) {
        //         GoogleTagManager.event("Sold");
        //         this.navigate(`/thank-you/id-${data.buyerId}/?lid=${data.leadId}`, 500);
        //     } else {
        //         GoogleTagManager.event("Not Sold");
        //         this.navigate(`/thank-you/?lid=${data.leadId}`, 500);
        //     }
        // }));
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        if (!CoreTools.state.appOptions.includes("nocss")) {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
            this.importStyle("/css/CanadianLifeRates.css");
            this.importStyle("/css/animate.css");
        }
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}));
    };

    versions = {
        v1: {
            "#Province":                        this.panels.province(40),
            "#Gender":                          this.panels.gender(35),
            "#Age":                             this.panels.age(30),
            "#InsuranceAmount":                 this.panels.insuranceAmount(25),
            "#InsuranceType":                   this.panels.insuranceType(20),
            "#InsuranceSmokingStatus":          this.panels.smoking(15),
            "#ContactInformation":              this.panels.contactInformation(10),
            "#Processing":                      <ClrProcessing />
        },
        v2: {
            "#Province":                        this.panels.province(40),
            "#Gender":                          this.panels.gender(35),
            "#Age":                             this.panels.age(30),
            "#InsuranceAmount":                 this.panels.insuranceAmount(25),
            "#InsuranceType":                   this.panels.insuranceType(20),
            "#InsuranceSmokingStatus":          this.panels.smoking(15),
            "#ContactInformation":              this.panels.contactInformation(10),
            "#Processing":                      <ClrProcessing />
        },
        v3: {
            "#Province":                        this.panels.province(45),
            "#Gender":                          this.panels.gender(40),
            "#DOB":                             this.panels.dob2(35),
            "#InsuranceAmount":                 this.panels.insuranceAmount(25),
            "#InsuranceType":                   this.panels.insuranceType(20),
            "#InsuranceSmokingStatus":          this.panels.smoking(15),
            "#ContactInformation":              this.panels.contactInformation(10),
            "#Processing":                      <ClrProcessing />
        },
        v4: {
            "#Province":                        this.panels.province(55),
            "#Gender":                          this.panels.gender(50),
            "#DOB":                             this.panels.dob(45),
            "#InsuranceAmount":                 this.panels.insuranceAmount(35),
            "#InsuranceType":                   this.panels.insuranceType(30),
            "#InsuranceSmokingStatus":          this.panels.smoking(25),
            "#ContactName":                     this.panels.contactName(20),
            "#ContactEmail":                    this.panels.contactEmail(15),
            "#ContactPhone":                    this.panels.contactPhone(10),
            "#Processing":                      <ClrProcessing />
        },
        v5: {
            "#Province":                        this.panels.province(55),
            "#NameGender":                      this.panels.nameAndGender(50)
        }
    };

    content = (state, props) => <div className="overcard v1">
        <div className="appForm_inner">
            <PanelEffects.ProgressBar
                caption={Lang("progress")}
                // barColor="rgb(255, 180, 2)"
                barImage="https://solarsmartinstall.com/assets/img/progress-bg-spacer.png"
                style={{
                    borderStyle: "solid",
                    borderWidth: "2px", 
                    borderColor: "#ffffff",
                    backgroundColor: "rgb(233, 236, 239)",
                    borderRadius: "5px", 
                    padding: "2px", 
                    fontSize: "13px"
                }}
            />
            <PanelEffects.Transition3>
                <PanelEffects.Selector
                    selector={state.hash}
                    panels={this.versions[props.version]} 
                />
            </PanelEffects.Transition3>
            <ClrObjects.NavPanel />
        </div>
    </div>;
}
