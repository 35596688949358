import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";
import OptionLists from "../../../services/OptionLists";
import GoogleAddress from "../../../directives/GoogleAddress";

let subValidate = null;

class RifcoAddressCA extends GenSet {
    provinceOptions = [
        {value: "", caption: "Select Province"},
        ...OptionLists.Provinces.map(ol => ({value: ol.short, caption: ol.long}))
    ];

    postalCodeFormat = event => {
        if (typeof(event.target.value) === "string") {
            const proper = event.target.value.replace(/[^a-z0-9]/gi, "").toUpperCase().substring(0, 6);
            if (event.target.value !== proper) {
                const cPos = event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = cPos;
                event.target.selectionEnd = cPos;
            }
        }
    };

    postalCodeValidate = value => (
        typeof(value) === "string" &&
        value.length === 6 &&
        CoreTools.match(value.substring(0, 1), /[A-Z]/) &&
        CoreTools.match(value.substring(1, 2), /[0-9]/) &&
        CoreTools.match(value.substring(2, 3), /[A-Z]/) &&
        CoreTools.match(value.substring(3, 4), /[0-9]/) &&
        CoreTools.match(value.substring(4, 5), /[A-Z]/) &&
        CoreTools.match(value.substring(5, 6), /[0-9]/)
    );

    onMount = (state, props) => {subValidate = () => this.state.addressSet;};
    onUnmount = () => {subValidate = null;};

    onApplicant = (state, props) => this.setVal({
        addressSet: this.applicant.field("street_number") && this.applicant.field("street_name") && this.applicant.field("city") && this.applicant.field("state") && this.applicant.field("postal_code") ? true : false
    });
    
    content = (state, props) => <div className="card-body-address">
        <div className="form-row text-center mt-2 mb-3">
            <div className="col-12">
                <div id="gm-autocomplete-wrap" className="form-field mb-3 address-form">                    
                    <GoogleAddress
                        country="ca"
                        className="form-control text-center google-address-input"
                        placeholder="Enter Address Here To Lookup"
                        onSelect={address => {
                            this.applicant.setField("google_address", address.full);
                            this.applicant.setField("street_number", address.number);
                            this.applicant.setField("street_name", address.street);
                            this.applicant.setField("city", address.city);
                            this.applicant.setField("state", address.provinceShort);
                            this.applicant.setField("zip_code", address.postalcode.split(" ").join("").toUpperCase());
                            this.setVal({addressSet: true}, () => console.log(this.state.addressSet));
                        }}
                    />
                </div>
            </div>
            <div className="col-12">
                <span onClick={() => {this.setVal({addressSet: true})}} className="manual-address">My Address Is Not Listed</span>
            </div>
        </div>
        {state.addressSet && <div className="card-fields" id="address">
            <div className="form-row">
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="street_number" placeholder="Street Number" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="street_name" placeholder="Street Name" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="unit" placeholder="Unit/Apt/Suite" className="form-control special-field" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="city" placeholder="City" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Select type="text" name="state" options={this.provinceOptions} className="form-control province-selector special-field" onValidate={v => v} failClass="invalid" />			
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="zip_code" placeholder="Postal Code" className="form-control special-field" onValidate={this.postalCodeValidate} failClass="invalid" onChange={this.postalCodeFormat} />
                </div>
            </div>
        </div>}
    </div>;
}

class Processing extends GenSet {
    onApplicant = (state, props, applicant) => CoreTools.switch(
        [!applicant.field("first_name"),                        () => this.navigate("#Contact")],
        [!applicant.field("last_name"),                         () => this.navigate("#Contact")],
        [!applicant.field("phone"),                             () => this.navigate("#Contact")],
        [!applicant.field("email"),                             () => this.navigate("#Contact")],
        [true, () => applicant.post("ca-auto-refinance", "submit", {})]
    );
 
    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            {/* <h5 className="card-title text-center">{"Hold up! We're matching you with a provider in " + OptionLists.States.find(s => s.short === this.applicant.field("state"))}</h5> */}
            <h5 className="card-title text-center">{"Hold up! We're matching you with a provider."}</h5>
            <div>
                <img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{display: "block", margin: "20px auto", width: "100%"}} />
            </div>
        </div>
    </div>;
}

export default class ConsumerGeniusGenPanel extends GenSet {
    continue = () => this.navigate(this.props.continue);
    dobContinue = () => {this.applicant.validate(isValid => {if (isValid) {this.applicant.setField("dob", [this.applicant.field("dobYear"), this.applicant.field("dobMonth"), this.applicant.field("dobDay")].join("-"));this.navigate(this.props.continue);} else {window.scrollTo(0, 0);}});};
    validate = () => this.applicant.validate(isValid => isValid && (typeof(subValidate) === "function" ? subValidate() : true) ? this.navigate(this.props.continue) : '');
    setOutput = event => {if (event.target.value === "0") {this.setVal({output: "0"});} else if (event.target.value === "900") {this.setVal({output: "900+"});} else {this.setVal({output: event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")});}};

    yearOptions = (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year - 15; i > year - 100; i--) {years.push(i.toString());} return years;})();
    monthOptions = (() => {let months = []; for (let i = 1; i <= 12; i++) {months.push(i);} return [{value: "", caption: "Month"}, ...months.map(m => ({value: m < 10 ? "0" + m : m.toString(), caption: m}))];})();
    dayOptions = (() => {let days = []; for (let i = 1; i <= 31; i++) {days.push(i);} return [{value: "", caption: "Day"}, ...days.map(m => ({value: m < 10 ? "0" + m : m.toString(), caption: m}))];})();

    onMount = (state, props) => {
        this.wHolder(CoreTools.on("setErrorMessage", (name, msg) => {
            const msgs = state.errorMsgs || {};
            msgs[name] = msg;
            this.setVal({errorMsgs: msgs});
        }));
    };

    radioFormat = elem => CoreTools.switch(
        [CoreTools.isObject(elem), () => ({caption: typeof(elem.caption) === "string" ? elem.caption : elem.value, value: elem.value})],
        [typeof(elem) === "string", () => ({caption: elem, value: elem})],
        [typeof(elem) === "number", () => ({caption: elem.toString(), value: elem.toString()})],
        [typeof(elem) === "boolean", () => ({caption: elem ? "Yes" : "No", value: elem ? "Yes" : "No"})],
        [true, () => ({caption: "", value: ""})]
    );

    content = (state, props) => <div className="card card-inputs last">
        <div className="card-body">
            <p className="card-title text-center">{props.options.title}</p>
            {props.options.subtitle && <div className="form-subtext"><p className="text-center">Auto refinance.ai can only help you with lowering your existing rate on your existing vehicle.<br/><br/><u>If you do not have financing on your existing vehicle or are looking to trade in your existing vehicle</u>, then please click below and our partner company Carsfast can help you."</p></div>}
            {!props.options.horizontalLine && <div className="horizontal-line mt-4 mb-4"></div>}
            {Array.isArray(props.options.fields) && <div className="card-fields row">
                {props.options.fields.map((f, fi) => CoreTools.switch(
                    f.type,
                    ["input", () => <div key={fi} className="card-fields row mt-1"><div className="col-12 mb-3"><AppInput.Input type={f.inputType || "text"} name={f.name} placeholder={f.placeholder} onValidate={f.onValidate} onChange={f.onChange} onKeyDown={f.onKeyDown} className="form-control input-field" failClass="invalid"/></div></div>],
                    ["phone", () => <div key={fi} className="card-fields row"><div className="col-12 adjusted-col">{f.countryCode && <span id="country-code"><b>{f.countryCode}</b></span>}<AppInput.Input type="tel" name={f.name} className="form-control input-field mb-2" placeholder={f.placeholder} onValidate={f.onValidate} onKeyDown={f.onKeyDown} onChange={f.onChange} failClass="invalid" /></div>{f.SMS && <div className="col-12"><div className="checkbox-container"><label className="btn-checkbox mt-1"><input className="no-text-phone-checkbox" type="checkbox" value="no-text" onChange={() => this.setVal({display: !state.display})} /><span className="no-text-phone">My phone does <b>NOT</b> support texting.</span></label></div></div>}</div>],
                    ["radio", () => (f.values || []).map(this.radioFormat).map((o, i) => <div className="col-12" key={fi + "_" + i}><label className="btn btn-option"><AppInput.Radio name={f.name} value={o.value} onClick={this.continue} /><span>{o.caption}</span></label></div>)],
                    ["radioCustom", () => <><div className="col-12" key="1-yes"><label className="btn btn-option"><AppInput.Radio name="refinancing" value="Yes" onClick={() => this.navigate("#CurrentRate")} /><span>Yes</span></label></div><div className="col-12" key="2-no"><label className="btn btn-option"><AppInput.Radio name="refinancing" value="No" onClick={() => this.navigate("#Redirect")} /><span>No</span></label></div></>],
                ))}
            </div>}
            {props.options.range && <div className="col-12 text-center"><div className="form-slider"><span className="form-value two-value"><div className="dollar"><span id="dollar-sign">{props.options.range.currency}</span>{state.output}</div></span><AppInput.Range className="mb-5" name={props.options.range.name} onValidate={props.options.range.onValidate} failClass="invalid" onChange={this.setOutput} onLoad={this.setOutput} min={props.options.range.min} max={props.options.range.max} step={props.options.range.step} defaultValue={props.options.range.defaultValue} /></div></div>}
            {props.options.birthdate && <div className="form-row birthdate-container"><div className="col-12 mb-2"><div className="day-fld-box"><div className="col-12 mb-2"><AppInput.Select name="dobDay" className="form-control text-field birthdate-text" options={this.dayOptions} onValidate={v => v} failClass="invalid" /></div><div className="col-12 mb-2"><AppInput.Select name="dobMonth" className="form-control text-field birthdate-text" options={this.monthOptions} onValidate={v => v} failClass="invalid" /></div><div className="col-12 mb-2"><AppInput.Select name="dobYear" className="form-control text-field birthdate-text" options={this.yearOptions} onValidate={v => v} failClass="invalid" /></div></div></div></div>}
            {props.options.addressCA && <RifcoAddressCA />}
            {props.options.processing && <Processing />}
            {props.options.processingAnimation && <div className="card card-options"><div className="card-body"><div><img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{display: "block", margin: "20px auto"}} /></div></div></div>}
            {props.options.continue && <button className="continue-button mt-3" onClick={() => this.validate(props.options.continue.disabled)}>{props.options.continue.caption}</button>}
            {props.options.continueCustom && <button className="continue-button mt-3" onClick={() => this.navigate("https://carsfast.ca/")}>{props.options.continueCustom.caption}</button>}
        </div>
    </div>;
}
