import CoreTools from "./CoreTools";

const FormValidate = {
    dobDDMMYYYYY: event => {
        if (event.keyCode === 8) {return;}
        const cPos = event.target.value.length - event.target.selectionStart;
        const isEnd = event.target.value.length === event.target.selectionStart;
        let date = event.target.value.replace(/\D/g, "").slice(0, 8);
        let nDate = CoreTools.switch(
            [date.length >= 4, `${date.slice(0, 2)}/${date.slice(2, 4)}/${date.slice(4, 8)}`],
            [date.length >= 2, `${date.slice(0, 2)}/${date.slice(2, 4)}`],
            [true, `${date.slice(0, 2)}`]
        );
        if (!["", "0", "1"].includes(nDate.slice(3,4))) {
            let bStr = nDate.split("");
            bStr.splice(3, 1, "1");
            nDate = bStr.join("");
        }
        if (nDate !== event.target.value) {
            event.target.value = nDate;
            event.target.selectionStart = isEnd ? nDate.length : event.target.value.length - cPos;
            event.target.selectionEnd = isEnd ? nDate.length : event.target.value.length - cPos;
        }
    },
    toPostalCode: value => {
        const pc = value.toUpperCase().replace(/[^A-Z0-9]/g, "").slice(0, 6);
        return CoreTools.switch(
            [() => !CoreTools.match(pc.slice(0, 1), /[A-Z]/), ""],
            [() => !CoreTools.match(pc.slice(1, 2), /[0-9]/), () => pc.slice(0, 1)],
            [() => !CoreTools.match(pc.slice(2, 3), /[A-Z]/), () => pc.slice(0, 2)],
            [() => !CoreTools.match(pc.slice(3, 4), /[0-9]/), () => pc.slice(0, 3)],
            [() => !CoreTools.match(pc.slice(4, 5), /[A-Z]/), () => pc.slice(0, 3) + " " + pc.slice(3, 4)],
            [() => !CoreTools.match(pc.slice(5, 6), /[0-9]/), () => pc.slice(0, 3) + " " + pc.slice(3, 5)],
            [true, () => pc.slice(0, 3) + " " + pc.slice(3, 6)]
        );
    },
    postalCodeFormat: event => {
        if (event.keyCode === 8) {return;}
        const cPos = event.target.value.length - event.target.selectionStart;
        const isEnd = event.target.value.length === event.target.selectionStart;
        const nPostalCode = FormValidate.toPostalCode(event.target.value);
        if (nPostalCode !== event.target.value) {
            event.target.value = nPostalCode;
            event.target.selectionStart = isEnd ? nPostalCode.length : event.target.value.length - cPos;
            event.target.selectionEnd = isEnd ? nPostalCode.length : event.target.value.length - cPos;
        }
    },
    isPostalCode: value => (
        typeof(value) === "string" &&
        value.trim().replace(" ", "").length === 6 &&
        CoreTools.match(value.trim().replace(" ", "").slice(0, 1), /[A-Z]/) &&
        CoreTools.match(value.trim().replace(" ", "").slice(1, 2), /[0-9]/) &&
        CoreTools.match(value.trim().replace(" ", "").slice(2, 3), /[A-Z]/) &&
        CoreTools.match(value.trim().replace(" ", "").slice(3, 4), /[0-9]/) &&
        CoreTools.match(value.trim().replace(" ", "").slice(4, 5), /[A-Z]/) &&
        CoreTools.match(value.trim().replace(" ", "").slice(5, 6), /[0-9]/)
    ),
    zipCodeFormat: event => {
        if (typeof(event.target.value) === "string") {
            const proper = event.target.value.replace(/[^0-9]/gi, "").substring(0, 5);
            if (event.target.value !== proper) {
                const cPos = event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = cPos;
                event.target.selectionEnd = cPos;
            }
        }
    },
    isZipCode: value => (
        typeof(value) === "string" &&
        value.trim().replace(" ", "").length === 5 &&
        CoreTools.match(value.trim().replace(" ", "").slice(0, 1), /[0-9]/) &&
        CoreTools.match(value.trim().replace(" ", "").slice(1, 2), /[0-9]/) &&
        CoreTools.match(value.trim().replace(" ", "").slice(2, 3), /[0-9]/) &&
        CoreTools.match(value.trim().replace(" ", "").slice(3, 4), /[0-9]/) &&
        CoreTools.match(value.trim().replace(" ", "").slice(4, 5), /[0-9]/) 
    ),
    isChecked: value => {
        if (!value) {return false;}
        return true;
    },
    isAccountNumber: code => {
        if (typeof(code) !== "string") {return false;}
        if (code.length !== 8) {return false;}
        if (code.match(/[^0-9]/g)){return false;}
        return true;
    },
    isEmail: email => {
        if (typeof(email) !== "string") {return false;}
        email = email.trim();
        if (email.includes(" ")) {return false;}
        if (email.includes("\\")) {return false;}
        if (('()<>[],;:|ç%&"').split("").filter(c => email.includes(c)).length) {return false;}
        const mainParts = email.split("@");
        if (mainParts.length !== 2) {return false;}
        if (mainParts[0].split(".").includes("")) {return false;}
        const aParts = mainParts[1].split(".");
        if (aParts.includes("")) {return false;}
        if (aParts.length < 2) {return false;}
        if (aParts[aParts.length - 1].length < 2) {return false;}
        if (aParts[aParts.length - 1].length > 3) {return false;}
        return true;
    },
    isPhone: phone => {
        if (typeof(phone) !== "string") {return false;}
        if (phone.replace(/\D/g, "").length !== 10) {return false;}
        if (phone.charAt(1) === '0' || phone.charAt(1) === '1') {return false;}
        return true;
    },
    isPhoneUk: phone => {
        if (typeof(phone) !== "string") {return false;}
        if (phone.replace(/\D/g, "").length !== 9) {return false;}
        return true;
    },
    isName: name => {
        if (typeof(name) !== "string" || name.length < 2) {return false;}
        if (('()<>[]{},;:|%&"*@!#^*_+=?').split("").filter(c => name.includes(c)).length) {return false;}
        return true;
    },
    isSortCode: code => {
        if (typeof(code) !== "string") {return false;}
        if (code.length !== 6) {return false;}
        if (code.match(/[^0-9]/g)){return false;}
        return true;
    },
    isSIN: code => {
        if(code.length !== 9) {return false;} 
        return true;
    },
    maxLength: (len, numbersOnly) => event => {
        if ([8, 9, 46, 37, 39].includes(event.keyCode)) {return;}
        CoreTools.log("KeyCode:", event.keyCode);
        if (event.target.value.length >= len) {event.preventDefault(); return;}
        if (numbersOnly && !['1','2','3','4','5','6','7','8','9','0'].includes(event.key)) {event.preventDefault();}
    },
    ukPhone: len => event => {
        if ([8, 46, 37].includes(event.keyCode)) {return;}
        if (event.target.value.length >= len) {
            event.preventDefault();
        }
        if (!['1','2','3','4','5','6','7','8','9','0'].includes(event.key)){
            event.preventDefault();
        }
    },
    onlyLetters: event => {
        if (typeof(event.target.value) === "string") {
            let proper = event.target.value.replace(/[0-9]/g, "");
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    },
    onlyNumbers: event => {
        if (typeof(event.target.value) === "string") {
            let proper = event.target.value.replace(/\D/g, "");
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    },
    minMax: (min, max, errorCB) => value => {
        if (["", null, undefined].includes(value)) {if (typeof(errorCB) === "function") {errorCB(0);} return false;}
        if (parseInt(value) < min) {if (typeof(errorCB) === "function") {errorCB(-1);} return false;}
        if (parseInt(value) > max) {if (typeof(errorCB) === "function") {errorCB(1);} return false;}
        return true; 
    },
    phoneFormat: event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    },
    socialInsuranceNumberFormat: event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asSocialInsuranceNumber(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    },
    socialSecurityNumberFormatKeyDown: event => {
        if ([8, 9, 46, 37, 39].includes(event.keyCode)) {return;}
        if (!['1','2','3','4','5','6','7','8','9','0'].includes(event.key)) {event.preventDefault();}
        if (event.target.value.length >= 11) {event.preventDefault();}
    },
    socialSecurityNumberFormatChange: event => {
        if (typeof(event.target.value) === "string") {
            let proper = '';
            let m = event.target.value.replace(/\D/g, "");
            if (m.length <= 3) {proper = m;}
            else if (m.length <= 5) {proper = m.slice(0,3) + '-' + m.slice(3,5);}
            else {proper = m.slice(0, 3) + '-' + m.slice(3,5) + '-' + m.slice(5,9);}
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    },
};
export default FormValidate;



