import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import HeComponents from "../inserts/HeComponents";

export default class HeInterestedIn extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };
    
    content = (state, props) => <HeComponents.Card
        title="Most interested in?"
        controls={<HeComponents.Controls continue={this.continue} buttonText={false} disclaimer={props.disclaimer} />}
    >
        <div className="form-row">
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="interested_in" value="Debt Consolidation" onClick={this.continue} />
                    <span>Debt Consolidation</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="interested_in" value="Cash for Renovations" onClick={this.continue} />
                    <span>Cash for Renovations</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="interested_in" value="Other" onClick={this.continue} />
                    <span>Other</span>
                </label>
            </div>
        </div>
    </HeComponents.Card>;
}