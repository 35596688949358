import ReactDOM from 'react-dom/client';
import CoreTools from './services/CoreTools';
import GenSet from "./services/GenSet";
import FormValidate from "./services/FormValidate";
import Applicant from "./services/Applicant";

import TrayTab from "./support/TrayTab";
import Tray from "./support/Tray";
import Popup from "./support/Popup";

import SurexSupport from "./sites/Surex/SurexSupport";

class SupportElem extends GenSet {
    init = (() => {
        this.importStyle("/css2/Support.css", () => this.setVal({loaded: true}));
        this.setVal({
            messages: [],
            errorMsg: "",
            loaded: false,
            openTray: false,
            openPopup: false,
            openQuestion: null,
            detailsEntered: false,
            
        });
    })();
    routines = {

    };

    applicant = Applicant();

    onMount = () => {
        this.wHolder(this.applicant.chat.on(msg => this.setVal({messages: [...this.state.messages, msg]})));
    };

    sendMessage = (value) => {
        this.applicant.chat.send(value);
    };

    submitContact = values => {
        if (!FormValidate.isName(values.first_name)) {return this.setVal({errorMsg: "Invalid First Name"});}
        if (!FormValidate.isName(values.last_name)) {return this.setVal({errorMsg: "Invalid Last Name"});}
        if (!FormValidate.isPhone(values.phone)) {return this.setVal({errorMsg: "Invalid Phone"});}
        if (!FormValidate.isEmail(values.email)) {return this.setVal({errorMsg: "Invalid Email"});}
        this.setVal({errorMsg: ""});
        this.setVal({detailsEntered: true});
        this.applicant.setField("first_name", values.first_name);
        this.applicant.setField("last_name", values.last_name);
        this.applicant.setField("phone", values.phone);
        this.applicant.setField("email", values.email);
    };

    chatScreen = () => {
        if (!this.applicant.field("first_name") || !this.applicant.field("last_name") || !this.applicant.field("phone") || !this.applicant.field("first_name")) {
            return <form onSubmit={CoreTools.fromForm(this.submitContact)}>
                <h2>Please enter your contact information</h2>
                {this.state.errorMsg && <div className="error-message">{this.state.errorMsg}</div>}
                <div><label>First Name</label><input type="text" name="first_name" defaultValue={this.applicant.field("first_name")} /></div>
                <div><label>Last Name</label><input type="text" name="last_name" defaultValue={this.applicant.field("last_name")} /></div>
                <div><label>Phone</label><input type="text" name="phone" defaultValue={this.applicant.field("phone")} onChange={FormValidate.phoneFormat} onPaste={FormValidate.phoneFormat} /></div>
                <div><label>Email</label><input type="text" name="email" defaultValue={this.applicant.field("email")} /></div>
                <button type="submit" >Submit</button>
            </form>;
        }


        return this.state.messages.map(msg => {
            var date = new Date(msg.stamp);
            
            return <div className={`message ${msg.name==="Me"?"to":"from"}`}>
                <div>
                    <div>
                        <p>{msg.name}</p>
                        <p>{date.getHours()>12?date.getHours()-12:date.getHours()}:{date.getMinutes()<10?0:""}{date.getMinutes()}{date.getHours()>11?"PM":"AM"}</p>
                    </div>
                    <p>{msg.msg}</p>
                </div>
            </div>
        });
    }

    supportFlow = {
        SurexSupport: SurexSupport,
    }[CoreTools.state.funnelSupport?.getAttribute("flow")];

    content = (state) => this.supportFlow && state.loaded && <>
        <Popup open={state.openPopup} questions={this.supportFlow.questions} closePopup={() => this.setVal({openTray: false,openPopup: false})} /> 
        <Tray detailsEntered={state.detailsEntered} sendMessage={this.sendMessage} chatScreen={this.chatScreen} open={state.openTray && !state.openPopup} closeTray={() => this.setVal({openTray: false,openPopup: false})} openPopup={() => this.setVal({openPopup: true})} /> 
        <TrayTab open={!state.openTray && !state.openPopup} openTray={() => this.setVal({openTray: true})} /> 
    </>;
}

const Support = funnelSupport => {
    const root = ReactDOM.createRoot(funnelSupport);
    root.render(<SupportElem />);
};

export default Support;