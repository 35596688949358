import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";

export default class SurexResidenceType extends GenSet {
    continue = (state, props) => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };
    
    content = (state, props) => <SurexComponents.Card
        title="What's your residence type?"
    >
        <div className="form-row justify-content-center">
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_type" value="SingleFamilyHome" onClick={this.continue}/>
                    <span>Single Family Home</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_type" value="Apartment" onClick={this.continue}/>
                    <span>Apartment</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_type" value="Condo" onClick={this.continue}/>
                    <span>Condo</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_type" value="Townhome" onClick={this.continue}/>
                    <span>Townhouse</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_type" value="Rowhouse" onClick={this.continue}/>
                    <span>Rowhouse</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_type" value="MultiFamilyHome" onClick={this.continue}/>
                    <span>Multi Family Home</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_type" value="MobileHome" onClick={this.continue}/>
                    <span>Mobile Home</span>
                </label>
            </div>
        </div>
    </SurexComponents.Card>;
}
