import FormValidate from "../../services/FormValidate";
import LoanzFormData from "./LoanzFormData";

const LoanzStructureUSFlatForm = {
    amountUS: {
        title: "How Much Would You Like To Borrow?",
        fields: [
            {type: "select", name: "loan_amount", options: LoanzFormData.us.amounts, onValidate: v => v}
        ],
        continue: {caption: "Continue"},
    },
    bankAba: {
        title: "Please Enter Your Bank's Name",
        bankRoutingNumbers: "true",
    }, 
    applicationSummary: {
        customTitle: "true",
        applicationSummaryUS: "true",
    },
    email: {
        title: "Where Can We Email Your Loan Options?",
        fields: [
            {type: "input", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail}
        ],
        continue: {caption: "Continue"},
    },
    processingUS: {
        title: "We are processing your application.",
        processing: "us",
        processingAnimation: 'true',
    },
    reasonUS: {
        title: "What Do You Need The Money For?",
        fields: [
            {type: "radio", name: "loan_reason", values: LoanzFormData.us.reasons}
        ]
    },
};

export default LoanzStructureUSFlatForm;
