import GenSet from "../../../services/GenSet";
import GoogleAddress from "../../../directives/GoogleAddress";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";
import OptionLists from "../../../services/OptionLists";
import FormValidate from "../../../services/FormValidate";
import GeoCode from "../../../services/GeoCode";

export default class SurexPostalCodeV2 extends GenSet {
    resetInvalid = event => {
        if (event) {event.target.className = event.target.className.split(" ").filter(c => c !== "invalid").join(" ");}
        this.setVal({errorMsg: false});
    };
    
    continue = () => {
        const setInvalid = msg => {
            if (this.addressRef) {
                this.addressRef.className += " invalid";
            }
            this.setVal({errorMsg: msg});
        };
        const zipcode = typeof(this.addressRef.value) === "string" ? this.addressRef.value.trim().replace(" ", "") : "";
        if (!FormValidate.isZipCode(zipcode)) {setInvalid("Valid zipcode required.");}
        else if (this.applicant.field("geo_zip") && this.applicant.field("geo_zip").replace(" ", "") === zipcode && this.applicant.field("state") && this.applicant.field("city")) {
            this.applicant.setField("zip_code", zipcode);
            this.navigate(this.props.continue);
        } else {
            GeoCode("us", zipcode, geo => {
                CoreTools.log("Geo:", geo, this.applicant.field("state"));
                if (!geo.success) {
                    setInvalid("Valid zipcode required.");
                } else {
                    this.applicant.setField("zip_code", zipcode);
                    this.applicant.setField("city", geo.city);
                    this.applicant.setField("state", geo.stateShort);
                    this.applicant.setField("geo_zip", geo.zipcode);
                    this.navigate(this.props.continue);
                }
            });
        }
    };

    provinceOptions = [
        {value: "", caption: "Select Province"},
        ...OptionLists.Provinces.map(i => ({value: i.short, caption: i.long}))
    ];
    
    onApplicant = (state, props, applicant) => {
        this.setVal({
            google_postalcode: applicant.field("google_postalcode") && applicant.field("state") ? applicant.field("google_postalcode") : "",
            zipCode: applicant.field("zip_code") || ""
        });
    };

    content = (state, props) => <SurexComponents.Card
        title="What's your zipcode?"
        text="Your zipcode will help us narrow in on your best options."
        controls={<SurexComponents.Controls continue={this.continue} />}
    > 
        {state.errorMsg && <div className="text-center">
            <span className="text-danger">{state.errorMsg}</span>
        </div>}
        <div className="form-row mb-3 justify-content-center">
            <div className="col-md-8 form-field mb-3 text-center" id="gm-autocomplete-wrap">
                <GoogleAddress
                    type="postal_code"
                    display="postal_code"
                    onRef={r => this.addressRef = r}
                    country="us"
                    className="form-control text-center"
                    onClick={this.resetInvalid}
                    placeholder="Type Here to Search Zipcodes"
                    defaultValue={state.zipCode}
                    onChange={FormValidate.zipCodeFormat}
                    onSelect={address => {
                        CoreTools.log("GooglePostalCode:", address);
                        this.setVal({google_postalcode: address.full});
                        this.applicant.setField("google_postalcode", address.full);
                        this.applicant.setField("city", address.city);
                        this.applicant.setField("state", address.provinceShort);
                        this.applicant.setField("zip_code", address.postalcode);
                        this.applicant.setField("geo_zip", address.postalcode);
                        this.addressRef.value = address.postalcode;
                    }}
                />
            </div>
        </div>
    </SurexComponents.Card>;
}
