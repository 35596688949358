import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import FormValidate from "../../../services/FormValidate";

export default class SurexYourName extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };
    
    content = (state, props) => <SurexComponents.Card
        title="What's Your Name?"
        controls={<SurexComponents.Controls continue={this.continue} />}
    >
        <div className="form-row">
            <div className="col-12 mb-2">
                <AppInput.Input type="text" name="first_name" className="form-control text-field" placeholder="First Name" onValidate={FormValidate.isName} failClass="invalid" />
            </div>
            <div className="col-12 mb-2">
                <AppInput.Input type="text" name="last_name" className="form-control text-field" placeholder="Last Name" onValidate={FormValidate.isName} failClass="invalid" />
            </div>
        </div>
    </SurexComponents.Card>;
}