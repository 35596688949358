import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import HeFunctions from "../inserts/HeFunctions";
import HeComponents from "../inserts/HeComponents";

export default class HeAdditionalCash extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };

    setOutput = event => {
        this.setVal({
            dollar:{
                val: event.target.value,
                min: "$" + (event.target.value - event.target.min + 1 ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                max: "$" + event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            }
        });
    };

    content = (state, props) => <HeComponents.Card
        title="Consolidate your debts with a single payment!"
        text="Select the current credit card amount you owe for a free, no-obligation consultation."
        controls={<HeComponents.Controls continue={this.continue} buttonText={props.buttonText} disclaimer={props.disclaimer} />}
    >
        <div className="form-row">
            <div className="col-12 text-center ">
                <div className="form-slider">
                    {props.dropdown
                        ? <AppInput.Select 
                            defaultValue="50000" 
                            type="text" 
                            name="cc_debt_amount" 
                            options={HeFunctions.createDropdowns(1000,100000,1000)} 
                            className="form-control" 
                            onValidate={v => v} 
                            failClass="invalid" 
                        />	
                        : <>
                            <span className="form-value two-value">
                                <div className="dollar">{state.dollar?.val === "100000" ? "Over" : state.dollar?.min + " to"} {state.dollar?.max}</div>
                            </span>
                            <AppInput.Range
                                style={{sidplay: "block"}}
                                name="cc_debt_amount_range"
                                onValidate={v => Number(v)}
                                failClass="invalid"
                                onChange={this.setOutput}
                                onLoad={this.setOutput}
                                min="1000"
                                max="100000"
                                step="1000"
                                defaultValue="50000"
                            />
                            <div className="slider-values">
                                <span>$0</span>
                                <span>$100,000+</span>
                            </div>
                        </> 
                    }
                </div>
            </div>
        </div>
    </HeComponents.Card>;
}