const Card = props => <fieldset>
    <div>
        <div className="card-fields">
            <h5 className="card-title text-center">{props.title}</h5>
            {props.text && <p className="card-text text-center">{props.text}</p>}
            <div className="card-fields-options">
                {props.children}
            </div>
        </div>
        {props.controls}
    </div>
</fieldset>;

const Controls = props => {
    return <div className="card-controls text-center">
        { props.buttonText !== false && <button disabled={props.disabled} onClick={props.continue} className={"mx-auto btn btn-navigate " + props.buttonClass}>{props.buttonText || "Continue"}</button>}
        { props.disclaimer && <label className="term-txt mt-1"><input type="hidden" id="leadid_tcpa_disclosure"/><b>By clicking "{props.buttonText || "Complete My Application"}", I agree to: </b><ul><li>Share your information with participating vendors and other marketing partners, which may include the service providers, their vendors and partners and you expressly consent to be contacted by us, our agents, representatives, affiliates, third party, or anyone calling on our behalf for any and all purposes arising out of or relating to your inquiry for products or services with us.</li><li>Receive disclosures and communications about my inquiry in electronic form (I confirm that I have a working computer system and internet website browser to view this webpage).</li><li>Be contacted about solar services by a live agent, artificial or pre-recorded voice, and SMS text at my residential or cellular number, dialed manually or by auto dialer (consent to be contacted is not a condition to purchase services) and to be contacted via email; I consent to be contacted even if my phone number or email address appears on a SolarQuotesGenius or its partners Do Not Call / Do Not Email List, a State or National Do Not Call Registry, or any other Do Not Contact List; </li><li>I consent to SolarQuotesGenius or its partners to perform a credit check on me if required based on the information I have provided; AND further consent to;</li><li>Our <a href="/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="/terms-and-conditions/" target="_blank" rel="noreferrer">Terms of Use</a></li><li>Standard message and data rates may apply</li></ul></label>}
    </div>;
}

const UKComponents = {
    Card: Card,
    Controls: Controls
};

export default UKComponents;
