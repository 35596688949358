import CoreTools from "../services/CoreTools";

const Icon = props => {
    const icon = CoreTools.switch(
        props.name,
        false,
        ["1car", {src: "/images/1car.png", alt: "1 Car", type: "img"}],
        ["2cars", {src: "/images/2cars.png", alt: "2 Cars", type: "img"}],
        ["3cars", {src: "/images/3cars.png", alt: "3 Cars", type: "img"}],
        ["1car-blue", {src: "/images/1car-blue.png", alt: "1 Car", type: "img"}],
        ["2cars-blue", {src: "/images/2cars-blue.png", alt: "2 Cars", type: "img"}],
        ["3cars-blue", {src: "/images/3cars-blue.png", alt: "3 Cars", type: "img"}],
        ["arched", {src: "/images/arched.png", alt: "Arched/Ellpitical", type: "img"}],
        ["asphalt", {src: "/images/asphalt.jpg", alt: "Asphalt", type: "img"}],
        ["bathtub", {src: "/images/bathtub.png", alt: "Bathtub", type: "img"}],
        ["back", {src: "/images/LifeInsurance/back-arw.png", alt: "Back", type: "img"}],
        ["backv2", {src: "/images/SpeedyInsurance/back-arw.png", alt: "Back", type: "img"}],
        ["casement", {src: "/images/casement.png", alt: "Casement", type: "img"}],
        ["car", {src: "/icons/car-solid.svg", alt: "Car", type: "svg"}],
        ["clay", {src: "/images/clay.jpg", alt: "clay", type: "img"}],
        ["cfCar", {src: "/icons/cf_car2.svg", alt: "Car", type: "img"}],
        ["cfSUV", {src: "/icons/cf_suv2.svg", alt: "SUV", type: "img"}],
        ["cfTruck", {src: "/icons/cf_truck2.svg", alt: "Truck", type: "img"}],
        ["cfVan", {src: "/icons/cf_van2.svg", alt: "Van", type: "img"}],
        ["chk", {src: "/images/chk.png", alt: "Checkmark", type: "img"}],
        ["chk", {src: "/images/unchk.png", alt: "Check Box", type: "img"}],
        ["condohome", {src: "/icons/condo.svg", alt: "Condominium", type: "svg"}],
        ["doubleHung", {src: "/images/doubleHung.png", alt: "Double Hung", type: "img"}],
        ["female", {src: "/images/woman.png", alt: "Female", type: "img"}],
        ["female-alt", {src: "/icons/female-alt.svg", alt: "Female", type: "img"}],
        ["fixedPicture", {src: "/images/fixedPicture.png", alt: "Fixed/Picture", type: "img"}],
        ["gutters", {src: "/images/gutters.png", alt: "Gutters", type: "img"}],
        ["hotTub", {src: "/images/hot-bath.png", alt: "Hot Tub", type: "img"}],
        ["lifeInsuranceFamily", {src: "/images/LifeInsurance2/family-ic.png", alt: "My Family", type: "img"}],
        ["lifeInsuranceMortgage", {src: "/images/LifeInsurance2/mortgage-ic.png", alt: "My Mortgage", type: "img"}],
        ["lifeInsuranceFamilyMortgage", {src: "/images/LifeInsurance2/fm-mortgage-ic.png", alt: "My Family & Mortgage", type: "img"}],
        ["lifeInsuranceEnd", {src: "/images/LifeInsurance2/coverage-ic.png", alt: "End of Life Coverage", type: "img"}],
        ["lifeInsuranceMale", {src: "/images/LifeInsurance2/male-ic.png", alt: "Male", type: "img"}],
        ["lifeInsuranceFemale", {src: "/images/LifeInsurance2/female-ic.png", alt: "Female", type: "img"}],
        ["lifeInsuranceSmoking", {src: "/images/LifeInsurance2/smoke-ic.png", alt: "Smoking", type: "img"}],
        ["lifeInsuranceNonSmoking", {src: "/images/LifeInsurance2/no-smoke-ic.png", alt: "Non-Smoking", type: "img"}],
        ["male", {src: "/images/man.png", alt: "Male", type: "img"}],
        ["male-alt", {src: "/icons/male-alt.svg", alt: "Male", type: "img"}],
        ["multihome", {src: "/icons/multi-family.svg", alt: "Mult-Family Home", type: "svg"}],
        ["mobilehome", {src: "/icons/mobile.svg", alt: "Mobile Home", type: "svg"}],
        ["not-interested", {src: "/images/not_interested.png", alt: "Non Interested", type: "img"}],
        ["non-smoking", {src: "/images/NonSmokingHR.png", alt: "Non Smoking", type: "img"}],
        ["other", {src: "/images/other.jpg", alt: "Other", type: "img"}],
        ["remove", {src: "/icons/trash-can-solid.svg", alt: "Remove", type: "svg"}],
        ["roof", {src: "/images/roof.png", alt: "roof", type: "img"}],
        ["question", {src: "/icons/circle-question-regular.svg", alt: "Question", type: "svg"}],
        ["skylight", {src: "/images/skylight.png", alt: "Skylight", type: "img"}],
        ["slate", {src: "/images/slate.jpg", alt: "Slate", type: "img"}],
        ["sliding", {src: "/images/sliding.png", alt: "Sliding", type: "img"}],
        ["singlehome", {src: "/icons/single-family.svg", alt: "Single Family Home", type: "svg"}],
        ["smoking", {src: "/images/SmokingHR.png", alt: "Smoking", type: "img"}],
        ["solar", {src: "/images/solar-panel.png", alt: "Solar", type: "img"}],
        ["tik", {src: "/images/tik.png", alt: "Checkmark", type: "img"}],
        ["toilet", {src: "/images/toilet.png", alt: "Toilet", type: "img"}],
        ["ribbon", {src: "/images/wrnty-ic.png", alt: "Warranty Ribbon", type: "img"}],
        ["window", {src: "/images/window.png", alt: "Window", type: "img"}],
        ["wood", {src: "/images/wood.jpg", alt: "Wood", type: "img"}],
    );
    const path = CoreTools.state.isLocal ? "/libs" : "https://form.consumergenius.com/libs"
    return <img
        src={path + icon.src}
        alt={props.alt || icon.alt}
        className={props.className}
        style={props.style}
        onClick={props.onClick}
        title={props.title}
    />;
};
export default Icon;
