

import PanelEffects from "../../directives/PanelEffects";
import CoreTools from "../../services/CoreTools";
import GenSet from "../../services/GenSet";
import ConsumerGeniusGenPanel from "./panels/ConsumerGeniusGenPanel";
import ConsumerGeniusProcessing from "./panels/ConsumerGeniusProcessing";
import FormValidate from "../../services/FormValidate";
import ConsumerGeniusFormData from "./ConsumerGeniusFormData";

export default class ConsumerGeniusIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (CoreTools.state.appOptions.includes("nocss")) {  
            this.setVal({
                cssBootstrap: true,
                cssMain: true
            });
        } else {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css", () => this.setVal({cssBootstrap: true}));
            this.importStyle("/css/ConsumerGenius.css", () => this.setVal({cssMain: true}));
            this.importStyle("/css/animate.css");
        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider3.css");
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}));
    };

    structure = {
        contact: {
            title: "Let us know who we are contacting and how to get a hold of you",
            fields: [
                {type: "input", name: "first_name", placeholder: "First Name", onValidate: FormValidate.isName, onChange: FormValidate.onlyLetters},
                {type: "input", name: "last_name", placeholder: "Last Name", onValidate: FormValidate.isName, onChange: FormValidate.onlyLetters},
                {type: "input", name: "company_name", placeholder: "Company Name", onChange: FormValidate.onlyLetters},
                {type: "input", name: "phone", placeholder: "(123) 456 7890", onKeyDown: FormValidate.isPhone, onChange: FormValidate.phoneFormat},
                {type: "input", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail}
            ],
            continue: {caption: "Continue"},
        },
        delivery: {
            title: "How do you want leads to be delivered?",
            fields: [
                {type: "radio", name: "delivery", values: ConsumerGeniusFormData.delivery}
            ]
        },
        leads: {
            title: "Have you bought leads before?",
            fields: [
                {type: "radio", name: "leads", values: ConsumerGeniusFormData.yesNo}
            ]
        },
        leadQuantity: {
            title: "How many leads are you looking to purchase?",
            fields: [
                {type: "radio", name: "lead_quantity", values: ConsumerGeniusFormData.leadQuantity}
            ]
        },
        leadWhen: {
            title: "When are you looking to start buying leads?",
            fields: [
                {type: "radio", name: "lead_when", values: ConsumerGeniusFormData.leadWhen}
            ]
        },
        outOfHours: {
            title: "Are you interested in 'out of hours' leads at a reduced rate?",
            fields: [
                {type: "radio", name: "out_of_hours", values: ConsumerGeniusFormData.yesNo}
            ]
        },
        processing: {
            processingAnimation: true,
        },
        team: {
            title: "How big is your sales team?",
            fields: [
                {type: "radio", name: "team", values: ConsumerGeniusFormData.team}
            ]
        },
        thankYou: {
            title: "Thank you! A representative will be in contact with you shortly!",
            horizontalLine: false,
        },
        weekly: {
            title: "How many leads can you handle per week?",
            fields: [
                {type: "radio", name: "weekly", values: ConsumerGeniusFormData.weekly}
            ]
        },
        whichVertical: {
            title: "What vertical are you looking to buy leads in?",
            fields: [
                {type: "radio", name: "vertical", values: ConsumerGeniusFormData.vertical}
            ]
        }
    }

    versions = {
        v1: {
            "#Leads":               <ConsumerGeniusGenPanel options={this.structure.leads} />,
            "#Team":                <ConsumerGeniusGenPanel options={this.structure.team} />,
            "#Weekly":              <ConsumerGeniusGenPanel options={this.structure.weekly} />,
            "#Out":                 <ConsumerGeniusGenPanel options={this.structure.outOfHours} />,
            "#Delivery":            <ConsumerGeniusGenPanel options={this.structure.delivery} />,
            "#Contact":             <ConsumerGeniusGenPanel options={this.structure.contact} />,
            "#Processing":          <ConsumerGeniusProcessing />,
            "#ThankYou":            <ConsumerGeniusGenPanel options={this.structure.thankYou} />,
        },
        v2: {
            "#Vertical":            <ConsumerGeniusGenPanel options={this.structure.whichVertical} />,
            "#Quantity":            <ConsumerGeniusGenPanel options={this.structure.leadQuantity} />,
            "#When":                <ConsumerGeniusGenPanel options={this.structure.leadWhen} />,
            "#Leads":               <ConsumerGeniusGenPanel options={this.structure.leads} />,
            "#Team":                <ConsumerGeniusGenPanel options={this.structure.team} />,
            "#Weekly":              <ConsumerGeniusGenPanel options={this.structure.weekly} />,
            "#Out":                 <ConsumerGeniusGenPanel options={this.structure.outOfHours} />,
            "#Delivery":            <ConsumerGeniusGenPanel options={this.structure.delivery} />,
            "#Contact":             <ConsumerGeniusGenPanel options={this.structure.contact} />,
            "#Processing":          <ConsumerGeniusProcessing />,
            "#ThankYou":            <ConsumerGeniusGenPanel options={this.structure.thankYou} />,
        },
    };

    content = (state, props) => state.cssBootstrap && state.cssMain && <>
        <div className="main-content">
            <PanelEffects.ProgressBar
                caption=" "
                barColor="#40AD87"
                className="progress-bar"
                style={{
                    borderStyle: "solid",
                    borderWidth: "2px", 
                    borderColor: "#ffffff",
                    backgroundColor: "rgb(233, 236, 239)",
                    borderRadius: "5px", 
                    padding: "2px", 
                    fontSize: "13px",
                    margin: "0 10px"
                }}
            />
            <PanelEffects.Transition1 className="panel-container">
                <div id="appForm" style={{marginTop: "15px"}} key={state.hash}>
                    <div className="appForm_inner">
                        <PanelEffects.Selector
                            selector={state.hash}
                            panels={CoreTools.switch(
                                [props.version === 'v1',        () => this.versions.v1],
                                [props.version === 'v2',        () => this.versions.v2],
                            )} 
                        />
                    </div>
                </div>
            </PanelEffects.Transition1>
        </div>
    </>;
}
