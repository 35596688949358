import CoreTools from "../../services/CoreTools";
import FormValidate from "../../services/FormValidate";
import LoanzFormData from "./LoanzFormData";

const errMsgHdlr = (cond, name, msg) => v => {
    console.log('v:', v.length)
    if (!cond(v)) {
        CoreTools.emit("setErrorMessage", name, msg);
    } 
    return cond(v);
};

const LoanzStructureUS = {
    activeMilitary: {
        title: "Are you currently enrolled in the military?",
        fields: [
            {type: "radio", name: "active_military", values: LoanzFormData.yesNo}
        ]
    },
    addressUS: {
        title: "What Is Your Home Address?",
        subtitle: "Enter your physical address in the box below to quickly autocomplete all required fields.",
        address: "us",
        continue: {caption: "Continue"},
    },
    amountUS: {
        title: "How Much Would You Like To Borrow?",
        fields: [
            {type: "select", name: "loan_amount", options: LoanzFormData.us.amounts, onValidate: v => v}
        ],
        continue: {caption: "Continue"},
    },
    amountUSRange: {
        title: "How Much Would You Like To Borrow?",
        subtitle: "Get instant offers of personal loans up to $50,000",
        range: {name: "loan_amount", min: "500", max: "50000", step: "500", defaultValue: "5000", currency: "$"},
        continue: {caption: "Continue"},
    },
    autoTitle: { 
        title: "Do you have a free and clear title to a vehicle under your name?",
        subtitle: "Answering this question will expand your loan request to include auto title loan options.",
        fields: [
            {type: "radio", name: "auto_title", values: LoanzFormData.yesNo}
        ],
    },
    bankAba: {
        title: "Please Enter Your Bank's Name",
        bankRoutingNumbers: "true",
    }, 
    bankAccountNumber: {
        title: "Please Enter Your Bank Account Number",
        fields: [
            {type: "errorMsg", name: "bank_account_number"},
            {
                type: "input",
                inputType: "number",
                name: "bank_account_number",
                placeholder: "Bank Account Number",
                onValidate: errMsgHdlr(v => v.length >= 4 && v.length <= 25, "bank_account_number", "Please enter a bank account number."),
                onClick: () => CoreTools.emit("setErrorMessage", "bank_account_number", ""),
                onKeyDown: FormValidate.maxLength(25),
                onChange: FormValidate.onlyNumbers
            }
        ],
        continue: {caption: "Continue"},
    }, 
    bankAccountType: {
        title: "What Is Your Bank Account Type?",
        fields: [
            {type: "radio", name: "bank_account_type", values: LoanzFormData.bankAccountType},
        ],
    }, 
    bankDirectDeposit: {
        title: "Do You Have Direct Deposit",
        fields: [
            {type: "radio", name: "bank_direct_deposit", values: LoanzFormData.directDeposit, onValidate: v => v },
        ],
    },
    consent: {
        title: "Congratulations, Great Almost There!",
        subtitle: "Based on the information you have provided, you may qualify for a loan! There are just a few last steps to complete to get you your cash today.",
        fields: [
            {type: "consent"},
        ],
        consent: "true",
        continue: {caption: "Continue"},
    },
    credit: {
        title: "How is Your Credit?",
        subtitle: "★ Most people have good credit ★",
        fields: [
            {type: "credit", name: "credit", values: LoanzFormData.credit},
        ],
    },
    driversLicense: {
        title: "What is your driver's license number?",
        fields: [
            {type: "input", name: "drivers_license", placeholder: "Drivers License", onKeyDown: FormValidate.maxLength(25), onValidate: v => v.length >= 2 && v.length <= 25},
            {type: "select", name: "drivers_license_state", options: LoanzFormData.us.states, onValidate: v => v}
        ],
        continue: {caption: "Continue"},
    }, 
    email: {
        title: "Where Can We Email Your Loan Options?",
        fields: [
            {type: "input", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail}
        ],
        emailConsent: "true",
        continue: {caption: "Continue"},
    },
    employerName: {
        title: "Your Employer's Name",
        fields: [
            {type: "input", name: "employer_name", placeholder: "Employer Name", onValidate: v => v, onChange: FormValidate.onlyLetters}
        ],
        continue: {caption: "Continue"},
    },
    employerPhone: {
        title: "What is your workplace phone number?",
        subtitle: "In the event a lender cannot reach you, you may be contacted at this number regarding your loan inquiery, but the lender will not disclose to your employer the reason for the call.",
        fields: [
            {type: "errorMsg", name: "work_phone"},
            {
                type: "phone",
                name: "work_phone",
                placeholder: "(123) 456 7890", 
                countryCode: "+1", 
                onValidate: errMsgHdlr(v => v.length === 14, "work_phone", "Please enter a valid work phone number."),
                onClick: () => CoreTools.emit("setErrorMessage", "work_phone", ""),
                onKeyDown: FormValidate.isPhone,
                onChange: FormValidate.phoneFormat
            }
        ],
        continue: {caption: "Continue"},
    },
    incomeType: {
        title: "What is the source of your income?",
        fields: [
            {type: "radio", name: "income_type", values: LoanzFormData.us.incomeType}
        ]
    },
    incomeType2: {
        title: "What is the source of your income?",
        fields: [
            {type: "radio", name: "income_type", values: LoanzFormData.us.incomeType2}
        ]
    },
    name: {
        title: "What Is Your Name?",
        subtitle: "Please enter your First and Last Name as it appears on your drivers licence.",
        fields: [
            {type: "input", name: "first_name", placeholder: "First Name", onValidate: FormValidate.isName, onChange: FormValidate.onlyLetters},
            {type: "input", name: "last_name", placeholder: "Last Name", onValidate: FormValidate.isName, onChange: FormValidate.onlyLetters}
        ],
        continue: {caption: "Continue"},
    },
    name2: {
        title: "What Is Your Name?",
        subtitle: "Please enter your First and Last Name as it appears on your drivers licence.",
        fields: [
            {type: "input", name: "first_name", placeholder: "First Name"},
            {type: "input", name: "last_name", placeholder: "Last Name"}
        ],
        continue: {caption: "Continue"},
    },
    netPayUS: {
        title: "What is your monthly net income?",
        subtitle: "For your last pay period, what is your take home pay after taxes and deductions",
        fields: [
            {type: "radio", name: "monthly_income", values: LoanzFormData.monthlyIncome}
        ]
    },
    nextPayDate: {
        title: "When is your next pay date?",
        datePicker: {
            title: 'Next Pay Date',
            name: "next_pay_date"
        },
    },
    followingPayDate: {
        title: "When is your following pay date?",
        datePicker: {
            title: 'Following Pay Date',
            name: "following_pay_date"
        },
    },
    payPeriodUS: {
        title: "How often do you get paid?",
        fields: [
            {type: "radio", name: "income_payment_frequency", values: LoanzFormData.us.incomePaymentFrequency}
        ]
    },
    phoneUS: {
        title: "How Can We Reach You?",
        fields: [
            {type: "errorMsg", name: "phone"},
            {
                type: "phone",
                name: "phone",
                placeholder: "(123) 456 7890", 
                countryCode: "+1", 
                onValidate: errMsgHdlr(v => v.length === 14, "phone", "Please enter a valid work phone number."),
                onClick: () => CoreTools.emit("setErrorMessage", "phone", ""),
                onKeyDown: FormValidate.isPhone,
                onChange: FormValidate.phoneFormat
            }
        ],
        continue: { caption: "Continue"},
    },
    processingUS: {
        title: "We are processing your application.",
        processing: "us",
        processingAnimation: 'true',
    },
    reasonUS: {
        title: "What Do You Need The Money For?",
        fields: [
            {type: "radio", name: "loan_reason", values: LoanzFormData.us.reasons}
        ]
    },
    reasonUS2: {
        title: "What Do You Need The Money For?",
        fields: [
            {type: "radio", name: "loan_reason", values: LoanzFormData.us.reasons2}
        ]
    },
    residentialStatus: {
        title: "What Is Your Residential Status?",
        fields: [
            {type: "radio", name: "residential_status", values: LoanzFormData.us.residentialStatus}
        ]
    },   
    ssn: {
        title: "What Is Your Social Security Number?",
        subtitle: "This will NOT affect your credit. SSN is used to verify your identity.",
        fields: [
           {type: "errorMsg", name: "ssn"},
            {
                type: "SSN",
                name: "ssn",
                onValidate: errMsgHdlr(v => v.length === 11, "ssn", "Please enter a valid social security number."),
                onClick: () => CoreTools.emit("setErrorMessage", "ssn", ""),
                onKeyDown: FormValidate.socialSecurityNumberFormatKeyDown,
                onChange: FormValidate.socialSecurityNumberFormatChange
            }
        ],
        continue: {caption: "Continue"},
    },
    timeAtAddress: {
        title: "How Many Months Have You Been Living At Your Current Address?",
        range: {name: "time_at_address", min: "1", max: "60", step: "1", defaultValue: "30"},
        continue: {caption: "Continue"},
    },
    unsecuredDebtUS: {    
        title: "Do You Have $7,500 Or More In Credit Card Debt or Personal Loan Debt And Would Like One Of Our Partners To Contact You?",
        subtitle: "Answering these questions will NOT stop this loan inquiry process and will NOT affect your qualification for a loan. It is simply a tool to help you connect with reputable companies that help resolve debt, etc.",
        fields: [
            {type: "radio", name: "unsecured_debt", values: LoanzFormData.us.debtAmounts}
        ]
    },
    workExperience: {
        title: "How Many Months Have You Worked At Your Current Workplace?",
        range: {name: "work_experience", min: "1", max: "60", step: "1", defaultValue: "30"},
        continue: {caption: "Continue"},
    },
};

export default LoanzStructureUS;
