import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";

export default class SurexOccupation extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };

    occupations = CoreTools.orderBy([
        {short: "", caption: "Please Select"},
        {short: "ACCT", caption: "CPA/Auditor"},
        {short: "ACT", caption: "Actor/Entertainer/Dancer"},
        {short: "ADD", caption: "Military Officer"},
        {short: "ADJ", caption: "Claims Adjuster"},
        {short: "ADM", caption: "Administrative Clerical"},
        {short: "AIR", caption: "Airline Personnel"},
        {short: "APR", caption: "Appraiser"},
        {short: "AR", caption: "Architect"},
        {short: "ART", caption: "Artist"},
        {short: "ASB", caption: "Assembler"},
        {short: "ATH", caption: "Athlete (Professional)"},
        {short: "AUD", caption: "Auditor"},
        {short: "BFI", caption: "Customer Service/Teller"},
        {short: "BMG", caption: "Banking/Mortgage"},
        {short: "BRB", caption: "Barber/Beautician"},
        {short: "BRO", caption: "Broker"},
        {short: "CAN", caption: "Sales Outside"},
        {short: "CAS", caption: "Casino Worker"},
        {short: "CASH", caption: "Cashier"},
        {short: "CHEM", caption: "Chemist"},
        {short: "CITY", caption: "City Worker"},
        {short: "CL", caption: "Clergy"},
        {short: "CLR", caption: "Clergy/Religious"},
        {short: "CMP", caption: "Computer/Math/Research"},
        {short: "CON", caption: "Contract Employee"},
        {short: "CONF", caption: "Construction/Facilities"},
        {short: "COT", caption: "Management Consulting"},
        {short: "CPA", caption: "Certified Public Accountant"},
        {short: "CRT", caption: "Government"},
        {short: "CS", caption: "Non-Profit/Volunteer"},
        {short: "CSLR", caption: "Counselor"},
        {short: "CTR", caption: "Business Owner"},
        {short: "DAY", caption: "ChildCare/Nanny"},
        {short: "DEC", caption: "Decorator"},
        {short: "DEF", caption: "Military/Defense"},
        {short: "DELY", caption: "Delivery Driver"},
        {short: "DEN", caption: "Dentist"},
        {short: "DIR", caption: "Director"},
        {short: "DIS", caption: "Disabled"},
        {short: "DR", caption: "Doctor/Dentist"},
        {short: "EDU", caption: "Education/Training"},
        {short: "ELE", caption: "Electrician"},
        {short: "ENAR", caption: "Engineering/Architectecture"},
        {short: "ENG", caption: "Engineer"},
        {short: "ENT", caption: "Arts/Entertainment/Publishing"},
        {short: "EXE", caption: "CEO"},
        {short: "FAC", caption: "Manufacturing/Machinist/Machine Operator"},
        {short: "FF", caption: "Firefighter"},
        {short: "FIT", caption: "Healthcare"},
        {short: "FL", caption: "Minor Not Applicable"},
        {short: "FRM", caption: "Farmer"},
        {short: "GC", caption: "General Contractor"},
        {short: "HO", caption: "Homemaker"},
        {short: "HAT", caption: "Hospitality/Travel"},
        {short: "IAB", caption: "Insurance"},
        {short: "INS", caption: "Instructor/Coach/TA"},
        {short: "INT", caption: "Internet/News Media"},
        {short: "JAN", caption: "Custodian/Cleaning/Janitorial"},
        {short: "JOU", caption: "Journalist"},
        {short: "LAB", caption: "Construction Trades"},
        {short: "LAW", caption: "Lawyer/Legal"},
        {short: "LAWR", caption: "Lawyer"},
        {short: "LEGL", caption: "Legal"},
        {short: "LIB", caption: "Librarian/Historian"},
        {short: "LTEC", caption: "LabTechnician"},
        {short: "MAIN", caption: "Maintenance/Operations"},
        {short: "MEC", caption: "Mechanic"},
        {short: "MGR", caption: "Manager Supervisor"},
        {short: "MI", caption: "Military Enlisted"},
        {short: "MI1", caption: "Military E1 - E4"},
        {short: "MI5", caption: "Military E5 - E7"},
        {short: "MKT", caption: "Marketing"},
        {short: "MOD", caption: "Model"},
        {short: "NRS", caption: "Nurse/CNA"},
        {short: "OF", caption: "SalesInside"},
        {short: "OT", caption: "Other Non Technical"},
        {short: "OTH", caption: "Other"},
        {short: "PAD", caption: "Finance/Ins Prof w/Degree"},
        {short: "PARA", caption: "Paramedic"},
        {short: "PAY", caption: "Human Resources"},
        {short: "PCD", caption: "Other - Prof w/Coll Degree"},
        {short: "PHO", caption: "Photographer"},
        {short: "PHR", caption: "Pharmaceutical/Biotech"},
        {short: "PHY", caption: "Physician"},
        {short: "PLT", caption: "Pilot/Navigator"},
        {short: "POL", caption: "Law Enforcement/Security"},
        {short: "POST", caption: "PostalWorker"},
        {short: "PRO", caption: "Professor"},
        {short: "PTO", caption: "Transportation/Logistics"},
        {short: "REA", caption: "Real Estate"},
        {short: "RET", caption: "Retired"},
        {short: "RSC", caption: "Restaurant/Food Service"},
        {short: "RTS", caption: "Retail"},
        {short: "SA", caption: "Professional Salaried/Professional Worker"},
        {short: "SAL", caption: "Sales"},
        {short: "SC", caption: "Scientist"},
        {short: "SCT", caption: "School Teacher"},
        {short: "SE", caption: "Self-Employed"},
        {short: "SEC", caption: "Security"},
        {short: "SK", caption: "Skilled Semi-Skilled"},
        {short: "ST", caption: "Student"},
        {short: "TEC", caption: "Telecommunications"},
        {short: "TECH", caption: "Technology"},
        {short: "TES", caption: "OtherTechnical"},
        {short: "TRNR", caption: "Personal Trainer"},
        {short: "UN", caption: "Unknown/Other"},
        {short: "UNEM", caption: "Unemployed"},
        {short: "VET", caption: "Veterinarian"},
        {short: "WAIT", caption: "Waiter/Bartender/Host"},
        {short: "WRT", caption: "Advertising/Public Relations"},
    ], "caption");
	
    content = (state, props) => <SurexComponents.Card
        title="What's your occupational title?"
        controls={<SurexComponents.Controls continue={this.continue} buttonText={props.buttonText} disclaimer={props.disclaimer} />}
    >
        <div className="form-row justify-content-center">
            <div className="col-md-8 mb-2">
                <span className="input-group">                    
                    <AppInput.Select name="occupation" options={this.occupations.map(o => ({value: o.short, caption: o.caption}))} onValidate={v => v} failClass="invalid" />
                </span>
            </div>
        </div>
    </SurexComponents.Card>;
}
