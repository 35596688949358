import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import JugglingDebtComponents from "../inserts/JugglingDebtComponents";

export default class JugglingDebtMonthlyDebtRepayment extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };
    
    setOutput = event => {
        this.setVal({
            dollar:{
                val: "$" + event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            }
        });
    };

    content = (state, props) => <JugglingDebtComponents.Card
        title="Monthly Debt Repayment"
        controls={<JugglingDebtComponents.Controls continue={this.continue} buttonText={props.buttonText} disclaimer={props.disclaimer} />}
    >
        <div className="form-row">
            <div className="col-12">
                <div className="form-slider">
                    <span className="form-value two-value">
                        <div className="dollar">{state.dollar?.val}</div>
                    </span>
                    <AppInput.Range
                        style={{sidplay: "block"}}
                        name="monthly_debt_repayment"
                        onValidate={v => Number(v)}
                        failClass="invalid"
                        onChange={this.setOutput}
                        onLoad={this.setOutput}
                        min="1"
                        max="10000"
                        step="500"
                        defaultValue="500"
                    />
                    <div className="slider-values">
                        <span>$1</span>
                        <span>$10,000+</span>
                    </div>
                </div>
            </div>
        </div>
    </JugglingDebtComponents.Card>;
}