import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import HeFunctions from "../inserts/HeFunctions";
import HeComponents from "../inserts/HeComponents";

export default class HeAdditionalCash extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };

    /*init = (() => {
        if ( this.applicant.field("interested_in") !== "Cash for Renovations" ) this.continue();
    })();*/

    setOutput = event => {
        this.setVal({
            dollar:{
                val: event.target.value,
                min: "$" + (event.target.value - event.target.min + 1 ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                max: "$" + event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            }
        });
    };

    content = (state, props, applicant) => <HeComponents.Card
        title="You can qualify to take cash out - how much would you like to see if you qualify for?"
        controls={<HeComponents.Controls continue={this.continue} buttonText={props.buttonText} disclaimer={props.disclaimer} />}
    >
        <div className="form-row"> 
            <div className="col-12 text-center ">
                <div className="form-slider">
                    {props.dropdown
                        ? <AppInput.Select 
                            defaultValue={Math.max(0,parseInt(applicant.field("property_value_range").replace(/\D/g,'')) * .8 - parseInt(applicant.field("mortgage_balance").replace(/\D/g,'')))}
                            type="text" 
                            name="additional_cash" 
                            options={[
                                ...HeFunctions.createDropdowns(0,200000,5000),
                                ...HeFunctions.createDropdowns(200000,400000,10000),
                                ...HeFunctions.createDropdowns(400000,800000,20000),
                            ]} 
                            className="form-control" 
                            onValidate={v => v} 
                            failClass="invalid" 
                        />	
                        : <>
                            <span className="form-value two-value">
                                <div className="dollar">{state.dollar?.val === "800000" ? "Over" : state.dollar?.min + " to"} {state.dollar?.max}</div>
                            </span> 
                            <AppInput.Range
                                style={{sidplay: "block"}} 
                                name="additional_cash_range"
                                onValidate={v => Number(v)}
                                failClass="invalid"
                                onChange={this.setOutput}
                                onLoad={this.setOutput}
                                min="5000"
                                max="800000"
                                step="5000"
                                // defaultValue={Math.max(0,parseInt(applicant.field("property_value_range").replace(/\D/g,'')) * .8 - parseInt(applicant.field("mortgage_balance").replace(/\D/g,'')))}
                                defaultValue="400000"
                            />
                            <div className="slider-values">
                                <span>$0</span>
                                <span>$800,000+</span>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    </HeComponents.Card>;
}