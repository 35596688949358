import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";
import TrustedFormCert from "../../../directives/TrustedFormCert";
import JugglingDebtComponents from "../inserts/JugglingDebtComponents";
import FormValidate from "../../../services/FormValidate";
import PhoneVerify from "../../../services/PhoneVerify";

export default class JugglingDebtContactInformation extends GenSet {
    verify = PhoneVerify("Your phone verification pin is {pin}.");

    continue = () => CoreTools.switch(
        [this.props.smsVerify && !this.state.verifyStatus, () => this.applicant.validate(isValid => isValid ? this.verify.submitNumber(this.applicant.field("phone"), false) : window.scrollTo(0, 0))],
        [this.props.smsVerify && this.state.verifyStatus === "pin", () => this.applicant.validate(isValid => isValid ? this.verify.submitPin(this.applicant.field("pin"), false) : window.scrollTo(0, 0))],
        [this.props.smsVerify && this.state.verifyStatus === "verified", () => this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0))],
        [!this.props.smsVerify, () => this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0))]
    );
    
    phoneOptions = [{caption: "Mobile", value: "mobile"}, {caption: "Landline", value: "landline"}];
    phoneFormat = event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };

    showButton = event => {
        this.setVal({
            disabled: CoreTools.switch(
                [!this.applicant.field("tcpa"), "{true}"],
                [this.applicant.field("tcpa"), null],
            )
        });
    };

    setButton = () => this.setVal({
        disabled: CoreTools.switch(
            [!this.applicant.field("tcpa"), "{true}"],
            [this.applicant.field("tcpa"), null],
        )
    });

    setButtonText = () => this.setVal({
        buttonText: CoreTools.switch(
            [this.props.smsVerify && !this.state.verifyStatus, "Verify My Phone Number"],
            [this.props.smsVerify && this.state.verifyStatus === "pin", `Verify My Pin`],
            [true, this.props.buttonText]
        )
    });
    
    onMount = () => {
        this.setVal({tcpa: false});
        this.setButton();
        this.setButtonText();
        this.verify.onError(msg => this.setVal({errorMsg: msg}));
        this.verify.onGetPin(() => this.setVal({errorMsg: "", verifyStatus: "pin"}, this.setButtonText));
        this.verify.onVerified(() => this.setVal({errorMsg: "", verifyStatus: "verified"}, this.setButtonText));
    };

    onUnmount = () => {
        this.verify.end();
    };
    
    content = (state, props) => <JugglingDebtComponents.Card
        title="Chat with a debt relief consultant. 100% FREE initial consultation with no obligation."
        text="Enter your information for personalized results"
        controls={<JugglingDebtComponents.Controls continue={this.continue} buttonText={state.buttonText} disclaimer={props.disclaimer} disabled={state.disabled} />}
    >
        <TrustedFormCert>
            <div className="form-row">
                <div className="col-12 mb-2">
                    <AppInput.Input type="text" name="first_name" className="form-control text-field" placeholder="First Name" onValidate={FormValidate.isName} failClass="invalid" />
                </div>
                <div className="col-12 mb-2">
                    <AppInput.Input type="text" name="last_name" className="form-control text-field" placeholder="Last Name" onValidate={FormValidate.isName} failClass="invalid" />
                </div>
                <div className="col-12 mb-2">
                    <AppInput.Input type="email" name="email" className="form-control text-field" placeholder="Email Address" onValidate={FormValidate.isEmail} failClass="invalid" />
                </div>
                {state.verifyStatus === "verified" && <div style={{color: "#00BB00", textAlign: "center"}} className="col-12 mb-2">Phone Number Verified</div>}
                <div className="col-12 mb-2">
                    <AppInput.Input type="tel" name="phone" className="form-control text-field" placeholder="Mobile Phone Number" onChange={this.phoneFormat} onValidate={FormValidate.isPhone} failClass="invalid" />
                </div>
                {state.errorMsg && <div style={{color: "#BB0000", textAlign: "center"}} className="col-12 mb-2">{state.errorMsg}</div>}
                {this.state.verifyStatus === "pin" && <div className="col-12 mb-2">
                    <AppInput.Input type="text" name="pin" className="form-control text-field" placeholder="PIN" onValidate={v => v && v.length === 4} failClass="invalid" />
                </div>}
                { props.disclaimer === "US" && state.verifyStatus !== "verified" && this.state.verifyStatus !== "pin" && 
                <div className="col-12 mb-2">
                    <div className="btn-checkbox casl-checkbox">
                        <AppInput.CheckBox name="tcpa" onClick={this.showButton} />
                        <label><input type="hidden" id="leadid_tcpa_disclosure"/>By checking this box, I agree to the <a href="/terms-of-use/" target="_blank" rel="noreferrer">Terms of Use</a> and <a href="/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policies</a>. By checking this box I consent to receive text messages and calls via automatic telephone dialing system and an artificial or prerecorded voice to the phone number I entered above from JugglingDebt.com as well as from debt relief solution companies, loan providers, debt consolidation service providers and partners, including but not limited to Americor Funding, LLC dba Americor Financial, Credit9, LLC, for debt relief or debt consolidation service offers even if I am on a national, state, or entity-specific Do-Not-Call list. I understand that consent is not required to receive a quotation or make a purchase.  Message and data rates may apply. For more information, please review our <a href="/tcpa-disclosure/" target="_blank" rel="noreferrer">TCPA Disclosure</a>.</label>
                    </div>
                </div>
                }
            </div>
        </TrustedFormCert>
    </JugglingDebtComponents.Card>;
}