import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import JugglingDebtComponents from "../inserts/JugglingDebtComponents";

export default class JugglingDebtDebtConcerns extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };
    
    content = (state, props) => <JugglingDebtComponents.Card
        title="What are you debt concerns?"
        controls={<JugglingDebtComponents.Controls continue={this.continue} buttonText={false} disclaimer={props.disclaimer} />}
    >
        <div className="form-row split">
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="debt_concerns" value="Missed Payments" onClick={this.continue} />
                    <span>Missed Payments</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="debt_concerns" value="Demands From Lenders" onClick={this.continue} />
                    <span>Demands From Lenders</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="debt_concerns" value="Bank Charges" onClick={this.continue} />
                    <span>Bank Charges</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="debt_concerns" value="Affected Lifestyle" onClick={this.continue} />
                    <span>Affected Lifestyle</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="debt_concerns" value="Baliff or Court Actions" onClick={this.continue} />
                    <span>Baliff or Court Actions</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="debt_concerns" value="None of The Above" onClick={this.continue} />
                    <span>None of The Above</span>
                </label>
            </div>
        </div>
    </JugglingDebtComponents.Card>;
}