import PanelEffects from "../../directives/PanelEffects";
import CoreTools from "../../services/CoreTools";
import GenSet from "../../services/GenSet";
import CLRFlatFormProcessing from "./panels/CLRFlatFormProcessing";
import CLRFlatForm from "./panels/CLRFlatForm";
import CLRWho from "./panels/CLRWho";
import CLRGender from "./panels/CLRGender";
import CLRCover from "./panels/CLRCover";
import CLRSmoke from "./panels/CLRSmoke";
import CLRAmount from "./panels/CLRAmount";
import CLRType from "./panels/CLRType";
import ClrFormData from "./ClrFormData";

export default class CLRIndexFlatForm extends GenSet {
  init = (() => {
    this.setVal({ hash: this.location.hash });
    if (CoreTools.state.appOptions.includes("nocss")) {
      this.setVal({
        cssBootstrap: true,
        cssMain: true,
      });
    } else {
      CoreTools.importStyle(
        "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css",
        () => this.setVal({ cssBootstrap: true })
      );
        this.importStyle("/css/BritishSenior.css", () =>
        this.setVal({ cssMain: true })
      );
      this.importStyle("/css/animate.css");
      this.importStyle("/css/loanz-flatform-us.css");
    }
    this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
    this.importStyle("/css/rangeslider3.css");
  })();

  onMount = (state, props) => {
    this.onNavigate((location) =>
      this.setVal({ hash: location.hash }, () =>
        setTimeout(CoreTools.centerForm, 50)
      )
    );
  };

  panels = {
    "#Who":         <CLRWho />,
    "#Coverage":    <CLRCover />,
    "#Gender":      <CLRGender />,
    "#Smoke":       <CLRSmoke />,
    "#Amount":      <CLRAmount />,
    "#Type":        <CLRType />,
    "#Flatform":    <CLRFlatForm />,
    "#Processing":  <CLRFlatFormProcessing />,
  };

  content = (state, props) => 
    state.cssBootstrap &&
    state.cssMain && 
    <>
      <div className="main-content">
        <PanelEffects.Transition1 className="container-lg">
          <div id="appForm" style={{ marginTop: "15px" }} key={state.hash}>
            <div className="appForm_inner">
              <PanelEffects.Selector
                selector={state.hash}
                panels={this.panels}
              />
            </div>
          </div>
        </PanelEffects.Transition1>
      </div>
    </>
}
