import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";

export default class CarsfastExistingVehicle extends GenSet {
    title = CoreTools.emit("panelTitle", "Do you have an Existing Vehicle?");
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <div className="day-fld-box">
                <label className="btn btn-option">
                    <AppInput.Radio name="existing_vehicle" value="No" onClick={this.onClick} />
                    <span className="btn-option-text">No</span>
                </label>
                <label className="btn btn-option">
                    <AppInput.Radio name="existing_vehicle" value="Yes, I want to trade" onClick={this.onClick} />
                    <span className="btn-option-text">Yes, I want to trade</span>
                </label>
                <label className="btn btn-option">
                    <AppInput.Radio name="existing_vehicle" value="Yes, I'm keeping it" onClick={this.onClick} />
                    <span className="btn-option-text">Yes, I'm keeping it</span>
                </label>
            </div>
        </div>
    </div>;

}