import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";

export default class CarsfastMonthlyRent extends GenSet {
    title = CoreTools.emit("panelTitle", "What Are Your Monthly (Rent Or Mortgage) Payments?");
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };

    setOutput = event => {
        if (event.target.value === "0") {
            this.setVal({output: "What Are Your Monthly (Rent Or Mortgage) Payments?"});
        } else if (event.target.value === "5000") {
            this.setVal({output: "$5,000+"});
        } else {
            this.setVal({output: "$" + event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")});
        }
    };


    content = (state, props) => <div className="card card-options">
        <div className="card-body">
        
            <div className="day-fld-box">
                <div className="card-fields">
                    <div className="form-row">
                        <div className="col-12 text-center mt-3">
                            <div className="form-slider">
                                <div className="form-value">{state.output}</div>
                                <AppInput.Range
                                    style={{sidplay: "block"}}
                                    name="property_value"
                                    onValidate={v => Number(v)}
                                    failClass="invalid"
                                    onChange={this.setOutput}
                                    onLoad={this.setOutput}
                                    min="0"
                                    max="5000"
                                    step="100"
                                    defaultValue="2500"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer text-center mt-4">
                    <button onClick={this.continue} className="mx-auto btn btn-navigate">Continue</button>
                </div>
            </div>
        </div>
    </div>;

}