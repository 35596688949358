import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";

export default class SurexOwnHome extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };
    
    content = (state, props) => <SurexComponents.Card
        title="Would you like to be quoted on your home insurance ?"
        // tooltip={<SurexComponents.ToolTip tooltip="Test" />}
    >
        <div className="form-row">
            <div className="col-12 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="home_insurance" value="Yes" onClick={this.continue} />
                    <span>Yes</span>
                </label>
            </div>
            <div className="col-12 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="home_insurance" value="No" onClick={this.continue} />
                    <span>No</span>
                </label>
            </div>
        </div>
    </SurexComponents.Card>;
}
