import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import JugglingDebtComponents from "../inserts/JugglingDebtComponents";

export default class JugglingDebtResidenceType extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };
    
    content = (state, props) => <JugglingDebtComponents.Card
        title="Are you a Home Owner?"
        controls={<JugglingDebtComponents.Controls continue={this.continue} buttonText={false} disclaimer={props.disclaimer} />}
        counter={props.counter}
    >
        <div className="form-row">
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_type" value="Own" onClick={this.continue} />
                    <span>Yes</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_type" value="Rent" onClick={this.continue} />
                    <span>No</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_type" value="Other" onClick={this.continue} />
                    <span>Other</span>
                </label>
            </div>
        </div>
    </JugglingDebtComponents.Card>;
}