import CoreTools from "../../../services/CoreTools";

const CheckItem = (props) => <div>
    {/* find three images (checked, unchecked, spinning animation) */}
    <div className=""> 
        {CoreTools.switch(
            [props.status === 0, () => <i className="fa-solid fa-circle-notch" style={{color: "red"}}></i>],
            [props.status === 1, () => <i className="fa-regular fa-circle-check" style={{color: "green"}}></i>]
        )}
        <span className="ml-1">{props.caption}</span>
    </div>
</div>;

export default CheckItem;