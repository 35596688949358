import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";

export default class CarsfastBudget extends GenSet {
    title = CoreTools.emit("panelTitle", "What is your Monthly Budget?");
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <div className="form-row">
                <div className="col-lg-6 mb-2">
                    <label className="btn btn-option">
                        <AppInput.Radio name="monthly_budget" value="Under $250/month" onClick={this.onClick} />
                        <span className="btn-option-text">Under $250 /month</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-2">
                    <label className="btn btn-option">
                        <AppInput.Radio name="monthly_budget" value="$251-$375/month" onClick={this.onClick} />
                        <span className="btn-option-text">$251 - $375 /month</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-2">
                    <label className="btn btn-option">
                        <AppInput.Radio name="monthly_budget" value="$376-$500/month" onClick={this.onClick} />
                        <span className="btn-option-text">$376 - $500 /month</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-2">
                    <label className="btn btn-option">
                        <AppInput.Radio name="monthly_budget" value="$500+/month" onClick={this.onClick} />
                        <span className="btn-option-text">$500+ /month</span>
                    </label>
                </div>
            </div>
        </div>
    </div>;

}