import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import HeComponents from "../inserts/HeComponents";
import CoreTools from "../../../services/CoreTools";
import PhoneVerify from "../../../services/PhoneVerify";
import FormValidate from "../../../services/FormValidate";

export default class HeContact extends GenSet {
    verify = PhoneVerify("Your phone verification pin is {pin}.");
    
    continue = () => CoreTools.switch(
        [this.props.smsVerify && !this.state.verifyStatus, () => this.applicant.validate(isValid => isValid ? this.verify.submitNumber(this.applicant.field("phone"), false) : window.scrollTo(0, 0))],
        [this.props.smsVerify && this.state.verifyStatus === "pin", () => this.applicant.validate(isValid => isValid ? this.verify.submitPin(this.applicant.field("pin"), false) : window.scrollTo(0, 0))],
        [this.props.smsVerify && this.state.verifyStatus === "verified", () => this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0))],
        [!this.props.smsVerify, () => this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0))]
    );
    
    setButtonText = () => this.setVal({
        buttonText: CoreTools.switch(
            [this.props.smsVerify && !this.state.verifyStatus, "Verify My Phone Number"],
            [this.props.smsVerify && this.state.verifyStatus === "pin", `Verify My Pin`],
            [true, this.props.buttonText]
        )
    });
    
    onMount = () => {
        this.setButtonText();
        this.verify.onError(msg => this.setVal({errorMsg: msg}));
        this.verify.onGetPin(() => this.setVal({errorMsg: "", verifyStatus: "pin"}, this.setButtonText));
        this.verify.onVerified(() => this.setVal({errorMsg: "", verifyStatus: "verified"}, this.setButtonText));
    };

    onUnmount = () => {
        this.verify.end();
    };
    
    phoneOptions = [{caption: "Mobile", value: "mobile"}, {caption: "Landline", value: "landline"}];
    
    phoneFormat = event => {
        this.setVal({verifyStatus: false}, this.setButtonText);
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };

    content = (state, props) => <HeComponents.Card
        // title={props.title}
        title="Enter your contact info so we can personalize your results"
        controls={<HeComponents.Controls continue={this.continue} buttonText={state.buttonText} disclaimer={props.disclaimer} />}
    >
        <div className="form-row">
            {state.verifyStatus === "verified" && <div style={{color: "#00BB00", textAlign: "center"}} className="col-12 mb-2">Phone Number Verified</div>}
            <div className="col-12 mb-2">
                <AppInput.Input type="tel" name="phone" className="form-control text-field" placeholder="Mobile Phone Number" onChange={this.phoneFormat} onValidate={FormValidate.isPhone} failClass="invalid" />
            </div>
            {state.errorMsg && <div style={{color: "#BB0000", textAlign: "center"}} className="col-12 mb-2">{state.errorMsg}</div>}
            {this.state.verifyStatus === "pin" && <div className="col-12 mb-2">
                <AppInput.Input type="text" name="pin" className="form-control text-field" placeholder="PIN" onValidate={v => v && v.length === 4} failClass="invalid" />
            </div>}
            {props.email && <div className="col-12 mb-2">
                <AppInput.Input type="email" name="email" className="form-control text-field" placeholder="Email Address" onValidate={FormValidate.isEmail} failClass="invalid" />
            </div>}
            <div className="col-12 mb-2">
                <div className="btn-checkbox casl-checkbox">
                    <AppInput.CheckBox name="casl" onClick={v => this.setVal({casl: this.applicant.field('casl')})} />
                    <label>You authorize us to send email, SMS, or other electronic messaging, delivering offers based on information you've shared. You consent to receive messages from our firm and third-party's including financial agencies . You can withdraw your consent anytime.</label>
                </div>
            </div>
        </div>
    </HeComponents.Card>;
                
}