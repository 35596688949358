import Api from "./Api";
import CoreTools from "./CoreTools";
const fromDString = value => {
    if (typeof(value) === "string") {
        value = value.trim();
        return CoreTools.switch(
            [value.slice(0, 1) === '"' && value.slice(-1) === '"', () => {try {return JSON.parse(value);} catch (e) {return value;}}],
            [() => ["", "null"].includes(value.toLowerCase()), null],
            [() => value.toLowerCase() === "true", true],
            [() => value.toLowerCase() === "false", false],
            [() => !isNaN(value), () => Number(value)],
            [true, value]
        );
    }
    return null;
};
const parseCsv = text => {
    let lines = [];
    let line = [];
    let inString = false;
    let special = false;
    let str = "";
    text.split("\r\n").join("\n").split("").forEach(c => {
        if (inString) {
            if (special) {special = false;}
            else if (c === "\\") {special = true;}
            else if (c === '"') {inString = false;}
            str += c;
        } else if (c === ",") {
            line.push(fromDString(str));
            str = "";
        } else if (c === "\n") {
            line.push(fromDString(str));
            str = "";
            lines.push(line);
            line = [];
        } else if (c === '"') {
            inString = true;
            str += c;
        } else {
            str += c;
        }
    });
    const headers = lines[0];
    const arr = lines.slice(1).map(values => headers.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
    }, {}));
    return arr;
};
const importCsv = (url, localize) => callback => {
    Api(localize && CoreTools.state.isLocal ? url : "https://form.consumergenius.com" + url).fetch(res => {
        if (typeof(res.data) === "string") {
            callback(parseCsv(res.data));
        } else {
            callback([]);
        }
    });
};
const CsvHandler = {
    import: importCsv,
    parse: parseCsv
};
export default CsvHandler;