import CoreTools from "../../services/CoreTools";
import FormValidate from "../../services/FormValidate";
import LoanzFormData from "./LoanzFormData";

const LoanzStructureUK = {
    addressUK: {
        title: "What Is Your Home Address?",
        subtitle: "Enter your physical address in the box below to quickly autocomplete all required fields.",
        address: "uk",
        continue: {caption: "Continue"},
    },
    amountUK: {
        title: "How Much Would You Like To Borrow?",
        fields: [
            {type: "select", name: "loan_amount", options: LoanzFormData.uk.amounts, onValidate: v => v}
        ],
        continue: {caption: "Continue"},
    },
    bankInformationUK: {
        title: "Please Enter The Relevant Bank Information",
        subtitle: "Bank Name, Bank Sort Code, Bank Account Number, and Direct Deposit Information",
        fields: [
            {
                type: "input", name: "bank_account_number", 
                placeholder: "Account Number (8 digits)", 
                onValidate: FormValidate.isAccountNumber, 
                onChange: FormValidate.onlyNumbers, 
                onKeyDown: FormValidate.maxLength(8) 
            },
            {
                type: "input", name: "bank_sort_code", 
                placeholder: "Sort Code (6 digits)", 
                onValidate: FormValidate.isSortCode, 
                onChange: FormValidate.onlyNumbers, 
                onKeyDown: FormValidate.maxLength(6) 
            },
            {type: "select", name: "bank_card_type", options: LoanzFormData.bankDebitCardType },

        ],
        continue: {caption: "Continue"},
    }, 
    birthdate: {
        title: "Select Your Date Of Birth",
        fields: [
            {type: "birthdate"},
        ],
    },
    applicationSummary: {
        customTitle: true,
        applicationSummary: true,
    },
    applicationSummary2: {
        customTitle: true,
        applicationSummary2: true,
    },
    applicationSummary3: {
        customTitle: true,
        applicationSummary3: true,
    },
    dependents: {    
        title: "How Many Dependents Do You Have?",
        fields: [
            {type: "radio", name: "dependents", values: LoanzFormData.dependents, onValidate: v => v}
        ],
    },
    email: {
        title: "Where Can We Email Your Loan Options?",
        fields: [
            {type: "input", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail}
        ],
        continue: {caption: "Continue"},
    },
    employerName: {
        title: "Your Employer's Name",
        fields: [
            {type: "input", name: "employer_name", placeholder: "Employer Name", onValidate: v => v, onChange: FormValidate.onlyLetters}
        ],
        continue: {caption: "Continue"},
    },
    expenses: {
        title: "How Much Do You Spend On The Following Expenses Per Month?",
        subtitle: "Please enter your household's expenses in the specific category below",
        fields: [
            {type: "errorMsg", name: "expenses_housing"},
            {
                type: "expense", 
                name: "expenses_housing", 
                placeholder: "Housing Expenses", 
                currency: "£", 
                onValidate: FormValidate.minMax(1, 999, eNo => CoreTools.emit("setErrorMessage", "expenses_housing", "Please supply a value from 1 to 999.")),
                onClick: () => CoreTools.emit("setErrorMessage", "expenses_housing", ""),
                onChange: FormValidate.onlyNumbers,
                onKeyDown: FormValidate.maxLength(3)
            },
            {type: "errorMsg", name: "expenses_all_loans"},
            {
                type: "expense",
                name: "expenses_all_loans",
                placeholder: "All Loans Expenses",
                currency: "£", 
                onValidate: FormValidate.minMax(0, 999, eNo => CoreTools.emit("setErrorMessage", "expenses_all_loans", "Please supply a value from 0 to 999.")),
                onClick: () => CoreTools.emit("setErrorMessage", "expenses_all_loans", ""),
                onChange: FormValidate.onlyNumbers,
                onKeyDown: FormValidate.maxLength(3)
            },
            {type: "errorMsg", name: "expenses_food"},
            {
                type: "expense",
                name: "expenses_food",
                placeholder: "Food Expenses",
                currency: "£", 
                onValidate: FormValidate.minMax(1, 999, eNo => CoreTools.emit("setErrorMessage", "expenses_food", "Please supply a value from 1 to 999.")),
                onClick: () => CoreTools.emit("setErrorMessage", "expenses_food", ""),
                onChange: FormValidate.onlyNumbers,
                onKeyDown: FormValidate.maxLength(3)
            },
            {type: "errorMsg", name: "expenses_utilities"},
            {
                type: "expense",
                name: "expenses_utilities",
                placeholder: "Utilities Expenses",
                currency: "£",
                onValidate: FormValidate.minMax(1, 999, eNo => CoreTools.emit("setErrorMessage", "expenses_utilities", "Please supply a value from 1 to 999.")),
                onClick: () => CoreTools.emit("setErrorMessage", "expenses_utilities", ""),
                onChange: FormValidate.onlyNumbers,
                onKeyDown: FormValidate.maxLength(3)
            },
            {type: "errorMsg", name: "expenses_transport"},
            {
                type: "expense",
                name: "expenses_transport",
                placeholder: "Transport Expenses",
                currency: "£",
                onValidate: FormValidate.minMax(1, 999, eNo => CoreTools.emit("setErrorMessage", "expenses_transport", "Please supply a value from 1 to 999.")),
                onClick: () => CoreTools.emit("setErrorMessage", "expenses_transport", ""),
                onChange: FormValidate.onlyNumbers,
                onKeyDown: FormValidate.maxLength(3)
            },
            {type: "errorMsg", name: "expenses_other"},
            {
                type: "expense",
                name: "expenses_other",
                placeholder: "Other Expenses",
                currency: "£",
                onValidate: FormValidate.minMax(1, 999, eNo => CoreTools.emit("setErrorMessage", "expenses_other", "Please supply a value from 1 to 999.")),
                onClick: () => CoreTools.emit("setErrorMessage", "expenses_other", ""),
                onChange: FormValidate.onlyNumbers,
                onKeyDown: FormValidate.maxLength(3)
            },
        ],
        continue: {caption: "Continue"},
    },
    jobTitle: {
        title: "What is your job title?",
        fields: [
            {type: "input", name: "job_title", placeholder: "Employer Name", onValidate: v => v, onChange: FormValidate.onlyLetters},
        ],
        continue: {caption: "Continue"},
    },
    loanDuration: {    
        title: "How Long Do You Want To Borrow The Money For?",
        fields: [
            {type: "select", name: "loan_durations_months", options: LoanzFormData.loanDuration, onValidate: v => v},
        ],
        continue: {caption: "Continue"},
    },
    maritalStatus: {
        title: "What Is Your Marital Status?",
        fields: [
            {type: "radio", name: "marital_status", values: LoanzFormData.maritalStatus}
        ]
    },
    name: {
        title: "What Is Your Name?",
        subtitle: "Please enter your First and Last Name as it appears on your drivers licence.",
        fields: [
            {type: "input", name: "first_name", placeholder: "First Name", onValidate: FormValidate.isName},
            {type: "input", name: "last_name", placeholder: "Last Name", onValidate: FormValidate.isName}
        ],
        continue: {caption: "Continue"},
    },
    netPayUK: {
        title: "What is your monthly net income?",
        subtitle: "For your last pay period, what is your take home pay after taxes and deductions",
        fields: [
            {type: "errorMsg", name: "net_pay"},
            {   
                type: "expense",
                name: "net_pay",
                placeholder: "Net Pay",
                currency: "£",
                onValidate: FormValidate.minMax(1, 15000, eNo => CoreTools.emit("setErrorMessage", "net_pay", "Please supply a value from 1 to 15,000.")),
                onClick: () => CoreTools.emit("setErrorMessage", "net_pay", ""),
                onChange: FormValidate.onlyNumbers,
                onKeyDown: FormValidate.maxLength(5)
            },
            {type: "checkbox", name: "is_direct", caption: <span className="font-weight-bold">Is Direct Deposit</span>}
        ],
        continue: {caption: "Continue"},
    },
    nextPayDate: {
        title: "When is your next pay date?",
        datePicker: {
            title: 'Next Pay Date',
            name: "next_pay_date"
        },
    },
    followingPayDate: {
        title: "When is your following pay date?",
        datePicker: {
            title: 'Following Pay Date',
            name: "following_pay_date"
        },
    },
    payPeriodUK: {
        title: "How often do you get paid?",
        fields: [
            {type: "radio", name: "pay_period", values: LoanzFormData.uk.incomePaymentFrequency}
        ]
    },
    phoneUK: {
        title: "How Can We Reach You?",
        fields: [
            {type: "phone", name: "mobile_phone", placeholder: "Mobile Phone", countryCode: "+07", onValidate: FormValidate.isPhoneUk, onChange: FormValidate.onlyNumbers, onKeyDown: FormValidate.maxLength(9, true)}
        ],
        continue: {caption: "Continue"},
    },
    processingUK: {
        title: "We are processing your application.",
        processing: "uk",
        processingAnimation: 'true',
    },
    reasonUK: {
        title: "What Do You Need The Money For?",
        fields: [
            {type: "select", name: "loan_reason", options: LoanzFormData.uk.reasons}
        ],
        continue: {caption: "Continue"},
    },
    residentialStatus: {
        title: "What Is Your Residential Status?",
        fields: [
            {type: "radio", name: "residential_status", values: LoanzFormData.residentialStatus}
        ]
    },
    title: {
        title: "What Is Your Preferred Title?",
        fields: [
            {type: "radio", name: "title", values: LoanzFormData.titles}
        ]
    },
    timeAtAddress: {
        title: "How Long Have You Been Living At Your Current Address?",
        fields: [
            {type: "radio", name: "residence_duration", values: LoanzFormData.timeAtAddress}
        ]
    },
    workExperienceUK: {
        title: "How Many Months Have You Worked At Your Current Workplace?",
        fields: [
            {type: "radio", name: "employment_duration", values: LoanzFormData.workExperienceUK}
        ]
    },
    workIndustry: {
        title: "What Industry Do You Work In?",
        fields: [
            {type: "select", name: "work_industry", options: LoanzFormData.industries, onValidate: v => v},
        ],
        continue: {caption: "Continue"},
    },
    workType: {
        title: "What Is Your Income Type?",
        fields: [
            {type: "radio", name: "income_type", values: LoanzFormData.incomeType},
        ],
    },
};

export default LoanzStructureUK;
