import GenSet from "../../services/GenSet";
import CoreTools from "../../services/CoreTools"

import JugglingDebtAddressInformation from "./panels/JugglingDebtAddressInformation";
import JugglingDebtBehindPayment from "./panels/JugglingDebtBehindPayment";
import JugglingDebtCcDebtAmount from "./panels/JugglingDebtCcDebtAmount";
import JugglingDebtComments from "./panels/JugglingDebtComments";
import JugglingDebtContactInformation from "./panels/JugglingDebtContactInformation";
import JugglingDebtCredit from "./panels/JugglingDebtCredit";
import JugglingDebtCreditorsCount from "./panels/JugglingDebtCreditorsCount";
import JugglingDebtDebtType from "./panels/JugglingDebtDebtType";
import JugglingDebtEmploymentStatus from "./panels/JugglingDebtEmploymentStatus";
import JugglingDebtHomeCountry from "./panels/JugglingDebtHomeCountry";
import JugglingDebtMonthlyDebtRepayment from "./panels/JugglingDebtMonthlyDebtRepayment";
import JugglingDebtDebtConcerns from "./panels/JugglingDebtDebtConcerns";
import JugglingDebtMonthlyIncome from "./panels/JugglingDebtMonthlyIncome";
import JugglingDebtMortgageBalance from "./panels/JugglingDebtMortgageBalance";
import JugglingDebtProcessing from "./panels/JugglingDebtProcessing";
import JugglingDebtPropertyValue from "./panels/JugglingDebtPropertyValue";
import JugglingDebtResidenceType from "./panels/JugglingDebtResidenceType";
import JugglingDebtUnsecuredDebtAmount from "./panels/JugglingDebtUnsecuredDebtAmount";
import JugglingDebtUnsecuredDebtAmountUS from "./panels/JugglingDebtUnsecuredDebtAmountUS";

import PanelEffects from "../../directives/PanelEffects";

export default class JugglingDebtIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (CoreTools.state.appOptions.includes("nocss")) {
            this.setVal({
                cssBootstrap: true,
                cssMain: true
            });
        } else {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css", () => this.setVal({cssBootstrap: true}));
            this.importStyle("/css/JugglingDebt.css", () => this.setVal({cssMain: true}));
            this.importStyle("/css/animate.css");
            this.importStyle("/css/rangeslider2.css");
        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}));
    };

    ca1 = {
        "#CcDebtAmount":                        <JugglingDebtCcDebtAmount />,
        "#UnsecuredDebtAmount":                 <JugglingDebtUnsecuredDebtAmount />,
        "#DebtType":                            <JugglingDebtDebtType />,
        "#EmploymentStatus":                    <JugglingDebtEmploymentStatus />,
        "#Credit":                              <JugglingDebtCredit />,
        "#MonthlyIncome":                       <JugglingDebtMonthlyIncome />,
        "#BehindPayment":                       <JugglingDebtBehindPayment />,
        "#CreditorsCount":                      <JugglingDebtCreditorsCount />,
        "#ResidenceType":                       <JugglingDebtResidenceType />,
        "#PropertyValue":                       <JugglingDebtPropertyValue />,
        "#MortgageBalance":                     <JugglingDebtMortgageBalance />,
        "#Comments":                            <JugglingDebtComments />,
        "#AddressInformation":                  <JugglingDebtAddressInformation country="ca" />,
        "#ContactInformation":                  <JugglingDebtContactInformation buttonText="Complete My Application" disclaimer={true} />,
        "#Processing":                          <JugglingDebtProcessing />
    };

    us1 = {
        "#UnsecuredDebtAmount":                 <JugglingDebtUnsecuredDebtAmountUS />,
        "#DebtType":                            <JugglingDebtDebtType />,
        "#Credit":                              <JugglingDebtCredit />,
        "#MonthlyIncome":                       <JugglingDebtMonthlyIncome greaterThan={2500} title="Is your current Monthly Gross Income greater than $2,500?" />,
        "#ResidenceType":                       <JugglingDebtResidenceType />,
        "#AddressInformation":                  <JugglingDebtAddressInformation country="us" />,
        // "#ContactInformation":                  <JugglingDebtContactInformation smsVerify={true} counter buttonText="Complete My Application" disclaimer={"US"} disabled="true" />,
        "#ContactInformation":                  <JugglingDebtContactInformation counter buttonText="Complete My Application" disclaimer={"US"} disabled="true" />,
        "#Processing":                          <JugglingDebtProcessing />
    };

    us2 = {
        "#CcDebtAmount":                        <JugglingDebtCcDebtAmount slider counter />,
        "#DebtType":                            <JugglingDebtDebtType counter />,
        "#Credit":                              <JugglingDebtCredit counter />,
        "#MonthlyIncome":                       <JugglingDebtMonthlyIncome counter slider min={2000} title="What is your current Monthly Gross Income?" />,
        "#ResidenceType":                       <JugglingDebtResidenceType counter />,
        "#AddressInformation":                  <JugglingDebtAddressInformation country="us" />,
        "#ContactInformation":                  <JugglingDebtContactInformation counter buttonText="Complete My Application" disclaimer={"US"} />,
        "#Processing":                          <JugglingDebtProcessing />
    };

    uk1 = {
        "#CcDebtAmount":                        <JugglingDebtCcDebtAmount slider />,
        "#CreditorsCount":                      <JugglingDebtCreditorsCount />,
        "#DebtConcerns":                        <JugglingDebtDebtConcerns />,
        "#HomeCountry":                         <JugglingDebtHomeCountry />,
        "#ResidenceType":                       <JugglingDebtResidenceType />,
        "#EmploymentStatus":                    <JugglingDebtEmploymentStatus />,
        "#MonthlyIncome":                       <JugglingDebtMonthlyIncome slider />,
        "#MonthlyDebtRepayment":                <JugglingDebtMonthlyDebtRepayment />,
        "#ContactInformation":                  <JugglingDebtContactInformation buttonText="Complete My Application" disclaimer={true} />,
        "#Processing":                          <JugglingDebtProcessing />
    };

    content = (state, props) => state.cssBootstrap && state.cssMain && <>
        <div id="appForm" className="overcard v1">
	        <meta name="viewport" content="width=device-width, initial-scale=1" />
            <div className="appForm_inner">
                { ["ca1", "uk1"].includes(props.version) && <PanelEffects.ProgressBar
                    caption="Progress: "
                    barColor="#40AD87"
                    style={{
                        borderStyle: "solid",
                        borderWidth: "2px", 
                        borderColor: "#ffffff",
                        backgroundColor: "rgb(233, 236, 239)",
                        borderRadius: "5px", 
                        padding: "2px", 
                        margin: "20px", 
                        fontSize: "13px"
                    }}
                /> }
                <PanelEffects.Transition3>
                    <PanelEffects.Selector
                        selector={state.hash}
                        panels={CoreTools.switch(
                            [props.version === "ca1", this.ca1],
                            [props.version === "us1", this.us1],
                            [props.version === "us2", this.us2],
                            [props.version === "uk1", this.uk1],
                        )} 
                    />
                </PanelEffects.Transition3>
            </div>
        </div>
    </>;
}
