import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";

export default class SurexLicenceDob extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };
    
    
    options = {
        gender: [{caption: "Gender", value: ""}, {caption: "Female", value: "female"}, {caption: "Male", value: "male"}, {caption: "Other", value: "other"}],
        dobYears: (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year - 15; i > year - 100; i--) {years.push(i.toString());} return years;})(),
        months: (() => {let months = [{value: "", caption: "Month"}]; for (let i = 1; i <= 12; i++) {months.push(i.toString());} return months;})(),
        days: (() => {let days = [{value: "", caption: "Day"}]; for (let i = 1; i <= 31; i++) {days.push(i.toString());} return days;})(),
    };

    content = (state, props) => <SurexComponents.Card
        title="We just need a few more details about your Driver's Licence..."
        controls={<SurexComponents.Controls continue={this.continue} />}
    >
        
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <span>Gender on Your Licence</span>
            </div>
            <div className="col-md-6 mb-2">
                <span>Date Of Birth (Year | Month | Day) Listed on Your Licence</span>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <AppInput.Select name="gender" className="subForm form-control text-field" options={this.options.gender} onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-2 mb-2">
                <AppInput.Select name="dob_year" className="subForm form-control text-field" options={this.options.dobYears} onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-2 mb-2">
                <AppInput.Select name="dob_month" className="subForm form-control text-field" options={this.options.months} onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-2 mb-2">
                <AppInput.Select name="dob_day" className="subForm form-control text-field" options={this.options.days} onValidate={v => v} failClass="invalid" />
            </div>
        </div>
    </SurexComponents.Card>;
                
}
