import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import JugglingDebtComponents from "../inserts/JugglingDebtComponents";

export default class JugglingDebtMortgageBalance extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };
    
    setOutput = event => {
        this.setVal({
            dollar:{
                val: "$" + event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            }
        });
    };

    content = (state, props, applicant) => <JugglingDebtComponents.Card
        title="What is the remaining mortgage balance on this property?"
        controls={<JugglingDebtComponents.Controls continue={this.continue} buttonText={props.buttonText} disclaimer={props.disclaimer} />}
    >
        <div className="form-row">
            <div className="col-12">
                <div className="form-slider">
                    <span className="form-value two-value">
                        <div className="dollar">{state.dollar?.val}</div>
                    </span>
                    <AppInput.Range
                        style={{sidplay: "block"}}
                        name="mortgage_balance_range"
                        onValidate={v => Number(v)}
                        failClass="invalid"
                        onChange={this.setOutput}
                        onLoad={this.setOutput}
                        min="0"
                        max={Math.min(2000000,parseInt(applicant.field("property_value_range").replace(/\D/g,'')))}
                        step="5000"
                        defaultValue={Math.min(50000,parseInt(applicant.field("property_value_range").replace(/\D/g,'')))}
                    />
                    <div className="slider-values">
                        <span>$0</span>
                        <span>${Math.min(2000000,parseInt(applicant.field("property_value_range").replace(/\D/g,''))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}+</span>
                    </div>
                </div>
            </div>
        </div>
    </JugglingDebtComponents.Card>;
}