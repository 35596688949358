import PanelEffects from "../../directives/PanelEffects";
import CoreTools from "../../services/CoreTools";
import GenSet from "../../services/GenSet";
import ACAGenPanel from "./panels/ACAGenPanel";
import ACAFormData from "./ACAFormData";
import GoogleTagManager from "../../services/GoogleTagManager";
import FormValidate from "../../services/FormValidate";
import OptionLists from "../../services/OptionLists";

const stateOptions = [
    {value: "", caption: "Select State"},
    ...OptionLists.States.map(ol => ({value: ol.short, caption: ol.long}))
];

export default class ACAIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (!CoreTools.state.appOptions.includes("nocss")) {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
            this.importStyle("/css/ACA.css");
            this.importStyle("/css/animate.css");
            this.importStyle("/css/rangeslider2.css");

        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider2.css");
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}, () => GoogleTagManager.event({event: "first_page", pageName: this.state.hash || "#Residence"})));
        this.applicant.setField("roof_shade", "Full Sun");
        this.applicant.setField("credit", "Good");
    };

    structure = {
        answerPhone: {
            title: "Will you pick up your phone if we call in the next 2 minutes?",
            horizontalLine: true,
            fields: [
                {type: "radio", name: "answer_call", values: ACAFormData.yesNo }
            ],
        },
        age: {
            title: "What is your age?",
            horizontalLine: true,
            fields: [
                {type: "select", name: "age", options: ACAFormData.age, onValidate: v => v}
            ],
            continue: {caption: "Continue"} 
        },
        contact: {
            title: "What is your name?",
            subtitle: "Personal Information Safe & Secure",
            horizontalLine: true,
            fields: [
                {type: "input", label: "First Name", name: "first_name", placeholder: "First Name", onValidate: FormValidate.isName},
                {type: "input", label: "Last Name", name: "last_name", placeholder: "Last Name", onValidate: FormValidate.isName},
                {type: "input", label: "Email", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail },
                {type: "phone", label: "Phone", name: "phone", placeholder: "(123) 456 7890", onValidate: FormValidate.isPhone, onChange: FormValidate.phoneFormat},
            ],
           continue: {caption: "Continue"}
        },
        householdIncome: {
            title: "How many residents are living in the home?",
            horizontalLine: true,
            fields: [
                {type: "select", name: "household_income", options: ACAFormData.householdIncome, onValidate: v => v}
            ],
            continue: {caption: "Continue"} 
        },
        processing: {
            processing: true,
        },
        residents: {
            title: "How many residents are living in the home?",
            horizontalLine: true,
            fields: [
                {type: "select", name: "residents", options: ACAFormData.residents, onValidate: v => v}
            ],
            continue: {caption: "Continue"}
        },
        state: {
            title: "What is your state?",
            horizontalLine: true,
            fields: [
                {type: "select", name: "state", options: stateOptions, onValidate: v => v}
            ],
            continue: {caption: "Continue"}
        }
    };

    panels = {
        "#Age":               <ACAGenPanel options={this.structure.age} />,
        "#State":             <ACAGenPanel options={this.structure.state} />,
        "#Residents":         <ACAGenPanel options={this.structure.residents} />,
        "#Household":         <ACAGenPanel options={this.structure.householdIncome} />,
        "#Contact":           <ACAGenPanel options={this.structure.contact} />,
        "#Processing":        <ACAGenPanel options={this.structure.processing} />,
    };

    content = (state, props) => <>
        <PanelEffects.ProgressBar
            barColor="#000080"
            className="progress-bar"
            textColorSecondary="#000"
            style={{
                borderStyle: "solid",
                borderWidth: "2px", 
                borderColor: "#000",
                backgroundColor: "#fff",
                borderRadius: "5px", 
                padding: "2px", 
                fontSize: "13px",
                margin: "0 10px"
            }}
        />
        <PanelEffects.Transition1 className={["#Loading"].includes(state.hash) ? "loading-panel" : "panel-container"}>
            <div id="appForm" style={{marginTop: "15px"}} key={state.hash}>
                <div className="appForm_inner">
                    <PanelEffects.Selector
                        selector={state.hash}
                        panels={this.panels} 
                    />
                </div>
            </div>
        </PanelEffects.Transition1>
    </>;
}
