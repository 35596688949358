import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import FormValidate from "../../../services/FormValidate";
import CoreTools from "../../../services/CoreTools";

export default class SurexYourName extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };
    
    content = (state, props) => <SurexComponents.Card
        title="What is the Second Driver's Name?"
        controls={<SurexComponents.Controls continue={this.continue} />}
    >
    <div className="form-row justify-content-center">
            <div className="col-md-8 mb-2">
                <label className="border-label">First Name</label>
                <AppInput.Input type="text" name="second_first_name" className="form-control text-field" placeholder="First Name" onValidate={FormValidate.isName} failClass="invalid" />
            </div>
        </div>
        <div className="form-row justify-content-center">
            <div className="col-md-8 mb-2">
                <label className="border-label">Last Name</label>
                <AppInput.Input type="text" name="second_last_name" className="form-control text-field" placeholder="Last Name" onValidate={FormValidate.isName} failClass="invalid" />
            </div>
        </div>
    </SurexComponents.Card>;
}
