import Applicant from "./Applicant";
import CoreTools from "./CoreTools";

const Jornaya = _id => {
    const applicant = Applicant();

    let timeout = 120;
    let input = document.createElement("input");
    input.id = "leadid_token";
    input.name = "universal_leadid";
    input.type = "hidden";
    input.value = "";
    document.body.appendChild(input);

    let s = document.createElement("script");
    s.id = "LeadiDscript_campaign";
    s.type = "text/javascript";
    s.async = true;
    s.src = `https://create.lidstatic.com/campaign/${_id}.js?snippet_version=2`;
    document.head.appendChild(s);
    
    CoreTools.on("applicantLoaded", () => {
        const hasLeadIdToken = () => {
            const jornayaValue = document.getElementById("leadid_token")?.value;
            if (jornayaValue) {
                applicant.setField("leadid_token", jornayaValue);
            } else if (--timeout > 0) {
                setTimeout(hasLeadIdToken, 1000);
            }
        };
        setTimeout(hasLeadIdToken, 1000);
    });
};
export default Jornaya;