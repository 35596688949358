import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";
import GoogleAddress from "../../../directives/GoogleAddress";
import OptionLists from "../../../services/OptionLists";
import FormValidate from "../../../services/FormValidate";
import GeoCode from "../../../services/GeoCode";

let subValidate = null;

class HlAddressCA extends GenSet {
    resetInvalid = event => {
        if (event) {event.target.className = event.target.className.split(" ").filter(c => c !== "invalid").join(" ");}
        this.setVal({errorMsg: false});
    };
    
    continue = () => {
        const setInvalid = msg => {
            if (this.addressRef) {
                this.addressRef.className += " invalid";
            }
            this.setVal({errorMsg: msg});
        };
        const postalCode = this.addressRef && typeof(this.addressRef.value) === "string" ? this.addressRef.value.trim().replace(" ", "") : "";
        if (!FormValidate.isPostalCode(postalCode)) {
            setInvalid("Valid postal code required.");
        } else if (postalCode === this.applicant.field("zip_code") && this.applicant.field("state") && this.applicant.field("city")) {
            this.navigate(this.props.continue);
        } else {
            GeoCode("ca", postalCode, geo => {
                CoreTools.log("Geo:", geo, this.applicant.field("state"));
                if (!geo.success) {
                    setInvalid("Valid postal code required.");
                } else {
                    this.applicant.setField("google_postalcode", geo.full);
                    this.applicant.setField("state", geo.stateShort);
                    this.applicant.setField("zip_code", postalCode);
                    this.applicant.setField("city", geo.city);
                    this.navigate(this.props.continue);
                }
            });
        }
    };
    
    content = (state, props, applicant) => <>
        {state.errorMsg && <div className="text-center">
            <span className="text-danger">{state.errorMsg}</span>
        </div>}
        <div className="form-row mb-3">
            <div className="col-md-12 form-field mb-3 text-center" id="gm-autocomplete-wrap">
                <GoogleAddress
                    type="postal_code"
                    onRef={r => this.addressRef = r}
                    country="ca"
                    className="form-control text-center"
                    onClick={this.resetInvalid}
                    placeholder="Type Here to Search Postal Codes"
                    defaultValue={FormValidate.toPostalCode(this.applicant.field("zip_code") || "")}
                    onChange={FormValidate.postalCodeFormat}
                    onSelect={address => {
                        CoreTools.log("GooglePostalCode:", address);
                        applicant.setField("google_postalcode", address.full);
                        applicant.setField("city", address.city);
                        applicant.setField("state", address.provinceShort);
                        applicant.setField("zip_code", address.postalcode.replace(" ", ""));
                        this.addressRef.value = address.postalcode;
                    }}
                />
            </div>
        </div>
    </>
}

class HlAddressUS extends GenSet {
    stateOptions = [
        {value: "", caption: "Select State"},
        ...OptionLists.States.map(ol => ({value: ol.short, caption: ol.long}))
    ];

    zipCodeFormat = event => {
        if (typeof(event.target.value) === "string") {
            const proper = event.target.value.replace(/[^0-9]/gi, "").substring(0, 5);
            if (event.target.value !== proper) {
                const cPos = event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = cPos;
                event.target.selectionEnd = cPos;
            }
        }
    };

    zipCodeValidate = value => (
        typeof(value) === "string" &&
        value.length === 5 &&
        CoreTools.match(value.substring(0, 5), /[0-9]/g)
    );
    
    onMount = (state, props) => {subValidate = () => this.state.addressSet;};
    onUnmount = () => {subValidate = null;};

    onApplicant = (state, props, applicant) => this.setVal({
        addressSet: applicant.field("google_address_us") && applicant.field("street_number") && applicant.field("street_name") && applicant.field("city") && applicant.field("state") && applicant.field("zip_code") ? true : false
    });

    content = (state, props, applicant) => <div className="card-body-address">
        <div className="form-row text-center mt-2 mb-3">
            <div className="col-12">
                <div id="gm-autocomplete-wrap" className="form-field mb-3 address-form">                    
                    <GoogleAddress
                        country="us"
                        className="form-control text-center google-address-input"
                        placeholder="Enter Address Here To Lookup"
                        defaultValue={applicant.field("google_address_us")}
                        onSelect={address => {
                            applicant.setField("google_address_us", address.full);
                            applicant.setField("street_number", address.number);
                            applicant.setField("street_name", address.street);
                            applicant.setField("city", address.city);
                            applicant.setField("state", address.stateShort);
                            applicant.setField("zip_code", address.postalcode.split(" ").join("").toUpperCase());
                            this.setVal({addressSet: true}, () => CoreTools.log(this.state.addressSet));
                        }}
                    />
                </div>
            </div>
            <div className="col-12">
                <span onClick={() => {this.setVal({addressSet: true})}} className="manual-address">My Address Is Not Listed</span>
            </div>
        </div>
        {state.addressSet && <div className="card-fields" id="address">
            <div className="form-row">
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="street_number" placeholder="Street Number" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="street_name" placeholder="Street Name" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="unit" placeholder="Unit/Apt/Suite" className="form-control special-field" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="city" placeholder="City" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Select type="text" name="state" options={this.stateOptions} className="form-control province-selector special-field" onValidate={v => v} failClass="invalid" />			
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="zip_code" placeholder="Zip Code" className="form-control special-field" onValidate={this.zipCodeValidate} failClass="invalid" onChange={this.zipCodeFormat} />
                </div>
            </div>
        </div>}
    </div>;
}


class HlhadTest extends GenSet {
continue = () => setTimeout(() => { 
    this.navigate(CoreTools.switch(
        [this.applicant.field("had_test") === "No", "#Address"],
        [true, this.props.continue]
    ));
});

    content = () =>  
    <></>;

}

class HlLoading extends GenSet {
    onMount = () => {
        //change this to whatever you want Steven
        let delay = 2000
        setTimeout(() => this.navigate(this.props.continue), delay);
    };

    content = () => 
        <></>;
}


export default class HlGenPanel extends GenSet {
    onApplicant = (state, props, applicant) => {
        this.setVal({
            firstName: applicant.field('first_name'),
            city: applicant.field('city'),
        });
    };
    timedContinue = () => setTimeout(() => {this.navigate(this.props.continue)})
    continue = () => this.navigate(this.props.continue);
    validate = () => this.applicant.validate(isValid => isValid && (typeof(subValidate) === "function" ? subValidate() : true) ? this.navigate(this.props.continue) : CoreTools.centerForm());
    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <div className="card-body-format">
                {props.options.title && <h5 className="card-title">{typeof(props.options.title) === "function" ? props.options.title() : props.options.title}</h5>}
                {props.options.subTitle && <div className="form-subtext"><p className="text-center">{typeof(props.options.subTitle) === "function" ? props.options.subTitle() : props.options.subTitle}</p></div>}
                {Array.isArray(props.options.fields) && <div className="card-fields row">                
                    {props.options.fields.map((f, fi) => CoreTools.switch(
                        f.type,
                        ["input", () => <div key={fi} className="card-fields row mt-1"><div className="col-12 mb-3">{f.label && <h5 className="card-title">{f.label}</h5>}<AppInput.Input name={f.name} placeholder={f.placeholder} onValidate={f.onValidate} onKeyDown={f.onKeyDown} onChange={f.onChange} className="form-control" failClass="invalid" /></div></div>],
                        ["radio", () => (f.values || []).map((o, oi) => <div className="col-12" key={fi + "_" + oi}><label className="btn btn-option"><AppInput.Radio name={f.name} value={o} onClick={this.continue} /><span>{o}</span></label></div>)],
                        ["phone", () => <div key={fi} className="card-fields row mt-1"><div className="col-12 mb-3">{f.label && <h5 className="card-title">{f.label}</h5>}<AppInput.Input type="tel" name={f.name} className="form-control text-field" placeholder={f.placeholder} onValidate={f.onValidate} onChange={FormValidate.phoneFormat} failClass="invalid" /></div></div>],
                   ))}
                </div>}
                {Array.isArray(props.options.fields) && <div className="card-fields-image-panel row">                
                    {props.options.fields.map((f, i) => CoreTools.switch(
                        f.type,      
                        ["image", () => (f.values || []).map(o => <div className="col-md-6 image-panel-container" key={o.value}><label className="btn btn-option"><AppInput.Radio name={f.name} value={o.value} onClick={this.continue} /><div>{o.image}{o.label && <h5>{o.label}</h5>}</div></label></div>)]))}</div>}</div>
                    {props.options.range && <div className="col-12 text-center">
                        <div className="form-slider">
                            <span className="form-value two-value">
                                <div className="dollar"><span id="dollar-sign">{props.options.range.currency}</span>{state.output}<span id="dollar-sign">{props.options.range.percentage}</span></div>
                            </span>
                            <AppInput.Range
                                className=""
                                name={props.options.range.name}
                                onValidate={props.options.range.onValidate}
                                failClass="invalid"
                                onChange={this.setOutput}
                                onLoad={this.setOutput}
                                min={props.options.range.min}
                                max={props.options.range.max}
                                step={props.options.range.step}
                                defaultValue={props.options.range.defaultValue}
                            />
                        </div>
                    </div>}                
            {props.options.address && CoreTools.switch(
                props.options.address,
                ["ca", () => <HlAddressCA {...props} />],
                ["us", () => <HlAddressUS {...props} />],
            )}
            {props.options.loading && <HlLoading {...props} />}
            {props.options.hadTest && <HlhadTest {...props} />}

            {props.options.consent && <div className="col-12 mb-1">
                <div className="btn-checkbox casl-checkbox">
                    <AppInput.CheckBox name="casl" onClick={v => this.setVal({casl: this.applicant.field('casl')})} />
                    <label>By ticking this checkbox, you authorize us to extend our support through email, SMS, or other electronic channels, delivering offers finely attuned to your preferences based on the information you've shared. Expect a diverse array of products and services, not only from our organization but also from esteemed third-party entities like financial agencies, insurance providers, and dealerships—all tailored to your eligibility. SMS message and data rates may apply. Message frequency varies and recurring messages may happen. You can opt out by responding STOP at any time. Additionally, you may respond with HELP for assistance.</label>
                </div>
            </div>}
            {props.options.consent && <p className="term-txt text-center mt-1"><b>By clicking "I want to hear better", I have read and accept the <a href="/terms-of-use/" target="_blank" rel="noreferrer">Terms of Use</a>. I also acknowledge having read the <a href="/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> and agree to the terms herein.</b></p>}
            {props.options.continue && <div className="continue-button mt-3" onClick={() => this.validate(props.options.continue.disabled)}>{props.options.continue.caption}</div>}
            {props.options.processingAnimation && <div className="card-options"><div><img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{width: "100%"}} /></div></div>}
            {props.options.processingAnimation2 && <div className="card-options p-1"><div><img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading4.gif"} alt="Processing" style={{width: "250px"}} /></div></div>}
        </div>
    </div>;
}

