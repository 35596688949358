import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";
import SurexComponents from "../inserts/SurexComponents";
import FormValidate from "../../../services/FormValidate";
import OptionLists from "../../../services/OptionLists";
import vehicleDrill from "../../../services/VehicleDrill";
import React from "react";

class SurexAddVehicle extends GenSet {
    elements = {
        year: null,
        make: null,
        model: null,
        series: null,
        style: null,
        select: null
    };

    onMount = () => {
        setTimeout(() => {
            vehicleDrill({
                minYear: 2000,
                year: "vehicleYear",
                make: "vehicleMake",
                model: "vehicleModel",
                series: "vehicleSeries",
                style: "vehicleStyle",
                disableEmpty: true, //Optional
                yearDefault: "Select Your Vehicle's Year", //Optional
                makeDefault: "Select Your Vehicle's Make", //Optional
                modelDefault: "Select Your Vehicle's Model", //Optional
                seriesDefault: "Select Your Vehicle's Series", //Optional
                styleDefault: "Select Your Vehicle's Style", //Optional
                onUpdate: (state, vehicle) => { //Optional
                    this.vehicle = vehicle;
                    document.getElementById("submit").disabled = state !== "style";
                    console.log(state, vehicle);
                }
            });
        });
    };

    submit = () => this.props.onSelect({
        year: this.elements.year.value,
        make: this.elements.make.value,
        model: this.elements.model.value,
        series: this.elements.series.value,
        style: this.elements.style.value
    });

    cancel = () => this.props.onSelect(false);
    
    content = (state, props) => <SurexComponents.Card
        title="Select Your Vehicle(s)"
        text="Enter your contact info so we can personalize your results."
    >
        <div className="form-row">
            <div className="col-12 mb-2">
                <div className="input-group"><select id="vehicleYear" ref={r => this.elements.year = r}  className="form-control text-field"></select></div>
                <div className="input-group"><select id="vehicleMake" ref={r => this.elements.make = r}  className="form-control text-field"></select></div>
                <div className="input-group"><select id="vehicleModel" ref={r => this.elements.model = r}  className="form-control text-field"></select></div>
                <div className="input-group"><select id="vehicleSeries" ref={r => this.elements.series = r}  className="form-control text-field"></select></div>
                <div className="input-group"><select id="vehicleStyle" ref={r => this.elements.style = r}  className="form-control text-field"></select></div> 
                <div className="card-controls text-center">
                    <div className="input-group"><button id="submit" ref={this.elements.select}  className="mx-auto btn btn-navigate" onClick={() => this.submit()}>Add Selected Vehicle</button></div>
                    <div className="input-group"><button  className="mx-auto btn btn-Cancel" onClick={() => this.cancel()}>Cancel</button></div>
                </div>
            </div>
        </div>
    </SurexComponents.Card>;
}

export default class SurexVehicles extends GenSet {
    init = (() => {
        this.vehicles = this.applicant.field("vehicles") || [];
        this.setVal({vehicles: this.vehicles});
    })();

    options = {
        gender: [{caption: "Gender", value: ""}, {caption: "Female", value: "female"}, {caption: "Male", value: "male"}, {caption: "Other", value: "other"}],
        dobYears: (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year - 15; i > year - 100; i--) {years.push(i.toString());} return years;})(),
        licYears: (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year; i > year - 100; i--) {years.push(i.toString());} return years;})(),
        months: (() => {let months = [{value: "", caption: "Month"}]; for (let i = 1; i <= 12; i++) {months.push(i.toString());} return months;})(),
        days: (() => {let days = [{value: "", caption: "Day"}]; for (let i = 1; i <= 31; i++) {days.push(i.toString());} return days;})()
    };

    classes = () => [{caption: "Licence Class", value: ""}, ...(OptionLists.DriverClassesCanada.find(c => c.province === CoreTools.asString(this.applicant.field("state")).toUpperCase()) || {classes: []}).classes.map(c => ({caption: "Class " + c, value: c}))];

    licenceNumberValidation = value => {
        return value ? true : false;
    };


    continue = () => this.navigate(this.props.continue);
    
    deleteVehicle = vId => {
        CoreTools.remove(this.vehicles, this.vehicles.find(v => v.id === vId));
        this.applicant.setField("vehicles", this.vehicles);
        this.setVal({vehicles: this.vehicles});
    };

    manageHighlighted = vId => this.setVal({highlighted: this.state.highlighted.includes(vId) ? CoreTools.remove(this.state.highlighted, vId) : [...this.state.highlighted, vId]});


    onSelect = vehicle => {
        if (vehicle) {
            vehicle.id = CoreTools.last(this.vehicles)(v => v.id + 1, () => 1);
            this.vehicles.push(vehicle);
            this.applicant.setField("vehicles", this.vehicles);
            this.setVal({vehicles: this.vehicles, Panel: this.vehicleList()});
        } else {
            this.updatePanels();
        }
    };

    vehicleList = () => <SurexComponents.Card
        title="Select Your Vehicle(s)"
        text="Enter the information of the vehicle you would like to insure below."
        controls={<SurexComponents.Controls continue={this.continue} buttonText="Save Vehicle" />}
    >
        <div className="form-row">
            <div className="col-md-6 mb-2">
                    <span>First Name</span>
            </div>
            <div className="col-md-6 mb-2">
                    <span>Last Name</span>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <AppInput.Input name="first_name" className="subForm form-control text-field" placeholder="First Name" onValidate={FormValidate.isName} failClass="invalid" onPost={this.driversName} />
            </div>
            <div className="col-md-6 mb-2">
                <AppInput.Input name="last_name" className="subForm form-control text-field" placeholder="Last Name" onValidate={FormValidate.isName} failClass="invalid" onPost={this.driversName} />
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <span>Gender</span>
            </div>
            <div className="col-md-6 mb-2">
                <span>Date Of Birth (Year | Month | Day)</span>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <AppInput.Select name="gender" className="subForm form-control text-field" options={this.options.gender} onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-2 mb-2">
                <AppInput.Select name="dob_year" className="subForm form-control text-field" options={this.options.dobYears} onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-2 mb-2">
                <AppInput.Select name="dob_month" className="subForm form-control text-field" options={this.options.months} onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-2 mb-2">
                <AppInput.Select name="dob_day" className="subForm form-control text-field" options={this.options.days} onValidate={v => v} failClass="invalid" />
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <span>Class</span>
            </div>
            <div className="col-md-6 mb-2">
                <span>Date Licensed</span>
            </div>
        </div>
        <div className="form-row">
            <div className="col-6 mb-2">
                <AppInput.Select name="licence_class" className="subForm form-control text-field" options={this.classes()} defaultValue="" onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-2 mb-2">
                <AppInput.Select name="licence_year" className="subForm form-control text-field" options={this.options.licYears} onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-2 mb-2">
                <AppInput.Select name="licence_month" className="subForm form-control text-field" options={this.options.months} onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-2 mb-2">
                <AppInput.Select name="licence_day" className="subForm form-control text-field" options={this.options.days} onValidate={v => v} failClass="invalid" />
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-12 mb-2">
                <span>Licence Number</span>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-12 mb-2">
                <AppInput.Input name="licence_number" className="subForm form-control text-field" onValidate={this.licenceNumberValidation} failClass="invalid" placeholder="Licence Number" />
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-12 mb-2">
                <span>Highlight The Vehicles You Operate</span>
            </div>
        </div>
        
        <div className="form-row">
            {this.state.vehicles.map(v => <div key={v.id} className="carlist"><span className={["form-control", ].filter(c => c).join(" ")}>{[v.year, v.make, v.model, v.series].filter(i => i).join(" ")}</span><button type="button" className="btn btn-primary" onClick={() => this.deleteVehicle(v.id)}><i className="fa-solid fa-xmark"></i></button></div>)}
        </div>
        <div className="input-group"><button className="mx-auto btn addBtn" onClick={this.showAddVehicle}>Add Another vehicle</button></div>
    </SurexComponents.Card>;

    showAddVehicle = () => {
        this.setVal({Panel: <SurexAddVehicle onSelect={this.onSelect} />});
    };

    updatePanels = () => {
        if (!Array.isArray(this.state.vehicles) || this.state.vehicles.length === 0) {
            this.showAddVehicle();
        } else {
            this.setVal({Panel: this.vehicleList()});
        }
    };

    onMount = () => {
        this.wHolder(this.applicant.onFieldUpdate("vehicles", newValue => {
            this.vehicles = newValue;
            this.vehicles.filter(v => v && !v.id).forEach(v => {
                v.id = CoreTools.last(this.vehicles.filter(v => v && v.id))(v => v.id + 1, () => 1);
            });
            this.setVal({vehicles: this.vehicles}, this.updatePanels);
        }));
        this.updatePanels();
    };

    content = (state, props) => state.Panel;
}
