import GenSet from "../../../services/GenSet";
import SurexComponents from "../inserts/SurexComponents";
import LegacyDOBPicker from '../../../directives/LegacyDOBPicker';

export default class SurexDateBirth extends GenSet {
    continue = () => this.navigate(this.props.continue);
    content = (state, props) => <SurexComponents.Card
        title="When were you born?"
        controls={state.ready && <SurexComponents.Controls continue={this.continue} />}
    >
        <LegacyDOBPicker onSelect={v => this.setVal({ready: v})} />
    </SurexComponents.Card>;
}
