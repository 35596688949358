import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import ClrObjects from "../inserts/ClrObjects";
import CoreTools from "../../../services/CoreTools";
import LegacyDOBPicker from "../../../directives/LegacyDOBPicker";

export default class ClrGenPanel extends GenSet {
    onClick = () => this.navigate(this.props.continue);
    continue = () => this.applicant.validate(isValid => {
        if (isValid) {
            if (typeof(this.props.content.inValidate) === "function") {this.props.content.inValidate();}
            this.navigate(this.props.continue);
        } else {window.scrollTo(0, 0);}
    });

    content = (state, props) => <fieldset className="card card-options">
        <div className="card-body">
            <h5 className="card-title">{props.content.title}</h5>
            {props.content.subTitle && <p>{props.content.subTitle}</p>}
            {(props.content.elements || []).map((elem, index) => CoreTools.switch(
                
                [elem.type === "radio", () => <div key={index} className="card-fields row">
                    {(elem.options || []).map(option => <div key={option.value} className="col-12 fullwide">
                        <label className="btn btn-option">
                            <AppInput.Radio name={elem.name} value={option.value} onClick={this.onClick} />
                            <span>{option.caption !== undefined ? option.caption : option.value}</span>
                        </label>
                    </div>)}
                </div>],
                
                [elem.type === "split", () => <div key={index} className="card-fields row">
                    {(elem.options || []).map(option => <div key={option.value} className="col-12 col-md-6">
                        <label className="btn btn-option">
                            <AppInput.Radio name={elem.name} value={option.value} onClick={this.onClick} />
                            <span>{option.caption !== undefined ? option.caption : option.value}</span>
                        </label>
                    </div>)}
                </div>],

                [elem.type === "2Image", () => <div key={index} className="card-fields row bigbuttons">
                    {(elem.options || []).map(option => <div key={option.value} className="col-12 col-md-6">
                        <label className="btn btn-option">
                            <AppInput.Radio name={elem.name} value={option.value} onClick={this.onClick} />
                            <span>{option.image}</span>
                            <p>{option.caption !== undefined ? option.caption : option.value}</p>
                        </label>
                    </div>)}
                </div>],

                [elem.type === "select", () => <div key={index} className="card-fields row">
                    <div className="col-12 mb-3" style={{marginLeft: "auto", marginRight: "auto", maxWidth: "500px"}}>
                        <AppInput.Select name={elem.name} className="form-control text-field" options={elem.options} onValidate={elem.onValidate} failClass="invalid" />
                    </div>
                </div>],

                [elem.type === "continue", () => <div key={index} className="card-fields row">
                    <div className="col-12 mt-3">
                        <button className="btn btn-lg" onClick={this.continue}>{elem.caption}</button>
                    </div>
                </div>],

                [elem.type === "textInput", () => <div key={index} className="card-fields row">
                    <div className="col-12 mt-1" style={{marginLeft: "auto", marginRight: "auto", maxWidth: "500px"}}>
                        <AppInput.Input type="text" name={elem.name} className="form-control text-field" placeholder={elem.placeholder} onValidate={elem.onValidate} onChange={elem.onChange} failClass="invalid" />
                    </div>
                </div>],

                [elem.type === "content", () => <div key={index} className="card-fields row">
                    <div className="col-12 mt-1">
                        {elem.content}
                    </div>
                </div>],

                [elem.type === "dobLegacy", () => <div key={index}>
                    <LegacyDOBPicker onSelect={v => this.setVal({ready: v})} />
                    <div key={index} className="card-fields row">
                        <div className="col-12 mt-3">
                            <button className="btn btn-lg" disabled={!state.ready} onClick={this.continue}>{elem.caption}</button>
                        </div>
                    </div>
                </div>]
            ))}
        </div>
        <ClrObjects.NavOption previous={props.previous} time={props.content.time} />
    </fieldset>;
}