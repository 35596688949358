import CoreTools from "../../../services/CoreTools";

const Card = props => <fieldset className="card card-options">
    <div className="card-body">
        {props.counter && <div className="card-counter">
            {Array.from({length:CoreTools.state.totalPages - 1}, (v, i) => <div key={i} data-selected={i === CoreTools.state.currentPageNumber - 1}>{i + 1}</div>)}
        </div>}
        <div className="card-fields">
            <h5 className="card-title text-center">{props.title}</h5>
            {props.text && <p className="card-text text-center">{props.text}</p>}
            <div className="card-fields-options">
                {props.children}
            </div>
        </div>
        {props.controls}
    </div>
</fieldset>;

const Controls = props => <div className="card-controls text-center">
    { props.buttonText !== false && <button disabled={props.disabled} onClick={props.continue} className="mx-auto btn btn-navigate">{props.buttonText || "Next"}</button>}
    {/* { props.disclaimer && props.disclaimer !== "US" && <p className="term-txt text-center mt-1"><b>By clicking "{props.buttonText || "Next"}", I have read and accept the <a href="/terms-and-conditions/" target="_blank" rel="noreferrer">Terms of Use</a>. I also acknowledge having read the <a href="/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> and agree to the terms herein.</b></p>}
    { props.disclaimer === "US" && <p className="term-txt text-center mt-1"><input type="hidden" id="leadid_tcpa_disclosure"/>By clicking "{props.buttonText || "Next"}" you agree to:
    <ul>
        <li>You consent to be contacted and receive informational and marketing communications and messages from us and/or one or more of our service providers, partners , affiliates, or a third party (collectively, "Partners") about services listed on this webpage, by a live agent, artificial, automated, or pre-recorded voice, by email and by Short Message Service ("SMS") text at your residence, mobile, or other phone number, using any number or address you have provided, or that you will provide in the future (of which you hereby represent you are the owner, subscriber, or regular user) (collectively, "Contact Info"). Those communications may be dialed manually or by an automated telephone dialing system. For SMS messages, you acknowledge that standard message and data rates may apply.</li>
        <li>You consent to be contacted even if your Contact Info appears on ours or our Partners' Do Not Call / Do Not Email List, a State or National Do Not Call Registry, or any other Do Not Contact List. You consent to calls from us or our Partners at the phone number that you have provided on our webform in which this consent is associated. You will promptly notify us if you stop using a particular phone number or email address that you have provided to us. Consent to the contact in this paragraph is not a condition to purchase services and you acknowledge that you may revoke this consent at any time, by emailing us at <a href="mailto:compliance@jugglingdebt.com" rel="noreferrer">compliance@jugglingdebt.com</a></li>
        <li>You consent to and acknowledge that an automated message, or an artificial or prerecorded voice may be played when the telephone is answered whether by you or someone else.</li>
        <li>You consent to and acknowledge that we and/or one or more of our Partners may listen and/or record and retain recordings of calls between us and one or more Partners and you for quality assurance or other purposes, as permitted by applicable laws, without additional notice.</li>
        <li>You agree and acknowledge that we may disclose, share, sell, re-sell or market your personal data to one or more Partner(s) which may result in compensation to us or one of our Partners. You consent that we or our Partners can use this data for various purposes, including, but not limited to, re-targeting, marketing, analytics, product, and service improvement, cross marketing you to other products and to enhance user experience. Disclosures may include sharing and/or selling your data to third party service providers, their brokers and their service providers or Partners and you expressly consent to be contacted by us, our agents, representatives, affiliates, third parties, Partners or anyone calling for any and all purposes arising out of or relating to your inquiry with us, whether for the product or service you are applying for, or for any other product or service that we may deem a potential fit for you, based on the information you have submitted to us or our Partners.</li>
        <li>You consent to us using analytic and marketing tools utilizing your data based on, and including but not limited to Artificial Intelligence based software and technology. You consent to us using technology that may track your consent and capture screenshots and your IP address (or any other related digital footprint or evidence) of your journey through our web funnel(s) and application(s) to prove your consent.</li>
        <li>Our services may include an SMS message which may deliver up to five messages per day to your wireless device. We may use your information to contact you about your info, purchases, website updates, conduct surveys, or informational and service-related communications, including important security updates. You may remove your information at any time by replying "STOP" to the SMS message you received. After you send the SMS message "STOP" to us, we will send you an SMS message to confirm that you have unsubscribed. After this, you will no longer receive SMS messages from us. If you want to join again, just sign up as you did the first time, and we will start sending SMS messages to you again. For help, please reply to a text with HELP. Message and data rates may apply, depending on your cell phone plan. Carriers are not liable for delayed or undelivered messages. Alternatively, you may submit your request to stop sending SMS messages by email to us and include the email address and phone number you registered with us.</li>
        <li>You consent to us and/or one or more of our Partners performing a credit check on you based on the information you have provided. You agree to our  <a href="/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="/terms-and-conditions/" target="_blank" rel="noreferrer">Terms and Conditions</a>.</li>
    </ul></p>} */}
</div>;

const JugglingDebtComponents = {
    Card: Card,
    Controls: Controls
};

export default JugglingDebtComponents;