import React from "react";
import OptionLists from "../services/OptionLists";
import CoreTools from "../services/CoreTools";
import Applicant from "../services/Applicant";

const DatePickerSmall = props => {
    
    const initDate = () => {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        if (typeof(props.date) === "string" && props.date.split("-").length === 3) {
            const [year, month, day] = props.date.split("-");
            date.setFullYear(
                parseInt(year) || date.getFullYear(),
                parseInt(month) ? parseInt(month) - 1 : date.getMonth(),
                parseInt(day) || date.getDate()
            );
        }
        if (typeof(props.name) === "string" && typeof(Applicant().field(props.name)) === "string" && Applicant().field(props.name).split("-").length === 3) {
            const [year, month, day] = Applicant().field(props.name).split("-");
            date.setFullYear(
                parseInt(year) || date.getFullYear(),
                parseInt(month) ? parseInt(month) - 1 : date.getMonth(),
                parseInt(day) || date.getDate()
            );
        }
        return date;
    };

    const [thisDate, setThisDate] = React.useState(initDate());

    const dateString = () => `${thisDate.getFullYear()}-${(thisDate.getMonth() + 1).toString().padStart(2, "0")}-${thisDate.getDate().toString().padStart(2, "0")}`;

    React.useEffect(() => {
        if (props.name) {
            return Applicant().onFieldUpdate(props.name, value => {
                if (typeof(value) === "string" && value.split("-").length === 3) {
                    const [year, month, day] = value.split("-");
                    let date = new Date();
                    date.setHours(0, 0, 0, 0);
                    date.setFullYear(
                        parseInt(year) || date.getFullYear(),
                        parseInt(month) ? parseInt(month) - 1 : date.getMonth(),
                        parseInt(day) || date.getDate()
                    );
                    setThisDate(date);
                }
            });
        }
    }, [props.name]);

    React.useEffect(() => {
        CoreTools.importStyle((CoreTools.state.isLocal ? "" : "https://form.consumergenius.com") + "/css/DatePickerSmall.css");
    }, []);

    const close = () => {
        thisDate.setHours(0, 0, 0, 0);
        setThisDate(new Date(thisDate));
        CoreTools.log("Selected Date:", dateString());
        if (typeof(props.onSelect) === "function") {props.onSelect(dateString());}
        if (props.name) {Applicant().setField(props.name, dateString());}
    };

    const selectDate = day => {
        thisDate.setDate(day);
        close();
    };

    const buildDaysElement = () => {
        let date = new Date(thisDate);
        date.setDate(1);
        let wd = date.getDay();
        date.setMonth(date.getMonth() + 1);
        date.setDate(date.getDate() - 1);
        date.setHours(0, 0, 0, 0);
        let lastDay = date.getDate();
        let weeks = [];
        let week = [];
        for (let i = 0; i < wd; i++) {week.push(null); if (week.length === 7) {weeks.push(week); week = [];}}
        for (let i = 1; i <= lastDay; i++) {
            week.push(i);
            if (week.length === 7) {weeks.push(week); week = [];}
        }
        while (week.length > 0 || weeks.length < 6) {week.push(null); if (week.length === 7) {weeks.push(week); week = [];}}
        return weeks.map((wks, i) => <tr key={"w" + i}>{
            wks.map((d, ii) => <td
                key={"w" + i + "d" + ii}
                style={{cursor: d ? "pointer" : "default"}}
                className={[
                    "day",
                    CoreTools.switch(
                        [d && d === thisDate.getDate(), "bg-success text-center text-white bg-gradient"],
                        [d && d === new Date().getDate(), "bg-secondary text-center text-white bg-gradient"],
                        [d, "bg-light text-center text-black bg-gradient"],
                        [true, "bg-white"]
                    )
                ].filter(v => v).join(" ")}
                onClick={() => d && selectDate(d)}
            >{d}</td>)
        }</tr>);
    };
    
    return <table className="calContainer" id="DatePickerSmall">
        <tbody>
            <tr className="titlebar panel bg-primary text-white bg-gradient"><td colSpan={7}>{props.title}</td></tr>
            <tr>
                <td className="day bg-secondary text-white bg-gradient" title="Previous Month" onClick={() => setThisDate((() => {thisDate.setMonth(thisDate.getMonth() - 1); return new Date(thisDate);})())}><i className="fas fa-arrow-left"></i></td>
                <td className="text-center" colSpan={5}>{OptionLists.Months.filter(m => m.value === thisDate.getMonth() + 1).map(m => m.long).join("")} - {thisDate.getFullYear()}</td>
                <td className="day bg-secondary text-white bg-gradient" title="Next Month" onClick={() => setThisDate((() => {thisDate.setMonth(thisDate.getMonth() + 1); return new Date(thisDate);})())}><i className="fas fa-arrow-right"></i></td>
            </tr>
            {buildDaysElement()}
            <tr>
                <td className="day bg-secondary text-white bg-gradient" title="Previous Day" onClick={() => setThisDate((() => {thisDate.setDate(thisDate.getDate() - 1); return new Date(thisDate);})())}><i className="fa-solid fa-backward-step"></i></td>
                <td className="day bg-primary text-white bg-gradient" colSpan={2} onClick={() => setThisDate(new Date())}><i className="fa-solid fa-calendar-day"></i> Today</td>
                <td className="day bg-secondary text-white bg-gradient" title="Next Day" onClick={() => setThisDate((() => {thisDate.setDate(thisDate.getDate() + 1); return new Date(thisDate);})())}><i className="fa-solid fa-forward-step"></i></td>
                {
                    props.showClose ? 
                    <td className="day bg-primary text-white bg-gradient" colSpan={3} onClick={close}><i className="fas fa-door-open"></i> Close</td> :
                    <td className="day bg-white" colSpan={3}></td>
                }
            </tr>
        </tbody>
    </table>;
};
export default DatePickerSmall;