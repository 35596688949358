import ReactDOM from 'react-dom/client';
import CoreTools from './services/CoreTools';
import Applicant from './services/Applicant';
import ACAIndex from './sites/ACA/ACAIndex';
import AlrIndex from './sites/AmericanLifeRates/AlrIndex';
import AlrIndexRed from './sites/AmericanLifeRatesRed/AlrIndexRed';
import AidcIndex from "./sites/appleimplantdentalclinic/AidcIndex";
import AutomationIndex from './sites/Automation/AutomationIndex';
import BlrIndex from './sites/BritishLifeRates/BlrIndex';
import BSIndexFlatForm from './sites/BritishSeniors/BSIndexFlatForm';
import CLRIndexFlatForm from './sites/CLRFlat/CLRIndexFlatForm';
import CacIndex from "./sites/CanadianAlignersClub/CacIndex";
import CampLeJeuneIndex from './sites/CampLeJeune/CampLeJeuneIndex';
import CarsfastIndex from "./sites/CarsfastCanada/CarsfastIndex";
import CarsfastAltIndex from "./sites/CarsfastCanadaAlt/CarsfastAltIndex";
import CarsfastFlatFormIndex from "./sites/CarsfastFlatForm/CarsfastFlatFormIndex";
import ClrIndex from "./sites/CanadianLifeRates/ClrIndex";
import ClrFlatFormIndex from "./sites/CanadianLifeRatesFlatForm/ClrFlatFormIndex";
import ClrIndexV6 from './sites/CanadianLifeRatesV6/ClrIndexV6';
import ClrIndexQN from './sites/CanadianLifeRatesQN/ClrIndexQN';
import ClrIndexRed from './sites/CanadianLifeRatesRed/ClrIndexRed';
import ConsumerGeniusIndex from './sites/ConsumerGenius/ConsumerGeniusIndex';
import FinalExpenseIndex from './sites/FinalExpense/FinalExpenseIndex';
import HairJusticeIndex from './sites/HairJustice/HairJusticeIndex';
import HeIndex from "./sites/HomeEquity/HeIndex";
import HlIndex from "./sites/HearingLife/HlIndex";
import HlIndex2 from "./sites/HearingLife/HlIndex2";
import Jornaya from './services/Jornaya';
import JugglingDebtIndex from "./sites/JugglingDebt/JugglingDebtIndex";
import JugglingDebtv2Index from "./sites/JugglingDebt-v2/JugglingDebtIndex"; 
import LifeInsuranceIndex from './sites/LifeInsurance/LifeInsuranceIndex';
import LifeInsuranceIndexV2 from './sites/LifeInsurance/LifeInsuranceIndexV2';
import LoanConnectCanadaIndex from './sites/LoanConnectCanada/LoanConnectCanadaIndex';
import LoanzCashmasIndex from './sites/LoanzCashmas/LoanzCashmasIndex';
import LoanzFlatForm from "./sites/Loanz/LoanzFlatForm";
import LoanzFlatFormUK from "./sites/Loanz/LoanzFlatFormUK";
import LoanzIndex from "./sites/Loanz/LoanzIndex";
import LoanzIndexFlatForm from "./sites/Loanz/LoanzIndexFlatForm";
import LoanzCsIndex from "./sites/LoanzCreditScore/LoanzCsIndex";
import LoanzCsExIndex from "./sites/LoanzCreditScoreExtended/LoanzCsExIndex";
import MedicareIndex from './sites/Medicare/MedicareIndex';
import MicIndex from "./sites/MortgageInsuranceCanada/MicIndex";
import MVAIndex from './sites/MotorVehicleAccident/MVAIndex';
import NhpusIndex from "./sites/NewHomePurchaseUSA/NhpusIndex";
import OnetwothreeDentistIndex from "./sites/123Dentist/OnetwothreeDentistIndex";
import RefinIndex from "./sites/Refinance/RefinIndex";
import RenovateIndex from './sites/Renovate/RenovateIndex';
import RenovateFlatFormIndex from './sites/RenovateFlatForm/RenovateFlatFormIndex';
import RenovateMasterIndex from './sites/Renovate/RenovateMasterIndex';
import RenovateMasterIndexV2 from './sites/Renovate/RenovateMasterIndexV2';
import RenovateMasterIndexV3 from './sites/Renovate/RenovateMasterIndexV3';
import RenovateTradeshow from './sites/Renovate/RenovateTradeshow';
import RifcoIndex from './sites/Rifco/RifcoIndex';
import RVFastIndex from './sites/RVFast/RVFastIndex';
import SLRIndexFlatForm from './sites/SeniorsLifeRates/SLRIndexFlatForm';
import SmartFinancialIndex from './sites/SmartFinancial/SmartFinancialIndex';
import SpeedyInsuranceIndex from './sites/SpeedyInsuranceQuotes/SpeedyInsuranceIndex';
import SpeedyInsuranceQuotesCanadaIndex from "./sites/SpeedyInsuranceQuotesCanada/Design1/SpeedyInsuranceQuotesCanadaIndex";
import SiqcfIndex from "./sites/SpeedyInsuranceQuotesCanada/Design2/SiqcfIndex";
import SurexIndex from "./sites/Surex/SurexIndex";
import Surex2Index from "./sites/SurexV2/Surex2Index";
import SolarIndex from "./sites/Solar/SolarIndex";
import SolarV2Index from './sites/SolarV2/SolarV2Index';
import SolarV3Index from './sites/SolarV3/SolarV3Index';
import TestIndex from "./sites/Test/TestIndex";
import UKAuto from './sites/UKCarLoans/UKAutoIndex';
import UKIndex from './sites/UKLifeRates/UKIndex';
import Notes from './Notes'; 


const flows = {
    // ACA:                                () => <ACAIndex />,
    // AmericanLifeRates:                  () => <AlrIndex version="v1" />,
    // AmericanLifeRatesRed:               () => <AlrIndexRed />,
    // AppleDentalImplantClinic:           () => <AidcIndex />,
    // Automation:                         () => <AutomationIndex version="v1" />,
    BritishLifeRates:                   () => <BlrIndex />,
    BritishSeniors:                     () => <BSIndexFlatForm />,
    // CampLeJeune:                        () => <CampLeJeuneIndex />,
    // CanadianAlignersClub:               () => <CacIndex />,
    CanadianLifeRates:                  () => <ClrIndex version="v1" />,
    CanadianLifeRatesV2:                () => <ClrIndex version="v2" />,
    CanadianLifeRatesV3:                () => <ClrIndex version="v3" />,
    CanadianLifeRatesV4:                () => <ClrIndex version="v4" />,
    CanadianLifeRatesV5:                () => <ClrIndex version="v5" />,
    CanadianLifeRatesV6:                () => <ClrIndexV6 version="v1"/>,
    CanadianLifeRatesV7:                () => <ClrIndexV6 version="v2"/>,
    CanadianLifeRatesFE:                () => <ClrIndexV6 version="v3"/>,
    CanadianLifeRatesQN:                () => <ClrIndexQN version="v1"/>,
    CanadianLifeRatesShortened:         () => <ClrIndexQN version="v7"/>,
    CanadianLifeRatesShortenedFiftyPlus:() => <ClrIndexQN version="v8"/>,
    CanadianLifeRatesNameFirst:         () => <ClrIndexQN version="v5"/>,
    CanadianLifeRatesNameCombined:      () => <ClrIndexQN version="v6"/>,
    CanadianLifeRatesRed:               () => <ClrIndexRed />,
    CanadianLifeRatesFlat:              () => <ClrFlatFormIndex />,
    CarsfastCanada:                     () => <CarsfastIndex />,
    CarsfastCanadaAlt:                  () => <CarsfastAltIndex />,
    CarsfastFlat:                       () => <CarsfastFlatFormIndex />,
    ConsumerGenius:                     () => <ConsumerGeniusIndex version="v1"/>,
    ConsumerGeniusV2:                   () => <ConsumerGeniusIndex version="v2"/>,
    CLRFlat:                            () => <CLRIndexFlatForm />,
    EverQuote:                          () => <Surex2Index version="EverQuote" />,
    // FinalExpense:                       () => <FinalExpenseIndex />,
    HearingLifeCA:                      () => <HlIndex version="ca" />,
    HearingLifeCA2:                     () => <HlIndex version="ca2" />,
    // HearingLifeUS:                      () => <HlIndex version="us" />,
    HearingLifeALT:                     () => <HlIndex2 version="ca" />,
    HomeLoanzCanada:                    () => <NhpusIndex />,
    HomeEquityOriginal:                 () => <HeIndex version="original" />,
    HomeEquityOriginalUS:               () => <HeIndex version="originalUS" />,
    HomeEquityOriginalDropdowns:        () => <HeIndex version="originalDropdowns" />,
    HomeEquityShort:                    () => <HeIndex version="short" />,
    HomeEquityShort2:                   () => <HeIndex version="short2" />,
    HomeEquityShortSms:                 () => <HeIndex version="shortSms" />,
    HomeEquityShortToLong:              () => <HeIndex version="shortToLong" />,
    HomeEquityShortToLongDropdowns:     () => <HeIndex version="shortToLongDropdowns" />,
    // NewHomePurchaseUSA:                 () => <NhpusIndex />,
    // JugglingDebtCA:                     () => <JugglingDebtIndex version="ca1" />,
    JugglingDebtUS1:                    () => <JugglingDebtIndex version="us1" />,
    // JugglingDebtUS2:                    () => <JugglingDebtIndex version="us2" />,
    // JugglingDebtUK1:                    () => <JugglingDebtIndex version="uk1" />,
    // JugglingDebtV2:                     () => <JugglingDebtv2Index version="ca1" />,
    // HairJustice:                        () => <HairJusticeIndex />,
    LifeInsurance:                      () => <LifeInsuranceIndex />,
    LifeInsuranceV2:                    () => <LifeInsuranceIndexV2 />,
    // LoanConnectCanada:                  () => <LoanConnectCanadaIndex />,
    LoanzCashmas:                       () => <LoanzCashmasIndex version="cashmas" />,
    // LoanzCreditScore:                   () => <LoanzCsIndex />,
    // LoanzCreditScoreEx:                 () => <LoanzCsExIndex />,
    LoanzFlat:                          () => <LoanzFlatForm />,
    LoanzFlatUK:                        () => <LoanzFlatFormUK />,
    LoanzCA:                            () => <LoanzIndex version="ca" />,
    LoanzUK:                            () => <LoanzIndex version="uk" />,
    LoanzUK2:                            () => <LoanzIndex version="uk2" />,
    LoanzUK3:                            () => <LoanzIndex version="uk3" />,
    LoanzUS:                            () => <LoanzIndex version="us" />,
    LoanzUS2:                           () => <LoanzIndex version="us2" />,
    // LoanzFlatUS:                        () => <LoanzIndexFlatForm version="us" />,
    // LoanzFlatCA:                        () => <LoanzIndexFlatForm version="ca" />,
    // MediCare:                           () => <MedicareIndex />,
    // MortgageInsuranceCanada:            () => <MicIndex version="original" />,
    // MotorVehicleAccident:               () => <MVAIndex version="ca" />,
    // OnetwothreeDentist:                 () => <OnetwothreeDentistIndex />,
    // RefinanceCA:                        () => <RefinIndex version="ca" />,
    // RefinanceUS:                        () => <RefinIndex version="us" />,
    // RenovateMaster:                     () => <RenovateMasterIndex />,
    // RenovateMasterV2:                   () => <RenovateMasterIndexV2 />,
    // RenovateMasterV3:                   () => <RenovateMasterIndexV3 />,
    RenovateBathroom:                   () => <RenovateIndex version="bathroom" />,
    // RenovateBathroomFlat:               () => <RenovateFlatFormIndex version="bathroom" />,
    // RenovateBathroomFlatV2:             () => <RenovateFlatFormIndex version="bathroomV2" />,
    // RenovateGuttersFlat:                () => <RenovateFlatFormIndex version="gutters" />,
    // RenovateGuttersFlatV2:              () => <RenovateFlatFormIndex version="guttersV2" />,
    RenovateGutters:                    () => <RenovateIndex version="gutters" />,
    // RenovatePhilly:                     () => <RenovateIndex version="philly" />,
    RenovateRoofing:                    () => <RenovateIndex version="roofing" />,
    // RenovateRoofingV2:                  () => <RenovateIndex version="roofingV2" />,
    // RenovateRoofingFlat:                () => <RenovateFlatFormIndex version="roofing" />,
    // RenovateRoofingFlatV2:              () => <RenovateFlatFormIndex version="roofingV2" />,
    // RenovateSavannah:                   () => <RenovateIndex version="savannah" />,
    // RenovateTradeshow:                  () => <RenovateTradeshow />,
    // RenovateWalkIn:                     () => <RenovateIndex version="walkIn" />,
    // RenovateWalkInFlat:                 () => <RenovateFlatFormIndex version="walkIn" />,
    // RenovateWalkInFlatV2:               () => <RenovateFlatFormIndex version="walkInV2" />,
    // RenovateSolar:                      () => <RenovateIndex version="solar" />,
    RenovateWindows:                    () => <RenovateIndex version="windows" />,
    // RenovateWindowsV2:                  () => <RenovateIndex version="windowsV2" />,
    // RenovateWindowsFlat:                () => <RenovateFlatFormIndex version="windows" />,
    // RenovateWindowsFlatV2:              () => <RenovateFlatFormIndex version="windowsV2" />,
    Rifco:                              () => <RifcoIndex />,
    RVFast:                             () => <RVFastIndex />,
    SeniorsLifeRates:                   () => <SLRIndexFlatForm />,
    // SmartFinancial:                     () => <SmartFinancialIndex />,
    // Solar:                              () => <SolarIndex />,
    // SolarBetter:                        () => <SolarIndex version="better" />,
    // SolarMomentum:                      () => <SolarIndex version="momentum" />,
    // SolarV2:                            () => <SolarV2Index />,
    // SolarV3:                            () => <SolarV3Index />,
    // SpeedyInsurance:                    () => <SpeedyInsuranceIndex />,
    // SpeedyInsuranceQuotesCanadaV1:      () => <SpeedyInsuranceQuotesCanadaIndex />,
    // SpeedyInsuranceQuotesCanadaV2:      () => <SiqcfIndex />,
    Surex:                              () => <SurexIndex version="original" />,
    SurexV2:                            () => <Surex2Index version="V2" />,
    SurexV3:                            () => <Surex2Index version="V3" />,
    SurexV4:                            () => <Surex2Index version="V4" />,
    // Test:                               () => <TestIndex />,
    // UKAuto:                             () => <UKAuto version="original" />,
    UKLifeRates:                        () => <UKIndex />,
};


const JornayaKeys = {
    // SolarMomentum: "jornaya id/key here"
    // ACA: "",
    // AmericanLifeRates: "",
    // AmericanLifeRatesRed: "",
    // AppleDentalImplantClinic: "",
    // Automation: "",
    BritishLifeRates: "085becc4-a33f-fe81-ed4b-47ce2d7df8ab",
    BritishSeniors: "085becc4-a33f-fe81-ed4b-47ce2d7df8ab",
    // CampLeJeune: "",
    // CanadianAlignersClub: "",
    CanadianLifeRates: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    CanadianLifeRatesV2: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    CanadianLifeRatesV3: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    CanadianLifeRatesV4: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    CanadianLifeRatesV5: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    CanadianLifeRatesV6: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    CanadianLifeRatesV7: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    CanadianLifeRatesFE: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    CanadianLifeRatesQN: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    CanadianLifeRatesShortened: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    CanadianLifeRatesShortenedFiftyPlus: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    CanadianLifeRatesNameFirst: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    CanadianLifeRatesNameCombined: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    CanadianLifeRatesRed: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    CanadianLifeRatesFlat: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    CarsfastCanada: "8fce9643-4112-7a44-b3da-fdfa78e1c865",
    CarsfastCanadaAlt: "8fce9643-4112-7a44-b3da-fdfa78e1c865",
    CarsfastFlat: "8fce9643-4112-7a44-b3da-fdfa78e1c865",
    // ConsumerGenius: "",
    // ConsumerGeniusV2: "",
    CLRFlat: "d4ccf321-da8c-8c29-2794-d2b18d55388f",
    // EverQuote: "",
    // FinalExpense: "",
    // HearingLifeCA: "",
    // HearingLifeCA2: "",
    // HearingLifeUS: "",
    // HearingLifeALT: "",
    // HomeLoanzCanada: "",
    HomeEquityOriginalUS: "9a13dcae-40bc-a941-846e-9510d90c3a5a",
    HomeEquityOriginal: "cefc93ff-fedc-4f38-ffb8-9ce77311f7d1",
    HomeEquityOriginalDropdowns: "cefc93ff-fedc-4f38-ffb8-9ce77311f7d1",
    HomeEquityShort: "cefc93ff-fedc-4f38-ffb8-9ce77311f7d1",
    HomeEquityShort2: "cefc93ff-fedc-4f38-ffb8-9ce77311f7d1",
    HomeEquityShortSms: "cefc93ff-fedc-4f38-ffb8-9ce77311f7d1",
    HomeEquityShortToLong: "cefc93ff-fedc-4f38-ffb8-9ce77311f7d1",
    HomeEquityShortToLongDropdowns: "cefc93ff-fedc-4f38-ffb8-9ce77311f7d1",
    // NewHomePurchaseUSA: "",
    JugglingDebtCA: "a9e96822-cf77-636b-864d-f727e58bd343",
    // JugglingDebtUS1: "",
    // JugglingDebtUS2: "",
    // JugglingDebtUK1: "",
    // JugglingDebtV2: "",
    // HairJustice: "",
    // LifeInsurance: "",
    // LifeInsuranceV2: "",
    // LoanConnectCanada: "",
    // LoanzCashmas: "",
    // LoanzCreditScore: "",
    // LoanzCreditScoreEx: "",
    // LoanzFlat: "",
    // LoanzFlatUK: "",
    // LoanzCA: "",
    // LoanzUK: "",
    // LoanzUS: "",
    // LoanzUS2: "",
    // LoanzFlatUS: "",
    // LoanzFlatCA: "",
    // MediCare: "",
    // MortgageInsuranceCanada: "",
    // MotorVehicleAccident: "",
    // OnetwothreeDentist: "",
    // RefinanceCA: "",
    // RefinanceUS: "",
    // RenovateMaster: "",
    // RenovateMasterV2: "",
    // RenovateMasterV3: "",
    // RenovateBathroom: "",
    // RenovateBathroomFlat: "",
    // RenovateBathroomFlatV2: "",
    // RenovateGuttersFlat: "",
    // RenovateGuttersFlatV2: "",
    // RenovateGutters: "",
    // RenovatePhilly: "",
    // RenovateRoofing: "",
    // RenovateRoofingV2: "",
    // RenovateRoofingFlat: "",
    // RenovateRoofingFlatV2: "",
    // RenovateWalkIn: "",
    // RenovateWalkInFlat: "",
    // RenovateWalkInFlatV2: "",
    // RenovateSolar: "",
    // RenovateWindows: "",
    // RenovateWindowsV2: "",
    // RenovateWindowsFlat: "",
    // RenovateWindowsFlatV2: "",
    // Rifco: "",
    // RVFast: "",
    SeniorsLifeRates: "519aea9e-c703-21c8-da2c-c27079584e04",
    // SmartFinancial: "",
    // Solar: "",
    // SolarBetter: "",
    // SolarMomentum: "",
    // SolarV2: "",
    // SolarV3: "",
    // SpeedyInsurance: "",
    // SpeedyInsuranceQuotesCanadaV1: "",
    // SpeedyInsuranceQuotesCanadaV2: "",
    // Surex: "",
    // SurexV2: "",
    // SurexV3: "",
    SurexV4: "94fb8ea7-4a00-3eaa-1787-bb5dd0b083af",
    // Test: "",
    // UKAuto: "",
    // UKLifeRates: "",
};

// flowTitles is just to retitle your flow in the DomainPicker, use false to not show the flow and omit to just use the flow variable name
const flowTitles = {
    Test: "Test Screens",
    NewHomePurchaseUSA: "New Home Purchase USA",
    HomeEquityOriginal: "Home Equity (Original)",
    HomeEquityOriginalDropdowns: "Home Equity (Original with Dropdowns)",
    HomeEquityShort: "Home Equity (Short)",
    HomeEquityShortSms: "Home Equity (Short SMS)",
    HomeEquityShortToLong: "Home Equity (Short to Long)",
    HomeEquityShortToLongDropdowns: "Home Equity (Short to Long with Dropdowns)",
    CarsfastCanada: "Carsfast",
    CarsfastCanadaAlt: "Carsfast Alternate",
    SpeedyInsuranceQuotesCanadaV2: "Speedy Insurance Quotes Canada",
    OnetwothreeDentist: "123 Dentist",
    CanadianAlignersClub: "Canadian Aligners Club",
    AppleDentalImplantClinic: "Apple Dental Implant Clinic",
    HomeLoanzCanada: false
};

const DomainPicker = () => {
    const setDevDomain = flow => {if (flow) {sessionStorage.setItem("devflow", flow); window.location.reload();}};
    const deleteCookie = () => {CoreTools.memory.set("ukey", "", 0); window.location.reload();};
    return <div style={{width: "400px", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", textAlign: "center"}}>
        <select onChange={e => setDevDomain(e.target.value)} style={{display: "block", margin: "5px auto"}}>
            <option>Select Component</option>
            {Object.keys(flows).filter(flow => flowTitles[flow] !== false).map(flow => <option key={flow} value={flow}>{flowTitles[flow] || flow}</option>)}
        </select>
        <button onClick={deleteCookie} style={{display: "block", margin: "5px auto"}}>Delete Cookie</button>
    </div>;
};

const Funnels = funnelComp => {
    const globalStyles = document.createElement("style");
    globalStyles.innerHTML = [
        "#funnelComp .invalid {",
            "background-color: #f5a9a9 !important;",
            "border-color: rgb(250, 31, 31) !important;",
        "}"
    ].join(" ");
    document.head.appendChild(globalStyles);

    if (CoreTools.getQueryParam("localhost") && !document.getElementById("localScript")) {
        console.log("RemoteDev", CoreTools.getQueryParam("localhost"));
        CoreTools.importScript(`http://localhost:${CoreTools.getQueryParam("localhost")}/static/js/bundle.js`, false, false, "localScript");
    } else if (CoreTools.state.isLocal) {
        const applicant = Applicant();
        const flow = sessionStorage.getItem("devflow");
        if (flow && Object.keys(flows).includes(flow)) {
            CoreTools.state.appOptions = [];
            applicant.setFlow(flow);
            ReactDOM.createRoot(funnelComp).render(flows[flow]());
        } else {
            ReactDOM.createRoot(funnelComp).render(<DomainPicker />);
        }
    } else {
        const applicant = Applicant();
        const flow = CoreTools.getQueryParam("flow") || funnelComp.getAttribute("flow");
        if (flow && Object.keys(flows).includes(flow)) {
            CoreTools.state.appOptions = (funnelComp.getAttribute("options") || "").split(" ").map(e => e.trim().toLowerCase());
            applicant.setFlow(flow);
            ReactDOM.createRoot(funnelComp).render(flows[flow]());
            if (typeof(JornayaKeys[flow]) === "string") {Jornaya(JornayaKeys[flow]);}
            else if (funnelComp.getAttribute("jornaya")) {Jornaya(funnelComp.getAttribute("jornaya"));}
            Notes();
        } else {
            ReactDOM.createRoot(funnelComp).render(<div>This form is currently unavailable.</div>);
        }
    }
};

export default Funnels;
