import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";

export default class NhpusPropertyUse extends GenSet {
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <fieldset className="card card-options">
        <div className="card-body">
            <h5 className="card-title">How is this property being used?</h5>
            <div className="card-fields row">
                <div className="col-12">
                    <label className="btn btn-option long-text">
                        <AppInput.Radio name="property_use" value="Primary Residence" onClick={this.onClick} />
                        <span>Primary Home</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option long-text">
                        <AppInput.Radio name="property_use" value="Second/Vacation Home" onClick={this.onClick} />
                        <span>Secondary Home</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option long-text">
                        <AppInput.Radio name="property_use" value="Investment Property" onClick={this.onClick} />
                        <span>Rental Property</span>
                    </label>
                </div>
            </div>
        </div>
    </fieldset>;

}