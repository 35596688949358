import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";

export default class SLRGender extends GenSet {
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <div className="form-panel">
    <div className="row">
        <div className="col-md-12">
            <span className="radio-button-title">Are You:<span style={{color: "red"}}>*</span></span>                
            <span className="input-group"> 
                <label className="btn btn-option">
                    <AppInput.Radio name="gender" value="Male" onClick={this.onClick} />
                    <span className="btn-option-text">Male</span>
                </label>    
                <label className="btn btn-option">
                    <AppInput.Radio name="gender" value="Female" onClick={this.onClick} />
                    <span className="btn-option-text">Female</span>
                </label>         
            </span>
        </div>
    </div>
</div>;
}

