const States = [
    {short: "AL", long: "Alabama"},
    {short: "AK", long: "Alaska"},
    {short: "AZ", long: "Arizona"},
    {short: "AR", long: "Arkansas"},
    {short: "CA", long: "California"},
    {short: "CO", long: "Colorado"},
    {short: "CT", long: "Connecticut"},
    {short: "DE", long: "Delaware"},
    {short: "FL", long: "Florida"},
    {short: "GA", long: "Georgia"},
    {short: "HI", long: "Hawaii"},
    {short: "ID", long: "Idaho"},
    {short: "IL", long: "Illinois"},
    {short: "IN", long: "Indiana"},
    {short: "IA", long: "Iowa"},
    {short: "KS", long: "Kansas"},
    {short: "KY", long: "Kentucky"},
    {short: "LA", long: "Louisiana"},
    {short: "ME", long: "Maine"},
    {short: "MD", long: "Maryland"},
    {short: "MA", long: "Massachusetts"},
    {short: "MI", long: "Michigan"},
    {short: "MN", long: "Minnesota"},
    {short: "MS", long: "Mississippi"},
    {short: "MO", long: "Missouri"},
    {short: "MT", long: "Montana"},
    {short: "NE", long: "Nebraska"},
    {short: "NV", long: "Nevada"},
    {short: "NH", long: "New Hampshire"},
    {short: "NJ", long: "New Jersey"},
    {short: "NM", long: "New Mexico"},
    {short: "NY", long: "New York"},
    {short: "NC", long: "North Carolina"},
    {short: "ND", long: "North Dakota"},
    {short: "OH", long: "Ohio"},
    {short: "OK", long: "Oklahoma"},
    {short: "OR", long: "Oregon"},
    {short: "PA", long: "Pennsylvania"},
    {short: "RI", long: "Rhode Island"},
    {short: "SC", long: "South Carolina"},
    {short: "SD", long: "South Dakota"},
    {short: "TN", long: "Tennessee"},
    {short: "TX", long: "Texas"},
    {short: "UT", long: "Utah"},
    {short: "VT", long: "Vermont"},
    {short: "VA", long: "Virginia"},
    {short: "WA", long: "Washington"},
    {short: "WV", long: "West Virginia"},
    {short: "WI", long: "Wisconsin"},
    {short: "WY", long: "Wyoming"}
];

const Provinces = [
    {short: "AB", long: "Alberta", frLong: "L'Alberta"},
    {short: "BC", long: "British Columbia", frLong: "La Colombie-Britannique"},
    {short: "MB", long: "Manitoba", frLong: "Le Manitoba"},
    {short: "NB", long: "New Brunswick", frLong: "Le Nouveau-Brunswick"},
    {short: "NL", long: "Newfoundland and Labrador", frLong: "La Terre-Neuve-et-Labrador"},
    {short: "NS", long: "Nova Scotia", frLong: "La Nouvelle-Écosse"},
    {short: "ON", long: "Ontario", frLong: "L'Ontario"},
    {short: "PE", long: "Prince Edward Island", frLong: "Île-du-Prince-Édouard"},
    {short: "QC", long: "Quebec", frLong: "Le Québec"},
    {short: "SK", long: "Saskatchewan", frLong: "La Saskatchewan"},
    {short: "NT", long: "Northwest Territories", frLong: "Les Territoires du Nord-Ouest"},
    {short: "NU", long: "Nunavut", frLong: "Le Nunavut"},
    {short: "YT", long: "Yukon", frLong: "Le Yukon"}
];

const DriverClassesCanada = [
    {province: "ON", classes: ["A", "B", "C", "D", "E", "F", "G", "G1", "G2"]},
    {province: "AB", classes: ["1", "2", "3", "4", "5", "7"]},
    {province: "BC", classes: ["1", "2", "3", "4 (restricted)", "4 (unrestricted)", "5", "7"]},
    {province: "MB", classes: ["1", "2", "3", "4", "5"]},
    {province: "SK", classes: ["1", "2", "3", "4", "5", "7"]},
    {province: "QC", classes: ["1", "2", "3", "4A", "4B", "5"]},
    {province: "NS", classes: ["1", "2", "3", "4", "5", "7"]},
    {province: "NL", classes: ["1", "2", "3", "4", "5", "5ll", "5l"]},
    {province: "NB", classes: []},
    {province: "NT", classes: []},
    {province: "YT", classes: []},
    {province: "NV", classes: []},
    {province: "PE", classes: []}
];

const ProvincesUK = [
    {short: "GB-BDG", long: "Barking and Dagenham", subdivision: "London borough"},
    {short: "GB-BNE", long: "Barnet", subdivision: "London borough"},        
    {short: "GB-BEX", long: "Bexley", subdivision: "London borough"},        
    {short: "GB-BEN", long: "Brent", subdivision: "London borough"},        
    {short: "GB-BRY", long: "Bromley", subdivision: "London borough"},        
    {short: "GB-CMD", long: "Camden", subdivision: "London borough"},        
    {short: "GB-CRY", long: "Croydon", subdivision: "London borough"},        
    {short: "GB-EAL", long: "Ealing", subdivision: "London borough"},        
    {short: "GB-ENF", long: "Enfield", subdivision: "London borough"},        
    {short: "GB-GRE", long: "Greenwich", subdivision: "London borough"},        
    {short: "GB-HCK", long: "Hammersmith and Fulham", subdivision: "London borough"},        
    {short: "GB-HMF", long: "Hackney", subdivision: "London borough"},        
    {short: "GB-HRY", long: "Haringey", subdivision: "London borough"},        
    {short: "GB-HRW", long: "Harrow", subdivision: "London borough"},        
    {short: "GB-HAV", long: "Havering", subdivision: "London borough"},        
    {short: "GB-HIL", long: "Hillingdon", subdivision: "London borough"},        
    {short: "GB-HNS", long: "Hounslow", subdivision: "London borough"},        
    {short: "GB-ISL", long: "Islington", subdivision: "London borough"},        
    {short: "GB-KEC", long: "Kensington and Chelsea", subdivision: "London borough"},        
    {short: "GB-KTT", long: "Kingston upon Thames", subdivision: "London borough"},        
    {short: "GB-LBH", long: "Lambeth", subdivision: "London borough"},        
    {short: "GB-LEW", long: "Lewisham", subdivision: "London borough"},        
    {short: "GB-MRT", long: "Merton", subdivision: "London borough"},        
    {short: "GB-NWM", long: "Newham", subdivision: "London borough"},
    {short: "GB-RDB", long: "Redbridge", subdivision: "London borough"},        
    {short: "GB-RIC", long: "Richmond upon Thames", subdivision: "London borough"},        
    {short: "GB-SWK", long: "Southwark", subdivision: "London borough"},
    {short: "GB-STN", long: "Sutton", subdivision: "London borough"},        
    {short: "GB-TWH", long: "Tower Hamlets", subdivision: "London borough"}, 
    {short: "GB-WFT", long: "Waltham Forest", subdivision: "London borough"},        
    {short: "GB-WND", long: "Wandsworth", subdivision: "London borough"},        
    {short: "GB-WSM", long: "Westminster", subdivision: "London borough"},        
    {short: "GB-EAW", long: "England and Wales", subdivision: "Nation"},        
    {short: "GB-GBN", long: "Great Britain", subdivision: "Nation"},        
    {short: "GB-UKM", long: "United Kingdom", subdivision: "Nation"},        
    {short: "GB-LND", long: "London", subdivision: "city corporation"},        
    {short: "GB-ABE", long: "Aberdeen City", subdivision: "council area"},        
    {short: "GB-ABD", long: "Aberdeenshire", subdivision: "council area"},        
    {short: "GB-ANS", long: "Angus", subdivision: "council area"},        
    {short: "GB-AGB", long: "Argyll and Bute", subdivision: "council area"},        
    {short: "GB-CLK", long: "Clackmannanshire", subdivision: "council area"},        
    {short: "GB-DGY", long: "Dumfries and Galloway", subdivision: "council area"},        
    {short: "GB-DND", long: "Dundee City", subdivision: "council area"},        
    {short: "GB-EAY", long: "East Ayrshire", subdivision: "council area"},        
    {short: "GB-EDU", long: "East Dunbartonshire", subdivision: "council area"},        
    {short: "GB-ELN", long: "East Lothian", subdivision: "council area"},        
    {short: "GB-ERW", long: "East Renfrewshire", subdivision: "council area"},        
    {short: "GB-EDH", long: "Edinburgh", subdivision: "council area"},        
    {short: "GB-ELS", long: "Eilean Siar", subdivision: "council area"},
    {short: "GB-FAL", long: "Falkirk", subdivision: "council area"},        
    {short: "GB-FIF", long: "Fife", subdivision: "council area"},        
    {short: "GB-GLG", long: "Glasgow City", subdivision: "council area"},        
    {short: "GB-HLD", long: "Highland", subdivision: "council area"},        
    {short: "GB-IVC", long: "Inverclyde", subdivision: "council area"},        
    {short: "GB-MLN", long: "Midlothian", subdivision: "council area"},        
    {short: "GB-MRY", long: "Moray", subdivision: "council area"},   
    {short: "GB-NAY", long: "North Ayrshire", subdivision: "council area"},        
    {short: "GB-NLK", long: "North Lanarkshire", subdivision: "council area"},        
    {short: "GB-ORK", long: "Orkney Islands", subdivision: "council area"},        
    {short: "GB-PKN", long: "Perth and Kinross", subdivision: "council area"},        
    {short: "GB-RFW", long: "Renfrewshire", subdivision: "council area"},        
    {short: "GB-SCB", long: "Scottish Borders", subdivision: "council area"},        
    {short: "GB-ZET", long: "Shetland Islands", subdivision: "council area"},        
    {short: "GB-SAY", long: "South Ayrshire", subdivision: "council area"}, 
    {short: "GB-SLK", long: "South Lanarkshire", subdivision: "council area"},        
    {short: "GB-STG", long: "Stirling", subdivision: "council area"},        
    {short: "GB-WDU", long: "West Dunbartonshire", subdivision: "council area"},        
    {short: "GB-WLN", long: "West Lothian", subdivision: "council area"},  
    {short: "GB-ENG", long: "England", subdivision: "country"},        
    {short: "GB-SCT", long: "Scotland", subdivision: "country"},        
    {short: "GB-WLS", long: "Wales", subdivision: "country"},        
    {short: "GB-ANT", long: "Antrim", subdivision: "country"},        
    {short: "GB-ARD", long: "Ards", subdivision: "district council area"},        
    {short: "GB-ARM", long: "Armagh", subdivision: "district council area"},        
    {short: "GB-BLA", long: "Ballymena", subdivision: "district council area"},        
    {short: "GB-BLY", long: "Ballymoney", subdivision: "district council area"},        
    {short: "GB-BNB", long: "Banbridge", subdivision: "district council area"},        
    {short: "GB-BFS", long: "Belfast", subdivision: "district council area"},        
    {short: "GB-CKF", long: "Carrickfergus", subdivision: "district council area"},        
    {short: "GB-CSR", long: "Castlereagh", subdivision: "district council area"},        
    {short: "GB-CLR", long: "Coleraine", subdivision: "district council area"},        
    {short: "GB-CKT", long: "Cookstown", subdivision: "district council area"},        
    {short: "GB-CGV", long: "Craigavon", subdivision: "district council area"},        
    {short: "GB-DRY", long: "Derry", subdivision: "district council area"},        
    {short: "GB-DOW", long: "Down", subdivision: "district council area"},        
    {short: "GB-DGN", long: "Dungannon and South Tyrone", subdivision: "district council area"},        
    {short: "GB-FER", long: "Fermanagh", subdivision: "district council area"},        
    {short: "GB-LRN", long: "Larne", subdivision: "district council area"},        
    {short: "GB-LMV", long: "Limavady", subdivision: "district council area"},        
    {short: "GB-LSB", long: "Lisburn", subdivision: "district council area"},        
    {short: "GB-MFT", long: "Magherafelt", subdivision: "district council area"},        
    {short: "GB-MYL", long: "Moyle", subdivision: "district council area"},        
    {short: "GB-NYM", long: "Newry and Mourne District", subdivision: "district council area"},        
    {short: "GB-NTA", long: "Newtownabbey", subdivision: "district council area"},        
    {short: "GB-NDN", long: "North Down", subdivision: "district council area"},        
    {short: "GB-OMH", long: "Omagh", subdivision: "district council area"},        
    {short: "GB-STB", long: "Strabane", subdivision: "district council area"}, 
    {short: "GB-BNS", long: "Barnsley", subdivision: "metropolitan district"},        
    {short: "GB-BIR", long: "Birmingham", subdivision: "metropolitan district"},        
    {short: "GB-BOL", long: "Bolton", subdivision: "metropolitan district"},        
    {short: "GB-BRD", long: "Bradford", subdivision: "metropolitan district"},        
    {short: "GB-BUR", long: "Bury", subdivision: "metropolitan district"},        
    {short: "GB-CLD", long: "Calderdale", subdivision: "metropolitan district"},        
    {short: "GB-COV", long: "Coventry", subdivision: "metropolitan district"},        
    {short: "GB-DNC", long: "Doncaster", subdivision: "metropolitan district"},        
    {short: "GB-DUD", long: "Dudley", subdivision: "metropolitan district"},        
    {short: "GB-GAT", long: "Gateshead", subdivision: "metropolitan district"},        
    {short: "GB-KIR", long: "Kirklees", subdivision: "metropolitan district"}, 
    {short: "GB-KWL", long: "Knowsley", subdivision: "metropolitan district"},        
    {short: "GB-LDS", long: "Leeds", subdivision: "metropolitan district"},        
    {short: "GB-LIV", long: "Liverpool", subdivision: "metropolitan district"},        
    {short: "GB-MAN", long: "Manchester", subdivision: "metropolitan district"},        
    {short: "GB-NET", long: "Newcastle upon Tyne", subdivision: "metropolitan district"},        
    {short: "GB-NTY", long: "North Tyneside", subdivision: "metropolitan district"},        
    {short: "GB-OLD", long: "Oldham", subdivision: "metropolitan district"},        
    {short: "GB-RCH", long: "Rochdale", subdivision: "metropolitan district"},        
    {short: "GB-ROT", long: "Rotherham", subdivision: "metropolitan district"},
    {short: "GB-SLF", long: "Salford", subdivision: "metropolitan district"},        
    {short: "GB-SAW", long: "Sandwell", subdivision: "metropolitan district"},        
    {short: "GB-SFT", long: "Sefton", subdivision: "metropolitan district"},        
    {short: "GB-SHF", long: "Sheffield", subdivision: "metropolitan district"},        
    {short: "GB-SOL", long: "Solihull", subdivision: "metropolitan district"},        
    {short: "GB-STY", long: "South Tyneside", subdivision: "metropolitan district"},        
    {short: "GB-SHN", long: "St. Helens", subdivision: "metropolitan district"},        
    {short: "GB-SKP", long: "Stockport", subdivision: "metropolitan district"},        
    {short: "GB-SND", long: "Sunderland", subdivision: "metropolitan district"},        
    {short: "GB-TAM", long: "Tameside", subdivision: "metropolitan district"},
    {short: "GB-TRF", long: "Trafford", subdivision: "metropolitan district"},        
    {short: "GB-WKF", long: "Wakefield", subdivision: "metropolitan district"},        
    {short: "GB-WLL", long: "Walsall", subdivision: "metropolitan district"},        
    {short: "GB-WGN", long: "Wigan", subdivision: "metropolitan district"},        
    {short: "GB-WRL", long: "Wirral", subdivision: "metropolitan district"},        
    {short: "GB-WLV", long: "Wolverhampton", subdivision: "metropolitan district"},        
    {short: "GB-NIR", long: "Northern Ireland", subdivision: "province"},        
    {short: "GB-BKM", long: "Buckinghamshire", subdivision: "two-tier county"},        
    {short: "GB-CAM", long: "Cambridge", subdivision: "two-tier county"},
    {short: "GB-CMA", long: "Cumbria", subdivision: "two-tier county"},        
    {short: "GB-DBY", long: "Derbyshire", subdivision: "two-tier county"},        
    {short: "GB-DEV", long: "Devon", subdivision: "two-tier county"},        
    {short: "GB-DOR", long: "Dorset", subdivision: "two-tier county"},        
    {short: "GB-ESX", long: "East Sussex", subdivision: "two-tier county"},  
    {short: "GB-ESS", long: "Essex", subdivision: "two-tier county"},        
    {short: "GB-GLS", long: "Gloucestershire", subdivision: "two-tier county"},        
    {short: "GB-HAM", long: "Hampshire", subdivision: "two-tier county"},        
    {short: "GB-HRT", long: "Hertfordshire", subdivision: "two-tier county"},        
    {short: "GB-KEN", long: "Kent", subdivision: "two-tier county"},      
    {short: "GB-LAN", long: "Lancashire", subdivision: "two-tier county"},        
    {short: "GB-LEC", long: "Leicestershire", subdivision: "two-tier county"},        
    {short: "GB-LIN", long: "Lincolnshire", subdivision: "two-tier county"},        
    {short: "GB-NFK", long: "Norfolk", subdivision: "two-tier county"},        
    {short: "GB-NYK", long: "North Yorkshire", subdivision: "two-tier county"},        
    {short: "GB-NTH", long: "Northamptonshire", subdivision: "two-tier county"},        
    {short: "GB-NTT", long: "Nottinghamshire", subdivision: "two-tier county"},        
    {short: "GB-OXF", long: "Oxfordshire", subdivision: "two-tier county"},        
    {short: "GB-SOM", long: "Somerset", subdivision: "two-tier county"},        
    {short: "GB-STS", long: "Staffordshire", subdivision: "two-tier county"},        
    {short: "GB-SFK", long: "Suffolk", subdivision: "two-tier county"},        
    {short: "GB-SRY", long: "Surrey", subdivision: "two-tier county"},        
    {short: "GB-WAR", long: "Warwickshire", subdivision: "two-tier county"},        
    {short: "GB-WOR", long: "Worcestershire", subdivision: "two-tier county"},        
    {short: "GB-WSX", long: "West Sussex", subdivision: "two-tier county"},        
    {short: "GB-BAS", long: "Bath and North East Somerset", subdivision: "unitary authority"},        
    {short: "GB-BBD", long: "Blackburn with Darwen", subdivision: "unitary authority"},        
    {short: "GB-BPL", long: "Blackpool", subdivision: "unitary authority"},        
    {short: "GB-BGW", long: "Blaenau Gwent", subdivision: "unitary authority"},        
    {short: "GB-BMH", long: "Bournemouth", subdivision: "unitary authority"},        
    {short: "GB-BRC", long: "Bracknell Forest", subdivision: "unitary authority"},        
    {short: "GB-BGE", long: "Bridgend", subdivision: "unitary authority"},        
    {short: "GB-BNH", long: "Brighton and Hove", subdivision: "unitary authority"},        
    {short: "GB-BST", long: "Bristol, City of", subdivision: "unitary authority"},        
    {short: "GB-CAY", long: "Caerphilly", subdivision: "unitary authority"},        
    {short: "GB-CRF", long: "Cardiff", subdivision: "unitary authority"},        
    {short: "GB-CMN", long: "Carmarthenshire", subdivision: "unitary authority"},        
    {short: "GB-CBF", long: "Central Bedfordshire", subdivision: "unitary authority"},        
    {short: "GB-CGN", long: "Ceredigion", subdivision: "unitary authority"},        
    {short: "GB-CHE", long: "Cheshire East", subdivision: "unitary authority"},        
    {short: "GB-CHW", long: "Cheshire West and Chester", subdivision: "unitary authority"},        
    {short: "GB-CWY", long: "Conwy", subdivision: "unitary authority"},        
    {short: "GB-CON", long: "Cornwall", subdivision: "unitary authority"},        
    {short: "GB-DAL", long: "Darlington", subdivision: "unitary authority"},        
    {short: "GB-DEN", long: "Denbighshire", subdivision: "unitary authority"},        
    {short: "GB-DER", long: "Derby", subdivision: "unitary authority"},        
    {short: "GB-DUR", long: "Durham, County", subdivision: "unitary authority"},        
    {short: "GB-ERY", long: "East Riding of Yorkshire", subdivision: "unitary authority"},        
    {short: "GB-FLN", long: "Flintshire", subdivision: "unitary authority"},        
    {short: "GB-GWN", long: "Gwynedd", subdivision: "unitary authority"},        
    {short: "GB-HAL", long: "Halton", subdivision: "unitary authority"},        
    {short: "GB-HPL", long: "Hartlepool", subdivision: "unitary authority"},        
    {short: "GB-HEF", long: "Herefordshire", subdivision: "unitary authority"},        
    {short: "GB-AGY", long: "Isle of Anglesey", subdivision: "unitary authority"},        
    {short: "GB-IOW", long: "Isle of Wight", subdivision: "unitary authority"},        
    {short: "GB-IOS", long: "Isles of Scilly", subdivision: "unitary authority"},        
    {short: "GB-KHL", long: "Kingston upon Hull", subdivision: "unitary authority"},        
    {short: "GB-LCE", long: "Leicester", subdivision: "unitary authority"},        
    {short: "GB-LUT", long: "Luton", subdivision: "unitary authority"},        
    {short: "GB-MDW", long: "Medway", subdivision: "unitary authority"},        
    {short: "GB-MTY", long: "Merthyr Tydfil", subdivision: "unitary authority"},        
    {short: "GB-MDB", long: "Middlesbrough", subdivision: "unitary authority"},        
    {short: "GB-MIK", long: "Milton Keynes", subdivision: "unitary authority"},        
    {short: "GB-MON", long: "Monmouthshire", subdivision: "unitary authority"},        
    {short: "GB-NTL", long: "Neath Port Talbot", subdivision: "unitary authority"},        
    {short: "GB-NWP", long: "Newport", subdivision: "unitary authority"},        
    {short: "GB-NEL", long: "North East Lincolnshire", subdivision: "unitary authority"},        
    {short: "GB-NLN", long: "North Lincolnshire", subdivision: "unitary authority"},        
    {short: "GB-NSM", long: "North Somerset", subdivision: "unitary authority"},        
    {short: "GB-NBL", long: "Northumberland", subdivision: "unitary authority"},        
    {short: "GB-NGM", long: "Nottingham", subdivision: "unitary authority"},        
    {short: "GB-PEM", long: "Pembrokeshire", subdivision: "unitary authority"},        
    {short: "GB-PTE", long: "Peterborough", subdivision: "unitary authority"},        
    {short: "GB-PLY", long: "Plymouth", subdivision: "unitary authority"},        
    {short: "GB-POL", long: "Poole", subdivision: "unitary authority"},        
    {short: "GB-POR", long: "Portsmouth", subdivision: "unitary authority"},        
    {short: "GB-POW", long: "Powys", subdivision: "unitary authority"},        
    {short: "GB-RDG", long: "Reading", subdivision: "unitary authority"},        
    {short: "GB-RCC", long: "Redcar and Cleveland", subdivision: "unitary authority"},        
    {short: "GB-RCT", long: "Rhondda, Cynon, Taff", subdivision: "unitary authority"},        
    {short: "GB-RUT", long: "Rutland", subdivision: "unitary authority"},        
    {short: "GB-SHR", long: "Shropshire", subdivision: "unitary authority"},        
    {short: "GB-SLG", long: "Slough", subdivision: "unitary authority"},        
    {short: "GB-SGC", long: "South Gloucestershire", subdivision: "unitary authority"},        
    {short: "GB-STH", long: "Southampton", subdivision: "unitary authority"},        
    {short: "GB-SOS", long: "Southend-on-Sea", subdivision: "unitary authority"},        
    {short: "GB-STT", long: "Stockton-on-Tees", subdivision: "unitary authority"},        
    {short: "GB-STE", long: "Stoke-on-Trent", subdivision: "unitary authority"},        
    {short: "GB-SWA", long: "Swansea", subdivision: "unitary authority"},        
    {short: "GB-SWD", long: "Swindon", subdivision: "unitary authority"},        
    {short: "GB-TFW", long: "Telford and Wrekin", subdivision: "unitary authority"},        
    {short: "GB-THR", long: "Thurrock", subdivision: "unitary authority"},        
    {short: "GB-TOB", long: "Torbay", subdivision: "unitary authority"},        
    {short: "GB-TOF", long: "Torfaen", subdivision: "unitary authority"},        
    {short: "GB-VGL", long: "Vale of Glamorgan, The", subdivision: "unitary authority"},        
    {short: "GB-WRT", long: "Warrington", subdivision: "unitary authority"},        
    {short: "GB-WBK", long: "West Berkshire", subdivision: "unitary authority"},        
    {short: "GB-WIL", long: "Wiltshire", subdivision: "unitary authority"},        
    {short: "GB-WNM", long: "Windsor and Maidenhead", subdivision: "unitary authority"},        
    {short: "GB-WOK", long: "Wokingham", subdivision: "unitary authority"},        
    {short: "GB-WRX", long: "Wrexham", subdivision: "unitary authority"},        
    {short: "GB-YOR", long: "York", subdivision: "unitary authority"},        
]

const Months = [
    {short: "JAN", long: "January", value: 1, sValue: "01"},
    {short: "FEB", long: "February", value: 2, sValue: "02"},
    {short: "MAR", long: "March", value: 3, sValue: "03"},
    {short: "APR", long: "April", value: 4, sValue: "04"},
    {short: "MAY", long: "May", value: 5, sValue: "05"},
    {short: "JUN", long: "June", value: 6, sValue: "06"},
    {short: "JUL", long: "July", value: 7, sValue: "07"},
    {short: "AUG", long: "August", value: 8, sValue: "08"},
    {short: "SEP", long: "September", value: 9, sValue: "09"},
    {short: "OCT", long: "October", value: 10, sValue: "10"},
    {short: "NOV", long: "November", value: 11, sValue: "11"},
    {short: "DEC", long: "December", value: 12, sValue: "12"}
];

const OptionLists = {
    States: States,
    Provinces: Provinces,
    ProvincesUK: ProvincesUK,
    Months: Months,
    DriverClassesCanada: DriverClassesCanada
};

export default OptionLists;