import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";
import FormValidate from "../../../services/FormValidate";

export default class CarsfastFinalDetails extends GenSet {
    title = CoreTools.emit("panelTitle", "Congratulations! Your Pre-Approval is Ready!");
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };
    phoneOptions = [{caption: "Mobile", value: "mobile"}, {caption: "Landline", value: "landline"}];
    phoneFormat = event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };
    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <p className="card-text text-center">Enter your contact info so we can personalize your results.</p>
            <div className="card-fields">
                <div className="form-row">
                    <div className="col-lg-6 mb-2">
                        <AppInput.Input type="text" name="first_name" className="form-control text-field" placeholder="First Name" onValidate={FormValidate.isName} failClass="invalid" />
                    </div>
                    <div className="col-lg-6 mb-2">
                        <AppInput.Input type="text" name="last_name" className="form-control text-field" placeholder="Last Name" onValidate={FormValidate.isName} failClass="invalid" />
                    </div>
                    <div className="col-lg-6 mb-2">
                        <AppInput.Input type="email" name="email" className="form-control text-field" placeholder="Email Address" onValidate={FormValidate.isEmail} failClass="invalid" />
                    </div>
                    <div className="col-lg-6 mb-2">
                        <AppInput.Input type="tel" name="phone" className="form-control text-field" placeholder="Primary Phone" onChange={this.phoneFormat} onValidate={FormValidate.isPhone} failClass="invalid" />
                    </div>
                    <div className="col-12 mb-2">
                        <div className="btn-checkbox casl-checkbox">
                            <AppInput.CheckBox name="casl" onClick={v => this.setVal({casl: this.applicant.field('casl')})} />
                            <label>You authorize us to send email, SMS, or other electronic messaging, delivering offers based on information you've shared. You consent to receive messages from our firm and third-party's including financial agencies & dealerships. You can withdraw your consent anytime.</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer text-center mt-4">
            <p className="term-txt text-center mt-1"><input type="hidden" id="leadid_tcpa_disclosure"/>By clicking "Complete My Application", I have read and accept the <a href="https://carsfast.ca/terms-of-use.php/" target="_blank" rel="noreferrer">Terms of Use</a>. I also acknowledge having read the <a href="https://carsfast.ca/privacy-policy.php/" target="_blank" rel="noreferrer">Privacy Policy</a> and agree to the terms herein.</p>
                <button type="submit" className="btn btn-complete" onClick={this.continue}>Complete My Application</button>
            </div>
        </div>
    </div>;

}