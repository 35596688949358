import CoreTools from "../../../services/CoreTools";
import GenSet from "../../../services/GenSet";


export default class CarsfastFlatFormProcessing extends GenSet {
    onApplicant = (state, props, applicant) => CoreTools.switch(
        [!applicant.field("first_name"),                      () => this.navigate("#Personal")],
        [!applicant.field("last_name"),                       () => this.navigate("#Personal")],
        [!applicant.field("email"),                           () => this.navigate("#Personal")],
        [!applicant.field("phone"),                           () => this.navigate("#Personal")],
        [!applicant.field("dob"),                             () => this.navigate("#Personal")],
        [!applicant.field("residence_type"),                  () => this.navigate("#Address")],
        [!applicant.field("monthly_residence_cost"),          () => this.navigate("#Address")],
        [!applicant.field("city"),                            () => this.navigate("#Address")],
        [!applicant.field("zip_code"),                        () => this.navigate("#Address")],
        [!applicant.field("state"),                           () => this.navigate("#Address")],
        [!applicant.field("residence_duration"),              () => this.navigate("#Address")],
        [!applicant.field("employment_status"),               () => this.navigate("#Employment")],
        [!applicant.field("gross_monthly_income"),            () => this.navigate("#Employment")],
        [!applicant.field("employment_duration"),             () => this.navigate("#Employment")],
        [!applicant.field("company_name"),                    () => this.navigate("#Employment")],
        [!applicant.field("job_title"),                       () => this.navigate("#Employment")],
        [!applicant.field("desired_vehicle"),                 () => this.navigate("#Vehicle")],
        [!applicant.field("monthly_budget"),                  () => this.navigate("#Vehicle")],
        [!applicant.field("existing_vehicle"),                () => this.navigate("#Vehicle")],
        [true,                                                () => applicant.post("carsfastcanada", "submit", {source: "flatform"})]
    );

    content = () => <div className="card card-options">
        <div className="card-body">
            <div className="day-fld-box">
                <h3 className="text-center">We are processing your submission...</h3>
            </div>
            <div className="row">
                <img className="col-12" src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" />
            </div>
        </div>
    </div>;
}

