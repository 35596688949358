import GenSet from "../../../services/GenSet";
import GoogleAddress from "../../../directives/GoogleAddress";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";
import OptionLists from "../../../services/OptionLists";
import FormValidate from "../../../services/FormValidate";
import GeoCode from "../../../services/GeoCode";

export default class SurexPostalCode extends GenSet {
    resetInvalid = event => {
        if (event) {event.target.className = event.target.className.split(" ").filter(c => c !== "invalid").join(" ");}
        this.setVal({errorMsg: false});
    };
    
    continue = () => {
        const setInvalid = msg => {
            if (this.addressRef) {
                this.addressRef.className += " invalid";
            }
            this.setVal({errorMsg: msg});
        };
        const postalCode = typeof(this.addressRef.value) === "string" ? this.addressRef.value.trim().replace(" ", "") : "";
        if (!FormValidate.isPostalCode(postalCode)) {setInvalid("Valid postal code required.");}
        else if (this.applicant.field("geo_zip") && this.applicant.field("geo_zip").replace(" ", "") === postalCode) {
            this.applicant.setField("zip_code", postalCode);
            this.navigate(this.props.continue);
        } else {
            GeoCode("ca", postalCode, geo => {
                CoreTools.log("Geo:", geo, this.applicant.field("state"));
                if (!geo.success) {
                    setInvalid("Valid postal code required.");
                } else if (geo.stateShort !== this.applicant.field("state")) {
                    setInvalid(`Not a valid ${this.applicant.field("state")} postal code.`);
                } else {
                    this.applicant.setField("zip_code", postalCode);
                    this.applicant.setField("city", geo.city);
                    this.applicant.setField("geo_zip", geo.zipcode);
                    this.navigate(this.props.continue);
                }
            });
        }
    };

    provinceOptions = [
        {value: "", caption: "Select Province"},
        ...OptionLists.Provinces.map(i => ({value: i.short, caption: i.long}))
    ];
    
    onApplicant = (state, props, applicant) => {
        this.setVal({google_postalcode: applicant.field("google_postalcode") && applicant.field("state") ? applicant.field("google_postalcode") : ""});
    };

    content = (state, props) => <SurexComponents.Card
        title="What's your postal code?"
        text="Your postal code will help us narrow in on your best options."
        controls={<SurexComponents.Controls continue={this.continue} disabled={!state.google_postalcode} />}
    >
        {state.errorMsg && <div className="text-center">
            <span className="text-danger">{state.errorMsg}</span>
        </div>}
        <div className="form-row mb-3">
            <div className="col-md-12 form-field mb-3 text-center" id="gm-autocomplete-wrap">
                <GoogleAddress
                    type="postal_code"
                    display="postal_code"
                    onRef={r => this.addressRef = r}
                    country="ca"
                    className="form-control text-center"
                    onClick={this.resetInvalid}
                    placeholder="Type Here to Search Postal Codes"
                    defaultValue={FormValidate.toPostalCode(this.applicant.field("zip_code") || "")}
                    onChange={FormValidate.postalCodeFormat}
                    onSelect={address => {
                        CoreTools.log("GooglePostalCode:", address);
                        this.setVal({google_postalcode: address.full});
                        this.applicant.setField("google_postalcode", address.full);
                        this.applicant.setField("city", address.city);
                        this.applicant.setField("state", address.provinceShort);
                        this.applicant.setField("zip_code", address.postalcode.replace(" ", ""));
                        this.applicant.setField("geo_zip", address.postalcode);
                        this.addressRef.value = address.postalcode;
                    }}
                />
            </div>
        </div>
    </SurexComponents.Card>;
}