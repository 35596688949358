import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";

export default class CarsfastEmploymentStatus extends GenSet {
    title = CoreTools.emit("panelTitle", "What is your Employment Status?");
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <div className="form-row">
                <div className="col-lg-6 mb-3">
                    <label className="btn btn-option">
                        <AppInput.Radio name="employment_status" value="Employed" onClick={this.onClick} />
                        <span className="btn-option-text">Employed</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-3">
                    <label className="btn btn-option">
                        <AppInput.Radio name="employment_status" value="Self Employed" onClick={this.onClick} />
                        <span className="btn-option-text">Self Employed</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-3">
                    <label className="btn btn-option">
                        <AppInput.Radio name="employment_status" value="Retired" onClick={this.onClick} />
                        <span className="btn-option-text">Retired</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-3">
                    <label className="btn btn-option">
                        <AppInput.Radio name="employment_status" value="Disability" onClick={this.onClick} />
                        <span className="btn-option-text">Disability</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-3">
                    <label className="btn btn-option">
                        <AppInput.Radio name="employment_status" value="Unemployed" onClick={this.onClick} />
                        <span className="btn-option-text">Unemployed</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-3">
                    <label className="btn btn-option">
                        <AppInput.Radio name="employment_status" value="Other" onClick={this.onClick} />
                        <span className="btn-option-text">Other</span>
                    </label>
                </div>
            </div>
        </div>
    </div>;

}