import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import JugglingDebtComponents from "../inserts/JugglingDebtComponents";

export default class JugglingDebtEmploymentStatus extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };
    
    content = (state, props) => <JugglingDebtComponents.Card
        title="What is your employment status?"
        text="This will help us find the right programs for you"
        controls={<JugglingDebtComponents.Controls continue={this.continue} buttonText={false} disclaimer={props.disclaimer} />}
    >
        <div className="form-row split">
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="employment_status" value="Full-Time Employed" onClick={this.continue} />
                    <span>Full-Time Employed</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="employment_status" value="Part-Time Employed" onClick={this.continue} />
                    <span>Part-Time Employed</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="employment_status" value="Retired" onClick={this.continue} />
                    <span>Retired</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="employment_status" value="Unemployed" onClick={this.continue} />
                    <span>Unemployed</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="employment_status" value="Student" onClick={this.continue} />
                    <span>Student</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="employment_status" value="Other" onClick={this.continue} />
                    <span>Other</span>
                </label>
            </div>
        </div>
    </JugglingDebtComponents.Card>;
}