import GenSet from "../../services/GenSet";
import CoreTools from "../../services/CoreTools";
import React from "react";
import Applicant from "../../services/Applicant";
import AppInput from "../../directives/AppInput";
import GoogleAddress from "../../directives/GoogleAddress";
import OptionLists from "../../services/OptionLists";
import FormValidate from "../../services/FormValidate";

const PanelLoan = props => {
    const amountOptions = [
        {value: "", caption: "Select Loan Amount"},
        {value: "1", caption: "$1,000 - $1,500"},
        {value: "2", caption: "$1,501 - $3,000"},
        {value: "3", caption: "$3,001 - $5,000"},
        {value: "4", caption: "$5,001 - $7,500"},
        {value: "5", caption: "$7,501 - $15,000"}
    ];
    const purposeOptions = [
        {value: "", caption: "Select Loan Purpose"},
        {value: "1", caption: "Consolidate Debt"},
        {value: "2", caption: "Pay Off Credit Cards"},
        {value: "3", caption: "Pay Bills"},
        {value: "4", caption: "Emergency"},
        {value: "5", caption: "Wedding"},
        {value: "6", caption: "Buy a Car"},
        {value: "7", caption: "Other"}
    ];
    return <div className="form-panel">
        <h3>Personal Loan</h3>
        <div className="form-center">
            <div>
                <span className="label width-3">Loan Amount</span>
                <span className="label width-3">Loan Purpose</span>
            </div>
            <div className="input-group">
                <AppInput.Select name="loan_amount" options={amountOptions} onValidate={v => v} failClass="invalid" className="width-3" />
                <AppInput.Select name="loan_purpose" options={purposeOptions} onValidate={v => v} failClass="invalid" className="width-3" />
            </div>
        </div>
    </div>;
};

const PanelName = props => {
    return <div className="form-panel">
        <h3>Name</h3>
        <div className="form-center">
            <div>
                <span className="label width-3">Firstname</span>
                <span className="label width-3">Lastname</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="first_name" placeholder="Firstname" onValidate={FormValidate.isName} failClass="invalid" className="width-3" />
                <AppInput.Input name="last_name" placeholder="Lastname" onValidate={FormValidate.isName} failClass="invalid" className="width-3" />
            </div>
        </div>
    </div>;
};

const PanelDOB = props => {
    const yearOptions = (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year - 17; i > year - 100; i--) {years.push(i.toString());} return years;})();
    const monthOptions = (() => {let months = [{value: "", caption: "Month"}]; for (let i = 1; i <= 12; i++) {months.push(i.toString());} return months;})();
    const dayOptions = (() => {let days = [{value: "", caption: "Day"}]; for (let i = 1; i <= 31; i++) {days.push(i.toString());} return days;})();
    return <div className="form-panel">
        <h3>DOB</h3>
        <div className="form-center">
            <div>
                <span className="label width-1">Year</span>
                <span className="label width-1">Month</span>
                <span className="label width-1">Day</span>
            </div>
            <div className="input-group">
                <AppInput.Select name="dob_year" options={yearOptions} onValidate={v => v} failClass="invalid" className="width-1" />
                <AppInput.Select name="dob_month" options={monthOptions} onValidate={v => v} failClass="invalid" className="width-1" />
                <AppInput.Select name="dob_day" options={dayOptions} onValidate={v => v} failClass="invalid" className="width-1" />
            </div>
        </div>
    </div>;
};

const PanelContact = props => {
    const phoneTypeOptions = [{value: "1", caption: "Mobile"}, {value: "2", caption: "Land-Line"}];
    const phoneFormat = event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };
    return <div className="form-panel">
        <h3>Contact Details</h3>
        <div className="form-center">
            <div>
                <span className="label width-3">Phone Number / Type</span>
                <span className="label width-3">E-mail Address</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="phone" placeholder="Phone Number" onValidate={FormValidate.isPhone} failClass="invalid" className="width-2" onChange={phoneFormat} />
                <AppInput.Select name="phone_type" options={phoneTypeOptions} className="width-1" />
                <AppInput.Input name="email" placeholder="E-mail Address" onValidate={FormValidate.isEmail} failClass="invalid" className="width-3" />
            </div>
            <div><span className="label width-6">
                <p>You will receive a confirmation TEXT for Mobiles or a confirmation VOICE CALL for Land-Lines on the completion of this form.</p>
                <p>You will need to enter the 4 digit PIN provided to confirm your Phone Number.</p>
            </span></div>
        </div>
    </div>;
};

const PanelAddress = props => {
    const province = {
        options: [
            {value: "", caption: "Select Province"},
            ...OptionLists.Provinces.map(ol => ({value: ol.short, caption: ol.long}))
        ]
    };
    const postalCode = {
        format: event => {
            if (typeof(event.target.value) === "string") {
                const proper = event.target.value.replace(/[^a-z0-9]/gi, "").toUpperCase().substring(0, 6);
                if (event.target.value !== proper) {
                    const cPos = event.target.selectionStart;
                    event.target.value = proper;
                    event.target.selectionStart = cPos;
                    event.target.selectionEnd = cPos;
                }
            }
        },
        validate: value => (
            typeof(value) === "string" &&
            value.length === 6 &&
            CoreTools.match(value.substring(0, 1), /[A-Z]/) &&
            CoreTools.match(value.substring(1, 2), /[0-9]/) &&
            CoreTools.match(value.substring(2, 3), /[A-Z]/) &&
            CoreTools.match(value.substring(3, 4), /[0-9]/) &&
            CoreTools.match(value.substring(4, 5), /[A-Z]/) &&
            CoreTools.match(value.substring(5, 6), /[0-9]/)
        )
    };
    return <div className="form-panel">
        <h3>Address</h3>
        <div className="form-center">
            <div><span className="label width-6">Search Address</span></div>
            <div className="input-group"><GoogleAddress
                country="ca"
                className="width-6"
                placeholder="Type Here to Search For Your Address"
                onSelect={address => {
                    Applicant().setField("google_address", address.full);
                    Applicant().setField("street_number", address.number);
                    Applicant().setField("street_name", address.street);
                    Applicant().setField("city", address.city);
                    Applicant().setField("state", address.provinceShort);
                    Applicant().setField("zip_code", address.postalcode.split(" ").join("").toUpperCase());
                }}
            /></div>
            <div>
                <span className="label width-2">Street Number</span>
                <span className="label width-4">Street Name</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="street_number" placeholder="Street Number" onValidate={v => v} className="width-2" failClass="invalid" />
                <AppInput.Input name="street_name" placeholder="Street Name" onValidate={v => v} className="width-4" failClass="invalid" />
            </div>
            <div>
                <span className="label width-2">Unit / Apt / Suite</span>
                <span className="label width-4">City</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="unit" placeholder="U / A / S" className="width-2" />
                <AppInput.Input name="city" placeholder="City" onValidate={v => v} className="width-4" failClass="invalid" />
            </div>
            <div>
                <span className="label width-2">Postal Code</span>
                <span className="label width-4">Province</span>
            </div>
            <div className="input-group">
                <AppInput.Input name="postal_code" placeholder="Postal Code" className="width-2" onValidate={postalCode.validate} failClass="invalid" onChange={postalCode.format} />
                <AppInput.Select name="province" options={province.options} className="width-4" onValidate={v => v} failClass="invalid" />
            </div>
        </div>
    </div>;
};

const PanelLiving = props => {
    const [residenceTypeState, setResidenceType] = React.useState(Applicant().fAlt("residence_type", ""));
    const [verticalTypeState, setVerticalType] = React.useState(Applicant().fAlt("property_secured", "1"));
    const residenceType = {
        options: [
            {value: "", caption: "Select Residence Type"},
            {value: "1", caption: "Own"},
            {value: "2", caption: "Rent"}
        ],
        onChange: event => {
            setResidenceType(event.target.value);
            props.showFlinks(event.target.value === "2");
        }
    };
    const residenceCost = {
        onChange: event => {
            if (typeof(event.target.value) === "string") {
                let proper = CoreTools.numberCS(event.target.value, 0);
                if (proper === "0") {proper = "";}
                if (event.target.value !== proper) {
                    const cPos = event.target.value.length - event.target.selectionStart;
                    event.target.value = proper;
                    event.target.selectionStart = event.target.value.length - cPos;
                    event.target.selectionEnd = event.target.value.length - cPos;
                }
            }
        }
    };
    const propertySecured = {
        options: [
            {value: "1", caption: "Apply For a Secured Loan"},
            {value: "2", caption: "Apply For a Personal Loan"}
        ],
        onChange: event => {
            setVerticalType(event.target.value);
            props.showFlinks(event.target.value !== "1");
        }
    };
    const propertyValue = {
        onChange: event => {
            if (typeof(event.target.value) === "string") {
                let proper = CoreTools.numberCS(event.target.value, 0);
                if (proper === "0") {proper = "";}
                if (event.target.value !== proper) {
                    const cPos = event.target.value.length - event.target.selectionStart;
                    event.target.value = proper;
                    event.target.selectionStart = event.target.value.length - cPos;
                    event.target.selectionEnd = event.target.value.length - cPos;
                }
            }
        }
    };
    const mortgageValue = {
        onChange: event => {
            if (typeof(event.target.value) === "string") {
                let proper = CoreTools.numberCS(event.target.value, 0);
                if (proper === "0") {proper = "";}
                if (event.target.value !== proper) {
                    const cPos = event.target.value.length - event.target.selectionStart;
                    event.target.value = proper;
                    event.target.selectionStart = event.target.value.length - cPos;
                    event.target.selectionEnd = event.target.value.length - cPos;
                }
            }
        }
    };
    setTimeout(() => props.showFlinks(residenceTypeState === "2" || verticalTypeState === "2"));
    return <div className="form-panel">
        <h3>Living</h3>
        <div className="form-center">
            <div>
                <span className="label width-3">Residence Type</span>
                <span className="label width-3">Monthly {residenceTypeState === "1" ? "Mortgage" : "Rent"} Payment</span>
            </div>
            <div className="input-group">
                <AppInput.Select name="residence_type" options={residenceType.options} onChange={residenceType.onChange} onValidate={v => v} failClass="invalid" className="width-3" />
                <span className="currency-input">$</span><AppInput.Input name="residence_cost" placeholder="Monthly Residence Cost" onChange={residenceCost.onChange} onValidate={v => v} failClass="invalid" className="currency-input width-3" />
            </div>
            {residenceTypeState === "1" && <div>
                <div>
                    <span className="label width-6">Secured Loan</span>
                </div>
                <div className="input-group">
                    <AppInput.Select name="property_secured" options={propertySecured.options} onChange={propertySecured.onChange} className="width-6" />
                </div>
                {verticalTypeState === "1" && <div>
                    <div>
                        <span className="label width-3">Property Value</span>
                        <span className="label width-3">Remaining Mortgage Value</span>
                    </div>
                    <div>
                        <span className="currency-input">$</span><span className="input-group"><AppInput.Input name="property_value" placeholder="Property Value" onChange={propertyValue.onChange} onValidate={v => v} failClass="invalid" className="currency-input width-3" />
                        <span className="currency-input">$</span><AppInput.Input name="mortgage_value" placeholder="Remaining Mortgage Value" onChange={mortgageValue.onChange} onValidate={v => v} failClass="invalid" className="currency-input width-3" /></span>
                    </div>
                </div>}
            </div>}
        </div>
    </div>;
};

const PanelAccount = props => {
    return <div className="form-panel">
        <h3>Connect With Flinks</h3>
        <div className="form-center">
            <div><span className="label width-6">Securely Confirm Identity & Financial Details Using Flinks</span></div>
            <div className="input-group"><button className="width-6">Connect With Flinks</button></div>
            <div><span className="label width-6">
                <p>Flinks is a third party financial details provider that allows users to securely share information about their banking and income. This is an information service only and does not allow connecting companies such as Loanz to interact with your account in any way.</p>
                <p>Connecting your account details allows for instant evaluation by providing an official data source to collect and confirm details such as identity and income and is manditory for some lending options.</p>
                <p>For more information on Flinks, please visit <a href="https://flinks.com/" target="_new">https://flinks.com/</a>.</p>
            </span></div>
        </div>
    </div>;
};

const PanelIncome = props => {
    return <div className="form-panel">
        <h3>Income</h3>
    </div>;
};

export default class LoanzFlatForm extends GenSet {
    onMount = () => {
        if (!CoreTools.state.appOptions.includes("nocss")) {
            if (CoreTools.state.isLocal) {
                CoreTools.importStyle("/css/loanz-flatform.css");
            } else {
                CoreTools.importStyle("https://form.consumergenius.com/css/loanz-flatform.css");
            }
        }
    };
    content = state => <div className="form-body">
        <div style={{padding: "20px"}}>This is the Funnel Component, Hi There!!!</div>
        <PanelLoan />
        <PanelContact />
        <PanelName />
        <PanelDOB />
        <PanelAddress />
        <PanelLiving showFlinks={v => this.setVal({flinks: v})}/>
        {this.state.flinks && <PanelAccount />}
        <PanelIncome />
    </div>;
}
