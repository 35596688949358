import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import HeComponents from "../inserts/HeComponents";

export default class HeResidenceType extends GenSet {
    
    continue = () => this.navigate(this.props.continue);

    // loanz = () => this.navigate([
    //     "https://track.loanz.com/?",
    //     "c=1006&",
    //     "lp=124&",
    //     `s1=${CoreTools.getQueryParam("aid", CoreTools.state.location.search)}&`,
    //     `s2=${CoreTools.getQueryParam("cpid", CoreTools.state.location.search)}`
    // ].join(""));
    
    content = (state, props) => <HeComponents.Card
        title={props.title}
        controls={<HeComponents.Controls continue={this.continue} buttonText={false} disclaimer={props.disclaimer} />}
    >
        <div className="form-row">
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_type" value="Own" onClick={this.continue} />
                    <span>I am the homeowner</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_type" value="Rent" onClick={this.continue} />
                    <span>I am a tenant</span>
                </label>
            </div>
        </div>
    </HeComponents.Card>;
}