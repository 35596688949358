import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";

export default class CLRType extends GenSet {
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <div className="form-panel">
    <div className="row">
        <div className="col-md-12">
            <span className="radio-button-title">What type of life insurance are you looking for?</span>                
            <span className="input-group"> 
                <label className="btn btn-option">
                    <AppInput.Radio name="insurance_type" value="Term" onClick={this.onClick} />
                    <span className="btn-option-text">Term</span>
                </label>    
                <label className="btn btn-option">
                    <AppInput.Radio name="insurance_type" value="Permanent" onClick={this.onClick} />
                    <span className="btn-option-text">Permanent</span>
                </label>
                <label className="btn btn-option">
                    <AppInput.Radio name="insurance_type" value="Not Sure" onClick={this.onClick} />
                    <span className="btn-option-text">Not Sure</span>
                </label>                  
            </span>
        </div>
    </div>
</div>;
}

