import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";
import GoogleAddress from "../../../directives/GoogleAddress";
import OptionLists from "../../../services/OptionLists";
import FormValidate from "../../../services/FormValidate";
import LegacyDOBPicker from "../../../directives/LegacyDOBPicker";
import FieldValidate from "../../../services/FieldValidate";
import BlrComponents from "../inserts/BlrComponents";
import PhoneVerify from "../../../services/PhoneVerify";

let subValidate = null;
class BlrAddressUS extends GenSet {
    stateOptions = [
        {value: "", caption: "Select State"},
        ...OptionLists.States.map(ol => ({value: ol.short, caption: ol.long}))
    ];

    zipCodeFormat = event => {
        if (typeof(event.target.value) === "string") {
            const proper = event.target.value.replace(/[^0-9]/gi, "").substring(0, 5);
            this.setVal({zip_code: proper});
            if (event.target.value !== proper) {
                const cPos = event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = cPos;
                event.target.selectionEnd = cPos;
            }
        }
    };

    zipCodeValidate = value => (
        typeof(value) === "string" &&
        value.length === 5 &&
        CoreTools.match(value.substring(0, 5), /[0-9]/g)
    );
    
    onApplicant = (state, props, applicant) => {
        this.setVal({addressSet: applicant.field("street_number") && applicant.field("street_name") && applicant.field("city") && applicant.field("state") && applicant.field("zip_code") ? true : false});
    };

    content = (state, props, applicant) => <div className="card-body-address">
        <div className="form-row text-center mb-3">
        <div className="col-12">
            <div id="gm-autocomplete-wrap" className="form-field mb-3 address-fields">                    
                <GoogleAddress
                    country="us"
                    className="form-control text-center"
                    placeholder="Type Here to Search For Your Address"
                    defaultValue={applicant.field("google_address") || ""}
                    onSelect={address => {
                        applicant.setField("google_address", address.full);
                        applicant.setField("street_number", address.number);
                        applicant.setField("street_name", address.street);
                        applicant.setField("city", address.city);
                        applicant.setField("state", address.provinceShort);
                        applicant.setField("zip_code", address.postalcode.replace(" ", ""));
                        this.setVal({addressSet: true, zip_code: address.postalcode.replace(" ", "")}, () => console.log(state.addressSet));
                    }}
                />
            </div>
        </div>
            <div className="col-12">
                <span onClick={() => {this.setVal({addressSet: true})}} className="manual-address">My Address Is Not Listed</span>
            </div>
        </div>
        {state.addressSet && <div className="card-fields" id="address">
            <div className="form-row">
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="street_number" placeholder="Street Number" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="street_name" placeholder="Street Name" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="unit" placeholder="Unit/Apt/Suite" className="form-control special-field" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="city" placeholder="City" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Select type="text" name="state" options={this.stateOptions} className="form-control province-selector special-field" onValidate={v => v} failClass="invalid" />			
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="zip_code" placeholder="Zip Code" className="form-control special-field" onValidate={this.zipCodeValidate} failClass="invalid" onChange={this.zipCodeFormat} />
                </div>
            </div>
        </div>}
    </div>;
}

class BlrPhone extends GenSet {
    verify = PhoneVerify("Your Canadian Life Rates verification pin is: {pin}");
    
    continue = () => CoreTools.switch(
        [!this.state.verifyStatus, () => this.applicant.validate(isValid => isValid ? this.verify.submitNumber(this.applicant.field("phone"), this.state.voice) : window.scrollTo(0, 0))],
        [this.state.verifyStatus === "pin", () => this.applicant.validate(isValid => isValid ? this.verify.submitPin(this.applicant.field("pin"), false) : window.scrollTo(0, 0))],
        [this.state.verifyStatus === "verified", () => this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0))],
        [!this.props.smsVerify, () => this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0))]
    );

    setButtonText = () => this.setVal({
        buttonText: CoreTools.switch(
            [!this.state.verifyStatus, "Verify My Phone Number"],
            [this.state.verifyStatus === "pin", `Verify My Pin`],
            [true, this.props.buttonText]
        )
    });
    
    onMount = () => {
        this.setVal({voice: false});
        this.setButtonText();
        this.verify.onError(msg => this.setVal({errorMsg: msg}));
        this.verify.onGetPin(() => this.setVal({errorMsg: "", verifyStatus: "pin"}, this.setButtonText));
        this.verify.onVerified(() => {
            this.setVal({errorMsg: "", verifyStatus: "verified"}, () => {
                this.setButtonText();
                setTimeout(this.navigate("#Email"), 500);
            });
        });
    };

    onUnmount = () => {
        this.verify.end();
    };
    
    phoneOptions = [{caption: "Mobile", value: "mobile"}, {caption: "Landline", value: "landline"}];
    
    phoneFormat = event => {
        this.setVal({verifyStatus: false}, this.setButtonText);
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };

    content = (state, props) => <BlrComponents.Card
        title={
            this.state.verifyStatus !== "pin"
            ?"What number can we reach you on?"
            :"Enter the verification code sent to "+this.applicant.field("phone")+" via SMS"
        }
        text={
            this.state.verifyStatus !== "pin"
            ?"When you tap Verify My Phone Number, Canadian Life Rates will text or call with a verification code."
            :""
        }
        controls={<BlrComponents.Controls continue={this.continue} buttonText={state.buttonText} disclaimer={props.disclaimer} />}
    >
        <div className="form-row">
            {state.verifyStatus === "verified" && <div style={{color: "#00BB00", textAlign: "center"}} className="col-12 mb-2">Phone Number Verified</div>}
            {this.state.verifyStatus !== "pin" && <div className="col-12 mb-2">
                <AppInput.Input type="tel" name="phone" className="form-control text-field" placeholder="Mobile Phone Number" onChange={this.phoneFormat} onValidate={FormValidate.isPhone} failClass="invalid" />
                <label className="no-text-phone btn-checkbox mt-3 mb-2"><input className="no-text-phone-checkbox mr-2" type="checkbox" value="no-text" onChange={e => this.setVal({voice: e.currentTarget.checked})} /><span className="no-text-phone">My phone does <b>NOT</b> support texting.</span></label>
            </div>}
            {state.errorMsg && <div style={{color: "#BB0000", textAlign: "center"}} className="col-12 mb-2">{state.errorMsg}</div>}
            {this.state.verifyStatus === "pin" && <div className="col-12 mb-2">
                <AppInput.Input type="text" name="pin" className="form-control text-field" placeholder="PIN" onValidate={v => v && v.length === 4} failClass="invalid" />
                <p className="link" onClick={() => this.setVal({verifyStatus: false})}>Change Phone Number</p>
            </div>}
        </div>
    </BlrComponents.Card>;
};

export default class BlrGenPanel extends GenSet {
    continue = () => this.navigate(this.props.continue);
    validate = (disabled) => this.applicant.validate(isValid => !(typeof(disabled) === "function" ? disabled() : false) && isValid && (typeof(subValidate) === "function" ? subValidate() : true) ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    setOutput = event => {
        if (event.target.value === "0") {
            this.setVal({output: "0"});
        } else if (event.target.value === "5000") {
            this.setVal({output: "5,000+"});
        } else {
            this.setVal({output: event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")});
        }
    };
    phoneOptions = [{caption: "Mobile", value: "mobile"}, {caption: "Landline", value: "landline"}];
    phoneFormat = event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };
    
    onApplicant = (state, props, applicant) => {
        this.setVal({
            firstName: applicant.field('first_name'),
        });

        if (props.options.processing === "ca") {
            CoreTools.switch(
                [!applicant.field("province"), () => this.navigate("#Province")],
                [!applicant.field("gender"), () => this.navigate("#Gender")],
                // [!this.applicant.field("age_range"), () => this.navigate("#Age")],
                [!applicant.field("insurance_amount"), () => this.navigate("#InsuranceAmount")],
                [!applicant.field("insurance_type"), () => this.navigate("#InsuranceType")],
                [!applicant.field("smoking_status"), () => this.navigate("#InsuranceSmokingStatus")],
                [!FieldValidate.isName(), () => this.navigate("#ContactInformation")],
                [!FieldValidate.isEmail(), () => this.navigate("#ContactInformation")],
                [!FieldValidate.isPhone(), () => this.navigate("#ContactInformation")],
                [true, () => applicant.post("canadianliferates", "submit", {})]
            );
        } else if (props.options.processing === "fe") {
            CoreTools.switch(
                [!applicant.field("province"), () => this.navigate("#Province")],
                [!applicant.field("gender"), () => this.navigate("#Gender")],
                [!applicant.field("insurance_amount"), () => this.navigate("#InsuranceAmount")],
                [!applicant.field("smoking_status"), () => this.navigate("#InsuranceSmokingStatus")],
                [!FieldValidate.isName(), () => this.navigate("#ContactInformation")],
                [!FieldValidate.isEmail(), () => this.navigate("#ContactInformation")],
                [!FieldValidate.isPhone(), () => this.navigate("#ContactInformation")],
                [true, () => applicant.post("finalexpense", "submit", {})]
            );
        }
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <div className="card-body-format">
                <h5 className="card-title">{props.options.title}</h5>
                {props.options.titleSpecial && <div className="form-subtext"><h5 className="card-title">{state.firstName}, where can we send your free life quote?</h5></div>}
                {props.options.subTitle && <div className="form-subtext"><p className="text-center">{props.options.subTitle}</p></div>}
                {Array.isArray(props.options.fields) && <div className="card-fields row">                
                    {props.options.fields.map((f, i) => CoreTools.switch(
                        f.type,      
                        ["birthdate", () => <div className='dob-picker-container'><LegacyDOBPicker onSelect={v => this.setVal({ready: v})} /></div>],
                        ["input", () => <div className="card-fields row mt-1"><div className="col-12 mb-3">{f.label && <h3>{f.label}</h3>}<AppInput.Input name={f.name} placeholder={f.placeholder} onValidate={f.onValidate} className="form-control text-field" failClass="invalid" /></div></div>],
                        ["radio", () => (f.values || []).map(o => <div className="col-12" key={o}><label className="btn btn-option"><AppInput.Radio name={f.name} value={o} onClick={this.continue} /><span>{o}</span></label></div>)],
                        ["phone", () => <div className="card-fields row mt-1"><div className="col-12 mb-3">{f.label && <h3>{f.label}</h3>}<AppInput.Input type="tel" name={f.name} className="form-control text-field" placeholder={f.placeholder} onValidate={f.onValidate} onChange={FormValidate.phoneFormat} failClass="invalid" /></div></div>],
                        ["phoneSMS", () => <BlrPhone />]
                   ))}
                </div>}
                {Array.isArray(props.options.fields) && <div className="card-fields-image-panel row">                
                    {props.options.fields.map((f, i) => CoreTools.switch(
                        f.type,      
                        ["image", () => (f.values || []).map(o => <div className="col-6 image-panel-container" key={o.value}><label className="btn btn-option"><AppInput.Radio name={f.name} value={o.value} onClick={this.continue} /><div>{o.image}{o.label && <h5>{o.label}</h5>}</div></label></div>)]))}</div>}</div>
                    {props.options.range && <div className="col-12 text-center">
                        <div className="form-slider">
                            <span className="form-value two-value">
                                <div className="dollar"><span id="dollar-sign">{props.options.range.currency}</span>{state.output}<span id="dollar-sign">{props.options.range.percentage}</span></div>
                            </span>
                            <AppInput.Range
                                className=""
                                name={props.options.range.name}
                                onValidate={props.options.range.onValidate}
                                failClass="invalid"
                                onChange={this.setOutput}
                                onLoad={this.setOutput}
                                min={props.options.range.min}
                                max={props.options.range.max}
                                step={props.options.range.step}
                                defaultValue={props.options.range.defaultValue}
                            />
                        </div>
                    </div>}
            {props.options.address && CoreTools.switch(
                props.options.address,
                ["us", () => <BlrAddressUS />],
            )}
            {props.options.continue && <button className="mx-auto btn btn-navigate mt-4" onClick={() => this.validate(props.options.continue.disabled)}>{props.options.continue.caption}</button>}
            {props.options.consent && <div className="term-text mt-4"><p className="text-center widest"><input type="hidden" id="leadid_tcpa_disclosure"/>Clicking "Get My Quotes", I have read and accept the <a id="privacy-anchor-tag" href="https://loanz.com/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>. I also acknowledge having read the <a id="privacy-anchor-tag" href="https://loanz.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and agree to the terms herein.</p></div>}
            {props.options.processingAnimation && <div className="card card-options"><div className="card-body"><div><img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{display: "block", margin: "20px auto", width: "100%"}} /></div></div></div>}
        </div>
    </div>;
}
