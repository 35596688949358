import CoreTools from "../../../services/CoreTools";
import GenSet from "../../../services/GenSet";
import FieldValidate from "../../../services/FieldValidate";

export default class CarsfastForwardProcessing extends GenSet {
    onApplicant = (state, props, applicant) => CoreTools.switch(
        [!applicant.field("desired_vehicle"),                   () => this.navigate("#VehicleType")],
        [!applicant.field("monthly_budget_range"),              () => this.navigate("#Budget")],
        [!applicant.field("existing_vehicle"),                  () => this.navigate("#ExistingVehicle")],
        [!FieldValidate.isDob(),                                () => this.navigate("#DateBirth")],
        [!applicant.field("employment_status"),                 () => this.navigate("#EmploymentStatus")],
        [!applicant.field("gross_monthly_income_range"),        () => this.navigate("#MonthlyIncome")],
        [!applicant.field("employment_duration_range"),         () => this.navigate("#IncomeLength")],
        [!applicant.field("company_name"),                      () => this.navigate("#WhereWork")],
        [!applicant.field("job_title"),                         () => this.navigate("#WhereWork")],
        [!applicant.field("residence_type"),                    () => this.navigate("#OwnRent")],
        [!Number(applicant.field("monthly_residence_cost")),    () => this.navigate("#MonthlyRent")],
        [!FieldValidate.isAddress("ca"),                        () => this.navigate("#Address")],
        [!applicant.field("residence_duration_range"),          () => this.navigate("#ResidenceLength")],
        [!FieldValidate.isName(),                               () => this.navigate("#FinalDetails")],
        [!FieldValidate.isEmail(),                              () => this.navigate("#FinalDetails")],
        [!FieldValidate.isPhone(),                              () => this.navigate("#FinalDetails")],
        [!applicant.field("agreed"),                            () => this.navigate("#FinalDetails")],
        [true,                                                  () => applicant.post("carsfastcanada", "forward", {source: "forward"})]
    );
    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <h5 className="card-title">We are forwarding your application.</h5>
            <div>
                <img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{display: "block", margin: "20px auto"}} />
            </div>
        </div>
    </div>;
}