import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools"

export default class SurexSecondDriver extends GenSet {
    continue = () => setTimeout(() => { 
        this.navigate(CoreTools.switch(
            [this.applicant.field("home_owner") === "No", "#PhoneNumber"],
            [true, this.props.continue]
        ));
    });
    
    content = (state, props) => <SurexComponents.Card
        title="Add a second driver (save up to 25%)?"
    >
        <div className="form-row">
            <div className="col-12 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="second_driver" value="Yes" onClick={() => this.navigate("#SecondDriverName")} />
                    <span>Yes</span>
                </label>
            </div>
            <div className="col-12 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="second_driver" value="No" onClick={() => this.navigate("#PhoneNumber")} />
                    <span>No</span>
                </label>
            </div>
        </div>
    </SurexComponents.Card>;
}
