import CoreTools from "../../../services/CoreTools";
import GenSet from "../../../services/GenSet";

export default class HeProcessing extends GenSet {
    onApplicant = (state, props, applicant) => CoreTools.switch(
        [!applicant.field("street_number"), () => this.navigate("#AddressInformation")],
        [!applicant.field("street_name"), () => this.navigate("#AddressInformation")],
        [!applicant.field("city"), () => this.navigate("#AddressInformation")],
        [!applicant.field("state"), () => this.navigate("#AddressInformation")],
        [!applicant.field("zip_code"), () => this.navigate("#AddressInformation")],
        [!applicant.field("first_name"), () => this.navigate("#Name")],
        [!applicant.field("last_name"), () => this.navigate("#Name")],
        [!applicant.field("email"), () => this.navigate("#Email")],
        [!applicant.field("phone"), () => this.navigate("#Phone")],
        [true, () => applicant.post("homeequity-ca", "submit", {})]
    );
    content = (state, props) => <fieldset className="card card-options">
        <div className="card-body">
            <h5 className="card-title text-center">We are processing your application.</h5>
            <div>
                <img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{display: "block", margin: "20px auto"}} />
            </div>
        </div>
    </fieldset>;
}