import GenSet from "../../services/GenSet";
import CoreTools from "../../services/CoreTools"

import SurexVehicleNumber from "./panels/SurexVehicleNumber";
import SurexLicenceDob from "./panels/SurexLicenceDob";

import SurexCurrentProvider from "./panels/SurexCurrentProvider";
import SurexVehicles from "./panels/SurexVehicles";
import SurexVehicleOptions from "./panels/SurexVehicleOptions";
import SurexDateBirth from "./panels/SurexDateBirth";
import SurexYourName from "./panels/SurexYourName";
import SurexProvince from "./panels/SurexProvince";
import SurexProvinceAlt from "./panels/SurexProvinceAlt";
import SurexLicenceInfo from "./panels/SurexLicenceInfo";
import SurexLicenseDobPicker from "./panels/SurexLicenseDobPicker";
import SurexDobPicker from "./panels/SurexDobPicker";
import SurexLicenseIssuedPicker from "./panels/SurexLicenseIssuedPicker";
import SurexLicenceNumber from "./panels/SurexLicenceNumber";
import SurexLicenseReview from "./panels/SurexLicenseReview";
import SurexAddress from "./panels/SurexAddress";
import SurexOwnHome from "./panels/SurexOwnHome";
import SurexAddDrivers from "./panels/SurexAddDrivers";
import SurexSecondDriver from "./panels/SurexSecondDriver";
import SurexEmailAddress from "./panels/SurexEmailAddress";
import SurexPhoneNumber from "./panels/SurexPhoneNumber";
import SurexContactInfo from "./panels/SurexContactInfo";
import SurexMarried from "./panels/SurexMarried";
import SurexHomeInsurance from "./panels/SurexHomeInsurance";
import SurexSecondDateBirth from "./panels/SurexSecondDateBirth";
import SurexSecondName from "./panels/SurexSecondName";
import SurexProcessing from "./panels/SurexProcessing";

import PanelEffects from "../../directives/PanelEffects";
import CircleBar from "../../directives/CircleBar";
import SurexPostalCode from "./panels/SurexPostalCode";
import SurexPostalCodeV2 from "./panels/SurexPostalCodeV2";
import SurexVehicleOptionsV2 from "./panels/SurexVehicleOptionsV2";
import SurexResidenceStatus from "./panels/SurexResidenceStatus";
import SurexResidenceType from "./panels/SurexResidenceType";
import SurexResidenceLength from "./panels/SurexResidenceLength";
import SurexAnnualMileage from "./panels/SurexAnnualMileage";
import SurexCurrentlyInsured from "./panels/SurexCurrentlyInsured";
import SurexMaritalStatus from "./panels/SurexMaritalStatus";
import SurexEducation from "./panels/SurexEducation";
import SurexLicenseStatus from "./panels/SurexLicenseStatus";
import SurexAgeLicensed from "./panels/SurexAgeLicensed";
import SurexGender from "./panels/SurexGender";
import SurexOccupation from "./panels/SurexOccupation";
import SurexCurrentInsurer from "./panels/SurexCurrentInsurer";

export default class SurexIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (!CoreTools.state.appOptions.includes("nocss")) {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
            this.importStyle("/css/Surex.css");
            this.importStyle("/css/animate.css");
            this.importStyle("/css/rangeslider2.css");
        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.wHolder(this.applicant.on("hasBuyer", data => {
            console.log("hasBuyer", data);
            this.navigate("/surex-thank-you/");
            // if (data.isSold) {
            //     //Has Sold
            //     this.navigate(`/thank-you/id-${data.buyerId}/`);
            // } else {
            //     //Not Sold
            //     this.navigate("/thank-you/");
            // }
        }));
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}, () => setTimeout(CoreTools.centerForm, 50)));
    };

    onApplicant = (state, props, applicant) => {
        if (CoreTools.getQueryParam("province")) {
          applicant.setField("state", CoreTools.getQueryParam("province"));
          if (!this.location.hash) {
            this.navigate("#VehicleOptions");
          }
        }
    };

    panelsOriginal = {
        "#VehicleNumber":   <SurexVehicleNumber />,
        "#YourName":        <SurexYourName />,
        // "#DateBirth":       <SurexDateBirth />,
        "#Address":         <SurexAddress />,
        "#LicenceInfo":     <SurexLicenceInfo />,
        "#LicenceDob":      <SurexLicenceDob />,
        "#LicenceNumber":   <SurexLicenceNumber />,
        "#Vehicles":        <SurexVehicles />,
        // "#AddVehicle":      <SurexAddVehicle />,
        "#AddDrivers":      <SurexAddDrivers />,
        "#ContactInfo":     <SurexContactInfo />
    };

    panelsV2 = {
        "#CurrentProvider":         <SurexCurrentProvider />,
        "#Vehicles":                <SurexVehicles />,
        "#Address":                 <SurexAddress />,
        "#OwnHome":                 <SurexOwnHome />,
        "#YourName":                <SurexYourName />,
        "#LicenseInfo":             <SurexLicenceInfo />,
        "#LicenseDob":              <SurexLicenseDobPicker />,
        "#LicenseIssuedPicker":     <SurexLicenseIssuedPicker />,
        "#LicenseNumber":           <SurexLicenceNumber />,
        "#LicenseReview":           <SurexLicenseReview />,
        "#AddDrivers":              <SurexAddDrivers />,
        "#EmailAddress":            <SurexEmailAddress />,
        "#PhoneNumber":             <SurexPhoneNumber />
    };

    panelsV3 = {
        "#CurrentProvider":         <SurexCurrentProvider />,
        "#Province":                <SurexProvince />,
        "#Vehicles":                <SurexVehicles />,
        "#OwnHome":                 <SurexOwnHome />,
        "#YourName":                <SurexYourName />,
        "#DateOfBirth":             <SurexDobPicker />,
        "#EmailAddress":            <SurexEmailAddress />,
        "#PhoneNumber":             <SurexPhoneNumber />
    };

    panelsV4 = {
        "#Province":                <SurexProvinceAlt />,
        "#VehicleOptions":          <SurexVehicleOptions />,
        "#OwnHome":                 <SurexOwnHome />,
        "#HomeInsurance":           <SurexHomeInsurance />,
        "#Married":                 <SurexMarried />,
        "#DateOfBirth":             <SurexDateBirth />, 
        "#YourName":                <SurexYourName />,
        "#EmailAddress":            <SurexEmailAddress />,
        "#PostalCode":              <SurexPostalCode />,
        "#AddDrivers":              <SurexSecondDriver />,
        "#SecondDriverName":        <SurexSecondName />,
        "#SecondDriverDob":         <SurexSecondDateBirth />,
        "#PhoneNumber":             <SurexPhoneNumber />,
        "#Processing":              <SurexProcessing />
    };

    EverQuote = {
        "#Zipcode":                 <SurexPostalCodeV2 />,
        "#ResidenceStatus":         <SurexResidenceStatus />,
        "#ResidenceType":           <SurexResidenceType />,
        "#ResidenceLength":         <SurexResidenceLength />,
        "#Address":                 <SurexAddress />,
        "#VehicleOptions":          <SurexVehicleOptionsV2 />,
        "#AnnualMileage":           <SurexAnnualMileage />,
        "#CurrentlyInsured":        <SurexCurrentlyInsured />,
        "#CurrentInsurer":          <SurexCurrentInsurer />,
        "#Name":                    <SurexYourName />,
        "#Birthdate":               <SurexDobPicker />, 
        "#Gender":                  <SurexGender />, 
        "#MaritalStatus":           <SurexMaritalStatus />,
        "#Occupation":              <SurexOccupation />,
        "#Education":               <SurexEducation />,
        "#LicenseStatus":           <SurexLicenseStatus />,
        "#AgeLicensed":             <SurexAgeLicensed />, 
        "#EmailAddress":            <SurexEmailAddress />,
        "#PhoneNumber":             <SurexPhoneNumber />,
        "#Processing":              <SurexProcessing />
    };

    content = (state, props) => <div className="overcard v1">
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <div className="appForm_inner">
        
        <CircleBar color="#1713c0"/>
            <PanelEffects.Transition3>
                <PanelEffects.Selector
                    selector={state.hash}
                    panels={CoreTools.switch(
                        [props.version === "original", this.panelsOriginal],
                        [props.version === "V2", this.panelsV2],
                        [props.version === "V3", this.panelsV3],
                        [props.version === "V4", this.panelsV4],
                        [props.version === "EverQuote", this.EverQuote],
                    )} 
                />
            </PanelEffects.Transition3>
        </div>
    </div>;
}
