import PanelEffects from "../../directives/PanelEffects";
import CoreTools from "../../services/CoreTools";
import GenSet from "../../services/GenSet";
import GoogleTagManager from "../../services/GoogleTagManager";
import FormValidate from "../../services/FormValidate";
import RenovateFormData from "./RenovateFormData";
import RenovateGenPanel from "./panels/RenovateGenPanel";
import TrustedFormCert from "../../directives/TrustedFormCert";
import Warning from "./panels/Warning";

class ResetButton extends GenSet {
    cancel = () => { 
        clearTimeout(this.timeoutID);
        CoreTools.memory.set("ckey", "");
        setTimeout(() => window.location.href = "/philly/", 500); 
    };  
  
    content = (state) => {
      return (
        <div className="card-body">
            <div className="reset-button mt-3" onClick={this.cancel}>Reset</div>
        </div>
      );
    }
  }

const structure = {
    address: {
        title: "What Is Your Home Address?",
        subtitle: "Enter your physical address in the box below to quickly autocomplete all required fields.",
        address: "us",
        continue: {caption: "Continue"},
    },
    age: {
        title: "What is your current age?",
        range: {name: "age", min: "18", max: "120", step: "1", defaultValue: "50", percentage: " years old"},
        continue: {caption: "Continue"},
    },
    ageV2: {
        title: "What is your current age?",
        fields: [
            {type: "radio", name: "age", values: RenovateFormData.age},
        ],
    },
    attendance: {
        title: "How many times have you attended this show?",
        fields: [
            {type: "radio", name: "show_attendance", values: RenovateFormData.attendance},
        ],
    },
    attendanceReasons: {
        title: "Please select your reason(s) for attending today",
        subTitle: "Select all reasons that apply",
        fields: [
            {type: "multiSelect", name: "attendance_reasons", 
            options: [
                "Shop for a SPECIFIC product or service", 
                "Browse various products/services", 
                "Get ideas", 
                "Inspiration", 
                "Speak with exhibitors", 
                "Take advantage of show discounts", 
                "See what's new", 
                "None of the above", 
                "Other"
            ]},
        ], 
        continue: {caption: "Continue"},
    },
    attendanceReasonsV2: {
        title: "Please select your reason(s) for attending today",
        subTitle: "Select all reasons that apply",
        fields: [
            {type: "multiSelect", name: "attendance_reasons", 
            options: [
                "Boat Displays", 
                "Part Shopping",
                "Get Information",
                "Purchase A Boat",
                "Comparison Shop",
                "Other"
            ]},
        ], 
        continue: {caption: "Continue"},
    },
    activities: {
        title: "What other activities do you enjoy?",
        subTitle: "Select all reasons that apply",
        fields: [
            {type: "multiSelect", name: "activities", 
            options: [
                "Gambling",
                "Hiking",
                "Theme Parks",
                "Biking",
                "Sporting Events",
                "Beach",
                "Shopping",
                "Family Time",
            ]},
        ], 
        continue: {caption: "Continue"},
    },
    bathroom: {
        title: "Which of these best describe your needs?",
        fields: [
            {type: "radio", name: "bath_remodel_type", values: RenovateFormData.bathroom},
        ],
    },
    bathroomRemodel: {
        title: "Does the remodelling job require removing or adding any walls?",
        fields: [
            {type: "radio", name: "bath_remodel_optin", values: RenovateFormData.yesNo},
        ],
    },
    bill: {
        title: "What is your average monthly electric bill?",
        fields: [
            {type: "radio", name: "electric_bill", values: RenovateFormData.electricBill},
        ],
    },
    boatInterest: {
        title: "Please select the style of boat you are most interested in",
        fields: [
            {type: "radio", name: "boat_interest", values: RenovateFormData.boatStyleInterest},
        ],
    },
    boatStyle: {
        title: "Please select the style of boat you currently own",
        fields: [
            {type: "radio", name: "boat_style", values: RenovateFormData.boatStyle},
        ],
    },
    consent: {
        phillyConsent: true,
        fields: [
            {type: "radio", name: "tradeshow_consent", values: RenovateFormData.consent},
        ],
    },
    contact: {
        title: "One click away from big savings!",
        fields: [
            {type: "input", label: "Email", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail },
            {type: "phone", label: "Phone", name: "phone", placeholder: "(123) 456 7890", onValidate: FormValidate.isPhone },
        ],
        continue: {caption: "Finish"},
        consent: "true",
    },
    credit: {
        title: "How is Your Credit?",
        subtitle: "★ Most people have good credit ★",
        fields: [
            {type: "credit", name: "credit", values: RenovateFormData.credit, span: RenovateFormData.spans },
        ],
    },
    destination: {
        title: "What locations would you most like to travel to next?",
        subTitle: "Select all destinations that apply",
        fields: [
            {type: "multiSelect", name: "destinations", 
            options: [
                "Orlando", 
                "Daytona Beach", 
                "Cruise", 
                "Fort Lauderdale", 
                "Las Vegas", 
                "Mountain Destination", 
                "Poconos", 
                "Breckenridge"
            ]},
        ], 
        continue: {caption: "Continue"},
    },
    discovery: {
        title: "Please tell us how you learned of the show.",
        fields: [
            {type: "radio", name: "show_discovery", values: RenovateFormData.discovery},
        ],
    },
    discoveryV2: {
        title: "Please tell us how you learned of The Savannah Boat Show.",
        fields: [
            {type: "radioDiscovery", name: "show_discovery", values: RenovateFormData.discovery},
        ],
    },
    tradeShowContact: {
        title: "Please enter your contact details",
        fields: [
            {type: "input", label: "Email", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail },
            {type: "phone", label: "Phone", name: "phone", placeholder: "(123) 456 7890", onValidate: FormValidate.isPhone },
        ],
        continue: {caption: "Continue"},
        tradeShowConsent: "true",
    },
    education: {
        title: "Select your highest level of education",
        fields: [
            {type: "radio", name: "education", values: RenovateFormData.education},
        ]
    },
    email: {
        title: "What is your email?",
        fields: [
            {type: "input", label: "Email", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail },
        ],
        continue: {caption: "Continue"},
    },
    emailV2: {
        title: "Please enter your primary home email address.",
        fields: [
            {type: "input", label: "Email", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail },
        ],
        continue: {caption: "Continue"},
    },
    emailConsent: {
        title: "Do you Agree to receive Emails from Marketplace Events and show sponsors about future shows?",
        fields: [
            {type: "radio", name: "email_consent", values: RenovateFormData.yesNo},
        ]
    },
    event: {
        title: "How did you hear about this event?",
        fields: [
            {type: "radio", name: "event", values: RenovateFormData.event},
        ],
    },
    gender: {
        title: "Please select your gender.",
        fields: [
            {type: "radio", name: "gender", values: RenovateFormData.gender},
        ],
    },
    gutterCommercialLocation: {
        title: "Where will this project be taking place?",
        fields: [
            {type: "radio", name: "gutter_commercial_location", values: RenovateFormData.gutterCommercialLocation},
        ],
    },
    gutterType: {
        title: "What material are these gutters made of?",
        fields: [
            {type: "radio", name: "gutter_type", values: RenovateFormData.gutterType},
        ],
    },
    hotTub: {
        title: "Are you interested in a hot tub or swim spa?",
        fields: [
            {type: "radio", name: "hot_tub_type", values: RenovateFormData.hotTub},
        ],
    },
    hotTubSeating: {
        title: "How many people do you want the spa to seat?",
        fields: [
            {type: "radio", name: "hot_tub_seating", values: RenovateFormData.hotTubSeating},
        ],
    },
    hotTubPower: {
        title: "Are you looking for a 110v or 220v hot tub?",
        fields: [
            {type: "radio", name: "hot_tub_power", values: RenovateFormData.hotTubPower},
        ],
    },
    hotTubTimeframe: {
        title: "How soon are you looking to purchase a new spa?",
        fields: [
            {type: "radio", name: "hot_tub_timeframe", values: RenovateFormData.hotTubTimeframe},
        ],
    },
    householdIncome: {
        title: "Please give us a general idea of your estimated combined household income range?",
        fields: [
            {type: "radio", name: "household_income", values: RenovateFormData.householdIncome},
        ],
    },
    interest: {
        title: "What home improvement project are you most interested in?",
        subTitle: "Select all improvements that apply",
        fields: [
            {type: "multiSelect", name: "projects", 
            options: [
                "Solar",
                "Kitchen Remodel",
                "Bathroom Remodel",
                "Roofing",
                "Lawn and Landscaping",
                "Gutters",
                "Outdoor Furniture",
            ]},
        ], 
        continueMaster: {caption: "Continue", url: "/mass-inbound2/"},
    },
    maritalStatus: {
        title: "Please Select Your Marital Status?",
        fields: [
            {type: "radioMaritalStatus", name: "marital_status", values: RenovateFormData.maritalStatus},
        ],
    },
    name: {
        title: "What is your name?",
        fields: [
            {type: "input", label: "First Name", name: "first_name", placeholder: "First Name", onValidate: FormValidate.isName },
            {type: "input", label: "Last Name", name: "last_name", placeholder: "Last Name", onValidate: FormValidate.isName },
        ],
        continue: {caption: "Continue"},
    },
    notices: {
        title: "I would like to receive emails from Marketplace Events, producer of the Southern Christmas Show, about ticket deals, show information and more. Email will not be shared with any third parties.",
        fields: [
            {type: "radio", name: "notices", values: RenovateFormData.yesNo},
        ],
    },
    other: {
        title: "Please tell us specifically where you learned of The Savannah Boat Show?",
        fields: [
            {type: "input", name: "show_discovery_other"},
        ],
        continue: {caption: "Continue"},
    },
    phillyConsent: {
        phillyConsent: true,
        fields: [
            {type: "radio", name: "notices", values: RenovateFormData.consent},
        ],
    },
    phone: {
        title: "What is your phone number?",
        fields: [
            {type: "phone", label: "Phone", name: "phone", placeholder: "(123) 456 7890", onValidate: FormValidate.isPhone },
        ],
        continue: {caption: "Continue"},
    },
    phoneV2: {
        title: "For notification purposes, please enter your primary phone number.",
        fields: [
            {type: "phone", label: "Phone", name: "phone", placeholder: "(123) 456 7890", onValidate: FormValidate.isPhone },
        ],
        continue: {caption: "Continue"},
    },
    processing: {
        title: "We are processing your application.",
        processing: "us",
        processingAnimation: true,
    },
    projectScope: {
        title: "Which of these best describe your needs?",
        fields: [
            {type: "radio", name: "windows_project_scope", values: RenovateFormData.projectScope},
        ],
    },
    projectSize: {
        title: "How many windows does this project involve?",
        range: {name: "number_of_windows", min: "1", max: "10", step: "1", defaultValue: "3"},
        continue: {caption: "Continue"},
    },
    provider: {
        title: "What is the name of the electric utility company that you use?",
        subTitle: "Choose from the utility providers in your area",
        solarProvider: true,
    },
    replaceRepairRoofing: {
        title: "Do you need to replace or repair an existing roof?",
        fields: [
            {type: "radio", name: "roofing_plan", values: RenovateFormData.roofingPlan},
        ],
    },
    replaceRepairWindow: {
        title: "Do you need to replace or repair existing windows?",
        fields: [
            {type: "radio", name: "windows_plan", values: RenovateFormData.replaceRepair},
        ],
    },
    residenceType: {
        title: "Do you rent or own the residence?",
        fields: [
            {type: "radio", name: "residence_type", values: RenovateFormData.residenceType},
        ],
    },
    return: {
        title: "Will you return to The 2024 Savannah Boat Show?",
        fields: [
            {type: "radio", name: "return", values: RenovateFormData.yesNo},
        ],
    },
    roof: {
        title: "How much sun does your roof get?",
        fields: [
            {type: "radio", name: "roof_shade", values: RenovateFormData.roof},
        ],
    },
    spouse: {
        title: "Please enter your spouse's name",
        fields: [
            {type: "input", label: "First Name", name: "spouse_first_name", placeholder: "First Name", onValidate: FormValidate.isName },
            {type: "input", label: "Last Name", name: "spouse_last_name", placeholder: "Last Name", onValidate: FormValidate.isName },
        ],
        continue: {caption: "Continue"},
    },
    ticketNumber: {
        title: "What is your ticket number?",
        fields: [   
            {type: "input", label: "Ticket Number", name: "ticket_number", placeholder: "Ticket Number"},
        ],
        continueMaster: {caption: "Continue", url: "/mass-inbound2/"},
    },
    ticketNumberV2: {
        title: "What is your ticket number?",
        fields: [   
            {type: "input", label: "Ticket Number", name: "ticket_number", placeholder: "Ticket Number"},
        ],
        continueMaster: {caption: "Continue", url: "/mass-inbound3/"},
    },
    timeframe: {
        title: "How soon are you looking to make this change?",
        fields: [
            {type: "radio", name: "buy_timeframe", values: RenovateFormData.timeframe},
        ],
    },
    typeRoofing: {
        title: "What type of roof are you looking for?",
        fields: [
            {type: "radio", name: "roofing_type", values: RenovateFormData.roofsTypeV2},
        ],
    },
    typeRoofingV2: {
        title: "What type of roof are you looking for?",
        fields: [
            {type: "radio", name: "roofing_type", values: RenovateFormData.roofsTypeV2},
        ],
    },
    typeWindows: {
        title: "What type of windows do you need repaired/replaced?",
        fields: [
            {type: "image", name: "windows_type", values: RenovateFormData.windowsType},
        ],
    },
    typeWindowsV2: {
        title: "What type of windows do you need repaired/replaced?",
        fields: [
            {type: "radio", name: "windows_type", values: RenovateFormData.windowsTypeV2},
        ],
    },
    walkIn: {
        title: "Why do you want to change your walk in bathtub?",
        fields: [
            {type: "radio", name: "walk_in", values: RenovateFormData.walkIn},
        ],
    },
};

const versions = {
    bathroom: {
        "#Timeframe":           <RenovateGenPanel options={structure.timeframe} />,
        "#ResidenceType":       <RenovateGenPanel options={structure.residenceType} />,
        "#Remodel":             <RenovateGenPanel options={structure.bathroomRemodel} />,
        "#Bathroom":            <RenovateGenPanel options={structure.bathroom} />,
        "#Address":             <RenovateGenPanel options={structure.address} />,
        "#Name":                <RenovateGenPanel options={structure.name} />,
        "#Contact":             <RenovateGenPanel options={structure.contact} />,
        "#Processing":          <RenovateGenPanel options={structure.processing} />,
    },
    bathroomTradeshow: {
        "#Timeframe":           <RenovateGenPanel options={structure.timeframe} />,
        "#ResidenceType":       <RenovateGenPanel options={structure.residenceType} />,
        "#Remodel":             <RenovateGenPanel options={structure.bathroomRemodel} />,
        "#Bathroom":            <RenovateGenPanel options={structure.bathroom} />,
        "#Processing":          <RenovateGenPanel options={structure.processing} />,
    },
    gutters: {
        "#Timeframe":           <RenovateGenPanel options={structure.timeframe} />,
        "#ResidenceType":       <RenovateGenPanel options={structure.residenceType} />,
        "#Project":             <RenovateGenPanel options={structure.projectScope} />,
        "#Location":            <RenovateGenPanel options={structure.gutterCommercialLocation} />,
        "#Material":            <RenovateGenPanel options={structure.gutterType} />,
        "#Address":             <RenovateGenPanel options={structure.address} />,
        "#Name":                <RenovateGenPanel options={structure.name} />,
        "#Contact":             <RenovateGenPanel options={structure.contact} />,
        "#Processing":          <RenovateGenPanel options={structure.processing} />,
    },
    guttersTradeshow: {
        "#Timeframe":           <RenovateGenPanel options={structure.timeframe} />,
        "#ResidenceType":       <RenovateGenPanel options={structure.residenceType} />,
        "#Project":             <RenovateGenPanel options={structure.projectScope} />,
        "#Location":            <RenovateGenPanel options={structure.gutterCommercialLocation} />,
        "#Material":            <RenovateGenPanel options={structure.gutterType} />,
        "#Processing":          <RenovateGenPanel options={structure.processing} />,
    },
    hotTubTradeshow: {
        "#Type":                <RenovateGenPanel options={structure.hotTub} />,
        "#Power":               <RenovateGenPanel options={structure.hotTubPower} />,
        "#Seating":             <RenovateGenPanel options={structure.hotTubSeating} />,
        "#Timeframe":           <RenovateGenPanel options={structure.hotTubTimeframe} />,
        "#Processing":          <RenovateGenPanel options={structure.processing} />,
    },
    philly: {
        "#Name":                <RenovateGenPanel options={structure.name} />,
        "#Gender":              <RenovateGenPanel options={structure.gender} />,
        "#Marital":             <RenovateGenPanel options={structure.maritalStatus} />,
        "#Spouse":              <RenovateGenPanel options={structure.spouse} />,
        "#Notices":             <RenovateGenPanel options={structure.notices} />,
        "#Phone":               <RenovateGenPanel options={structure.phoneV2} />,
        "#Email":               <RenovateGenPanel options={structure.emailV2} />,
        "#Address":             <RenovateGenPanel options={structure.address} />,
        "#ResidenceType":       <RenovateGenPanel options={structure.residenceType} />,
        "#Age":                 <RenovateGenPanel options={structure.age} />,
        "#HouseholdIncome":     <RenovateGenPanel options={structure.householdIncome} />,
        "#Discovery":           <RenovateGenPanel options={structure.discovery} />,
        "#Attendance":          <RenovateGenPanel options={structure.attendance} />,
        "#Reasons":             <RenovateGenPanel options={structure.attendanceReasons} />,
        "#Destinations":        <RenovateGenPanel options={structure.destination} />,
        "#Activities":          <RenovateGenPanel options={structure.activities} />,
        "#Consent":             <RenovateGenPanel options={structure.consent} />,
        "#Ticket":              <RenovateGenPanel options={structure.ticketNumberV2} />,
    },
    roofing: {
        "#Timeframe":           <RenovateGenPanel options={structure.timeframe} />,
        "#ResidenceType":       <RenovateGenPanel options={structure.residenceType} />,
        "#ReplaceRepair":       <RenovateGenPanel options={structure.replaceRepairRoofing} />,
        "#Type":                <RenovateGenPanel options={structure.typeRoofing} />,
        "#Address":             <RenovateGenPanel options={structure.address} />,
        "#Name":                <RenovateGenPanel options={structure.name} />,
        "#Contact":             <RenovateGenPanel options={structure.contact} />,
        "#Processing":          <RenovateGenPanel options={structure.processing} />,
    },
    roofingV2: {
        "#Timeframe":           <RenovateGenPanel options={structure.timeframe} />,
        "#ResidenceType":       <RenovateGenPanel options={structure.residenceType} />,
        "#ReplaceRepair":       <RenovateGenPanel options={structure.replaceRepairRoofing} />,
        "#Type":                <RenovateGenPanel options={structure.typeRoofingV2} />,
        "#Address":             <RenovateGenPanel options={structure.address} />,
        "#Name":                <RenovateGenPanel options={structure.name} />,
        "#Contact":             <RenovateGenPanel options={structure.contact} />,
        "#Processing":          <RenovateGenPanel options={structure.processing} />,
    },
    roofingTradeshow: {
        "#Timeframe":           <RenovateGenPanel options={structure.timeframe} />,
        "#ResidenceType":       <RenovateGenPanel options={structure.residenceType} />,
        "#ReplaceRepair":       <RenovateGenPanel options={structure.replaceRepairRoofing} />,
        "#Type":                <RenovateGenPanel options={structure.typeRoofing} />,
        "#Processing":          <RenovateGenPanel options={structure.processing} />,
    },
    savannah: {
        "#Name":                <RenovateGenPanel options={structure.name} />,
        "#Gender":              <RenovateGenPanel options={structure.gender} />,
        "#Marital":             <RenovateGenPanel options={structure.maritalStatus} />,
        "#Spouse":              <RenovateGenPanel options={structure.spouse} />,
        "#Notices":             <RenovateGenPanel options={structure.notices} />,
        "#Phone":               <RenovateGenPanel options={structure.phoneV2} />,
        "#Email":               <RenovateGenPanel options={structure.emailV2} />,
        "#Address":             <RenovateGenPanel options={structure.address} />,
        "#ResidenceType":       <RenovateGenPanel options={structure.residenceType} />,
        "#Age":                 <RenovateGenPanel options={structure.ageV2} />,
        "#HouseholdIncome":     <RenovateGenPanel options={structure.householdIncome} />,
        "#Education":           <RenovateGenPanel options={structure.education} />,
        "#Discovery":           <RenovateGenPanel options={structure.discoveryV2} />,
        "#Other":               <RenovateGenPanel options={structure.other} />,
        "#Attendance":          <RenovateGenPanel options={structure.attendance} />,
        "#Return":              <RenovateGenPanel options={structure.return} />,
        "#Reasons":             <RenovateGenPanel options={structure.attendanceReasonsV2} />,
        "#Style":               <RenovateGenPanel options={structure.boatStyle} />,
        "#Interest":            <RenovateGenPanel options={structure.boatInterest} />,
        "#Destinations":        <RenovateGenPanel options={structure.destination} />,
        "#Activities":          <RenovateGenPanel options={structure.activities} />,
        "#Consent":             <RenovateGenPanel options={structure.consent} />,
        "#Ticket":              <RenovateGenPanel options={structure.ticketNumber} />,
    },
    solarTradeshow: {
        "#Property":            <RenovateGenPanel options={structure.residenceType} />,
        "#Provider":            <RenovateGenPanel options={structure.provider} />,
        "#Roof":                <RenovateGenPanel options={structure.roof} />,
        "#Bill":                <RenovateGenPanel options={structure.bill} />,
        "#Credit":              <RenovateGenPanel options={structure.credit} />,
        "#Processing":          <RenovateGenPanel options={structure.processing} />,
    },
    tradeshow: {
        "#Name":                <RenovateGenPanel options={structure.name} />,
        "#Consent":             <RenovateGenPanel options={structure.emailConsent} />,
        "#Contact":             <RenovateGenPanel options={structure.tradeShowContact} />,
        "#Address":             <RenovateGenPanel options={structure.address} />,
        "#Event":               <RenovateGenPanel options={structure.event} />,
        "#Age":                 <RenovateGenPanel options={structure.age} />,
        "#Ticket":              <RenovateGenPanel options={structure.ticketNumber} />,
        "#Interest":            <RenovateGenPanel options={structure.interest} />,
    },  
    walkIn: {
        "#Timeframe":           <RenovateGenPanel options={structure.timeframe} />,
        "#ResidenceType":       <RenovateGenPanel options={structure.residenceType} />,
        "#WalkIn":              <RenovateGenPanel options={structure.walkIn} />,
        "#Address":             <RenovateGenPanel options={structure.address} />,
        "#Name":                <RenovateGenPanel options={structure.name} />,
        "#Contact":             <RenovateGenPanel options={structure.contact} />,
        "#Processing":          <RenovateGenPanel options={structure.processing} />,
    },
    walkInTubsTradeshow: {
        "#WalkIn":              <RenovateGenPanel options={structure.walkIn} />,
        "#Processing":          <RenovateGenPanel options={structure.processing} />,
    },
    windows: {
        "#Timeframe":           <RenovateGenPanel options={structure.timeframe} />,
        "#ResidenceType":       <RenovateGenPanel options={structure.residenceType} />,
        "#Type":                <RenovateGenPanel options={structure.typeWindowsV2} />,
        "#Project":             <RenovateGenPanel options={structure.projectScope} />,
        "#ProjectSize":         <RenovateGenPanel options={structure.projectSize} />,
        "#Address":             <RenovateGenPanel options={structure.address} />,
        "#Name":                <RenovateGenPanel options={structure.name} />,
        "#Contact":             <RenovateGenPanel options={structure.contact} />,
        "#Processing":          <RenovateGenPanel options={structure.processing} />,
    },
    windowsV2: {
        "#Timeframe":           <RenovateGenPanel options={structure.timeframe} />,
        "#ResidenceType":       <RenovateGenPanel options={structure.residenceType} />,
        "#Type":                <RenovateGenPanel options={structure.typeWindowsV2} />,
        "#Project":             <RenovateGenPanel options={structure.projectScope} />,
        "#ProjectSize":         <RenovateGenPanel options={structure.projectSize} />,
        "#Address":             <RenovateGenPanel options={structure.address} />,
        "#Name":                <RenovateGenPanel options={structure.name} />,
        "#Contact":             <RenovateGenPanel options={structure.contact} />,
        "#Processing":          <RenovateGenPanel options={structure.processing} />,
    },
    windowsTradeshow: {
        "#Timeframe":           <RenovateGenPanel options={structure.timeframe} />,
        "#ResidenceType":       <RenovateGenPanel options={structure.residenceType} />,
        "#Type":                <RenovateGenPanel options={structure.typeWindows} />,
        "#Project":             <RenovateGenPanel options={structure.projectScope} />,
        "#ProjectSize":         <RenovateGenPanel options={structure.projectSize} />,
        "#Processing":          <RenovateGenPanel options={structure.processing} />,
    },
};

export default class RenovateIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (!CoreTools.state.appOptions.includes("nocss")) {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
            this.importStyle("/css/Renovate.css");
            this.importStyle("/css/animate.css");
        }
        if (CoreTools.state.appOptions.includes("kiosk") && CoreTools.state.appOptions.includes("philly") && !this.props.isChild) {
            window.addEventListener("click", () => this.resetTimer());
            window.addEventListener("mousemove", () => this.resetTimer());
        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider2.css");
    })();

    onMount = (state, props, applicant) => {
        this.onNavigate(location => this.setVal({hash: location.hash}, () => GoogleTagManager.event({event: "first_page", pageName: this.state.hash || "#Residence"})));
    };

    onApplicant = (state, props, applicant) => {
        CoreTools.switch(
            [props.version === "bathroom",  () => applicant.setField("service_type", "Bathroom Remodel")],
            [props.version === "gutters",   () => applicant.setField("service_type", "Gutters")],
            [props.version === "hotTub",    () => applicant.setField("service_type", "HotTub")],
            [props.version === "solar",     () => applicant.setField("service_type", "Solar")],
            [props.version === "philly",    () => applicant.setField("service_type", "Philly")],
            [props.version === "roofing",   () => applicant.setField("service_type", "Roofing")],
            [props.version === "roofingV2", () => applicant.setField("service_type", "Roofing")],
            [props.version === "savannah",  () => applicant.setField("service_type", "Savannah")],
            [props.version === "tradeshow", () => applicant.setField("service_type", "Tradeshow")],
            [props.version === "windows",   () => applicant.setField("service_type", "Windows")],
            [props.version === "windowsV2", () => applicant.setField("service_type", "Windows")],
        );
        this.setVal({loaded: true});
    };

    resetTimer = () => {
        clearTimeout(this.timeoutID);
        if(!this.state.warning) {
            this.timeoutID = setTimeout(() => {
                this.setVal({warning: true});
            }, 30000);
        };
    };

    continue = () => {
        this.setVal({warning: false}, this.resetTimer);
    };

    cancel = (url) => {
        this.applicant.post("homeservices-us-tradeshow", "submit", {});
        clearTimeout(this.timeoutID);
        CoreTools.memory.set("ckey", "");
        setTimeout(() => window.location.href = url || "/philly/", 500); 
    };

    content = (state, props) => state.loaded && <>
        <PanelEffects.ProgressBar
            caption=""
            barColor="#40AD87"
            className="progress-bar"
            style={{
                borderStyle: "solid",
                borderWidth: "2px", 
                borderColor: "#ffffff",
                backgroundColor: "rgb(233, 236, 239)",
                borderRadius: "5px", 
                padding: "2px", 
                fontSize: "13px",
                margin: "0 10px"
            }}
        />
        <PanelEffects.Transition1 className="panel-container">
            <div id="appForm" style={{marginTop: "15px"}} key={state.hash}>
                <div className="appForm_inner">
                    <TrustedFormCert>
                        <PanelEffects.Selector
                            selector={state.hash}
                            panels={CoreTools.switch(
                                [props.version === "bathroom",              () => versions.bathroom],
                                [props.version === "bathroomTradeshow",     () => versions.bathroomTradeshow],
                                [props.version === "gutters",               () => versions.gutters],
                                [props.version === "guttersTradeshow",      () => versions.guttersTradeshow],
                                [props.version === "hotTubTradeshow",       () => versions.hotTubTradeshow],
                                [props.version === "philly",                () => versions.philly],
                                [props.version === "roofing",               () => versions.roofing],
                                [props.version === "roofingTradeshow",      () => versions.roofingTradeshow],
                                [props.version === "roofingV2",             () => versions.roofingV2],
                                [props.version === "savannah",              () => versions.savannah],
                                [props.version === "solarTradeshow",        () => versions.solarTradeshow],
                                [props.version === "tradeshow",             () => versions.tradeshow],
                                [props.version === "windows",               () => versions.windows],
                                [props.version === "windowsTradeshow",      () => versions.windowsTradeshow],
                                [props.version === "windowsV2",             () => versions.windowsV2],
                                [props.version === "walkIn",                () => versions.walkIn],
                                [props.version === "walkInTubsTradeshow",   () => versions.walkInTubsTradeshow],
                            )} 
                        />
                    </TrustedFormCert>
                </div>
            </div>
        </PanelEffects.Transition1>
        {CoreTools.switch(
            [state.warning, () => <Warning continue={this.continue} cancel={this.cancel} url="/philly/" />],
        )}

        {["philly"].includes(this.props.version) && <ResetButton />}
    </>;
}
