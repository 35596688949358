import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import HeFunctions from "../inserts/HeFunctions";
import HeComponents from "../inserts/HeComponents";

export default class HePropertyValue extends GenSet {
    continue = () => {
        this.applicant.setField('mortgage_balance',this.applicant.field("property_value_range"));
        this.navigate(this.props.continue);
    };

    content = (state, props) => <HeComponents.Card
        title="What is the estimated value of your property?"
        controls={<HeComponents.Controls continue={this.continue} buttonText={props.buttonText} disclaimer={props.disclaimer} />}
    >
        <div className="form-row">
            <div className="col-12">
                <AppInput.Select 
                    type="text"
                    name="property_value_range" 
                    options={[
                        ...HeFunctions.createDropdowns(90000,200000,5000),
                        ...HeFunctions.createDropdowns(200000,400000,10000),
                        ...HeFunctions.createDropdowns(400000,1000000,20000),
                        {value: "1500000", caption: "$1.0M - $1.5M"},
                        {value: "2000000", caption: "$1.51M - $2.0M"},
                        {value: "2000001", caption: "$2.0M or more"},
                    ]} 
                    className="form-control" 
                    onValidate={v => v} 
                    failClass="invalid" 
                />	
            </div>
        </div>
    </HeComponents.Card>;
}