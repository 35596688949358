import FormValidate from "../../services/FormValidate";
import LoanzFormData from "./LoanzFormData";

const LoanzStructureCA = {
    addressCA: {
        title: "What Is Your Home Address?",
        subtitle: "Enter your physical address in the box below to quickly autocomplete all required fields.",
        address: "ca",
        continue: {caption: "Continue"},
    },
    amount: {
        title: "How Much Would You Like To Borrow?",
        fields: [
            {type: "radio", name: "loan_amount", values: LoanzFormData.amounts}
        ]
    },
    consent: {
        title: "Congratulations, Great Almost There!",
        subtitle: "Based on the information you have provided, you may qualify for a loan! There are just a few last steps to complete to get you your cash today.",
        fields: [
            {type: "consent"},
        ],
        consent: "true",
        continue: {caption: "Continue"},
    },
    creditCard: {
        title: "How Much Credit Card Debt Do You Have?",
        range: {name: "credit_card", min: "0", max: "100000", step: "250", defaultValue: "10000", currency: "$"},
        continue: {caption: "Continue"},
    },
    currentStatus: {
        title: "Are You Currently In:",
        fields: [
            {type: "radio", name: "current_status", values: LoanzFormData.currentStatus}
        ]
    },
    debtRelief: {
        title: "You May Qualify To Cut Your Debt By Up To 80%",
        subtitle: "So we don't seem to have any lenders that can pre-approve you at this stage, BUT the good news is we can see you have a large amount of unsecured debt (if over 10k) and we have partners on stand by that are confident they can cut your credit card debt by up to 80%",
        fields: [
            {type: "radio", name: "debt_relief", values: LoanzFormData.currentStatus}
        ]
    },
    debtCall: {
        title: "When Is The Best Time To Talk To Our Debt Relief Team?",
        fields: [
            {type: "radio", name: "call_available", values: LoanzFormData.debtCall}
        ]
    },
    email: {
        title: "Where Can We Email Your Loan Options?",
        fields: [
            {type: "input", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail}
        ],
        continue: {caption: "Continue"},
    },
    mogo: {
        title: "Congratulations, Almost There!",
        image: "true",
        subtitle: "While we wish we could help everyone, we have still found you a great option to help you get the funds you need!",
        fields: [
            {type: "mogo"},
        ],
        comtinue: "Complete Your Application",
    },
    name: {
        title: "What Is Your Name?",
        subtitle: "Please enter your First and Last Name as it appears on your drivers licence.",
        fields: [
            {type: "input", name: "first_name", placeholder: "First Name", onValidate: FormValidate.isName},
            {type: "input", name: "last_name", placeholder: "Last Name", onValidate: FormValidate.isName}
        ],
        continue: {caption: "Continue"},
    },
    phone: {
        title: "How Can We Reach You?",
        subtitle: "When you tap Continue, Loanz will text or call with a verification code. Message and data rates may apply.",
        fields: [
            {type: "phone", name: "phone", placeholder: "(123) 456 7890", countryCode: "+1", SMS: "true", onValidate: FormValidate.isPhone, onChange: FormValidate.phoneFormat}
        ],
        continue: {caption: "Continue"},
    },
    processingCA: {
        title: "We are processing your application.",
        processing: "ca",
        processingAnimation: 'true',
    },
    reason: {
        title: "What Do You Need The Money For?",
        fields: [
            {type: "radio", name: "loan_reason", values: LoanzFormData.reasons}
        ]
    },
    residenceType: {
        title: "Do You Own Or Rent Your Home?",
        fields: [
            {type: "radio", name: "residence_type", values: LoanzFormData.homeStatus}
        ]
    },
    rentAmount: { 
        title: "What Are Your Monthly (Rent Or Mortgage) Payments?",
        subtitle: "Make sure to only enter the part you pay for if renting.", 
        range: {name: "rent_amount", min: "0", max: "5000", step: "25", defaultValue: "1000", currency: "$"},
        continue: {caption: "Continue"},
    },
    sin: {
        title: "What Is Your Social Insurance Number?",
        subtitle: "SIN is not required, however, providing your SIN may help to speed up the loan approval process.",
        fields: [
            {type: "SIN", name: "sin", text: "Yes, I would like to provide my SIN.", onChange: FormValidate.socialInsuranceNumberFormat, onKeydown: FormValidate.ukPhone}
        ],
        continue: {caption: "Continue"},
    },
    unsecuredDebt: {    
        title: "How Much Total Unsecured Debt Do You Have?",
        subtitle: "Enter any other unsecured debt you may have (Credit Card, Payday Loans, Personal Loans, Line of Credit (unsecured), Student Loans, CERB, Any Government Taxes (GST/HST), Payroll Taxes, Personal Taxes to CRA, etc.)", 
        range: {name: "unsecured_debt", min: "0", max: "100000", step: "250", defaultValue: "10000", currency: "$"},
        continue: {caption: "Continue"},
    },
    verification: {
        title: "Banking Verification",
        subtitle: "By choosing to manually upload your statements you will NOT be able to get an instant approval and instant payout in your account today",
        fields: [
            {type: "button", className:"banking-verification-button", caption: "YES - I want to be approved right away (I will do real-time bank verification)", onClick: () => this.navigate("#Institution")},
            {type: "bouncingText", text: "637 people chose to do real-time verification today!"},
            {type: "button", className:"banking-verification-button", caption: "NO - I still want to manually upload my bank information and I am okay waiting for an approval for 3-5 days", onClick: () => this.navigate("#Employer")},
        ]
    },  
};

export default LoanzStructureCA;
