import CoreTools from "../../../services/CoreTools";
import GenSet from "../../../services/GenSet";

export default class CLRFlatFormProcessing extends GenSet {
    onApplicant = (state, props, applicant) => CoreTools.switch(
        [!applicant.field("insurance_who"),                     () => this.navigate("#Who")],
        [!applicant.field("insurance_cover"),                   () => this.navigate("#Coverage")],
        [!applicant.field("gender"),                            () => this.navigate("#Gender")],
        [!applicant.field("insurance_amount"),                  () => this.navigate("#Amount")],
        [!applicant.field("insurance_type"),                    () => this.navigate("#Type")],
        [!applicant.field("smoking_status"),                    () => this.navigate("#Smoke")],
        [!applicant.field("first_name"),                        () => this.navigate("#Flatform")],
        [!applicant.field("last_name"),                         () => this.navigate("#Flatform")],
        [!applicant.field("phone"),                             () => this.navigate("#Flatform")],
        [!applicant.field("email"),                             () => this.navigate("#Flatform")],
        // [!applicant.field("province"),                          () => this.navigate("#Flatform")],
        [!applicant.field("dob_day"),                           () => this.navigate("#Flatform")],
        [!applicant.field("dob_month"),                         () => this.navigate("#Flatform")],
        [!applicant.field("dob_year"),                          () => this.navigate("#Flatform")],
        [true,                                                  () => applicant.post("canadianliferates", "submit", {})]
    );

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <div className="day-fld-box">
                <h3 className="text-center">We are processing your submission...</h3>
            </div>
            <div className="row">
                <img className="col-12" src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" />
            </div>
        </div>
    </div>;
}

