import Applicant from "../services/Applicant";
import GenSet from "../services/GenSet";
import CoreTools from "../services/CoreTools";
import React from "react";

const Input = props => {
    const formSource = props.subForm || Applicant();
    const [className, setClassName] = React.useState(typeof(props.className) === "string" ? props.className : "");
    const [value, setValue] = React.useState((() => {
        let currentValue = formSource.field(props.name);
        if (currentValue === undefined && props.defaultValue !== undefined) {
            currentValue = props.defaultValue;
            formSource.setField(props.name, currentValue);
        }
        return CoreTools.switch(
            [[undefined, null, "text", "email", "tel"].includes(props.type) && typeof(currentValue) === "string" , () => currentValue],
            [[undefined, null, "text", "email", "tel"].includes(props.type) && ["number", "boolean"].includes(typeof(currentValue)), () => currentValue.toString()],
            [[undefined, null, "text", "email", "tel"].includes(props.type), ""],
            [["number", "range"].includes(props.type) && typeof(currentValue) === "number", () => currentValue],
            [["number", "range"].includes(props.type) && typeof(currentValue) === "string", () => Number(currentValue) || 0],
            [["number", "range"].includes(props.type) && typeof(currentValue) === "boolean", () => currentValue ? 1 : 0],
            [["number", "range"].includes(props.type), 0],
            [true, ""]
        );
    })());

    let iRef = null;
    
    React.useEffect(() => {
        if (typeof(props.onValidate) === "function") {
            const valWatcher = formSource.valWatch(() => {
                if (iRef && iRef.value !== value) {
                    if (typeof(props.onChange) === "function") {props.onChange({target: iRef});}
                    setValue(iRef.value);
                    formSource.setField(props.name, iRef.value);
                }
                if (props.onValidate(value)) {
                    if (typeof(props.failClass) === "string" && props.failClass !== "" && className.split(" ").includes(props.failClass)) {
                        setClassName(className.split(" ").filter(c => c !== "" && c !== props.failClass).join(" "));
                    }
                    return true;
                } else {
                    if (typeof(props.failClass) === "string" && props.failClass !== "" && !className.split(" ").includes(props.failClass)) {
                        setClassName([...className.split(" "), props.failClass].filter(c => c !== "").join(" "));
                    }
                    return false;
                }
            });
            return valWatcher;
        }
    });

    React.useEffect(() => {
        const fieldWatcher = formSource.onFieldUpdate(props.name, newValue => {
            if (iRef && iRef.value !== newValue) {
                iRef.value = newValue;
                let event = {target: iRef};
                if (typeof(props.onChange) === "function") {props.onChange(event);}
                setValue(event.target.value);
            } else {
                setValue(newValue);
            }
        });
        return fieldWatcher;
    });

    const setRef = ref => {
        if (ref) {
            iRef = ref;
            if (typeof(props.onLoad) === "function") {setTimeout(() => props.onLoad({target: iRef}));}
        }
    };

    const events = {
        onBlur: event => {
            if (typeof(props.onBlur) === "function") {props.onBlur(event);}
            setValue(event.target.value);
            formSource.setField(props.name, event.target.value);
            if (typeof(props.onPost) === "function") {props.onPost(event);}
        },
        onChange: event => {
            if (typeof(props.onChange) === "function") {props.onChange(event);}
            setValue(event.target.value);
        },
        onInput: event => {
            if (typeof(props.onChange) === "function") {props.onChange(event);}
            setValue(event.target.value);
        },
        onFocus: event => {
            if (typeof(props.failClass) === "string" && props.failClass !== "" && className.split(" ").includes(props.failClass)) {
                setClassName(className.split(" ").filter(c => c !== "" && c !== props.failClass).join(" "));
            }
            if (typeof(props.onFocus) === "function") {props.onFocus(event);}
        },
        onClick: event => {
            event.target.focus();
            if (typeof(props.onClick) === "function") {props.onClick(event);}
        }
    };
    
    return <input
        ref={setRef}
        title={props.title}
        placeholder={props.placeholder}
        type={props.type || "text"}
        name={props.name}
        className={className}
        style={props.style}
        onBlur={events.onBlur}
        onFocus={events.onFocus}
        onChange={props.onChange}
        onClick={events.onClick}
        onKeyDown={props.onKeyDown}
        onKeyUp={props.onKeyUp}
        defaultValue={value}
        min={props.min}
        max={props.max}
        step={props.step}
    />;
};

class Range extends GenSet {
    iRef = null;
    updateTimer = null;
    formSource = this.props.subForm || Applicant();
    state = {
        className: typeof(this.props.className) === "string" ? this.props.className : ""
    };
    value = (() => {
        const appValue = this.formSource.field(this.props.name);
        return appValue !== undefined ? appValue : this.props.defaultValue;
    })();
    onMount = (state, props) => {
        if (typeof(props.onValidate) === "function") {
            this.wHolder(this.formSource.valWatch(() => {
                if (props.onValidate(this.value)) {
                    if (typeof(props.failClass) === "string" && props.failClass !== "" && this.state.className.split(" ").includes(props.failClass)) {
                        this.setVal({className: this.state.className.split(" ").filter(c => c !== "" && c !== props.failClass).join(" ")});
                    }
                    return true;
                } else {
                    if (typeof(props.failClass) === "string" && props.failClass !== "" && !this.state.className.split(" ").includes(props.failClass)) {
                        this.setVal({className: [...this.state.className.split(" "), props.failClass].filter(c => c !== "").join(" ")});
                    }
                    return false;
                }
            }));
        }
        this.wHolder(this.formSource.onFieldUpdate(props.name, value => {
            this.value = value;
            if (this.iRef && this.iRef.value !== value) {this.iRef.value = value;}
            if (typeof(this.props.onChange) === "function") {this.props.onChange({target: this.iRef});}
            this.formSource.setField(props.name, this.value);
        }));
        if (typeof(props.onLoad) === "function") {setTimeout(() => props.onLoad({target: this.iRef}));}
        setTimeout(() => this.iRef && this.iRef.value && this.formSource.setField(this.props.name, this.iRef.value), 1000);
    };
    onUnmount = (state, props) => {
        clearTimeout(this.updateTimer);
        this.formSource.setField(this.props.name, this.value);
    };
    onBlur = event => {
        if (typeof(this.props.onBlur) === "function") {this.props.onBlur(event);}
        this.value = event.target.value;
        clearTimeout(this.updateTimer);
        this.formSource.setField(this.props.name, event.target.value);
        if (typeof(this.props.onPost) === "function") {this.props.onPost(event);}
    };
    onChange = event => {
        if (typeof(this.props.onChange) === "function") {this.props.onChange(event);}
        this.value = event.target.value;
        clearTimeout(this.updateTimer);
        this.updateTimer = setTimeout(() => this.formSource.setField(this.props.name, this.value), 500);
    };
    onFocus = event => {
        if (typeof(this.props.failClass) === "string" && this.props.failClass !== "" && this.state.className.split(" ").includes(this.props.failClass)) {
            this.setVal({className: this.state.className.split(" ").filter(c => c !== "" && c !== this.props.failClass).join(" ")});
        }
        if (typeof(this.props.onFocus) === "function") {this.props.onFocus(event);}
    };
    onClick = event => {
        event.target.focus();
        if (typeof(this.props.onClick) === "function") {this.props.onClick(event);}
    };
    content = (state, props) => <input
        ref={r => this.iRef = r}
        title={props.title}
        placeholder={props.placeholder}
        type="range"
        name={props.name}
        className={state.className}
        style={props.style}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onChange={this.onChange}
        onClick={this.onClick}
        defaultValue={this.value}
        min={props.min}
        max={props.max}
        step={props.step}
    />;
}

class Select extends GenSet {
    state = {
        className: typeof(this.props.className) === "string" ? this.props.className : ""
    };
    formSource = this.props.subForm || Applicant();
    iRef = null;
    value = (() => {
        let value = this.formSource.field(this.props.name);
        if (value === undefined && this.props.defaultValue !== undefined) {
            value = this.props.defaultValue;
            this.formSource.setField(this.props.name, value);
        }
        return CoreTools.switch(
            [typeof(value) === "string" , () => value],
            [["number", "boolean"].includes(typeof(value)), () => value.toString()],
            [true, ""]
        );
    })();
    onMount = (state, props) => {
        if (typeof(props.onValidate) === "function") {
            this.wHolder(this.formSource.valWatch(() => {
                if (props.onValidate(this.value)) {
                    if (typeof(props.failClass) === "string" && props.failClass !== "" && this.state.className.split(" ").includes(props.failClass)) {
                        this.setVal({className: this.state.className.split(" ").filter(c => c !== "" && c !== props.failClass).join(" ")});
                    }
                    return true;
                } else {
                    if (typeof(props.failClass) === "string" && props.failClass !== "" && !this.state.className.split(" ").includes(props.failClass)) {
                        this.setVal({className: [...this.state.className.split(" "), props.failClass].filter(c => c !== "").join(" ")});
                    }
                    return false;
                }
            }));
        }
        this.wHolder(this.formSource.onFieldUpdate(props.name, value => {
            this.value = value;
            if (this.iRef && this.iRef.value !== value) {this.iRef.value = value;}
        }));
        if (typeof(props.onLoad) === "function") {setTimeout(() => props.onLoad({target: this.iRef}));}
    };
    onChange = event => {
        if (typeof(this.props.onChange) === "function") {this.props.onChange(event);}
        this.formSource.setField(this.props.name, event.target.value);
        this.value = event.target.value;
    };
    onFocus = event => {
        if (typeof(this.props.failClass) === "string" && this.props.failClass !== "" && this.state.className.split(" ").includes(this.props.failClass)) {
            this.setVal({className: this.state.className.split(" ").filter(c => c !== "" && c !== this.props.failClass).join(" ")});
        }
        if (typeof(this.props.onFocus) === "function") {this.props.onFocus(event);}
    };
    content = (state, props) => <select
        size={props.size}
        ref={r => this.iRef = r}
        title={props.title}
        type={props.type || "text"}
        name={props.name}
        className={state.className}
        style={props.style}
        onChange={this.onChange}
        onClick={props.onClick}
        onFocus={this.onFocus}
        onBlur={props.onBlur}
        defaultValue={this.value}
    >{(props.options || []).map(
        o => CoreTools.isObject(o) ? <option key={o.key || o.value} value={o.value} disabled={o.disabled || false}>{typeof(o.caption) === "string" ? o.caption : o.value}</option> : <option key={o} value={o}>{o}</option>
    )}</select>;
}

const Radio = props => {
    const formSource = props.subForm || Applicant();
    const [checked, setChecked] = React.useState(formSource.field(props.name) === props.value);
    React.useEffect(() => {
        const fieldWatch = formSource.onFieldUpdate(props.name, value => setChecked(value === props.value));
        return fieldWatch;
    });
    const onClick = event => {
        formSource.setField(props.name, props.value);
        if (typeof(props.onClick) === "function") {setTimeout(() => props.onClick(event));}
    };
    return <input
        title={props.title}
        type="radio"
        name={props.name}
        className={props.className}
        style={props.style}
        defaultChecked={checked}
        onClick={onClick}
    />;
};

class CheckBox extends GenSet {
    cbRef = null;
    formSource = this.props.subForm || this.applicant;
    onApplicant = (state, props, applicant) => {
        this.wHolder(this.formSource.onFieldUpdate(props.name, value => this.cbRef.checked = value ? true : false));
        this.cbRef.checked = this.formSource.field(props.name) ? true : false;
    };
    onClick = event => {
        this.formSource.setField(this.props.name, event.target.checked);
        if (typeof(this.props.onClick) === "function") {setTimeout(() => this.props.onClick(event));}
    };
    content = (state, props, applicant) => <input
        title={props.title}    
        type="checkbox"
        name={props.name}
        className={props.className}
        style={props.style}
        onClick={this.onClick}
        ref={r => this.cbRef = r}
    />;
}

const AppInput = {
    Input: Input,
    Select: Select,
    Radio: Radio,
    Range: Range,
    CheckBox: CheckBox
};

export default AppInput;
