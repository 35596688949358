import GenSet from "../../services/GenSet";
import CoreTools from "../../services/CoreTools"
import HeLoanAmount from "./panels/HeLoanAmount";
import HeResidenceType from "./panels/HeResidenceType";
import HePropertyValue from "./panels/HePropertyValue";
import HeMortgageBalance from "./panels/HeMortgageBalance";
import HeInterestedIn from "./panels/HeInterestedIn";
import HeAdditionalCash from "./panels/HeAdditionalCash";
import HeCcDebtAmount from "./panels/HeCcDebtAmount";
import HeUnsecuredDebtAmount from "./panels/HeUnsecuredDebtAmount";
import HeUnsecuredDebtAmountUS from "./panels/HeUnsecuredDebtAmountUS";
import HeAddressInformation from "./panels/HeAddressInformation";
import HeAddressInformationUS from "./panels/HeAddressInformationUS";
import HeName from "./panels/HeName";
import HeEmail from "./panels/HeEmail";
import HeContact from "./panels/HeContact";
import HeProcessing from "./panels/HeProcessing";
import PanelEffects from "../../directives/PanelEffects";

export default class HeIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (!CoreTools.state.appOptions.includes("nocss")) {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
            this.importStyle("/css/HomeEquity.css");
            this.importStyle("/css/animate.css");
            this.importStyle("/css/rangeslider2.css");
        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        // this.wHolder(this.applicant.on("hasBuyer", data => {
        //     console.log("hasBuyer", data);
        //     this.navigate(`/thank-you/?lid=${data.leadId}`, 500);
        //     // if (data.isSold) {
        //     //     //Has Sold
        //     //     this.navigate(`/thank-you/id-${data.buyerId}/?lid=${data.leadId}`, 500);
        //     // } else {
        //     //     //Not Sold
        //     //     this.navigate(`/thank-you/?lid=${data.leadId}`, 500);
        //     // }
        // }));
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}));
    };

    panelsOriginal = {
        "#LoanAmount":                      <HeLoanAmount />,
        "#ResidenceType":                   <HeResidenceType title="Are you a homeowner or a tenant?" />,
        "#PropertyValue":                   <HePropertyValue />,
        "#MortgageBalance":                 <HeMortgageBalance />,
        "#InterestedIn":                    <HeInterestedIn />,
        "#AdditionalCash":                  <HeAdditionalCash />,
        "#CcDebtAmount":                    <HeCcDebtAmount />,
        "#UnsecuredDebtAmount":             <HeUnsecuredDebtAmount />,
        "#AddressInformation":              <HeAddressInformation />,
        "#Name":                            <HeName />,
        "#Email":                           <HeEmail />,
        "#Phone":                           <HeContact title="Last step!" buttonText="Complete My Application" disclaimer={true} />,
        "#Processing":                      <HeProcessing />,
    };

    panelsOriginalUS = {
        "#LoanAmount":                      <HeLoanAmount />,
        "#ResidenceType":                   <HeResidenceType title="Are you a homeowner or a tenant?" />,
        "#PropertyValue":                   <HePropertyValue />,
        "#MortgageBalance":                 <HeMortgageBalance />,
        "#InterestedIn":                    <HeInterestedIn />,
        "#AdditionalCash":                  <HeAdditionalCash />,
        "#CcDebtAmount":                    <HeCcDebtAmount />,
        "#UnsecuredDebtAmount":             <HeUnsecuredDebtAmountUS />,
        "#AddressInformation":              <HeAddressInformationUS />,
        "#Name":                            <HeName />,
        "#Email":                           <HeEmail />,
        "#Phone":                           <HeContact title="Last step!" buttonText="Complete My Application" disclaimer={true} />,
        "#ProcessingUS":                      <HeProcessing />,
    };

    panelsOriginalDropdowns = {
        "#LoanAmount":                      <HeLoanAmount dropdown={true} />,
        "#ResidenceType":                   <HeResidenceType title="Are you a homeowner or a tenant?" />,
        "#PropertyValue":                   <HePropertyValue />,
        "#MortgageBalance":                 <HeMortgageBalance />,
        "#InterestedIn":                    <HeInterestedIn />,
        "#AdditionalCash":                  <HeAdditionalCash dropdown={true} />,
        "#CcDebtAmount":                    <HeCcDebtAmount dropdown={true} />,
        "#UnsecuredDebtAmount":             <HeUnsecuredDebtAmount dropdown={true} />,
        "#AddressInformation":              <HeAddressInformation />,
        "#Name":                            <HeName />,
        "#Email":                           <HeEmail />,
        "#Phone":                           <HeContact smsVerify={true} title="Last step!" buttonText="Complete My Application" disclaimer={true} />,
        "#Processing":                      <HeProcessing />,
    };
    panelsShort = {
        "#ResidenceType":                   <HeResidenceType title="Let's get your free report over to you!" />,
        "#AddressInformation":              <HeAddressInformation />,
        "#Name":                            <HeName />,
        "#Contact":                         <HeContact email={true} title="Please enter your contact details:" buttonText="Complete My Application" disclaimer={true} />,
        "#Processing":                      <HeProcessing />,
    };
    panelsShort2 = {
        "#ResidenceType":                   <HeResidenceType title="Let's get your free report over to you!" />,
        "#AddressInformation":              <HeAddressInformation />,
        "#PropertyValue":                   <HePropertyValue />,
        "#MortgageBalance":                 <HeMortgageBalance />,
        "#AdditionalCash":                  <HeAdditionalCash />,
        "#Name":                            <HeName />,
        "#Contact":                         <HeContact email={true} title="Please enter your contact details:" buttonText="Complete My Application" disclaimer={true} />,
        "#Processing":                      <HeProcessing />,
    };
    panelsShortSms = {
        "#ResidenceType":                   <HeResidenceType title="Lets get your free report over to you!" />,
        "#AddressInformation":              <HeAddressInformation />,
        "#Name":                            <HeName />,
        "#Contact":                         <HeContact email={true} smsVerify={true} title="Please enter your contact details:" buttonText="Complete My Application" disclaimer={true} />,
        "#Processing":                      <HeProcessing />,
    };
    panelsShortToLong = {
        "#AddressInformation":              <HeAddressInformation />,
        "#Name":                            <HeName />,
        "#Email":                           <HeEmail />,
        "#LoanAmount":                      <HeLoanAmount />,
        "#ResidenceType":                   <HeResidenceType title="Are you a homeowner or a tenant?" />,
        "#PropertyValue":                   <HePropertyValue />,
        "#MortgageBalance":                 <HeMortgageBalance />,
        "#InterestedIn":                    <HeInterestedIn />,
        "#AdditionalCash":                  <HeAdditionalCash />,
        "#CcDebtAmount":                    <HeCcDebtAmount />,
        "#UnsecuredDebtAmount":             <HeUnsecuredDebtAmount />,
        "#Phone":                           <HeContact title="Last step!" buttonText="Complete My Application" disclaimer={true} />,
        "#Processing":                      <HeProcessing />,
    };
    panelsShortToLongDropdowns = {
        "#AddressInformation":              <HeAddressInformation />,
        "#Name":                            <HeName />,
        "#Email":                           <HeEmail />,
        "#Phone":                           <HeContact title="Last step!" disclaimer={true} />,
        "#LoanAmount":                      <HeLoanAmount dropdown={true} />,
        "#ResidenceType":                   <HeResidenceType title="Are you a homeowner or a tenant?" />,
        "#PropertyValue":                   <HePropertyValue />,
        "#MortgageBalance":                 <HeMortgageBalance />,
        "#InterestedIn":                    <HeInterestedIn />,
        "#AdditionalCash":                  <HeAdditionalCash dropdown={true} />,
        "#CcDebtAmount":                    <HeCcDebtAmount dropdown={true} />,
        "#UnsecuredDebtAmount":             <HeUnsecuredDebtAmount buttonText="Complete My Application" dropdown={true} />,
        "#Processing":                      <HeProcessing />,
    };

    content = (state, props) => <div id="appForm" className="overcard v1">
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <div className="appForm_inner">
            <PanelEffects.ProgressBar
                caption="Progress: "
                barColor="#007bff"
                style={{
                    borderStyle: "solid",
                    borderWidth: "2px", 
                    borderColor: "#ffffff",
                    backgroundColor: "rgb(233, 236, 239)",
                    borderRadius: "5px", 
                    padding: "2px", 
                    margin: "20px", 
                    fontSize: "13px"
                }}
            />
            <PanelEffects.Transition3>
                <PanelEffects.Selector
                    selector={state.hash}
                    panels={CoreTools.switch(
                        [props.version === "original", this.panelsOriginal],
                        [props.version === "originalUS", this.panelsOriginalUS],
                        [props.version === "originalDropdowns", this.panelsOriginalDropdowns],
                        [props.version === "short", this.panelsShort],
                        [props.version === "short2", this.panelsShort2],
                        [props.version === "shortSms", this.panelsShortSms],
                        [props.version === "shortToLong", this.panelsShortToLong],
                        [props.version === "shortToLongDropdowns", this.panelsShortToLongDropdowns],
                    )} 
                />
            </PanelEffects.Transition3>
        </div>
    </div>;
}
