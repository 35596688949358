import React from "react";
import CoreTools from "../../services/CoreTools";
import BuildLogin from "./BuildLogin";
import BuildSocket from "./BuildSocket";
import GenSet from "../../services/GenSet";

const Tab = props => <button className="tab" onClick={() => props.showMenu()}>Funnel Manager</button>;

const Menu = props => {
    const socket = BuildSocket();

    return <div className="menu">
        <div className="banner" onClick={() => props.hideMenu()}>Funnel Manager</div>
        {socket.state.admin && <div className="body"></div>}
        {!socket.state.admin && <BuildLogin />}
    </div>;
};

export default class BuildManager extends GenSet {
    init = (() => {
        if (!CoreTools.state.admin) {CoreTools.state.admin = {};}
        this.importStyle("/css2/BuildManager.css");
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.setVal({showMenu: false});
    })();
    socket = BuildSocket();
    
    content = (state, props) => state.showMenu ? <Menu hideMenu={() => this.setVal({showMenu: false})} /> : <Tab showMenu={() => this.setVal({showMenu: true})} />;
};