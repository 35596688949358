import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import OptionLists from "../../../services/OptionLists";
import CoreTools from "../../../services/CoreTools";

export default class SurexLicenceInfo extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };
    
    options = {
        licYears: (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year; i > year - 100; i--) {years.push(i.toString());} return years;})(),
        months: (() => {let months = [{value: "", caption: "Month"}]; for (let i = 1; i <= 12; i++) {months.push(i.toString());} return months;})(),
        days: (() => {let days = [{value: "", caption: "Day"}]; for (let i = 1; i <= 31; i++) {days.push(i.toString());} return days;})()
    };

    classes = () => [{caption: "Licence Class", value: ""}, ...(OptionLists.DriverClassesCanada.find(c => c.province === CoreTools.asString(this.applicant.field("state")).toUpperCase()) || {classes: []}).classes.map(c => ({caption: "Class " + c, value: c}))];

    content = (state, props) => <SurexComponents.Card
        title="Awesome! Can you give us some additional information about your Driver's licence?"
        controls={<SurexComponents.Controls continue={this.continue} />}
    >
        
        <div className="form-row">
            <div className="col-6 mb-2">
                <span>Licence Class</span>
            </div>
            <div className="col-6 mb-2">
                <span>Date Licensed</span>
            </div>
        </div>
        <div className="form-row">
            <div className="col-6 mb-2">
                <AppInput.Select name="licence_class" className="subForm form-control text-field" options={this.classes()} defaultValue="" onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-2 mb-2">
                <AppInput.Select name="licence_year" className="subForm form-control text-field" options={this.options.licYears} onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-2 mb-2">
                <AppInput.Select name="licence_month" className="subForm form-control text-field" options={this.options.months} onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-2 mb-2">
                <AppInput.Select name="licence_day" className="subForm form-control text-field" options={this.options.days} onValidate={v => v} failClass="invalid" />
            </div>
        </div>
    </SurexComponents.Card>;
                
}