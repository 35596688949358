import GenSet from "../../../services/GenSet";
import SurexComponents from "../inserts/SurexComponents";
import FormValidate from "../../../services/FormValidate";

export default class SurexDateBirth extends GenSet {
    inputRef = null;
    continue = () => {
        if (this.inputRef?.value && this.inputRef.value.length === 10) {
            const [day, month, year] = this.inputRef.value.split("/");
            this.applicant.setField("dob", `${year}-${month}-${day}`);
            this.navigate(this.props.continue);
        } else {
            this.inputRef.className += " invalid";
        }
    };
    
    onApplicant = (state, props, applicant) => {
        if (this.inputRef && applicant.field("dob")) {
            const [year, month, day] = applicant.field("dob").split("-");
            this.inputRef.value = `${day}/${month}/${year}`;
        }
    };

    content = (state, props) => <SurexComponents.Card
        title="When were you born?"
        controls={<SurexComponents.Controls continue={this.continue} />}
    >
        <div className="form-row justify-content-center">
            <div className="col-sm-6 mb-2">
                <label className="border-label">Date Of Birth (DD/MM/YYYY)</label>
                <input
                    type="text"
                    name="dob"
                    className="form-control text-field"
                    placeholder="DD/MM/YYYY"
                    onKeyUp={FormValidate.dobDDMMYYYYY}
                    ref={r => this.inputRef = r}
                    onClick={e => e.target.className = e.target.className.split(" ").filter(c => c !== "invalid").join(" ")}
                />
            </div>
        </div>
    </SurexComponents.Card>;
}
