import GenSet from "../services/GenSet";
import CoreTools from "../services/CoreTools";

export default class GoogleAddress extends GenSet {
    init = (() => {
        if (!CoreTools.state.googleLocationApi) {
            CoreTools.state.googleLocationApi = CoreTools.importScript(
              "https://maps.googleapis.com/maps/api/js?key=AIzaSyD-KMFZXB4lVhte9gDoL86ceR8tP_kRFFE&libraries=places",
              () => setTimeout(() => this.apiLoaded = true)
            );
        } else {
            this.apiLoaded = true;
        }
    })();
    
    renderPlace = place => {
        if (Array.isArray(place?.address_components) && typeof(this.props.onSelect) === "function") {
            CoreTools.log("Google Address Lookup:", place.address_components);
            this.props.onSelect({
                full: place.formatted_address || "",
                number: CoreTools.first(place.address_components.filter(p => p.types.includes("street_number")))(p => p.long_name) || "",
                street: CoreTools.first(place.address_components.filter(p => p.types.includes("route")))(p => p.long_name) || "",
                city: (
                    CoreTools.first(place.address_components.filter(p => p.types.includes("locality")))(p => p.long_name) || 
                    CoreTools.first(place.address_components.filter(p => p.types.includes("neighborhood")))(p => p.long_name) || 
                    CoreTools.first(place.address_components.filter(p => p.types.includes("postal_town")))(p => p.long_name) || 
                    CoreTools.first(place.address_components.filter(p => p.types.includes("sublocality")))(p => p.long_name) || 
                    CoreTools.first(place.address_components.filter(p => p.types.includes("administrative_area_level_2")))(p => p.long_name) || 
                    ""
                ),
                state: CoreTools.first(place.address_components.filter(p => p.types.includes("administrative_area_level_1")))(p => p.long_name) || "",
                stateShort: CoreTools.first(place.address_components.filter(p => p.types.includes("administrative_area_level_1")))(p => p.short_name) || "",
                province: CoreTools.first(place.address_components.filter(p => p.types.includes("administrative_area_level_1")))(p => p.long_name) || "",
                provinceShort: CoreTools.first(place.address_components.filter(p => p.types.includes("administrative_area_level_1")))(p => p.short_name) || "",
                country: CoreTools.first(place.address_components.filter(p => p.types.includes("country")))(p => p.long_name) || "",
                postalcode: CoreTools.first(place.address_components.filter(p => p.types.includes("postal_code")))(p => p.long_name) || "",
                zipcode: CoreTools.first(place.address_components.filter(p => p.types.includes("postal_code")))(p => p.long_name) || ""
            });
        }
    };

    onChange = event => {
        if (!this.target && this.apiLoaded) {
            this.target = event.target;
            const autoComplete = new window.google.maps.places.Autocomplete(this.target, {
                types: [this.props.type || "address"],
                componentRestrictions: {country: this.props.country || "ca"}
            });
            autoComplete.setFields(["address_components", "formatted_address"]);
            autoComplete.addListener("place_changed", () => this.renderPlace(autoComplete.getPlace()));
        }
        if (typeof(this.props.onChange) === "function") {
            this.props.onChange(event);
        }
    };

    content = (state, props) => <form autoComplete="off" onSubmit={e => e.preventDefault()}><input
        type="text"
        ref={r => r && typeof(props.onRef) === "function" && props.onRef(r)}
        className={props.className}
        defaultValue={props.defaultValue}
        style={props.style}
        title={props.title}
        placeholder={props.placeholder}
        onChange={this.onChange}
        onKeyDown={props.onKeyDown}
        onClick={props.onClick}
        autoComplete="off"
    /></form>;
}

/*
Supported Types
address,
postal_code

*/
