import PanelEffects from "../../directives/PanelEffects";
import CoreTools from "../../services/CoreTools";
import GenSet from "../../services/GenSet";
import RifcoGenPanel from "./panels/RifcoGenPanel";
import RifcoFormData from "./RifcoFormData";
import GoogleTagManager from "../../services/GoogleTagManager";
import FormValidate from "../../services/FormValidate";

export default class RifcoIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (!CoreTools.state.appOptions.includes("nocss")) {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
            this.importStyle("/css/Rifco.css");
            this.importStyle("/css/animate.css");
            this.importStyle("/css/rangeslider2.css");

        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider2.css");
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}, () => GoogleTagManager.event({event: "first_page", pageName: this.state.hash || "#Residence"})));
    };

    structure = {
        addressCA: {
            title: "What is your address?",
            addressCA: true,
            continue: {caption: "Continue"},
        },
        birthdate: {
            title: "What is your birthdate?",
            birthdate: true,
            continue: {caption: "Continue", disabled: () => !this.applicant.field("dob") },
        },
        contact: {
            title: "Congratulations your pre-approval is ready!",
            fields: [
                {type: "input", label: "First Name", name: "first_name", placeholder: "First Name", onValidate: FormValidate.isName},
                {type: "input", label: "Last Name", name: "last_name", placeholder: "Last Name", onValidate: FormValidate.isName},
                {type: "input", label: "Email", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail },
                {type: "phone", label: "Phone", name: "phone", placeholder: "(123) 456 7890", onValidate: FormValidate.isPhone, onChange: FormValidate.phoneFormat},
            ],
            continue: {caption: "Continue"},
        },
        currentAddress: {
            title: "How long have you lived at your current address?",
            fields: [
                {type: "radio", name: "residence_duration", values: RifcoFormData.currentAddress }, 
            ],
        },
        currentEmployer: {
            title: "Where are you working?",
            fields: [
                {type: "input", label: "Text", name: "company_name", placeholder: "Company Name", onValidate: v => v, onChange: FormValidate.onlyLetters },
                {type: "input", label: "Text", name: "job_title", placeholder: "Job Title" },
            ],
            continue: {caption: "Continue"},
        },
        currentEmployment: {
            title: "How long have you been earning this income?",
            fields: [
                {type: "radio", name: "employment_duration", values: RifcoFormData.currentEmployment }, 
            ],
        },
        currentRate: {
            title: "What is your current financing rate?",
            fields: [
                {type: "radio", name: "current_rate", values: RifcoFormData.currentRate }, 
            ],
        },
        employmentStatus: {
            title: "What is your employment status?",
            fields: [
                {type: "radio", name: "employment_status", values: RifcoFormData.employmentStatus }, 
            ],
        },
        income: {
            title: "What is your current monthly gross income?",
            fields: [
                {type: "radio", name: "gross_monthly_income", values: RifcoFormData.income }, 
            ],
        },
        processing: {
            processing: true,
        },
        redirect: {
            subtitle: true,
            continueCustom: {caption: "Take Me To CarsFast"},
        },
        refinancing: {
            title: "Do you have financing on your existing vehicle?",
            fields: [
                {type: "radioCustom", name: "refinancing", values: RifcoFormData.yesNo }, 
            ],           
        },
        residenceType: {
            title: "Are you a homeowner?", 
            fields: [
                {type: "radio", name: "residence_type", values: RifcoFormData.residenceType },  
            ],
        },
    };

    panels = {
      "#Refinancing":       <RifcoGenPanel options={this.structure.refinancing} />,
      "#Redirect":          <RifcoGenPanel options={this.structure.redirect} />,
      "#CurrentRate":       <RifcoGenPanel options={this.structure.currentRate} />,
      "#Birthdate":         <RifcoGenPanel options={this.structure.birthdate} />,
      "#Employment":        <RifcoGenPanel options={this.structure.employmentStatus} />,
      "#Income":            <RifcoGenPanel options={this.structure.income} />,
      "#Employee":          <RifcoGenPanel options={this.structure.currentEmployment} />,
      "#Employer":          <RifcoGenPanel options={this.structure.currentEmployer} />,
      "#Housing":           <RifcoGenPanel options={this.structure.residenceType} />,
      "#Address":           <RifcoGenPanel options={this.structure.addressCA} />,
      "#CurrentAddress":    <RifcoGenPanel options={this.structure.currentAddress} />,
      "#Contact":           <RifcoGenPanel options={this.structure.contact} />,
      "#Processing":        <RifcoGenPanel options={this.structure.processing} />,
    };

    content = (state, props) => <>
        <PanelEffects.ProgressBar
            barColor="#40AD87"
            className="progress-bar"
            textColorSecondary="#000"
            style={{
                borderStyle: "solid",
                borderWidth: "2px", 
                borderColor: "#fff",
                backgroundColor: "#fff",
                borderRadius: "5px", 
                padding: "2px", 
                fontSize: "13px",
                margin: "0 10px"
            }}
        />
        <PanelEffects.Transition1 className="panel-container">
            <div id="appForm" style={{marginTop: "15px"}} key={state.hash}>
                <div className="appForm_inner">
                    <PanelEffects.Selector
                        selector={state.hash}
                        panels={this.panels} 
                    />
                </div>
            </div>
        </PanelEffects.Transition1>
    </>;
}
