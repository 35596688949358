import GenSet from "../../services/GenSet";
import CoreTools from "../../services/CoreTools";
import NhpusPropertyType from "./panels/NhpusPropertyType";
import NhpusPropertyUse from "./panels/NhpusPropertyUse";
import NhpusFirstTime from "./panels/NhpusFirstTime";
// import NhpusFoundHome from "./panels/NhpusFoundHome";
import NhpusTimeline from "./panels/NhpusTimeline";
import NhpusCredit from "./panels/NhpusCredit";
import NhpusPropertyValue from "./panels/NhpusPropertyValue";
// import NhpusDownPayment  from "./panels/NhpusDownPayment";
// import NhpusAddressInformation from "./panels/NhpusAddressInformation";
import NhpusZipCode from "./panels/NhpusZipCode";
import NhpusContactInformation from "./panels/NhpusContactInformation";
import PanelEffects from "../../directives/PanelEffects";
import NhpusProcessing from "./panels/NhpusProcessing";
import NhpusMilitary from "./panels/NhpusMilitary";
import GoogleTagManager from "../../services/GoogleTagManager";
// import NhpusDateOfBirth from "./panels/NhpusDateOfBirth";

export default class NhpusIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (!CoreTools.state.appOptions.includes("nocss")) {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
            this.importStyle("/css/HomeLoansCanada.css");
            this.importStyle("/css/animate.css");
        }
        
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider2.css");

        this.wHolder(this.applicant.on("hasBuyer", data => {
            console.log("hasBuyer", data);
            if (data.isSold) {
                GoogleTagManager.event("Sold");
                this.navigate(`/thank-you/id-${data.buyerId}/`, 500);
            } else {
                GoogleTagManager.event("Not Sold");
                this.navigate("/thank-you/", 500);
            }
        }));
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}, () => GoogleTagManager.event({event: "first_page", pageName: this.state.hash || "#PropertyType"})));
    };

    // panels = [
    //     ["#PropertyType",        <NhpusPropertyType />,           f => f.property_type],
    //     ["#PropertyUse",         <NhpusPropertyUse />,            f => f.property_use],
    //     ["#FirstTime",           <NhpusFirstTime />,              f => f.first_time_home_buyer],
    //     ["#Timeline",            <NhpusTimeline />,               f => f.purchase_timeline],
    //     ["#Credit",              <NhpusCredit />,                 f => f.credit],
    //     ["#Military",            <NhpusMilitary />,               f => f.military],
    //     ["#PropertyValue",       <NhpusPropertyValue />,          f => f.property_value],
    //     //["#AddressInformation",  <NhpusAddressInformation />,     f => f.street_name && f.city && f.state && f.zip_code],
    //     ["#ZipCode",             <NhpusZipCode />,                f => f.zip_code],
    //     ["#ContactInformation",  <NhpusContactInformation />,     f => f.first_name && f.last_name && f.email && f.phone && f.consent],
    //     ["#Processing",          <NhpusProcessing />,             true]
    // ];

    panels = {
        "#PropertyType":        <NhpusPropertyType />,
        "#PropertyUse":         <NhpusPropertyUse />,
        "#FirstTime":           <NhpusFirstTime />,
        "#Timeline":            <NhpusTimeline />,
        "#Credit":              <NhpusCredit />,
        "#Military":            <NhpusMilitary />,
        "#PropertyValue":       <NhpusPropertyValue />,
        "#ZipCode":             <NhpusZipCode />,
        "#ContactInformation":  <NhpusContactInformation />,
        "#Processing":          <NhpusProcessing />
    };

    content = (state, props) => <>
        <PanelEffects.ProgressBar
            caption="Progress: "
            barColor="#40AD87"
            style={{borderStyle: "solid", borderWidth: "1px", borderColor: "#40AD87", borderRadius: "5px"}}
        />
        <PanelEffects.Transition3>
            <PanelEffects.Selector
                selector={state.hash}
                panels={this.panels} 
            />
        </PanelEffects.Transition3>
    </>;
}
