import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import JugglingDebtComponents from "../inserts/JugglingDebtComponents";

export default class JugglingDebtCredit extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };
    content = (state, props) => <JugglingDebtComponents.Card
        title="How is Your Credit?"
        text="★ Most people have good credit ★"
        controls={<JugglingDebtComponents.Controls continue={this.continue} buttonText={false} disclaimer={props.disclaimer} />}
        counter={props.counter}
    >
        <div className="form-row colored">
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="credit" value="Excellent" onClick={this.continue} />
                    <span>720+ Excellent</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="credit" value="Very Good" onClick={this.continue} />
                    <span>719-690 Very Good</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="credit" value="Good" onClick={this.continue} />
                    <span>689-640 Good</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="credit" value="Fair" onClick={this.continue} />
                    <span>639-560 Fair</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="credit" value="Poor" onClick={this.continue} />
                    <span>&lt;560 Poor</span>
                </label>
            </div>
        </div>
    </JugglingDebtComponents.Card>;
}