import Api from "./Api";
import CoreTools from "./CoreTools";

let secureCookie = false;
const SecureCookie = () => secureCookie || (() => {
    let cookie = CoreTools.memory.get("ckey") || null;
    let confirmed = false;
    const cookieWatchers = [];

    Api("https://form.consumergenius.com/api/form/secure").json({ckey: cookie}).fetch(res => {
        if (CoreTools.isObject(res.data) && res.data.success) {
            if (res.data.ckey) {
                CoreTools.memory.set("ckey", res.data.ckey, res.data.days || 90);
                cookie = res.data.ckey;
            }
            confirmed = true;
            while (cookieWatchers.length) {
                cookieWatchers.shift().cb(cookie);
            }
            CoreTools.log("Cookie:", cookie);
        }
    });

    secureCookie = {
        onReady: cb => {
            if (confirmed) {
                cb(cookie);
            } else {
                const watch = {cb: cb};
                cookieWatchers.push(watch);
                return () => CoreTools.remove(cookieWatchers, watch);
            }
        },
        get: () => ({cookie: cookie, confirmed: confirmed})
    };
    return secureCookie;
})();
export default SecureCookie;