import GenSet from "../../services/GenSet";
import CoreTools from "../../services/CoreTools";
import PanelEffects from "../../directives/PanelEffects";
import HlFormData from "./panels/HlFormData";
import HlGenPanel from "./panels/HlGenPanel";
import FormValidate from "../../services/FormValidate";
import HlProcessing from "./panels/HlProcessingV3";
import OptionLists from "../../services/OptionLists";

export default class HlIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash, pageNumber: [1, 1]});
        if (CoreTools.state.appOptions.includes("nocss")) {
            this.setVal({
                cssBootstrap: true,
                cssMain: true
            });
        } else {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css", () => this.setVal({cssBootstrap: true}));
            this.importStyle("/css/HearingLife2.css", () => this.setVal({cssMain: true}));
            this.importStyle("/css/animate.css");
        }
        
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider2.css");
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}, () => setTimeout(CoreTools.centerForm, 50)));
    };

    onApplicant = (state, props, applicant) => {
        if (this.location.hash) {setTimeout(CoreTools.centerForm, 100);}
    };
    
    structure = {
        age: {
            title: "Your Age:",
            fields: [
                {type: "radio", name: "age", values: HlFormData.ages },
            ],
        },
        ageOver50: {
            title: "Are you above 50 years of age?",
            fields: [
                {type: "radio", name: "age", values: HlFormData.yesNo },
            ],
        },
        addressCA: {
            title: "Please enter your Postal Code to locate a trusted & licensed hearing partner near you.",
            address: "ca",
            continue: {caption: "Claim Free Trial"},
        },
        addressUS: {
            title: "Please enter your Address to locate a trusted & licensed hearing partner near you.",
            address: "us",
            continue: {caption: "Claim Free Trial"},
        },
        contact: {
            title: () => <><span className="text-green">Congratulations</span>, you qualify for a <span className="text-green">FREE</span> 30 day trial for the newest groundbreaking hearing aids in {this.applicant.field("city")}.</>,
            fields: [
                {type: "phone", label: "Phone", name: "phone", placeholder: "(123) 456 7890", onValidate: FormValidate.isPhone, },
                {type: "input", label: "Email", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail},
            ],
            consent: "true",
            continue: {caption: "I want to hear better!"},
        },
        contactV2: {
            title: () => <><span className="text-green">Congratulations</span>, you qualify for a <span className="text-green">FREE</span> 30 day trial for the newest groundbreaking hearing aids in {this.applicant.field("city")}.</>,
            fields: [
                {type: "input", label: "First Name", name: "first_name", placeholder: "First Name (Formal)", onValidate: FormValidate.isName, onChange: FormValidate.onlyLetters},
                {type: "input", label: "Last Name", name: "last_name", placeholder: "Last Name", onValidate: FormValidate.isName, onChange: FormValidate.onlyLetters},
                {type: "phone", label: "Phone", name: "phone", placeholder: "(123) 456 7890", onValidate: FormValidate.isPhone},
                {type: "input", label: "Email", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail},
            ],
            consent: "true",
            continue: {caption: "I want to hear better!"},
        },
        contactEmail: {
            title: "Your email address?",
            fields: [
                {type: "input", label: "Email", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail},
            ],
            continue: {caption: "Continue"},
        },
        contactPhone: {
            title: () => <><span className="text-green">Congratulations</span>, you qualify for a <span className="text-green">FREE</span> 30 day trial for the newest groundbreaking hearing aids in {this.applicant.field("city")}.</>,
            fields: [
                {type: "phone", label: "Phone", name: "phone", placeholder: "(123) 456 7890", onValidate: FormValidate.isPhone, },
            ],
            consent: "true",
            continue: {caption: "I want to hear better!"},
        },
        crowdedSpace: {
            title: "Do you struggle to hear in crowded environments?",
            fields: [
                {type: "radio", name: "crowded_space", values: HlFormData.yesNo },
            ],
        },
        crowdedSpaceV2: {
            title: "Do you sometimes have trouble understanding speec in noisy environments?",
            fields: [
                {type: "radio", name: "crowded_space", values: HlFormData.yesNo },
            ],
        },
        creditScore: {
            title: "Do you struggle to hear in crowded environments?",
            fields: [
                {type: "radio", name: "credit_score", values: HlFormData.yesNo },
            ],
        },
        difficultSituation: {
            title: "In what type of hearing situations do you experience difficulties?",
            fields: [
                {type: "radio", name: "difficult_situations", values: HlFormData.difficultSituations },
            ],
        },
        employmentStatus: {
            title: "What is your current employment status?",
            fields: [
                {type: "radio", name: "employment_status", values: HlFormData.employmentStatus },
            ],
        },
        excitedFor: {
            title: "What are you looking forward to with better hearing?",
            fields: [
                {type: "radio", name: "excited_for", values: HlFormData.excitedFor },
            ],
        },
        forWhom: {
            title: "For whom do you need a hearing aid?",
            fields: [
                {type: "radio", name: "for_whom", values: HlFormData.forWhom },
            ],
        },
        gender: {
            title: "What is your gender?",
            fields: [
                {type: "image", name: "gender", values: HlFormData.gender},
            ],
        },
        hearingAids: {
            title: "Do you already have hearing aids?",
            fields: [
                {type: "radio", name: "hearing_aids", values: HlFormData.yesNo },
            ],
        },
        hadTest: {
            title: "Have you already had a hearing test?",
            fields: [
                {type: "radio", name: "had_test", values: HlFormData.yesNo },
            ],
        },
        insurance: {
            title: "What type of insurance plan do you have?",
            fields: [
                {type: "radio", name: "insurance", values: HlFormData.insurance },
            ],
        },
        loading: {
            title: "Hold on - we're finding suppliers that best match your needs...",
            loading: true,
            processingAnimation2: 'true',
        },
        mostImportant: {
            title: "What is most important for you in a new hearing solution?",
            fields: [
                {type: "radio", name: "most_important", values: HlFormData.mostImportant },
            ],
        },
        militaryService: {
            title: "There are special programs for people who serve our country. Are you...",
            fields: [
                {type: "radio", name: "military_service", values: HlFormData.militaryService },
            ],
        },
        name: {
            title: "What is your name?",
            subTitle: () => <>We have licensed clinics in <b>{OptionLists.Provinces.find(p => p.short === this.applicant.field("state"))?.long || "your area"}</b>.</>,
            fields: [
                {type: "input", label: "First Name", name: "first_name", placeholder: "First Name (Formal)", onValidate: FormValidate.isName, onChange: FormValidate.onlyLetters},
                {type: "input", label: "Last Name", name: "last_name", placeholder: "Last Name", onValidate: FormValidate.isName, onChange: FormValidate.onlyLetters}
            ],
            continue: {caption: "Continue"},
        },
        processing: {
            processingAnimation: 'true',
        },
        roadblocks: {
            title: "What has prevented you from getting hearing aids sooner?",
            fields: [
                {type: "radio", name: "roadblocks", values: HlFormData.roadblocks },
            ],
        },
        tinnitus: {
            title: "Do you have ringing in your ears (tinnitus)?",
            fields: [
                {type: "radio", name: "tinnitus", values: HlFormData.yesNo },
            ],
        },
        trial: {
            title: "Would you like to try this revolutionary, rechargeable hearing aid for FREE?",
            fields: [
                {type: "radio", name: "tinnitus", values: HlFormData.yesNo },
            ],
        },
        whenImprove: {
            title: "When do you want to improve your hearing?",
            fields: [
                {type: "radio", name: "when_improve", values: HlFormData.whenImprove },
            ],
        },
        whenTest: {
            title: "When was the last time you had a hearing test?",
            fields: [
                {type: "radio", name: "when_test", values: HlFormData.whenTest },
            ],
        },
        whereHear: {
            title: "Where did you hear about us?",
            fields: [
                {type: "radio", name: "where_hear", values: HlFormData.whereHear },
            ],
        },
    };

    versions = {
        ca: {
            "#HowOld":              <HlGenPanel options={this.structure.age} />,
            "#Gender":              <HlGenPanel options={this.structure.gender} />,
            "#HadTest":             <HlGenPanel options={this.structure.hadTest} />,
            "#WhenTest":            <HlGenPanel options={this.structure.whenTest} />,
            "#Address":             <HlGenPanel options={this.structure.addressCA} />,
            "#Loading":             <HlGenPanel options={this.structure.loading} />,
            "#Email":               <HlGenPanel options={this.structure.contactEmail} />,
            "#Name":                <HlGenPanel options={this.structure.name} />,
            "#Contact":             <HlGenPanel options={this.structure.contactPhone} />,
            "#Processing":          <HlProcessing />,
        },


    };

    content = (state, props) => state.cssBootstrap && state.cssMain && <div className="overcard">
        <PanelEffects.ProgressBar
            caption="Progress: "
            barColor="#40AD87"
            style={{borderStyle: "solid", borderWidth: "1px", borderColor: "#4e7fe5", borderRadius: "5px"}}
        />
           <PanelEffects.Transition3>
            <div id="appForm" style={{marginTop: "15px"}} key={state.hash}>
                <div className="appForm_inner">
                    <PanelEffects.Selector
                        selector={state.hash}
                        panels={CoreTools.switch(
                            [props.version === "ca", () => this.versions.ca],
                        )} 
                    />
                </div>
            </div>
        </PanelEffects.Transition3>
    </div>;
}
