

import PanelEffects from "../../directives/PanelEffects";
import CoreTools from "../../services/CoreTools";
import FormValidate from "../../services/FormValidate";
import GenSet from "../../services/GenSet";
import LoanzFormData from "../Loanz/LoanzFormData";
import LoanzCashmasGenPanel from "./LoanzCashmasGenPanel";

export default class LoanzCashmasIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (CoreTools.state.appOptions.includes("nocss")) {  
            this.setVal({
                cssBootstrap: true,
                cssMain: true
            });
        } else {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css", () => this.setVal({cssBootstrap: true}));
            this.importStyle("/css/LoanzCashmas.css", () => this.setVal({cssMain: true}));
            this.importStyle("/css/animate.css");
        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider3.css");
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}));
    };

    onApplicant = (state, props, applicant) => {
        if (!applicant.field("loan_amount")) {applicant.setField("loan_amount", 500);}
    };
    
    structure = {
        addressUK: {
            title: "What Is Your Home Address?",
            subtitle: "Enter your physical address in the box below to quickly autocomplete all required fields.",
            address: "uk",
            continue: {caption: "Continue"},
        },
        amountUK: {
            title: "How Much Would You Like To Borrow?",
            fields: [
                {type: "select", name: "loan_amount", options: LoanzFormData.amountsCashmas, onValidate: (v => v)}
            ],
            continue: {caption: "Continue"},
        },
        bankInformationUK: {
            title: "Please Enter The Relevant Bank Information",
            subtitle: "Bank Name, Bank Sort Code, Bank Account Number, and Direct Deposit Information",
            fields: [
                {type: "input", name: "bank_account_number", placeholder: "Account Number (8 digits)", onValidate: FormValidate.isAccountNumber, onChange: FormValidate.onlyNumbers, onKeyDown: FormValidate.maxLength(8) },
                {type: "input", name: "bank_sort_code", placeholder: "Sort Code (6 digits)", onValidate: FormValidate.isSortCode, onChange: FormValidate.onlyNumbers, onKeyDown: FormValidate.maxLength(6) },
                {type: "select", name: "bank_card_type", options: LoanzFormData.bankDebitCardType },

            ],
            continue: {caption: "Continue"},
        }, 
        birthdate: {
            title: "Select Your Date Of Birth",
            fields: [
                {type: "birthdate"},
            ],
        },
        consentUK: {
            customTitle: "true",
            fields: [
                {type: "applicationSummary" },
            ],
            consentUK: "true",
        },
        dependents: {    
            title: "How Many Dependents Do You Have?",
            fields: [
                {type: "radio", name: "dependents", values: LoanzFormData.dependents, onValidate: (v => v)}
            ],
        },
        email: {
            title: "Where Can We Email Your Loan Options?",
            fields: [
                {type: "input", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail}
            ],
            continue: {caption: "Continue"},
        },
        employerName: {
            title: "What is the name of your employer?",
            fields: [
                {type: "input", name: "employer_name", placeholder: "Employer Name", onValidate: (v => v)}
            ],
            continue: {caption: "Continue"},
        },
        employmentStatus: {
            title: "Are you employed or receiving benefits?",
            fields: [
                {type: "radio", name: "employment_status", values: LoanzFormData.employmentStatus}
            ]
        },
        expenses: {
            title: "How Much Do You Spend On The Following Expenses Per Month?",
            subtitle: "Please enter your household's expenses in the specific category below",
            fields: [
                {type: "expense", name: "expenses_housing", placeholder: "Housing Expenses", currency: "£", onValidate: (v => v), onChange: FormValidate.onlyNumbers},
                {type: "expense", name: "expenses_all_loans", placeholder: "All Loans Expenses", currency: "£", onValidate: (v => v), onChange: FormValidate.onlyNumbers},
                {type: "expense", name: "expenses_food", placeholder: "Food Expenses", currency: "£", onValidate: (v => v), onChange: FormValidate.onlyNumbers},
                {type: "expense", name: "expenses_utilities", placeholder: "Utilities Expenses", currency: "£", onValidate: (v => v), onChange: FormValidate.onlyNumbers},
                {type: "expense", name: "expenses_transport", placeholder: "Transport Expenses", currency: "£", onValidate: (v => v), onChange: FormValidate.onlyNumbers},
                {type: "expense", name: "expenses_other", placeholder: "Other Expenses", currency: "£", onValidate: (v => v), onChange: FormValidate.onlyNumbers},
            ],
            continue: {caption: "Continue"},
        },
        jobTitle: {
            title: "What is your job title?",
            fields: [
                {type: "input", name: "job_title", placeholder: "Job Title", onValidate: (v => v)}
            ],
            continue: {caption: "Continue"},
        },
        loanDuration: {    
            title: "How Long Do You Want To Borrow The Money For?",
            fields: [
                {type: "select", name: "loan_durations_months", options: LoanzFormData.loanDuration},
            ],
            continue: {caption: "Continue"},
        },
        maritalStatus: {
            title: "What Is Your Marital Status?",
            fields: [
                {type: "radio", name: "marital_status", values: LoanzFormData.maritalStatus}
            ]
        },
        nameUK: {
            title: "What Is Your Name?",
            subtitle: "Please enter your First and Last Name as it appears on your drivers licence.",
            fields: [
                {type: "input", name: "first_name", placeholder: "First Name", onValidate: FormValidate.isName},
                {type: "input", name: "last_name", placeholder: "Last Name", onValidate: FormValidate.isName}
            ],
            continue: {caption: "Continue"},
        },
        netPay: {
            title: "What is your monthly net income?",
            subtitle: "For your last pay period, what is your take home pay after taxes and deductions",
            fields: [
                {type: "input", name: "net_pay", placeholder: "Net Pay", currency: "$", onValidate: (v => v)},
            ],
            continue: {caption: "Continue"},
        },
        payLastDate: {
            title: "When did you receive your last pay check?",
            datePicker: {title: 'Last Pay Date', },
            continue: {caption: "Continue"},
        },
        payFollowingDate: {
            title: "When will you receive your next pay check?",
            datePicker: {title: 'Following Pay Date', },
            continue: {caption: "Continue"},
        },
        payPeriodUK: {
            title: "How often do you get paid?",
            fields: [
                {type: "radio", name: "pay_period", values: LoanzFormData.incomePaymentFrequency}
            ]
        },
        phoneUK: {
            title: "How Can We Reach You?",
            fields: [
                {type: "phone", name: "mobile_phone", placeholder: "Mobile Phone", countryCode: "+44", onValidate: FormValidate.isPhoneUK, onKeyDown: FormValidate.ukPhone(10)}
            ],
            continue: {caption: "Continue"},
        },
        processingUK: {
            title: "We are processing your application.",
            processing: "uk",
            processingAnimation: 'true',
        },
        reasonUK: {
            title: "What Do You Need The Money For?",
            fields: [
                {type: "select", name: "loan_reason", options: LoanzFormData.reasonsUK}
            ],
            continue: {caption: "Continue"},
        },
        residentialStatus: {
            title: "What Is Your Residential Status?",
            fields: [
                {type: "radio", name: "residential_status", values: LoanzFormData.residentialStatus}
            ]
        },
        rentAmountUK: {
            title: "What Are Your Monthly (Rent Or Mortgage) Payments?",
            subtitle: "Make sure to only enter the part you pay for if renting.", 
            range: {name: "rent_amount", min: "0", max: "5000", step: "25", defaultValue: "1000", currency: "£" },
            continue: {caption: "Continue"},
        },
        title: {
            title: "What Is Your Preferred Title?",
            fields: [
                {type: "radio", name: "title", values: LoanzFormData.titles}
            ]
        },
        timeAtAddress: {
            title: "How Long Have You Been Living At Your Current Address?",
            fields: [
                {type: "radio", name: "residence_duration", values: LoanzFormData.timeAtAddress}
            ]
        },
        workExperienceUK: {
            title: "How Many Months Have You Worked At Your Current Workplace?",
            fields: [
                {type: "radio", name: "employment_duration", values: LoanzFormData.workExperienceUK}
            ]
        },
        workIndustry: {
            title: "What Industry Do You Work In?",
            fields: [
                {type: "select", name: "work_industry", options: LoanzFormData.industries, onValidate: (v => v)},
            ],
            continue: {caption: "Continue"},
        },
        workType: {
            title: "What Is Your Income Type?",
            fields: [
                {type: "radio", name: "income_type", values: LoanzFormData.incomeType},
            ],
        },
    };

    versions = {
        cashmas: {
            "#Amount":              <LoanzCashmasGenPanel options={this.structure.amountUK} />,
            "#Email":               <LoanzCashmasGenPanel options={this.structure.email} />,
            "#LoanDuration":        <LoanzCashmasGenPanel options={this.structure.loanDuration} />,
            "#Reason":              <LoanzCashmasGenPanel options={this.structure.reasonUK} />,
            "#Title":               <LoanzCashmasGenPanel options={this.structure.title} />,
            "#Name":                <LoanzCashmasGenPanel options={this.structure.nameUK} />,
            "#Birthdate":           <LoanzCashmasGenPanel options={this.structure.birthdate} />,
            "#MaritalStatus":       <LoanzCashmasGenPanel options={this.structure.maritalStatus} />,
            "#Dependents":          <LoanzCashmasGenPanel options={this.structure.dependents} />,
            "#Address":             <LoanzCashmasGenPanel options={this.structure.addressUK} />,
            "#ResidentialStatus":   <LoanzCashmasGenPanel options={this.structure.residentialStatus} />,
            "#TimeAtAddress":       <LoanzCashmasGenPanel options={this.structure.timeAtAddress} />,
            "#Phone":               <LoanzCashmasGenPanel options={this.structure.phoneUK} />,
            "#WorkIndustry":        <LoanzCashmasGenPanel options={this.structure.workIndustry} />,
            "#WorkType":            <LoanzCashmasGenPanel options={this.structure.workType} />,
            "#WorkExperience":      <LoanzCashmasGenPanel options={this.structure.workExperienceUK} />,
            "#EmployerName":        <LoanzCashmasGenPanel options={this.structure.employerName} />,
            "#JobTitle":            <LoanzCashmasGenPanel options={this.structure.jobTitle} />,
            "#PayPeriod":           <LoanzCashmasGenPanel options={this.structure.payPeriodUK} />,
            "#NetPay":              <LoanzCashmasGenPanel options={this.structure.netPay} />,
            "#PayLastDate":         <LoanzCashmasGenPanel options={this.structure.payLastDate} />,
            "#PayFollowingDate":    <LoanzCashmasGenPanel options={this.structure.payFollowingDate} />,
            "#Expenses":            <LoanzCashmasGenPanel options={this.structure.expenses} />,
            "#BankInformation":     <LoanzCashmasGenPanel options={this.structure.bankInformationUK} />,
            "#Consent":             <LoanzCashmasGenPanel options={this.structure.consentUK} />,
            "#Processing":          <LoanzCashmasGenPanel options={this.structure.processingUK} />,
        },
    };

    content = (state, props) => state.cssBootstrap && state.cssMain && <>
        <div className="main-content">
            <PanelEffects.ProgressBar
                caption=" "
                barColor="#40AD87"
                className="progress-bar"
                style={{
                    borderStyle: "solid",
                    borderWidth: "2px", 
                    borderColor: "#ffffff",
                    backgroundColor: "rgb(233, 236, 239)",
                    borderRadius: "5px", 
                    padding: "2px", 
                    fontSize: "13px",
                    margin: "0 10px"
                }}
            />
            <PanelEffects.Transition1 className="panel-container">
                <div id="appForm" style={{marginTop: "15px"}} key={state.hash}>
                    <div className="appForm_inner">
                        <PanelEffects.Selector
                            selector={state.hash}
                            panels={CoreTools.switch(
                                [props.version === "cashmas",   () => this.versions.cashmas],
                            )} 
                        />
                    </div>
                </div>
            </PanelEffects.Transition1>
        </div>
    </>;
}
