import GenSet from "../../../services/GenSet";
// import AppInput from "../../../directives/AppInput";
// import Applicant from "../../../services/Applicant";
// import LoanzObjects from "../inserts/LoanzObjects";

export default class LoanzInstitution extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <div className="card card-inputs last">
        <div className="card-body">
            <p className="card-title text-center">Where Should We Send The Money?</p>
            <div className="horizontal-line mt-3 mb-3"></div>
            <div className="form-subtext wider">
                <p className="text-center wider bold small-text mb-0">In order to get the fastest approval for the highest amount possible we need to verify your banking details. Please use the bank account where you receive your income.</p>
            </div>
            <div className="form-subtext wider">
                <p className="text-center">Flinks will access this information each time only during a loan application.</p>
                <p className="text-center">Loanz uses Flinks® to verify your banking details.</p>
            </div>
            <div className="text-center">
                <button className="skip-flinks" onClick={this.continue}>I don't have access to my online banking I will manually upload my income statements</button>
            </div>
        </div> 
    </div>;
}