import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";

let subValidate = null;

export default class ConsumerGeniusGenPanel extends GenSet {
    continue = () => this.navigate(this.props.continue);
    dobContinue = () => {this.applicant.validate(isValid => {if (isValid) {this.applicant.setField("dob", [this.applicant.field("dobYear"), this.applicant.field("dobMonth"), this.applicant.field("dobDay")].join("-"));this.navigate(this.props.continue);} else {window.scrollTo(0, 0);}});};
    validate = () => this.applicant.validate(isValid => isValid && (typeof(subValidate) === "function" ? subValidate() : true) ? this.navigate(this.props.continue) : '');

    onMount = (state, props) => {
        this.wHolder(CoreTools.on("setErrorMessage", (name, msg) => {
            const msgs = state.errorMsgs || {};
            msgs[name] = msg;
            this.setVal({errorMsgs: msgs});
        }));
    };

    radioFormat = elem => CoreTools.switch(
        [CoreTools.isObject(elem), () => ({caption: typeof(elem.caption) === "string" ? elem.caption : elem.value, value: elem.value})],
        [typeof(elem) === "string", () => ({caption: elem, value: elem})],
        [typeof(elem) === "number", () => ({caption: elem.toString(), value: elem.toString()})],
        [typeof(elem) === "boolean", () => ({caption: elem ? "Yes" : "No", value: elem ? "Yes" : "No"})],
        [true, () => ({caption: "", value: ""})]
    );

    content = (state, props) => <div className="card card-inputs last">
        <div className="card-body">
            <p className="card-title text-center">{props.options.title}</p>
            {props.options.subtitle && <div className="form-subtext"><p className="text-center">{props.options.subtitle}</p></div>}
            {!props.options.horizontalLine && <div className="horizontal-line mt-4 mb-4"></div>}
            {Array.isArray(props.options.fields) && <div className="card-fields row">                
                {props.options.fields.map((f, fi) => CoreTools.switch(
                    f.type,
                    ["input", () => <div key={fi} className="card-fields row mt-1"><div className="col-12 mb-3"><AppInput.Input type={f.inputType || "text"} name={f.name} placeholder={f.placeholder} onValidate={f.onValidate} onChange={f.onChange} onKeyDown={f.onKeyDown} className="form-control text-field special-field" failClass="invalid"/></div></div>],
                    ["phone", () => <div key={fi} className="card-fields row"><div className="col-12 adjusted-col">{f.countryCode && <span id="country-code"><b>{f.countryCode}</b></span>}<AppInput.Input type="tel" name={f.name} className="form-control text-field special-field phone mb-4" placeholder={f.placeholder} onValidate={f.onValidate} onKeyDown={f.onKeyDown} onChange={f.onChange} failClass="invalid" /></div>{f.SMS && <div className="col-12"><div className="checkbox-container"><label className="btn-checkbox mt-1"><input className="no-text-phone-checkbox" type="checkbox" value="no-text" onChange={() => this.setVal({display: !state.display})} /><span className="no-text-phone">My phone does <b>NOT</b> support texting.</span></label></div></div>}</div>],
                    ["radio", () => (f.values || []).map(this.radioFormat).map((o, i) => <div className="col-12" key={fi + "_" + i}><label className="btn btn-option"><AppInput.Radio name={f.name} value={o.value} onClick={this.continue} /><span>{o.caption}</span></label></div>)],
                ))}
            </div>}
            {props.options.processingAnimation && <div className="card card-options"><div className="card-body"><div><img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{display: "block", margin: "20px auto"}} /></div></div></div>}
            {props.options.continue && <div className="continue-button mt-3" onClick={() => this.validate(props.options.continue.disabled)}>{props.options.continue.caption}</div>}
        </div>
    </div>;
}


