import CoreTools from "../../../services/CoreTools";

const Card = props => <fieldset className="card card-options">
    <div className="card-body">
        <div className="card-fields">
            <h5 className="card-title text-center">{props.title}</h5>
            {props.text && <p className="card-text text-center">{props.text}</p>}
            <div className="card-fields-options">
                {props.children}
            </div>
        </div>
        {props.controls}
    </div>
</fieldset>;

const Controls = props => {
    const pagesLeft = CoreTools.state.totalPages - CoreTools.state.currentPageNumber;

    return <div className="card-controls text-center">
        { pagesLeft > 2 && <span><i className="fa-solid fa-clock-rotate-left"></i><b>{(pagesLeft - 2) * 5}</b> seconds left...</span> }
        { props.buttonText !== false && <button disabled={props.disabled} onClick={props.continue} className="mx-auto btn btn-navigate">{props.buttonText || "Next"}</button>}
        { props.disclaimer && <p className="term-txt text-center mt-1"><b>By clicking "{props.buttonText || "Next"}", I have read and accept the <a href="/terms-and-conditions/" target="_blank" rel="noreferrer">Terms of Use</a>. I also acknowledge having read the <a href="/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> and agree to the terms herein.</b></p>}
    </div>;
}

const HeComponents = {
    Card: Card,
    Controls: Controls
};

export default HeComponents;