import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";

export default class NhpusPropertyValue extends GenSet {

    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };

    setOutput = event => {
        if (event.target.value === "0") {
            this.setVal({output: "Select Your Purchase Price"});
        } else if (event.target.value === "800000") {
            this.setVal({output: "$800,000+"});
        } else {
            this.setVal({output: "$" + event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")});
        }
    };

    content = (state, props) => <fieldset className="card card-inputs">
        <div className="card-body">
            <h5 className="card-title">What is the estimated purchase price?</h5>
            <div className="card-fields">
                <div className="form-row">
                    <div className="col-12 text-center mt-3">
                        <div className="form-slider">
                            <div className="form-value">{state.output}</div>
                            <AppInput.Range
                                style={{sidplay: "block"}}
                                name="property_value"
                                onValidate={v => Number(v)}
                                failClass="invalid"
                                onChange={this.setOutput}
                                onLoad={this.setOutput}
                                min="0"
                                max="800000"
                                step="25000"
                                defaultValue="0"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer text-center mt-4">
                <button onClick={this.continue} className="mx-auto btn btn-navigate">Next</button>
            </div>
        </div>
    </fieldset>;

}