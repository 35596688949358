

import PanelEffects from "../../directives/PanelEffects";
import CoreTools from "../../services/CoreTools";
import GenSet from "../../services/GenSet";
import CarsfastFlatFormForward from "./panels/CarsfastFlatFormForward";
import CarsFastFlatformGenPanel from "./panels/CarsFastFlatFormGenPanel";
import CarsfastFlatFormProcessing from "./panels/CarsfastFlatFormProcessing";

export default class CarsfastFlatFormIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (CoreTools.state.appOptions.includes("nocss")) {  
            this.setVal({
                cssBootstrap: true,
                cssMain: true
            });
        } else {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css", () => this.setVal({cssBootstrap: true}));
            this.importStyle("/css/carsfast-flatform.css", () => this.setVal({cssMain: true}));
            this.importStyle("/css/animate.css");
        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider3.css");
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}, () => setTimeout(CoreTools.centerForm, 50)));
    };
    
    structure = {
        panelAddress: {
            counter: "Panel 2 of 4",
            title: "Your Address Details",
            panelAddress: "true",
            continue: {caption: "Continue"},
        },
        panelEmployment: {
            counter: "Panel 3 of 4",
            title: "Your Employment Experience",
            panelEmployment: "true",
            continue: {caption: "Continue"},
        },
        panelPersonal: {
            counter: "Panel 1 of 4",
            title: "Your Personal Information",
            panelContact: "true",
            continue: {caption: "Continue"},
        },
        panelVehicle: {
            counter: "Panel 4 of 4",
            title: "Your Vehicle Information",
            panelVehicle: "true",
            continue: {caption: "Continue"},
        },
        thankYou: {
            title: "Thank you!",
            subtitle: {caption: "A representative will be in contact with you shortly!"},
            horizontalLine: "false",
        },
    };

    panels = {
        "#Personal":            <CarsFastFlatformGenPanel options={this.structure.panelPersonal} />,
        "#Address":             <CarsFastFlatformGenPanel options={this.structure.panelAddress} />,
        "#Employment":          <CarsFastFlatformGenPanel options={this.structure.panelEmployment} />,
        "#Vehicle":             <CarsFastFlatformGenPanel options={this.structure.panelVehicle} />,
        "#Processing":          <CarsfastFlatFormProcessing />,
        "#Forward":             <CarsfastFlatFormForward />,
    };

    content = (state, props) => state.cssBootstrap && state.cssMain && <>
            <PanelEffects.Transition1 className="form-body">
                <div id="appForm" style={{marginTop: "15px"}} key={state.hash}>
                    <div className="appForm_inner">
                        <PanelEffects.Selector
                            selector={state.hash}
                            panels={this.panels} 
                        />
                    </div>
                </div>
            </PanelEffects.Transition1>
    </>;
}
