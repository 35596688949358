import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";
import SubForm from "../../../services/SubForm";
import SurexComponents from "../inserts/SurexComponents";
import FormValidate from "../../../services/FormValidate";
import OptionLists from "../../../services/OptionLists";

class SurexAddDrivers extends GenSet {
    // driverId = CoreTools.timeStamp().toString();
    vehicles = this.applicant.fAlt("vehicles", []);
    init = (() => {
        this.subForm = SubForm();
        if (this.props.driver) {Object.keys(this.props.driver).forEach(key => this.subForm.setField(key, this.props.driver[key]));}
        //this.subForm.setField("id", this.driverId);
        this.subForm.setField("vehicles", this.vehicles.map(v => v.id));
        this.setVal({
            highlighted: this.subForm.fAlt("vehicles", []),
            driversName: "This Driver"
        });
    })();

    continue = () => {
        this.subForm.validate(isValid => {
            if (isValid) {
                this.props.onSelect(this.subForm.fields);
            } else {
                CoreTools.centerForm();
            }
        });
    };

    cancel = () => this.props.onSelect(false);

    options = {
        gender: [{caption: "Gender", value: ""}, {caption: "Female", value: "female"}, {caption: "Male", value: "male"}, {caption: "Other", value: "other"}],
        dobYears: (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year - 15; i > year - 100; i--) {years.push(i.toString());} return years;})(),
        licYears: (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year; i > year - 100; i--) {years.push(i.toString());} return years;})(),
        months: (() => {let months = [{value: "", caption: "Month"}]; for (let i = 1; i <= 12; i++) {months.push(i.toString());} return months;})(),
        days: (() => {let days = [{value: "", caption: "Day"}]; for (let i = 1; i <= 31; i++) {days.push(i.toString());} return days;})()
    };

    classes = () => [{caption: "Licence Class", value: ""}, ...(OptionLists.DriverClassesCanada.find(c => c.province === CoreTools.asString(this.applicant.field("state")).toUpperCase()) || {classes: []}).classes.map(c => ({caption: "Class " + c, value: c}))];

    licenceNumberValidation = value => {
        return value ? true : false;
    };

    manageHighlighted = vId => this.setVal({highlighted: this.state.highlighted.includes(vId) ? CoreTools.remove(this.state.highlighted, vId) : [...this.state.highlighted, vId]});

    drivers = () => this.setVal({drivers: this.subForm.fields.firstname && this.subForm.fields.lastname ? `${this.subForm.fields.firstname} ${this.subForm.fields.lastname}` : "This Driver"});
    
    content = (state, props) => <SurexComponents.Card
        title="Who's Driving?"
        text="Enter the information of the drivers you would like to insure below."
        controls={<SurexComponents.Controls continue={this.continue} buttonText="Save Driver" />}
    >
        <div className="form-row">
            <div className="col-md-6 mb-2">
                    <span>First Name</span>
            </div>
            <div className="col-md-6 mb-2">
                    <span>Last Name</span>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <AppInput.Input subForm={this.subForm} name="first_name" className="subForm form-control text-field" placeholder="First Name" onValidate={FormValidate.isName} failClass="invalid" onPost={this.driversName} />
            </div>
            <div className="col-md-6 mb-2">
                <AppInput.Input subForm={this.subForm} name="last_name" className="subForm form-control text-field" placeholder="Last Name" onValidate={FormValidate.isName} failClass="invalid" onPost={this.driversName} />
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <span>Gender</span>
            </div>
            <div className="col-md-6 mb-2">
                <span>Date Of Birth (Year | Month | Day)</span>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <AppInput.Select subForm={this.subForm} name="gender" className="subForm form-control text-field" options={this.options.gender} onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-2 mb-2">
                <AppInput.Select subForm={this.subForm} name="dob_year" className="subForm form-control text-field" options={this.options.dobYears} onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-2 mb-2">
                <AppInput.Select subForm={this.subForm} name="dob_month" className="subForm form-control text-field" options={this.options.months} onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-2 mb-2">
                <AppInput.Select subForm={this.subForm} name="dob_day" className="subForm form-control text-field" options={this.options.days} onValidate={v => v} failClass="invalid" />
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <span>Class</span>
            </div>
            <div className="col-md-6 mb-2">
                <span>Date Licensed</span>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <AppInput.Select subForm={this.subForm} name="licence_class" className="subForm form-control text-field" options={this.classes()} defaultValue="" onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-2 mb-2">
                <AppInput.Select subForm={this.subForm} name="licence_year" className="subForm form-control text-field" options={this.options.licYears} onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-2 mb-2">
                <AppInput.Select subForm={this.subForm} name="licence_month" className="subForm form-control text-field" options={this.options.months} onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-2 mb-2">
                <AppInput.Select subForm={this.subForm} name="licence_day" className="subForm form-control text-field" options={this.options.days} onValidate={v => v} failClass="invalid" />
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-12 mb-2">
                <span>Licence Number</span>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-12 mb-2">
                <AppInput.Input subForm={this.subForm} name="licence_number" className="subForm form-control text-field" onValidate={this.licenceNumberValidation} failClass="invalid" placeholder="Licence Number" />
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-12 mb-2">
                <span>Highlight The Vehicles {state.driversName} Operates</span>
            </div>
        </div>
            {this.vehicles.map(v => <div key={v.id} className="add-remove" style={{cursor: "pointer"}}><span className={["clickable", "wd-6", (state.highlighted || []).includes(v.id) ? "highlight" : "hover"].filter(c => c).join(" ")} onClick={() => this.manageHighlighted(v.id)}>{[v.year, v.make, v.model, v.series].filter(i => i).join(" ")}</span></div>)}
            <div className="input-group"><button className="mx-auto btn btn-Cancel" onClick={() => this.cancel()}>Cancel</button></div>
    </SurexComponents.Card>;
}

export default class SurexDrivers extends GenSet {
    drivers = Array.isArray(this.applicant.field("drivers")) ? this.applicant.field("drivers") : [];
    init = (() => {
        this.setVal({drivers: this.drivers});
    })();

    continue = () => this.navigate(this.props.continue);

    deleteDriver = vId => {
        CoreTools.remove(this.drivers, this.drivers.find(v => v.id === vId));
        this.applicant.setField("drivers", this.drivers);
        this.setVal({drivers: this.drivers});
    };

    onSelect = driver => {
        console.log(this.drivers,"drivers");
        if (driver) {
            if (driver.id) {
                let existing = this.drivers.find(d => d.id = driver.id);
                if (existing) {
                    Object.keys(existing).forEach(key => delete(existing[key]));
                    Object.keys(driver).forEach(key => existing[key] = driver[key]);
                }
                else {
                    driver.id = CoreTools.last(this.drivers.map(v => v.id).sort())(v => (Number(v) || 0) + 1, () => 1);
                    this.drivers.push(driver);
                }
            } else {
                driver.id = CoreTools.last(this.drivers.map(v => v.id).sort())(v => (Number(v) || 0) + 1, () => 1);
                this.drivers.push(driver);
            }
            
            this.applicant.setField("drivers", this.drivers);
            this.setVal({drivers: this.drivers, Panel: this.driverList()});
        } else {
            this.updatePanels();
        }
    };

    driverList = () => <SurexComponents.Card
        title="Add a second driver (save up to 25%)"
        text="These are the drivers you have added thus far. Click on a driver to edit their information or click continue to accept this list."
        controls={<SurexComponents.Controls continue={this.continue} buttonText="Continue" />}
    >
        <div className="form-row">
            {this.state.drivers.map(v => <div key={v.id} className="carlist" style={{cursor: "pointer"}}><span className={["form-control", ].filter(c => c).join(" ")} onClick={this.showAddDriver(v)}>{[v.first_name, v.last_name].filter(i => i).join(" ")}</span><button type="button" className="btn btn-primary" onClick={() => this.deleteDriver(v.id)} disabled={v.main}><i className="fa-solid fa-xmark"></i></button></div>)}
        </div>
        <div className="input-group"><button className="mx-auto btn addBtn" onClick={this.showAddDriver(false)}>Add Another Driver</button></div>
    </SurexComponents.Card>;

    showAddDriver = driver => () => {
        this.setVal({Panel: <SurexAddDrivers driver={driver} onSelect={this.onSelect} />});
    };

    updatePanels = () => {
        if (!Array.isArray(this.state.drivers) || this.state.drivers.length === 0) {
            let driver = {
                main: true,
                id: CoreTools.last(this.drivers.map(v => v.id).sort())(v => (Number(v) || 0) + 1, () => 1),
                vehicles: this.applicant.fAlt("vehicles", []).map(v => v.id),
                first_name: this.applicant.field("first_name"),
                last_name: this.applicant.field("last_name"),
                gender: this.applicant.field("gender"),
                dob: this.applicant.field("dob"),
                dob_year: this.applicant.field("dob_year"),
                dob_month: this.applicant.field("dob_month"),
                dob_day: this.applicant.field("dob_day"),
                licence_number: this.applicant.field("licence_number"),
                licence_class: this.applicant.field("licence_class"),
                licence_year: this.applicant.field("licence_year"),
                licence_month: this.applicant.field("licence_month"),
                licence_day: this.applicant.field("licence_day")
            };
            this.state.drivers = [driver];
        }
        this.setVal({Panel: this.driverList()});
    };

    onMount = () => {
        this.wHolder(this.applicant.onFieldUpdate("drivers", newValue => {
            this.drivers = newValue;
            this.drivers.filter(v => v && !v.id).forEach(v => {
                v.id = CoreTools.last(this.drivers.filter(v => v && v.id))(v => v.id + 1, () => 1);
            });
            this.setVal({drivers: this.drivers}, this.updatePanels);
        }));
        this.updatePanels();
    };

    content = (state, props) => state.Panel;
}