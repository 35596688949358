import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import JugglingDebtComponents from "../inserts/JugglingDebtComponents";

export default class JugglingDebtCreditorsCount extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };
    
    setOutput = event => {
        this.setVal({
            creditors:{
                val: event.target.value,
            }
        });
    };

    content = (state, props) => <JugglingDebtComponents.Card
        title="How many Creditors do you have?"
        controls={<JugglingDebtComponents.Controls continue={this.continue} buttonText={props.buttonText} disclaimer={props.disclaimer} />}
    >
        <div className="form-row">
            <div className="col-12">
                <div className="form-slider">
                    <span className="form-value two-value">
                        <div className="dollar">{state.creditors?.val}</div>
                    </span>
                    <AppInput.Range
                        style={{sidplay: "block"}}
                        name="creditors_count_range"
                        onValidate={v => Number(v)}
                        failClass="invalid"
                        onChange={this.setOutput}
                        onLoad={this.setOutput}
                        min="1"
                        max="6"
                        step="1"
                        defaultValue="1"
                    />
                    <div className="slider-values">
                        <span>1</span>
                        <span>6+</span>
                    </div>
                </div>
            </div>
        </div>
    </JugglingDebtComponents.Card>;
}