import GenSet from "../../../services/GenSet";
import CheckItem from "../inserts/CheckItem";

export default class HlChecklist extends GenSet {

    stage = 0;
    // progress = 0;

    init = this.setVal({
        progress: 0, 
        stages: [ 
            {status: 0, caption: "Credit Card Debt", time: 700},
            {status: 0, caption: "Medical Debt", time: 1300},
            {status: 0, caption: "Personal Loan Debt", time: 500},
            {status: 0, caption: "Collections Debt", time: 1500},
            {status: 0, caption: "Other Unsecured Debt", time: 600},
            {status: 0, caption: "Total Unsecured Debt", time: 1400},
            {status: 0, caption: "Credit Score", time: 800},
            {status: 0, caption: "Employement Status", time: 1200},
            {status: 0, caption: "Annual Income Range", time: 900},
            {status: 0, caption: "Pay Frequency", time: 1100}
        ]
    });

    onMount = (state, props) => {
        const next = () => {
            const stages = state.stages;
            stages[this.stage].status = 1;
            this.setVal({stages: stages});
            this.stage++;

            if (this.stage < state.stages.length) {
                this.setVal({progress: this.stage * 10});
                
                // bad way
                // this.progress += state.stages[this.stage - 1].time/100;
                // this.setVal({progress: this.progress});

                setTimeout(next, state.stages[this.stage].time);
            } else {
                this.setVal({progress: 100});
                setTimeout(() => this.navigate(props.continue), 500);
            }
        };
        setTimeout(next, state.stages[0].time);
    };

    content = state => <div className="card card-options">
        <div className="card-body">
            <div className="day-fld-box">
                <h5 className="card-title">Hearing Life is analyzing potential offers</h5>
                <h1>{state.progress} %</h1>
                <input type="range" min="0" max="100" value={state.progress} readOnly={true} />
                <div className="row">
                    {state.stages.map((stage, index) => <div className="col-6"><CheckItem status={stage.status} caption={stage.caption} /></div>) }
                </div>
            </div>
        </div>
    </div>;
}
