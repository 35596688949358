const vehicleDrill = options => {
    let hasStarted = false;
    const run = () => {
        const parseJSON = str => {
            if (typeof(str) === "string") {try {return str ? JSON.parse(str) : {};} catch (e) {return {};}} 
            else if (typeof(str) === "object") {return str || {};} 
            else {return {};}
        };
        const getDrill = (year, make, model, series, style) => cb => {
            const xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = () => xhttp.readyState === 4 && xhttp.status === 200 && xhttp.responseText ? cb(parseJSON(xhttp.responseText)) : undefined;
            xhttp.open("POST", "https://dsa.carmonk.ca/v1/assetBlackBook/drillUvc/jj", true);
            xhttp.send(JSON.stringify({year: year, make: make, model: model, series: series, style: style}));
        };
        const setOptions = (elem, arr, msg) => {
            while (elem.childNodes.length) {elem.removeChild(elem.childNodes[0]);}
            let nI = document.createElement("option");
            nI.value = "NULL";
            nI.innerHTML = msg || "";
            elem.appendChild(nI);
            if (options.disableEmpty) {elem.disabled = !arr.length;}
            arr.forEach(c => {
                let nI = document.createElement("option");
                nI.value = c;
                nI.innerHTML = !c ? "Default" : c;
                elem.appendChild(nI);
            });
            if (arr.length === 1) {elem.value = arr[0];}
        };
        const toNull = value => typeof(value) !== "string" ? "NULL" : value;
        const fromNull = value => value === "NULL" ? null : value; 
        const ref = {
            year: typeof(options.year) === "string" ? document.getElementById(options.year) : options.year,
            make: typeof(options.make) === "string" ? document.getElementById(options.make) : options.make,
            model: typeof(options.model) === "string" ? document.getElementById(options.model) : options.model,
            series: typeof(options.series) === "string" ? document.getElementById(options.series) : options.series,
            style: typeof(options.style) === "string" ? document.getElementById(options.style) : options.style
        };
        const onUpdate = () => {
            if (typeof(options.onUpdate) === "function") {
                let lastValue = null;
                Object.keys(ref).filter(key => ref[key] && ref[key].value !== "NULL").forEach(key => {lastValue = key;});
                options.onUpdate(lastValue, {
                    year: fromNull(ref.year.value),
                    make: fromNull(ref.make.value),
                    model: fromNull(ref.model.value),
                    series: fromNull(ref.series.value),
                    style: fromNull(ref.style.value)
                });
            }
        };
        Object.keys(ref).forEach(key => {if (!ref[key]) {console.log(`Missing Field '${key}'`);}});
        const byDrill = target => {
            if (target) {
                let fieldIndex = [
                    ref.year,
                    ref.make,
                    ref.model,
                    ref.series,
                    ref.style
                ].indexOf(target);
                if (fieldIndex < 1) {ref.make.value = "NULL";}
                if (fieldIndex < 2) {ref.model.value = "NULL";}
                if (fieldIndex < 3) {ref.series.value = "NULL";}
                if (fieldIndex < 4) {ref.style.value = "NULL";}
                getDrill(
                    fromNull(ref.year.value),
                    fromNull(ref.make.value),
                    fromNull(ref.model.value),
                    fromNull(ref.series.value),
                    fromNull(ref.style.value)
                )(data => {
                    if (typeof(options.debug) === "function") {options.debug(data);}
                    else if (options.debug === true) {console.log("Drill Response:", data);}
                    if (data.success) {
                        if (ref.make.value !== data.selected.make) {ref.make.value = toNull(data.selected.make);}
                        if (ref.model.value !== data.selected.model) {ref.model.value = toNull(data.selected.model);}
                        if (ref.series.value !== data.selected.series) {ref.series.value = toNull(data.selected.series);}
                        if (ref.style.value !== data.selected.style) {ref.style.value = toNull(data.selected.style);}
                        if (fieldIndex < 1) {setOptions(ref.make, data.lists.makes, options.makeDefault || "Select Make");}
                        if (fieldIndex < 2) {setOptions(ref.model, data.lists.models, options.modelDefault || "Select Model");}
                        if (fieldIndex < 3) {setOptions(ref.series, data.lists.series, options.seriesDefault || "Select Series");}
                        if (fieldIndex < 4) {setOptions(ref.style, data.lists.styles, options.styleDefault || "Select Style");}
                        setTimeout(onUpdate);
                    }
                });
            }
        };
        setOptions(ref.year, (() => {
            let years = [];
            let useDate = new Date();
            let thisYear = useDate.getFullYear();
            if (useDate.getMonth() > 8) {thisYear++;}
            for (let i = thisYear; i >= (options.minYear || 2002); i--) {years.push(i.toString());}
            return years;
        })(), options.yearDefault || "Select Year");
        setOptions(ref.make, [], options.makeDefault || "Select Make");
        setOptions(ref.model, [], options.modelDefault || "Select Model");
        setOptions(ref.series, [], options.seriesDefault || "Select Series");
        setOptions(ref.style, [], options.styleDefault || "Select Style");
        ref.year.addEventListener("change", event => byDrill(event.target));
        ref.make.addEventListener("change", event => byDrill(event.target));
        ref.model.addEventListener("change", event => byDrill(event.target));
        ref.series.addEventListener("change", event => byDrill(event.target));
        ref.style.addEventListener("change", event => byDrill(event.target));
        onUpdate();
    };
    const start = () => {if (!hasStarted) {hasStarted = true; run();}};
    window.addEventListener("load", () => start());
    if (document.readyState === "complete") {start();}
};

export default vehicleDrill;