import React from "react";
import CoreTools from "../services/CoreTools";
import GenSet from "../services/GenSet";
import OptionLists from "../services/OptionLists";

let subValidate = null;

const Month = props => <div className='dob-picker-month'>
    <ul>{OptionLists.Months.map((month) => <li key={month.sValue} onClick={() => props.onSelect(month.sValue)}>{month.short}</li>)}</ul>
</div>;

const Day = props => <div className='dob-picker-day'>
    <ul>{props.days.map(day => <li key={day} onClick={() => props.onSelect(day.toString())}>{day}</li>)}</ul>
</div>;

const Year = props => {
    const [more, showMore] = React.useState(false);
    const olderYears = [];
    const youngerYears = [];
    const currYear = new Date().getFullYear();
    const oldestYear = currYear - 78;
    for (let i = currYear - 19; i >= currYear - 108; i--) {
        if (i < oldestYear) {olderYears.push(i);} else {youngerYears.push(i);}
    };
    return <div className='dob-picker-year'>
        <ul>{youngerYears.map(youngerYear => <li key={youngerYear} onClick={() => props.onSelect(youngerYear.toString())}>{youngerYear}</li>)}</ul>
        {!more && <span className="more-options" onClick={() => showMore(true)}>More Options</span>}
        {more && <ul>{olderYears.map(olderYear => <li key={olderYear} onClick={() => props.onSelect(olderYear.toString())}>{olderYear}</li>)}</ul>}
    </div>;
};

export default class LegacyDOBPicker extends GenSet {
    init = (() => {
        this.importStyle("/css/LegacyDOBPicker.css", () => this.setVal({loaded: true}));
        this.setVal({highlight: null});
    })();
    validate = () => this.applicant.validate(isValid => isValid && (typeof(subValidate) === "function" ? subValidate() : true) ? this.navigate(this.props.continue) : window.scrollTo(0, 0));

    onMount = () => {
        const popDob = dob => {
            let year = null;
            let month = null;
            let day = null;
            if (dob && typeof(dob) === 'string') {
                let dobParts = dob.split('-');
                if (dobParts.length === 3) {
                    year = parseInt(dobParts[0]) || null;
                    month = parseInt(dobParts[1]) || null;
                    day = parseInt(dobParts[2]) || null;
                }
            }
            this.setVal({year: year, month: month, day: day}, () => {
                if (typeof(this.props.onSelect) === "function") {
                    this.props.onSelect(year && month && day ? true : false);
                }
            });
        };
        popDob(this.applicant.field('dob'));
        this.wHolder(this.applicant.onFieldUpdate("dob", newValue => popDob(newValue)));
    };
 
    getDays = () => {
        let days = [];
        for (let i = 1; i <= 31; i++) {
            const day = i.toString();
            days.push(day.length === 1 ? "0" + day : day);
        }
        return days;
    };

    fontChange = highlight => highlight !== this.state.highlight && setTimeout(() => this.setVal({highlight: highlight}, () => {
        if (
            !this.state.highlight && 
            this.state.month && 
            this.state.day && 
            this.state.year
        ) {
            this.applicant.setField("dob", `${this.state.year}-${this.state.month}-${this.state.day}`);
            if (typeof(this.props.onSelect) === "function") {
                this.props.onSelect(true);
            }
        } else if (typeof(this.props.onSelect) === "function") {
            this.props.onSelect(false);
        }
    }));

    reset = () => this.setVal({year: null, month: null, day: null}, () => this.applicant.setField("dob", ''));

    content = state => state.loaded && <div className="LegacyDOBPicker">
        <div className='dob-picker mt-1'>
            <li className={state.highlight === 'm' ? 'dob-picker-display highlight' : 'dob-picker-display'} name='month' onClick={() => this.setVal({panel: 'Month'})}>{state.month || 'MM'}</li> 
            <span className='dob-picker-display-slash'>/</span>
            <li className={state.highlight === 'd' ? 'dob-picker-display highlight' : 'dob-picker-display'} name='day' onClick={() => this.setVal({panel: 'Day'})}>{state.day || 'DD'}</li>
            <span className='dob-picker-display-slash'>/</span>
            <li className={state.highlight === 'y' ? 'dob-picker-display highlight' : 'dob-picker-display'} name='year' onClick={() => this.setVal({panel: 'Year'})}>{state.year || 'YYYY'}</li>
            <div className='dob-picker-reset-button'><span onClick={() => this.reset()}>reset</span></div>
        </div>
        {CoreTools.switch(
            [state.panel === 'Month' || !state.month, () => <Month init={this.fontChange('m')} onSelect={v => this.setVal({month: v, panel: null} )}/>],
            [state.panel === 'Day' || !state.day, () => <Day init={this.fontChange('d')} days={this.getDays()} onSelect={v => this.setVal({day: v, panel: null} )}/>],
            [state.panel === 'Year' || !state.year, () => <Year init={this.fontChange('y')} onSelect={v => this.setVal({year: v, panel: null} )} />],
            [true, () => this.fontChange(null)]
        )}
   </div>;
}
