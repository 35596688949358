import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import JugglingDebtComponents from "../inserts/JugglingDebtComponents";

export default class JugglingDebtDebtType extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };
    
    content = (state, props) => <JugglingDebtComponents.Card
        title="What type of debt do you have?"
        controls={<JugglingDebtComponents.Controls continue={this.continue} buttonText={false} disclaimer={props.disclaimer} />}
        counter={props.counter}
    >
        <div className="form-row split">
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="debt_type" value="Credit Cards" onClick={this.continue} />
                    <span>Credit Cards</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="debt_type" value="Payday Loans" onClick={this.continue} />
                    <span>Payday Loans</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="debt_type" value="Personal Loans" onClick={this.continue} />
                    <span>Personal Loans</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="debt_type" value="Store Cards" onClick={this.continue} />
                    <span>Store Cards</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="debt_type" value="Tax Debt" onClick={this.continue} />
                    <span>Tax Debt</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="debt_type" value="Student Loans" onClick={this.continue} />
                    <span>Student Loans</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="debt_type" value="Lines of Credit" onClick={this.continue} />
                    <span>Lines of Credit</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="debt_type" value="Other" onClick={this.continue} />
                    <span>Other</span>
                </label>
            </div>
        </div>
    </JugglingDebtComponents.Card>;
}