import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";

export default class CLRWho extends GenSet {
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <div className="form-panel">
    <div className="row">
        <div className="col-md-12">
            <span className="radio-button-title">I'd like quotes for:<span style={{color: "red"}}>*</span></span>                
            <span className="input-group"> 
                <label className="btn btn-option">
                    <AppInput.Radio name="insurance_who" value="Just Me" onClick={this.onClick} />
                    <span className="btn-option-text">Just Me</span>
                </label>    
                <label className="btn btn-option">
                    <AppInput.Radio name="insurance_who" value="Me & My Partner" onClick={this.onClick} />
                    <span className="btn-option-text">Me & My Partner</span>
                </label>         
            </span>
        </div>
    </div>
</div>;
}

