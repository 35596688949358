import Icon from "../../directives/Icon";

const RenovateFormData = ({
   age: ["Under 18", "18 - 24", "25 - 34", "35 - 44", "45 - 54", "55 - 64", "65+"],
   attendance: ["1st Time", "2nd Time", "3rd Time", "4th Time", "5 or More Times"],
   bathroom: ["Bathtub to Shower Conversion", "Bathtub or Shower Updates", "Bathtub Liner or Shower Enclosure", "Walk-in Shower", "Complete Bathroom Remodeling"],
   boatStyle: ["Fishing", "Cruiser", "Ski", "Wakeboard", "Pontoon", "Sail", "Do Not Own A Boat", "Other"],
   boatStyleInterest: ["Fishing", "Cruiser", "Ski", "Wakeboard", "Pontoon", "Sail", "Other", "Not Interested"],
   consent: ["Accept", "Deny"],
   credit: [{caption: "720+ Excellent", value: 'btn-option-excellent', key: '1'}, {caption: "719-690 Very Good", value: 'btn-option-good', key: '2'}, {caption: "689-640 Good", value: 'btn-option-good', key: '3'}, {caption: "639-560 Fair", value: 'btn-option-fair', key: '4'}, {caption: "559- Poor", value: 'btn-option-poor', key: '5'}],
   discovery: ["TV",  "Radio", "Newspaper or Magazine", "Internet", "Billboards", "Social Media", "Other"],
   education: ["Some High School",  "High School", "GED", "Technical School", "Some College", "Undergraduate Degree", "Some Graduate Work", "Graduate Degree", "Post-Graduate Work", "Post-Graduate Degree"],
   electricBill: ["Over $501", "$301-500", "$201-300", "$101-200", "Under $100"],
   event: ["Radio", "TV", "Newspaper", "Vendor", "Email", "Internet", "Other"],
   gender: ["Male", "Female"],
   gutterCommercialLocation: ["Business", "Home"],
   gutterType: ["Copper", "Galvanized", "PVC", "Seamless Metal", "Wood"],
   hotTub: ["Hot Tub", "Swim Spa"],
   hotTubSeating: ["1-2", "2-4", "4-6"],
   hotTubTimeframe: ["Immediately", "Within 30 Days", "30-90 Days", "More than 90 Days"],
   hotTubPower: ["110v", "220v"],
   householdIncome: ["Less than $50,000", "$50,000 to $69,999", "$70,000 to $89,999", "$90,000 to $149,999", "$150,000 to $199,000", "$200,000 to $349,999", "$350,000 to $499,999", "$500,000 or More"],
   maritalStatus: ["Single", "Married", "Partner", "Cohabitating", "Other"],
   projectScope: ["Install", "Repair"],
   projectSize: ["1", "2", "3 - 5", "6 - 9", "10+"],
   replaceRepair: ["Replace", "Repair"],
   residenceType: ["Own", "Rent"],
   roofsType: [
      {label: "Asphalt", value: "asphalt", image: <Icon name="asphalt" style={{width: '30vw', maxWidth: '175px', borderRadius: '15px'}} />},
      {label: "Clay", value: "clay", image: <Icon name="clay" style={{width: '30vw', maxWidth: '175px', borderRadius: '15px'}} />},
      {label: "Slate", value: "slate", image: <Icon name="slate" style={{width: '30vw', maxWidth: '175px', borderRadius: '15px'}} />},
      {label: "Wood", value: "wood", image: <Icon name="wood" style={{width: '30vw', maxWidth: '175px', borderRadius: '15px'}} />},
      {label: "Other", value: "other", image: <Icon name="other" style={{width: '30vw', maxWidth: '175px', borderRadius: '15px'}}  />},
      {label: "Not Sure", value: "other", image: <Icon name="other" style={{width: '30vw', maxWidth: '175px', borderRadius: '15px'}}  />},
   ],
   roofsTypeV2: ["Asphalt", "Cedar Shake", "Composite", "Metal", "Natural Slate", "Tar Touchdown", "Tile"],
   roofingPlan: ["Completely Replace Roof", "Install Roof on New Construction", "Repair Existing Roof"],
   roof: ["Full Sun", "Partial Sun", "Mostly Shaded", "Not Sure"],
   timeframe: ["1 - 6 Months", "Don't Know", "Immediately"],
   yesNo: ["Yes", "No"],
   windowsType: [
      {label: "Double Hung (slides up and down)", value: "double_hung", image: <Icon name="doubleHung" style={{width: '30vw', maxWidth: '175px', borderRadius: '15px'}} />},
      {label: "Fixed/Picture (does not open)", value: "fixed_picture", image: <Icon name="fixedPicture" style={{width: '30vw', maxWidth: '175px', borderRadius: '15px'}} />},
      {label: "Sliding (slides sideways)", value: "sliding", image: <Icon name="sliding" style={{width: '30vw', maxWidth: '175px', borderRadius: '15px'}} />},
      {label: "Casement (hinges open)", value: "casement", image: <Icon name="casement" style={{width: '30vw', maxWidth: '175px', borderRadius: '15px'}} />},
      {label: "Arched or Elliptical", value: "arched", image: <Icon name="arched" style={{width: '30vw', maxWidth: '175px', borderRadius: '15px'}}  />},
      {label: "Skylight", value: "skylight", image: <Icon name="skylight" style={{width: '30vw', maxWidth: '175px', borderRadius: '15px'}}  />},
   ],
   windowsTypeV2: ["Double Hung (slides up and down)", "Fixed/Picture (does not open)", "Sliding (slides sideways)", "Casement (hinges open)", "Arched or Elliptical", "Skylight"],
   walkIn: ["Safety", "Therapeutic Reasons", "Other Reasons"]
});

export default RenovateFormData;
