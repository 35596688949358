import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import HeComponents from "../inserts/HeComponents";
import FormValidate from "../../../services/FormValidate";

export default class HeName extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };
    
    content = (state, props) => <HeComponents.Card
        title="We will personalize your report"
        text="Enter your contact info so we can personalize your results."
        controls={<HeComponents.Controls continue={this.continue} buttonText={props.buttonText} disclaimer={props.disclaimer} />}
    >
        <div className="form-row">
            <div className="col-12 mb-2">
                <AppInput.Input type="text" name="first_name" className="form-control text-field" placeholder="First Name" onValidate={FormValidate.isName} failClass="invalid" />
            </div>
            <div className="col-12 mb-2">
                <AppInput.Input type="text" name="last_name" className="form-control text-field" placeholder="Last Name" onValidate={FormValidate.isName} failClass="invalid" />
            </div>
        </div>
    </HeComponents.Card>;
}