import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import GoogleAddress from "../../../directives/GoogleAddress";
import CoreTools from "../../../services/CoreTools";
import OptionLists from "../../../services/OptionLists";
import HeComponents from "../inserts/HeComponents";

export default class HeAddressinformation extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => this.state.addressSet && isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };

    provinceOptions = [
        {value: "", caption: "Select Province"},
        ...OptionLists.Provinces.map(i => ({value: i.short, caption: i.long}))
    ];
    
    onApplicant = (state, props, applicant) => {
        this.setVal({addressSet: applicant.field("street_number") && applicant.field("street_name") && applicant.field("city") && applicant.field("state") && applicant.field("zip_code") ? true : false});
        this.wHolder(this.applicant.onFieldUpdate("google_address", newValue => {
            this.setVal({addressSet: applicant.field("street_number") && applicant.field("street_name") && applicant.field("city") && applicant.field("state") && applicant.field("zip_code") ? true : false});
        }));
    };

    postalCodeFormat = event => {
        if (typeof(event.target.value) === "string") {
            const proper = event.target.value.replace(/[^a-z0-9]/gi, "").toUpperCase().substring(0, 6);
            if (event.target.value !== proper) {
                const cPos = event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = cPos;
                event.target.selectionEnd = cPos;
            }
        }
    };

    postalCodeValidate = value => (
        typeof(value) === "string" &&
        value.length === 6 &&
        CoreTools.match(value.substring(0, 1), /[A-Z]/) &&
        CoreTools.match(value.substring(1, 2), /[0-9]/) &&
        CoreTools.match(value.substring(2, 3), /[A-Z]/) &&
        CoreTools.match(value.substring(3, 4), /[0-9]/) &&
        CoreTools.match(value.substring(4, 5), /[A-Z]/) &&
        CoreTools.match(value.substring(5, 6), /[0-9]/)
    );

    content = (state, props, applicant) => <HeComponents.Card
        title="What is your address?"
        text="Enter your physical address in the box below to quickly autocomplete all required fields."
        controls={<HeComponents.Controls continue={this.continue} buttonText={props.buttonText} disclaimer={props.disclaimer} disabled={!this.state.addressSet} />}
    >
        <div className="form-row text-center mb-3">
            <div className="col-12">
                <div id="gm-autocomplete-wrap" className="form-field mb-3">                    
                    <GoogleAddress
                        country="ca"
                        className="form-control text-center"
                        placeholder="Type Here to Search For Your Address"
                        defaultValue={applicant.field("google_address") || ""}
                        onSelect={address => {
                            applicant.setField("google_address", address.full);
                            applicant.setField("street_number", address.number);
                            applicant.setField("street_name", address.street);
                            applicant.setField("city", address.city);
                            applicant.setField("state", address.provinceShort);
                            applicant.setField("zip_code", address.postalcode.replace(" ", ""));
                            this.setVal({addressSet: true}, () => console.log(state.addressSet));
                        }}
                    />
                </div>
            </div>
        </div>
        { state.addressSet && <div className="form-row address-fields">
            <div className="col-12 mb-1">
                <AppInput.Input type="text" name="street_number" placeholder="Street Number" onValidate={v => v} className="form-control text-field" failClass="invalid" />
            </div>
            <div className="col-12 mb-1">
                <AppInput.Input type="text" name="street_name" placeholder="Street Name" onValidate={v => v} className="form-control text-field" failClass="invalid" />
            </div>
            <div className="col-12 mb-1">
                <AppInput.Input type="text" name="unit" placeholder="Unit/Apt/Suite" className="form-control" />
            </div>
            <div className="col-12 mb-1">
                <AppInput.Input type="text" name="city" placeholder="City" onValidate={v => v} className="form-control text-field" failClass="invalid" />
            </div>
            <div className="col-12 mb-1">
                <AppInput.Select type="text" name="state" options={this.provinceOptions} className="form-control" onValidate={v => v} failClass="invalid" />			
            </div>
            <div className="col-12 mb-1">
                <AppInput.Input type="text" name="zip_code" placeholder="Postal Code" className="form-control" onValidate={this.postalCodeValidate} failClass="invalid" onChange={this.postalCodeFormat} />
            </div>
        </div> }
    </HeComponents.Card>;
}