import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";

export default class SurexAddress extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };

    content = (state, props) => <SurexComponents.Card
        title="What Province are you driving in?"
        // tooltip={<SurexComponents.ToolTip tooltip="" />}
    >
        <div className="form-row">
            <div className="col-md-12 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="AB" onClick={this.continue} />
                    <span>Alberta</span>
                </label>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-12 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="ON" onClick={this.continue} />
                    <span>Ontario</span>
                </label>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-12 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="Other" onClick={this.continue} />
                    <span>Other</span>
                </label>
            </div>
        </div>
    </SurexComponents.Card>;
}
