import CoreTools from "../../services/CoreTools";
import GenSet from "../../services/GenSet";
import BuildSocket from "./BuildSocket";
import FormValidate from "../../services/FormValidate";

export default class BuildLogin extends GenSet {
    socket = BuildSocket();
    init = this.setVal({
        warning: false,
        screen: "login",
        validKey: null
    });
    admin = {};

    setWarning = msg => {
        this.setVal({warning: msg});
        setTimeout(() => this.setVal({warning: false}), 5000);
    };

    submit = values => {
        console.log("Submit:", values);
        if (values.email && values.password && typeof(values.password) === "string" && values.password.length >= 8) {
            this.socket.send("login", values);
        } else {
            this.setWarning("Please Enter Email & Password");
        }
    };

    reset = values => {
        console.log("Reset:", values);
        if (!FormValidate.isEmail(values.email)) {
            this.setWarning("Please Supply a Valid Email Address");
        } else {
            this.socket.send("resetPassword", values);
        }
    };

    save = values => {
        console.log("Save:", values);
        if (!FormValidate.isEmail(values.email)) {
            this.setWarning("Please Supply a Valid Email Address");
        } else if (!values.key || values.key.length < 64) {
            this.setWarning("Please Supply the Key From Your Email");
        } else if (!values.password) {
            this.setWarning("Please Enter a new Password");
        } else if (values.password !== values.confirmPassword) {
            this.setWarning("Password & Confirm Password Don't Match");
        } else if (values.password.length < 8) {
            this.setWarning("Passwords must be atleast 8 characters long");
        } else {
            this.socket.send("savePassword", values);
        }
    };

    testKey = event => {
        if (event.target.value?.length !== 64) {
            if (this.state.validKey !== false) {this.setVal({validKey: false});}
        } else {
            this.socket.send("checkKey", {key: event.target.value});
        }
    };

    enterKey = event => {
        event.preventDefault();
        this.setVal({screen: "enterKey"});
    };

    onMount = () => {
        this.wHolder(this.socket.on("login", data => {
            console.log("Login Result:", data);
            if (data.success) {

            }
        }));
        this.wHolder(this.socket.on("setPanel", data => {
            console.log("setPanel", data);
            this.setVal({screen: data.panel});
        }));
        this.wHolder(this.socket.on("message", data => this.setWarning(data.message)));
        this.wHolder(this.socket.on("validKey", data => {
            if (data.valid) {
                this.setVal({validKey: true});
            } else {
                this.setVal({validKey: false});
                this.setWarning(data.message);
            }
        }));
    };


    screens = {
        login: state => <>
            <div className="desc-title">Admin Login</div>
            {state.warning && <div className="login-warning">{state.warning}</div>}
            <form onSubmit={CoreTools.fromForm(this.submit)}>
                <label>Email Address</label>
                <input type="text" name="email" placeholder="Email Address" defaultValue={this.admin.email} />
                <label>Password</label>
                <input type="password" name="password" placeholder="Password" />
                <div><button className="small" type="submit">Login</button></div>
            </form>
            <div className="login-link"><span onClick={() => this.setVal({screen: "reset"})}>Reset My Password</span></div>
        </>,
        reset: state => <>
            <div className="desc-title">Reset My Password</div>
            {state.warning && <div className="login-warning">{state.warning}</div>}
            <form onSubmit={CoreTools.fromForm(this.reset)}>
                <label>Email Address</label>
                <input type="text" name="email" placeholder="Email Address" defaultValue={this.admin.email} />
                <div><button className="small" type="submit">Request Reset</button></div>
            </form>
            <div className="login-link"><span onClick={() => this.setVal({screen: "enterKey"})}>Enter Key</span></div>
            <div className="login-link"><span onClick={() => this.setVal({screen: "login"})}>Return To Login</span></div>
        </>,
        enterKey: state => <>
            <div className="desc-title">Save New Password</div>
            {state.warning && <div className="login-warning">{state.warning}</div>}
            <form onSubmit={CoreTools.fromForm(this.save)}>
                <label>Email Address</label>
                <input type="text" name="email" placeholder="Email Address" defaultValue={this.admin.email} />
                <label>Reset Key (Check Email){CoreTools.switch(
                    [state.validKey === true, () => <i className="fa-solid fa-check" style={{float: "right", color: "#00dd00"}}></i>],
                    [state.validKey === false, () => <i className="fa-solid fa-x" style={{float: "right", color: "#dd0000"}}></i>],
                    [true, null]
                )}</label>
                <input type="text" name="key" placeholder="Reset Key (Check Email)" onChange={this.testKey} onPaste={this.testKey} />
                <label>Password</label>
                <input type="password" name="password" placeholder="Password" />
                <label>Confirm Password</label>
                <input type="password" name="confirmPassword" placeholder="Confirm Password" />
                <div><button className="small" type="submit">Save Password</button></div>
            </form>
            <div className="login-link"><span onClick={() => this.setVal({screen: "login"})}>Return To Login</span></div>
        </>
    };

    content = (state, props) => <div className="login">{
        CoreTools.switch(
            state.screen,
            ["login", () => this.screens.login(state)],
            ["reset", () => this.screens.reset(state)],
            ["enterKey", () => this.screens.enterKey(state)]
        )
    }</div>;
};
