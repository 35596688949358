import GenSet from "../../../services/GenSet";
import CoreTools from "../../../services/CoreTools";
import React from "react";
import AppInput from "../../../directives/AppInput";
import FormValidate from "../../../services/FormValidate";

class PanelPersonal extends GenSet {
    phoneFormat = event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };

    yearOptions = (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year - 17; i > year - 100; i--) {years.push(i.toString());} return years;})();
    monthOptions = (() => {let months = [{value: "", caption: "Month"}]; for (let i = 1; i <= 12; i++) {months.push(i.toString());} return months;})();
    dayOptions = (() => {let days = [{value: "", caption: "Day"}]; for (let i = 1; i <= 31; i++) {days.push(i.toString());} return days;})();
    
    errMsgHdlr = (cond, name, msg) => v => {
        console.log('v:', v.length)
        if (!cond(v)) {
            CoreTools.emit("setErrorMessage", name, msg);
        } 
        return cond(v);
    };

    continue = () => this.applicant.validate(isValid => {
        if (isValid) {
            this.applicant.setField("dob", [this.applicant.field("dob_year"), this.applicant.field("dob_month"), this.applicant.field("dob_day")].map(e => typeof(e) === "string" && e.padStart(2, "0")).join("-"));
            this.navigate("#Processing");
        } else {window.scrollTo(0, 0);}
    });

    content = () => <div className="form-panel">
        <div className="row">
            <div className="col-md-6">
                <span className="label pb-2 font-weight-bold">First Name</span>
                <span className="input-group">                    
                    <AppInput.Input name="first_name" placeholder="First Name" onValidate={FormValidate.isName} onChange={FormValidate.onlyLetters} failClass="invalid" />
                </span>
            </div>
            <div className="col-md-6">
                <span className="label pb-2 font-weight-bold">Last Name</span>   
                <span className="input-group">                    
                    <AppInput.Input name="last_name" placeholder="Last Name" onValidate={FormValidate.isName} onChange={FormValidate.onlyLetters} failClass="invalid" />
                </span>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <span className="label font-weight-bold">Birthdate</span>
                <div className="pb-2">
                    <span>UK residents aged between 50-80 can apply</span>
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="input-group">                    
                            <AppInput.Select name="dob_day" options={this.dayOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                    <div className="col-4">
                        <span className="input-group">                    
                            <AppInput.Select name="dob_month" options={this.monthOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                    <div className="col-4">
                        <span className="input-group">                    
                            <AppInput.Select name="dob_year" options={this.yearOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <span className=" label pb-2 font-weight-bold">Email</span>
                <span className="input-group">                    
                    <AppInput.Input name="email" onValidate={FormValidate.isEmail} failClass="invalid" placeholder="e.g. name@email.com" />
                </span>
            </div>
            <div className="col-md-6">
                <span className="label pb-2 font-weight-bold">Phone Number</span>
                <span className="input-group">                    
                    <AppInput.Input name="phone" onValidate={FormValidate.isPhone} placeholder="e.g. 07700900796" onKeyDown={FormValidate.maxLength(11)} type="tel" failClass="invalid" />
                </span>
            </div>
        </div>
        <div className="w-100">
            <div className="disclaimer-text p-3">
                <div className="row mt-3 border border-secondary">
                    <div className="col p-3">
                        <label className="btn-checkbox d-inline-flex flex-row">
                            <span className="consent-checkbox d-inline-block" style={{fontSize: "0.85em", lineHeight: "1.4" }}>
                                More information about how we use your personal information can be found in our <a href="https://seniorliferates.uk/privacy-policy/" target="_blank" rel="noreferrer" style={{fontSize: "0.85em"}}>Privacy and Policy</a>. Please also see our <a href="https://seniorliferates.uk/terms-and-conditions/" target="_blank" rel="noreferrer" style={{fontSize: "0.85em"}}>Terms of Use</a> for more about our service. By clicking “Request a Quote” you agree to seniorliferates sharing your personal information with an FCA regulated over 50s life insurance provider we partner with, who will contact you by telephone or email, to provide you with a quote. We currently partner with British Seniors. British Seniors’ Privacy Policy can be found <a href="https://www.britishseniors.co.uk/privacy-policy/" target="_blank" rel="noreferrer" style={{fontSize: "0.85em"}}>here</a>
                            </span>
                        </label>
                    </div>
                </div>
                <div className="row mt-3 border border-secondary">
                    <div className="col-12 pt-2 px-2">
                        <label className="btn-checkbox mt-1">
                            <span className="consent-checkbox d-inline-block" style={{fontSize: "0.85em", lineHeight: "1.4" }}>
                                We would like to send you more information on our products and services in accordance with the <a href="https://seniorliferates.uk/privacy-policy/" target="_blank" rel="noreferrer" style={{fontSize: "0.85em"}}>Privacy Policy</a>. You can unsubscribe at any time. If you would like to receive such communications from us please tick the boxes below:
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div className="checkbox-main-container">
            {/* <div className="col-12 pb-2 px-2">
                <div className="row"> */}
                    <div className="checkbox-container col">
                        <label className="btn-checkbox mt-1">
                            <AppInput.CheckBox className="consent-checkbox-item" name="cgi_sms_consent"/>
                            <b>SMS</b>
                        </label>
                    </div>
                    <div className="checkbox-container col">
                        <label className="btn-checkbox mt-1">
                            <AppInput.CheckBox className="consent-checkbox-item" name="cgi_email_consent"/>
                            <b>Email</b>
                        </label>
                    </div>
                    <div className="checkbox-container col">
                        <label className="btn-checkbox mt-1">
                            <AppInput.CheckBox className="consent-checkbox-item" name="cgi_phone_consent"/>
                            <b>Telephone</b>
                        </label>
                    </div>
                {/* </div>
            </div> */}
        </div>
        <div className="continue-button mt-3" onClick={this.continue}>Request a Quote</div>
    </div>;
};

export default class BSFlatForm extends GenSet {
    onMount = () => {
        if (!CoreTools.state.appOptions.includes("nocss")) {
            if (CoreTools.state.isLocal) {
                CoreTools.importStyle("/css/loanz-flatform-us.css");
            } else {
                CoreTools.importStyle("https://form.consumergenius.com/css/loanz-flatform-us.css");
            }
        }
    };

    content = state => <div className="form-body">
        <PanelPersonal {...this.props}/>
    </div>;
}
