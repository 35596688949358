import GenSet from "../services/GenSet";

export default class TrustedFormCert extends GenSet {
    onMount = () => {
        this.tf = document.createElement('script');
        this.tf.type = 'text/javascript';
        this.tf.async = true;
        this.tf.src = "https://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&l=" + new Date().getTime() + Math.random();
        const s = document.getElementsByTagName('script')[0]; 
        s.parentNode.insertBefore(this.tf, s);
    };

    onUnmount = () => {
        if (this.tf) {this.tf.remove();}
    };

    xxTF = {
        xxTrustedFormToken: "",
        xxTrustedFormCertUrl: "",
        xxTrustedFormPingUrl: "",
        timeout: 15
    };

    setRef = ref => {
        const getByName = name => {
            let input = document.getElementsByName(name);
            return input && input.length ? (input[0].value || "") : "";
        };
        const load = () => {
            this.xxTF.xxTrustedFormToken = getByName("xxTrustedFormToken");
            this.xxTF.xxTrustedFormCertUrl = getByName("xxTrustedFormCertUrl");
            this.xxTF.xxTrustedFormPingUrl = getByName("xxTrustedFormPingUrl");
            if (!this.xxTF.xxTrustedFormToken || !this.xxTF.xxTrustedFormCertUrl || !this.xxTF.xxTrustedFormPingUrl) {
                if (this.xxTF.timeout > 0) {
                    this.xxTF.timeout--;
                    setTimeout(load, 1000);
                }
            } else {
                this.applicant.setField("xxTrustedFormToken", this.xxTF.xxTrustedFormToken);
                this.applicant.setField("xxTrustedFormCertUrl", this.xxTF.xxTrustedFormCertUrl);
                this.applicant.setField("xxTrustedFormPingUrl", this.xxTF.xxTrustedFormPingUrl);
                console.log("xxTrustedForm Complete", this.xxTF);
            }
        };
        setTimeout(load, 1000);
    };
    content = (state, props) => <form ref={ref => this.setRef(ref)} onSubmit={e => e.preventDefault()}>{props.children}</form>;
}

