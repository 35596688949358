import CoreTools from "../../../services/CoreTools";
import GenSet from "../../../services/GenSet";
import Lang from "../../../services/Lang";

class NavPanel extends GenSet {
    onMount = (state, props) => {
        this.wHolder(CoreTools.on("NavChange", (previous, time, msg) => setTimeout(() => this.setVal({previous: previous, time: time, msg: msg}))));
        this.wHolder(CoreTools.on("resize", event => {
            if (this.iRef && this.iRef.offsetWidth < 420 && !this.state.mobile) {this.setVal({mobile: true});}
            if (this.iRef && this.iRef.offsetWidth >= 420 && this.state.mobile) {this.setVal({mobile: false});}
        }));
    };

    setElem = ref => {
        this.iRef = ref;
        setTimeout(() => {
            if (this.iRef && this.iRef.offsetWidth < 420 && !this.state.mobile) {this.setVal({mobile: true});}
            if (this.iRef && this.iRef.offsetWidth >= 420 && this.state.mobile) {this.setVal({mobile: false});}
        });
    };

    content = (state, props) => <div ref={this.setElem}>{state.mobile ? <div>
        <div style={{textAlign: "center", padding: "10px 0px"}}><i className="fa-solid fa-stopwatch"></i>{state.time} {state.msg || "Seconds Left"}</div>
        <div style={{textAlign: "center"}}><button disabled={!state.previous} onClick={() => this.navigate(state.previous)} className="btn" style={{width: "200px", margin: "0 auto"}}><i className="fa-solid fa-arrow-left"></i> <Lang>previous</Lang></button></div>
    </div> : <div>
        <button disabled={!state.previous} onClick={() => this.navigate(state.previous)} className="btn" style={{width: "200px"}}><i className="fa-solid fa-arrow-left"></i> <Lang>previous</Lang></button>
        <span className="timer"><i className="fa-solid fa-stopwatch"></i>{state.time} {state.msg || <Lang>secondsLeft</Lang>}</span>
    </div>}</div>;
}

const NavOption = props => {
    setTimeout(() => CoreTools.emit("NavChange", props.previous, props.time, props.msg));
};

const ClrObjects = {
    NavPanel: NavPanel,
    NavOption: NavOption
};

export default ClrObjects;