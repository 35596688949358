import Applicant from "../../../services/Applicant";

const Card = props => <fieldset className="card card-options">
    <div className="card-body">
        <div className="card-fields">
            {props.lastSlideSubtitle && <h5 className="card-title text-center">Last step, {Applicant().field("first_name")}</h5>}
            {props.lastSlideSubtitleTwo && <h5 className="card-title text-center mb-5">Rates for your {Applicant().field("year")} {Applicant().field("make")} are ready!</h5>}
            <h5 className="card-title text-center">{props.title}</h5>
            {props.text && <p className="card-text text-center">{props.text}</p>}
            <div className="card-fields-options">
                {props.children}
            </div>
        </div>
        {props.controls}
        {props.tooltip}
    </div>
</fieldset>;

const Controls = props => <div className="card-controls text-center">
    { props.buttonText !== false && <button disabled={props.disabled} onClick={props.continue} className="mx-auto btn btn-navigate">{props.buttonText || "Next"}</button>}
    { props.disclaimer && <p className="term-txt text-center mt-1"><input type="hidden" id="leadid_tcpa_disclosure"/><b>By clicking "{props.buttonText || "Next"}", I have read and accept the <a href="/terms-and-conditions/" target="_blank" rel="noreferrer">Terms of Use</a>. I also acknowledge having read the <a href="/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> and agree to the terms herein.</b></p>}
</div>;

const ToolTip = props => <div className="card-tooltip form-row justify-content-center align-items-center">
        <div className="col-8 mb-2">
            <span className="tooltitle"></span>
            <span className="card-text text-center">{props.tooltip}</span>
        </div>
    </div>;

const SurexComponents = {
    Card: Card,
    Controls: Controls,
    ToolTip: ToolTip
};

export default SurexComponents;
