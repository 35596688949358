import CoreTools from "./CoreTools";

const addTag = id => {
    if (!window["dataLayer"]) {window["dataLayer"] = [];}
    window["dataLayer"].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
    let j = document.createElement("script");
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + id;

    const f = document.getElementsByTagName("script")[0];
    f.parentNode.insertBefore(j, f);
};

const event = event => {
    if (!window["dataLayer"]) {window["dataLayer"] = [];}
    try {
        if (typeof(event) === "string") {
            window.dataLayer.push({event: event});
        } else if (CoreTools.isObject(event)) {
            window.dataLayer.push(event);
        }
    } catch (err) {
        console.log("dataLayer Push Error:", err);
    }
};

const page = (name, extra) => event(CoreTools.fuseObj({event: "page", pageName: name}, extra));

const GoogleTagManager = {
    addTag: addTag,
    event: event,
    page: page
};

export default GoogleTagManager;