import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";

export default class SurexCurrentProvider extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };
    
    content = (state, props) => <SurexComponents.Card
        title="Are you currently insured?"
    >
        <div className="form-row">
            <div className="col-md-12 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="currently_insured" value="Yes" onClick={this.continue} />
                    <span>Yes</span>
                </label>
            </div>
            <div className="col-md-12 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="currently_insured" value="No" onClick={this.continue} />
                    <span>No</span>
                </label>
            </div>
        </div>
    </SurexComponents.Card>;
}