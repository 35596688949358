import ReactDOM from 'react-dom/client';
import BuildManager from './builder/admin/BuildManager';
import CoreTools from "./services/CoreTools";

const Loading = () => <div>Funnel Component...</div>;

const Builder = funnelBuilder => {
    ReactDOM.createRoot(funnelBuilder).render(<Loading />);
    if (CoreTools.getQueryParam("admin") === "1") {
        const editor = document.createElement("div");
        editor.setAttribute("id", "buildManager");
        document.body.appendChild(editor);
        ReactDOM.createRoot(editor).render(<BuildManager />);
    }
};
export default Builder;