import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import FormValidate from "../../../services/FormValidate";
import CoreTools from "../../../services/CoreTools";

export default class SurexEmailAddress extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };
    
    content = (state, props) => <SurexComponents.Card
        title="What is your e-mail address?"
        controls={<SurexComponents.Controls continue={this.continue} />}
    >
        <div className="form-row justify-content-center">
            <div className="col-sm-8 mb-2">
                <label className="border-label">E-mail</label>
                <AppInput.Input type="email" name="email" className="form-control text-field" placeholder="Email Address" onValidate={FormValidate.isEmail} failClass="invalid" />
            </div>
        </div>
    </SurexComponents.Card>;
}
