import GenSet from "../../../services/GenSet";
import CoreTools from "../../../services/CoreTools";
import React from "react";
import AppInput from "../../../directives/AppInput";
import FormValidate from "../../../services/FormValidate";
// import Icon from "../../directives/Icon";

class PanelInsurance extends GenSet {
    whoInsured = [
        {value: "", caption: "Please Select"},
        {value: "me", caption: "Just Me"},
        {value: "me_and_family", caption: "My Family & I"}
    ]

    whoWhatInsured = [
        {value: "", caption: "Please Select"},
        {value: "my_family", caption: "My Family"},
        {value: "my_mortgage", caption: "My Mortgage"},
        {value: "my_family_my_mortgage", caption: "My Family & My Mortgage"},
        {value: "end_of_life_coverage", caption: "End of Life Coverage"}
    ]

    coverage = [
        {short: "", caption: "Please Select"},
        {short: "1_to_2500000", caption: "$1 - $250,000"},
        {short: "250001_to_500000", caption: "$250,001 - $500,000"},
        {short: "500001_to_750000", caption: "$500,001 - $750,000"},
        {short: "750001_to_1000000", caption: "$750,001 - $1,000,000"},
        {short: "1500000", caption: "$1,000,001 - $1,500,000"},
    ]

    insuranceType = [
        {short: "", caption: "Please Select"},
        {short: "term", caption: "Term"},
        {short: "permanent", caption: "Permanent"},
        {short: "not_sure", caption: "Not Sure"},
    ]

    insuranceGender = [
        {short: "", caption: "Please Select"},
        {short: "male", caption: "Male"},
        {short: "female", caption: "Female"},
    ]

    insuranceSmoker = [
        {short: "", caption: "Please Select"},
        {short: "smoker", caption: "Smoker"},
        {short: "non_smoker", caption: "Non-Smoker"},
    ]

    continue = () => this.navigate("#Processing");

    content = () => <div className="form-panel">
        <div className="row">
            <div className="col">
                <span className="section-header-text">Your Insurance Details</span>
            </div>
        </div>
        <hr className="horizontal-line"/>
        <div className="row">
            <div className="col-md-6">
                <span className="label">Who are you looking to be insured?</span>
                <div>
                    <span className="input-group">                    
                        <AppInput.Select name="who_insured" options={this.whoInsured} onValidate={v => v} failClass="invalid" />
                    </span>
                </div>
            </div>
            <div className="col-md-6">
                <span className="label">Which of the following would you like to cover?</span>   
                <div>
                    <span className="input-group">                    
                        <AppInput.Select name="who_what_insured" options={this.whoWhatInsured} onValidate={v => v} failClass="invalid" />
                    </span>
                </div>         
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <span className="label">How much coverage would you like?</span>
                <div>
                    <span className="input-group">                    
                        <AppInput.Select name="coverage" options={this.coverage} onValidate={v => v} failClass="invalid" />
                    </span>
                </div>
            </div>
            <div className="col-md-6">
                <span className="label">What type of life insurance are you looking for?</span>   
                <div>
                    <span className="input-group">                    
                        <AppInput.Select name="insurance_type" options={this.insuranceType} onValidate={v => v} failClass="invalid" />
                    </span>
                </div>         
            </div>            
        </div>
        <div className="row">
            <div className="col col-sm-6 col-12">
                <span className="label">Gender</span>
                <div>
                    <span className="input-group">                    
                        <AppInput.Select name="gender" options={this.insuranceGender} onValidate={v => v} failClass="invalid" />
                    </span>
                </div>
            </div>
            <div className="col col-sm-6 col-12">
                <span className="label">Smoking</span>
                <div>
                    <span className="input-group">                    
                        <AppInput.Select name="smoker" options={this.insuranceSmoker} onValidate={v => v} failClass="invalid" />
                    </span>
                </div>
            </div>
        </div>
    </div>;
    };

class PanelLoan extends GenSet {
    provinces = [
        {short: "", caption: "Please Select"},
        {short: "AB", caption: "Alberta"},
        {short: "BC", caption: "British Columbia"},
        {short: "MB", caption: "Manitoba"},
        {short: "NB", caption: "New Brunswick"},
        {short: "NL", caption: "Newfoundland and Labrador"},
        {short: "NS", caption: "Nova Scotia"},
        {short: "ON", caption: "Ontario"},
        {short: "PE", caption: "Prince Edward Island"},
        {short: "QC", caption: "Quebec"},
        {short: "SK", caption: "Saskatchewan"},
        {short: "NT", caption: "Northwest Territories"},
        {short: "NU", caption: "Nunavut"},
        {short: "YT", caption: "Yukon"} 
    ];

    phoneFormat = event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };

    yearOptions = (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year - 17; i > year - 100; i--) {years.push(i.toString());} return years;})();
    monthOptions = (() => {let months = [{value: "", caption: "Month"}]; for (let i = 1; i <= 12; i++) {months.push(i.toString());} return months;})();
    dayOptions = (() => {let days = [{value: "", caption: "Day"}]; for (let i = 1; i <= 31; i++) {days.push(i.toString());} return days;})();
    
    errMsgHdlr = (cond, name, msg) => v => {
        console.log('v:', v.length)
        if (!cond(v)) {
            CoreTools.emit("setErrorMessage", name, msg);
        } 
        return cond(v);
    };

    content = () => <div className="form-panel">
        <div className="row">
            <div className="col">
                <span className="section-header-text">Your Personal Details</span>
            </div>
        </div>
        <hr className="horizontal-line"/>
        <span className="customer-message-text">We take security very seriously. Your details will remain secure.</span>
              
        <div className="row">
            <div className="col">
                <span className="label">Birthdate</span>
                <div className="row">
                    <div className="col-4 p-1">
                        <span className="input-group">                    
                        <AppInput.Select name="dob_month" options={this.monthOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                    <div className="col-4 p-1">
                        <span className="input-group">                    
                        <AppInput.Select name="dob_day" options={this.dayOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                    <div className="col-4 p-1">
                        <span className="input-group">                    
                        <AppInput.Select name="dob_year" options={this.yearOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-4">
                <span className="label">First Name</span>
                <span className="input-group">                    
                    <AppInput.Input name="first_name" placeholder="First Name" onValidate={FormValidate.isName} onChange={FormValidate.onlyLetters} failClass="invalid" />
                </span>
            </div>
            <div className="col-md-4">
                <span className="label">Last Name</span>   
                <span className="input-group">                    
                    <AppInput.Input name="last_name" placeholder="Last Name" onValidate={FormValidate.isName} onChange={FormValidate.onlyLetters} failClass="invalid" />
                </span>
            </div>
            <div className="col-md-4">
                <span className=" label">Email</span>
                <span className="input-group">                    
                    <AppInput.Input name="email" onValidate={FormValidate.isEmail} failClass="invalid" />
                </span>
            </div>
        </div>
        <div className="row">
            <div className="col-md-4">
                <span className="label">Phone Number</span>
                <span className="input-group">                    
                    <AppInput.Input name="phone_number" onValidate={FormValidate.isPhone} placeholder="(XXX) XXX-XXXX" onChange={this.phoneFormat} onKeyDown={FormValidate.maxLength(10)} type="tel" failClass="invalid" />
                </span>
            </div>
            <div className="col-md-4">
                <span className="label">Province</span>   
                <span className="input-group">                    
                    <AppInput.Select name="province" options={this.provinces} onValidate={v => v} failClass="invalid" />
                </span>
            </div>
        </div>
        <div className="continue-button mt-3" onClick={() => this.continue()}>Continue</div>
    </div>;
};

export default class ClrFlatForm extends GenSet {
    onMount = () => {
        if (!CoreTools.state.appOptions.includes("nocss")) {
            if (CoreTools.state.isLocal) {
                CoreTools.importStyle("/css/loanz-flatform-us.css");
            } else {
                CoreTools.importStyle("https://form.consumergenius.com/css/loanz-flatform-us.css");
            }
        }
    };

    content = state => <div className="form-body">
    <PanelInsurance {...this.props}/>
        <PanelLoan {...this.props}/>
    </div>;
}
