import CoreTools from "./CoreTools";

const SubForm = () => {
    let fieldWatchers = [];
    let validateWatchers = [];
    let app = {
        fields: {}
    };

    const updateFields = fields => Object.keys(fields).forEach(key => {
        if (["string", "boolean", "number"].includes(typeof(fields[key])) || fields[key] === null) {
            if (app.fields[key] !== fields[key]) {
                app.fields[key] = fields[key];
                fieldWatchers.filter(w => w.name === key).forEach(w => w.cb(app.fields[key]));
            }
        } else {
            app.fields[key] = fields[key];
            fieldWatchers.filter(w => w.name === key).forEach(w => w.cb(app.fields[key]));
        }
    });

    return {
        field: (name, value) => {
            if (value !== undefined) {updateFields({[name]: value});}
            return app.fields[name];
        },
        setField: (name, value, defaultValue) => {
            const useValue = value === undefined ? (defaultValue || null) : value;
            updateFields({[name]: useValue});
        },
        fAlt: (name, def) => app.fields[name] !== undefined ? app.fields[name] : def,
        onFieldUpdate: (name, cb) => {
            const watch = {name: name, cb: cb};
            fieldWatchers.push(watch);
            return () => CoreTools.remove(fieldWatchers, watch);
        },
        valWatch: cb => {
            const watch = {cb: cb};
            validateWatchers.push(watch);
            return () => CoreTools.remove(validateWatchers, watch);
        },
        validate: cb => setTimeout(() => cb(!validateWatchers.map(w => w.cb() ? true : false).includes(false))),
        fields: app.fields
    };
};

export default SubForm;