import GenSet from "../../../services/GenSet";
import SurexComponents from "../inserts/SurexComponents";
import LegacyDOBPicker from '../../../directives/LegacyDOBPicker';
import CoreTools from "../../../services/CoreTools";

export default class SurexDateBirth extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => {
            if (isValid) {
                this.applicant.setField("dob", [this.applicant.field("dobYear"), this.applicant.field("dobMonth"), this.applicant.field("dobDay")].join("-"));
                this.navigate(this.props.continue);
            } else {CoreTools.centerForm();}
        });
    };
    content = (state, props) => <SurexComponents.Card
        title="When were you born?"
        text="Select your date of birth from the dropdowns below."
        controls={<SurexComponents.Controls continue={this.continue} />}
    >
        
        <LegacyDOBPicker onSelect={v => this.setVal({ready: v})} />
    </SurexComponents.Card>;
}