import GenSet from "../../../services/GenSet";
// import Applicant from "../../../services/Applicant";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";

export default class CarsfastMonthlyIncome extends GenSet {
    title = CoreTools.emit("panelTitle", "What is your current Monthly Gross Income?");
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <div className="form-row">
                <div className="col-lg-6 mb-2">
                    <label className="btn btn-option">
                        <AppInput.Radio name="monthly_income" value="Under $1800" onClick={this.onClick} />
                        <span className="btn-option-text">Under $1800</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-2">
                    <label className="btn btn-option">
                        <AppInput.Radio name="monthly_income" value="$1800 to $2200" onClick={this.onClick} />
                        <span className="btn-option-text">$1800 to $2200</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-2">
                    <label className="btn btn-option">
                        <AppInput.Radio name="monthly_income" value="$2200 to $3000" onClick={this.onClick} />
                        <span className="btn-option-text">$2200 to $3000</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-2">
                    <label className="btn btn-option">
                        <AppInput.Radio name="monthly_income" value="$3000 to $4500" onClick={this.onClick} />
                        <span className="btn-option-text">$3000 to $4500</span>
                    </label>
                </div>
                <div className="col-lg-6 mb-2">
                    <label className="btn btn-option">
                        <AppInput.Radio name="monthly_income" value="Over $4500" onClick={this.onClick} />
                        <span className="btn-option-text">Over $4500</span>
                    </label>
                </div>
            </div>
        </div>
    </div>;

}