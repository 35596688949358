import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import JugglingDebtComponents from "../inserts/JugglingDebtComponents";

export default class JugglingDebtHomeCountry extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };
    
    content = (state, props) => <JugglingDebtComponents.Card
        title="What are you debt concerns?"
        controls={<JugglingDebtComponents.Controls continue={this.continue} buttonText={false} disclaimer={props.disclaimer} />}
    >
        <div className="form-row split">
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="home_country" value="England" onClick={this.continue} />
                    <span>England</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="home_country" value="Scotland" onClick={this.continue} />
                    <span>Scotland</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="home_country" value="Wales" onClick={this.continue} />
                    <span>Wales</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="home_country" value="North Ireland" onClick={this.continue} />
                    <span>North Ireland</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="home_country" value="Baliff or Court Actions" onClick={this.continue} />
                    <span>Baliff or Court Actions</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="home_country" value="None of The Above" onClick={this.continue} />
                    <span>None of The Above</span>
                </label>
            </div>
        </div>
    </JugglingDebtComponents.Card>;
}