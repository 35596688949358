import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";

export default class SurexAddress extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };

    content = (state, props) => <SurexComponents.Card
        title="Where do you live?"
        text="Let us know what province you live in by selecting it from the options below."
    >
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="AB" onClick={this.continue} />
                    <span>Alberta</span>
                </label>
            </div>
            <div className="col-md-6 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="BC" onClick={this.continue} />
                    <span>British Columbia</span>
                </label>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="MB" onClick={this.continue} />
                    <span>Manitoba</span>
                </label>
            </div>
            <div className="col-md-6 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="NB" onClick={this.continue} />
                    <span>New Brunswick</span>
                </label>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="NL" onClick={this.continue} />
                    <span>Newfoundland</span>
                </label>
            </div>
            <div className="col-md-6 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="NT" onClick={this.continue} />
                    <span>Northwest Territories</span>
                </label>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="NS" onClick={this.continue} />
                    <span>Nova Scotia</span>
                </label>
            </div>
            <div className="col-md-6 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="NU" onClick={this.continue} />
                    <span>Nunavut</span>
                </label>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="ON" onClick={this.continue} />
                    <span>Ontario</span>
                </label>
            </div>
            <div className="col-md-6 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="PEI" onClick={this.continue} />
                    <span>Prince Edward Island</span>
                </label>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="QB" onClick={this.continue} />
                    <span>Quebec</span>
                </label>
            </div>
            <div className="col-md-6 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="SK" onClick={this.continue} />
                    <span>Saskatchewan</span>
                </label>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-3 mb-2"></div>
            <div className="col-md-6 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="state" value="YT" onClick={this.continue} />
                    <span>Yukon Territory</span>
                </label>
            </div>
            <div className="col-md-3 mb-2"></div>
        </div>
    </SurexComponents.Card>;
}