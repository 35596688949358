import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";

export default class SurexGender extends GenSet {
    continue = (state, props) => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };
    
    content = (state, props) => <SurexComponents.Card
        title="What's your gender?"
    >
        <div className="form-row justify-content-center">
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="gender" value="M" onClick={this.continue}/>
                    <span>Male</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="gender" value="F" onClick={this.continue}/>
                    <span>Female</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="gender" value="X" onClick={this.continue}/>
                    <span>Non-Binary</span>
                </label>
            </div>
        </div>
    </SurexComponents.Card>;
}
