import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";

export default class CarsfastForward extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };

    setOutput = event => this.setVal({output: "$" + event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")});

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
        <h5 className="card-title">Thank you for your application</h5>
        <p className="card-text text-center">Unfortunately we don’t have a match for you, but based on your profile you may qualify for a personal loan so you have the cash in your pocket!</p>
            <div className="card-fields">
                <div className="form-row">
                    <div className="form-slider text-center">
                        <div className="form-value">{state.output}</div>
                        <AppInput.Range
                            style={{sidplay: "block"}}
                            name="loan_amount"
                            onValidate={v => Number(v)}
                            failClass="invalid"
                            onChange={this.setOutput}
                            onLoad={this.setOutput}
                            min="1000"
                            max="15000"
                            step="500"
                            defaultValue="3000"
                        />
                    </div>
                </div>
            </div>
            <div className="card-footer text-center mt-4">
                <button onClick={this.continue} className="mx-auto btn btn-navigate">Yes, I want a Loan</button>
            </div>
        </div>
    </div>;

}