import GenSet from "../../services/GenSet";
import CoreTools from "../../services/CoreTools"

import SurexVehicleNumber from "./panels/SurexVehicleNumber";
import SurexYourName from "./panels/SurexYourName";
// import SurexDateBirth from "./panels/SurexDateBirth";
import SurexContactInfo from "./panels/SurexContactInfo";
import SurexAddress from "./panels/SurexAddress";
import SurexVehicles from "./panels/SurexVehicles";
import SurexLicenceNumber from "./panels/SurexLicenceNumber";
import SurexLicenceInfo from "./panels/SurexLicenceInfo";
import SurexLicenceDob from "./panels/SurexLicenceDob";
// import SurexAddVehicle from "./panels/SurexAddVehicle";
import SurexAddDrivers from "./panels/SurexAddDrivers";

import PanelEffects from "../../directives/PanelEffects";
import SurexProcessing from "./panels/SurexProcessing";
import CircleBar from "../../directives/CircleBar";

export default class SurexIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (!CoreTools.state.appOptions.includes("nocss")) {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
            this.importStyle("/css/Surex.css");
            this.importStyle("/css/animate.css");
            this.importStyle("/css/rangeslider2.css");
        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.wHolder(this.applicant.on("hasBuyer", data => {
            console.log("hasBuyer", data);
            this.navigate("/surex-thank-you/");
            // if (data.isSold) {
            //     //Has Sold
            //     this.navigate(`/thank-you/id-${data.buyerId}/`);
            // } else {
            //     //Not Sold
            //     this.navigate("/thank-you/");
            // }
        }));
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}));
    };

    panelsOriginal = {
        "#VehicleNumber":   <SurexVehicleNumber />,
        "#YourName":        <SurexYourName />,
        // "#DateBirth":       <SurexDateBirth />,
        "#Address":         <SurexAddress />,
        "#LicenceNumber":   <SurexLicenceNumber />,
        "#LicenceInfo":     <SurexLicenceInfo />,
        "#LicenceDob":      <SurexLicenceDob />,
        "#Vehicles":        <SurexVehicles />,
        // "#AddVehicle":      <SurexAddVehicle />,
        "#AddDrivers":      <SurexAddDrivers />,
        "#ContactInfo":     <SurexContactInfo />,
        "#Processing":      <SurexProcessing />
    };

    content = (state, props) => <div className="overcard v1">
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <div className="appForm_inner">
            <CircleBar color="#1713c0"/>
            <PanelEffects.Transition3>
                <PanelEffects.Selector
                    selector={state.hash}
                    panels={CoreTools.switch(
                        [props.version === "original", this.panelsOriginal],
                    )} 
                />
            </PanelEffects.Transition3>
        </div>
    </div>;
}
