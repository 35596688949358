import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import JugglingDebtComponents from "../inserts/JugglingDebtComponents";

export default class JugglingDebtBehindPayments extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };
    content = (state, props) => <JugglingDebtComponents.Card
        title="Are you a Behind on Payments?"
        controls={<JugglingDebtComponents.Controls continue={this.continue} buttonText={false} disclaimer={props.disclaimer} />}
    >
        <div className="form-row split">
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="behind_payments" value="Yes, 30 Days" onClick={this.continue} />
                    <span>Yes, 30 Days</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="behind_payments" value="Yes, 30-60 Days" onClick={this.continue} />
                    <span>Yes, 30-60 Days</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="behind_payments" value="Yes, 60-90 Days" onClick={this.continue} />
                    <span>Yes, 60-90 Days</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="behind_payments" value="Yes, 90+ Days" onClick={this.continue} />
                    <span>Yes, 90+ Days</span>
                </label>
            </div>
            <div className="col-12">
                <label className="btn btn-option">
                    <AppInput.Radio name="behind_payments" value="No" onClick={this.continue} />
                    <span>No</span>
                </label>
            </div>
        </div>
    </JugglingDebtComponents.Card>;
}