import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";

export default class CarsfastWhereWork extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <h5 className="card-title">Where are you Working?</h5>
                <div className="form-row">
                    <div className="col-12 mb-2">
                        <AppInput.Input type="text" name="company_name" className="form-control text-field" placeholder="Company Name" onValidate={v => v} failClass="invalid" />
                        </div>
                    <div className="col-12 mb-2">
                        <AppInput.Input type="text" name="job_title" className="form-control text-field" placeholder="Title" onValidate={v => v} failClass="invalid" />
                    </div>
                </div>
            <div className="card-footer text-center mt-4">
                <button onClick={this.continue} className="mx-auto btn btn-navigate">Next</button>
            </div>
        </div>
    </div>;

}