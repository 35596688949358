import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import OptionLists from "../../../services/OptionLists";
import CoreTools from "../../../services/CoreTools";

export default class SurexLicenceInfo extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };

    classes = () => [{caption: "Licence Class", value: ""}, ...(OptionLists.DriverClassesCanada.find(c => c.province === CoreTools.asString(this.applicant.field("state")).toUpperCase()) || {classes: []}).classes.map(c => ({caption: "Class " + c, value: c}))];
    
    options = {
        gender: [{caption: "Gender", value: ""}, {caption: "Female", value: "female"}, {caption: "Male", value: "male"}, {caption: "Other", value: "other"}],
    }

    content = (state, props) => <SurexComponents.Card
        title="Awesome! Can you give us some additional information about your driver's licence?"
        controls={<SurexComponents.Controls continue={this.continue} />}
    >
        
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <span>Licence Class</span>
            </div>
            <div className="col-md-6 mb-2">
                <span>Gender on the Licence</span>
            </div>
        </div>
        <div className="form-row">
            <div className="col-md-6 mb-2">
                <AppInput.Select name="licence_class" className="subForm form-control text-field" options={this.classes()} defaultValue="" onValidate={v => v} failClass="invalid" />
            </div>
            <div className="col-md-6 mb-2">
                <AppInput.Select name="gender" className="subForm form-control text-field" options={this.options.gender} onValidate={v => v} failClass="invalid" />
            </div>
        </div>
    </SurexComponents.Card>;
                
}
