import React from "react";

const Popup = ({questions,closePopup,open}) => {
    const [ openQuestion, setOpenQuestion ] = React.useState(null);
    return <div data-open={open} className="popup">
        <div className="popup_head">
            <p>Ask a question</p>
            <button onClick={closePopup}>✖</button>
        </div>
        <div className="popup_body">
            {questions.map((e,i) => <div key={i}>
                <button onClick={() => setOpenQuestion(e)}>{e.q}</button>
                { openQuestion === e && <div><p>{e.a}</p></div> }
            </div>)}
        </div>
    </div>;
}
export default Popup;