import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";
import FormValidate from "../../../services/FormValidate";
import TrustedFormCert from "../../../directives/TrustedFormCert";

export default class SurexPhoneNumber extends GenSet {
    continue = () => {
        this.applicant.setField("leadid_token", document.getElementById("leadid_token")?.value);
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };
    
    phoneOptions = [{caption: "Mobile", value: "mobile"}, {caption: "Landline", value: "landline"}];
    phoneFormat = event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };

    content = (state, props) => 
    <TrustedFormCert>
        <SurexComponents.Card
            lastSlideSubtitle="true"
            lastSlideSubtitleTwo="true"
            title="What is your phone number?"
            controls={<SurexComponents.Controls continue={this.continue} buttonText="Let's Save Some Money" />}
        >
            <div className="form-row justify-content-center">
                <div className="col-sm-6 mb-2">
                    <label className="border-label">Phone Number</label>
                    <AppInput.Input type="tel" name="phone" className="form-control text-field" placeholder="Phone Number" onChange={this.phoneFormat} onValidate={FormValidate.isPhone} failClass="invalid" />
                </div>
            </div>

            <div className="form-row">
                <div className="col-12 mb-2 casl-checkbox">
                    <AppInput.CheckBox name="casl" onClick={v => this.setVal({casl: this.applicant.field('casl')})} />
                    <label>By ticking this checkbox, you authorize us to extend our support through email, SMS, or other electronic channels, delivering offers finely attuned to your preferences based on the information you've shared. Expect a diverse array of products and services, not only from our organization but also from esteemed third-party entities like financial agencies, insurance providers, and dealerships—all tailored to your eligibility. SMS message and data rates may apply. Message frequency varies and recurring messages may happen. You can opt out by responding STOP at any time. Additionally, you may respond with HELP for assistance.</label>
                </div>
            </div>
            <div className="form-row">
                <div className="card-footer text-center mt-4">
                    <input id="leadid_token" name="universal_leadid" type="hidden" value=""/>             
                    <label className="term-txt mt-1"><input type="hidden" id="leadid_tcpa_disclosure"/>By clicking "Let's Save Some Money", you agree to sharing your information with our marketing <a href="https://quotenationusa.com/service-providers/">partners and service providers</a> and you consent to be contacted regarding your inquiry with us. You agree to being contacted by an agent, artificial or pre-recorded voice, and email, or SMS text at the number you have provided, dialed by a manual or auto dialer (consent to be contacted is not a condition to purchase services); even if your information appears on a our internal Do Not Call / Do Not Email List or any other do not call list including any a State or National Do Not Call Registry. You agree to Our <a href="/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="/terms-and-conditions/" target="_blank" rel="noreferrer">Terms of Use</a>. Standard message and data rates may apply.</label>
                </div>
            </div>
        </SurexComponents.Card>
    </TrustedFormCert>
}
