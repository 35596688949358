import CoreTools from "../../../services/CoreTools";
import GenSet from "../../../services/GenSet";

export default class LoanzDebtRelief extends GenSet {
    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <h5 className="card-title text-center widest">We were unable to connect you with a lender at this time.</h5>
            <p className="text-center m-4">However, we did match you with a debt relief representative.</p>
            <div className="row">
                <img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" className="col" />
            </div>
            <p className="text-center m-4">Redirecting you now.</p>
        </div>
    </div>;
}
