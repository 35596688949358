import PanelEffects from "../../directives/PanelEffects";
import CoreTools from "../../services/CoreTools";
import GenSet from "../../services/GenSet";
import GoogleTagManager from "../../services/GoogleTagManager";
import LifeInsuranceFormData from "./LifeInsuranceFormData";
import LifeInsuranceGenPanel from "./panels/LifeInsuranceGenPanel";
import FormValidate from "../../services/FormValidate";
import LoadingPanel from "./panels/LoadingPanel";

export default class LifeInsuranceIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (!CoreTools.state.appOptions.includes("nocss")) {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
            this.importStyle("/css/LifeInsurance.css");
            this.importStyle("/css/animate.css");
        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider2.css");
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}, () => GoogleTagManager.event({event: "first_page", pageName: this.state.hash || "#Residence"})));
    };

    structure = {
        amount: {
            title: "How much coverage would you like?",
            fields: [
                {type: "radio", name: "coverage_amount", values: LifeInsuranceFormData.amount}
            ], 
            back: "Previous",
        },
        birthdate: {
            title: "What is your birthdate?",
            fields: [
                {type: "birthdate"}
            ],
            continue: "Next",
            back: "Previous",
        },
        contact: {
            personaltitle: true,
            fields: [
                {type: "input", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail},
                {type: "phone", name: "phone", placeholder: "Phone", onValidate: FormValidate.isPhone},
            ],
            continue: "Get Free Quotes",
            back: "Previous",
        },
        gender: {
            title: "What is your gender?",
            fields: [
                {type: "radio", name: "gender", values: LifeInsuranceFormData.gender}
            ],
            back: "Previous",
        },
        insurance: {
            title: "What type of life insurance are you looking for?",
            fields: [
                {type: "radio", name: "insurance_type", values: LifeInsuranceFormData.insurance}
            ],
            back: "Previous",
        },
        location: {
            title: "Where are you located?",
            fields: [
                {type: "radio", name: "location", values: LifeInsuranceFormData.location}
            ],
            back: "Previous",
        },
        name: {
            title: "What is your name?",
            fields: [
                {type: "input", label: "First Name", name: "first_name", placeholder: "First Name", onValidate: FormValidate.isName},
                {type: "input", label: "Last Name", name: "last_name", placeholder: "Last Name", onValidate: FormValidate.isName},
            ],
            continue: "Next",
            back: "Previous",
        },
        processing: {
            processing: true,
        },
        smoking: {
            title: "Have you smoked tobacco in the last 12 months?",
            fields: [
                {type: "radio", name: "smoking", values: LifeInsuranceFormData.smoking}
            ],
            back: "Previous",
        },
        type: {
            title: "Which of the following would you like to cover?",
            fields: [
                {type: "radio", name: "coverage_type", values: LifeInsuranceFormData.type}
            ],
        },      
    };

    panels = {
        "#Type":            <LifeInsuranceGenPanel options={this.structure.type} />,
        "#Name":            <LifeInsuranceGenPanel options={this.structure.name} />,
        "#Gender":          <LifeInsuranceGenPanel options={this.structure.gender} />,
        "#Smoking":         <LifeInsuranceGenPanel options={this.structure.smoking} />,
        "#Amount":          <LifeInsuranceGenPanel options={this.structure.amount} />,
        "#Insurance":       <LifeInsuranceGenPanel options={this.structure.insurance} />,
        "#Location":        <LifeInsuranceGenPanel options={this.structure.location} />,
        "#Birthdate":       <LifeInsuranceGenPanel options={this.structure.birthdate} />,
        "#Contact":         <LifeInsuranceGenPanel options={this.structure.contact} />, 
        "#Loading":         <LoadingPanel />, 
        "#Processing":      <LifeInsuranceGenPanel options={this.structure.processing} />,
    };

    content = (state, props) => 
    <div className="quiz_section">
        <div className="container">
            <div className="quiz-inr-box">
                <PanelEffects.ProgressBar2
                    barColor="#06618a"
                    className="progress-bar"
                    textColorPrimary="#06618a"
                    textColorSecondary="#000"
                    height="25px"
                    style={{
                        borderStyle: "solid",
                        borderWidth: "2px", 
                        borderColor: "transparent",
                        backgroundColor: "#eeeeee",
                        borderRadius: "15px", 
                        padding: "2px 0px", 
                        fontSize: "13px",
                        margin: "0 10px"
                    }}
                />
                <div id="appForm" style={{marginTop: "15px"}} key={state.hash}>
                    <div className="appForm_inner">
                        <PanelEffects.Selector
                            selector={state.hash}
                            panels={this.panels} 
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}
