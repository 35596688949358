import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";

export default class NhpusTimeline extends GenSet {

    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <fieldset className="card card-options">
        <div className="card-body">
            <h5 className="card-title">When do you plan on buying your home?</h5>
            <div className="card-fields row">
                <div className="col-12">
                    <label className="btn btn-option long-text">
                        <AppInput.Radio name="purchase_timeline" value="Signed A Purchase Agreement" onClick={this.onClick} />
                        <span className="btn-option-text smaller-text">Signed A Purchase Agreement</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option long-text">
                        <AppInput.Radio name="purchase_timeline" value="Offer Pending / Found Property" onClick={this.onClick} />
                        <span className="btn-option-text smaller-text">Offer Pending / Found Property</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option long-text">
                        <AppInput.Radio name="purchase_timeline" value="Buying in 2-6 Months" onClick={this.onClick} />
                        <span className="btn-option-text small-text">Buying in 2-6 Months</span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option long-text">
                        <AppInput.Radio name="purchase_timeline" value="Researching Options" onClick={this.onClick} />
                        <span className="btn-option-text small-text">Researching Options</span>
                    </label>
                </div>
            </div>
        </div>
    </fieldset>;

}