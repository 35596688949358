import GenSet from "../../../services/GenSet";
import CoreTools from "../../../services/CoreTools";
import React from "react";
import AppInput from "../../../directives/AppInput";
import FormValidate from "../../../services/FormValidate";
import ClrFormData from "../ClrFormData";


class PanelPersonal extends GenSet {
    phoneFormat = event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };

    yearOptions = (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year - 17; i > year - 100; i--) {years.push(i.toString());} return years;})();
    monthOptions = (() => {let months = [{value: "", caption: "Month"}]; for (let i = 1; i <= 12; i++) {months.push(i.toString());} return months;})();
    dayOptions = (() => {let days = [{value: "", caption: "Day"}]; for (let i = 1; i <= 31; i++) {days.push(i.toString());} return days;})();
    
    errMsgHdlr = (cond, name, msg) => v => {
        console.log('v:', v.length)
        if (!cond(v)) {
            CoreTools.emit("setErrorMessage", name, msg);
        } 
        return cond(v);
    };

    content = () => <div className="form-panel">
        <div className="row">
            <div className="col-12 text-center">
                <span className="radio-button-title">Where can we send your Free Life Quotes?</span>
            </div>                
            <div className="col-md-6">
                <span className="label pb-2 font-weight-bold">First Name</span>
                <span className="input-group">                    
                    <AppInput.Input name="first_name" placeholder="First Name" onValidate={FormValidate.isName} onChange={FormValidate.onlyLetters} failClass="invalid" />
                </span>
            </div>
            <div className="col-md-6">
                <span className="label pb-2 font-weight-bold">Last Name</span>   
                <span className="input-group">                    
                    <AppInput.Input name="last_name" placeholder="Last Name" onValidate={FormValidate.isName} onChange={FormValidate.onlyLetters} failClass="invalid" />
                </span>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <span className="label font-weight-bold mt-1">Province</span>
                <div className="row">
                    <div className="col">
                        <span className="input-group">                    
                        <AppInput.Select name="province" options={ClrFormData.province} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <span className="label font-weight-bold mt-1">Birthdate</span>
                <div className="row">
                    <div className="col-4">
                        <span className="input-group">                    
                        <AppInput.Select name="dob_day" options={this.dayOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                    <div className="col-4">
                        <span className="input-group">                    
                        <AppInput.Select name="dob_month" options={this.monthOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                    <div className="col-4">
                        <span className="input-group">                    
                        <AppInput.Select name="dob_year" options={this.yearOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <span className=" label pb-2 font-weight-bold">Email</span>
                <span className="input-group">                    
                    <AppInput.Input name="email" onValidate={FormValidate.isEmail} failClass="invalid" placeholder="name@email.com" />
                </span>
            </div>
            <div className="col-md-6">
                <span className="label pb-2 font-weight-bold">Phone Number</span>
                <span className="input-group">                    
                    <AppInput.Input name="phone" onValidate={FormValidate.isPhone} placeholder="(123) 456-7890" onKeyDown={FormValidate.maxLength(10)} type="tel" failClass="invalid" />
                </span>
            </div>
            <div className="col-12 mb-1">
                <div className="btn-checkbox casl-checkbox">
                    <AppInput.CheckBox name="casl" onClick={v => this.setVal({casl: this.applicant.field('casl')})} />
                    <label>You authorize us to send email, SMS, or other electronic messaging, delivering offers based on information you've shared. You consent to receive messages from our firm and third-party's including financial agencies & insurance providers. You can withdraw your consent anytime.</label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <label className="disclaimer-text text-center" style={{fontSize: "0.85em", lineHeight: "1.4" }}>
                    Clicking "Request a Quote", I have read and accept the <a id="privacy-anchor-tag" href="https://canadianliferates.ca/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>. I also acknowledge having read the <a id="privacy-anchor-tag" href="https://canadianliferates.ca/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and agree to the terms herein.
                </label>
            </div>
        </div>
        <div className="continue-button mt-3" onClick={() => this.navigate("#Processing")}>Request a Quote</div>
    </div>;
};

export default class CLRFlatForm extends GenSet {
    onMount = () => {
        if (!CoreTools.state.appOptions.includes("nocss")) {
            if (CoreTools.state.isLocal) {
                CoreTools.importStyle("/css/loanz-flatform-us.css");
            } else {
                CoreTools.importStyle("https://form.consumergenius.com/css/loanz-flatform-us.css");
            }
        }
    };

    content = state => <div className="form-body">
        <PanelPersonal {...this.props}/>
    </div>;
}
