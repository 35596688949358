import CoreTools from "./CoreTools";
import Socket from "./Socket";
import Api from "./Api";
import GoogleTagManager from "./GoogleTagManager";
import SecureCookie from "./SecureCookie";

let applicant = false;

const Applicant = () => applicant || (() => {
    let hasLoaded = false;
    let socketCache = [];
    let fieldWatchers = [];
    let validateWatchers = [];
    let messageWatchers = [];
    let affiliateUploaded = false;

    const queryData = window.location.search.length > 1 ? window.location.search.substring(1).split("&").reduce((p, c) => {
        if (c && typeof(c) === "string") {
            const [n, v] = c.split("=");
            p[decodeURIComponent(n)] = typeof(v) === "string" ? decodeURIComponent(v) : "";
        }
        return p;
    }, {}) : {};

    CoreTools.log("QueryData:", window.location.search, queryData);

    const app = {
        version: 1.4,
        id: queryData.id || null,
        cookie: null,
        affiliate: false,
        formName: null,
        fields: {},
        messages: [],
        documents: [],
        stats: {pageIndex: -1}
    };

    if (queryData.aid || queryData.cpid || queryData.ci || queryData.rid || queryData.s1 || queryData.s2 || queryData.s3 || queryData.s4 || queryData.s5) {app.affiliate = queryData;}

    if (app.affiliate) {GoogleTagManager.event(CoreTools.fuseObj({event: "affiliate"}, app.affiliate));}

    const updateFields = (fields, holdSend) => {
        let updates = {};
        Object.keys(fields).forEach(key => {
            if (["string", "boolean", "number"].includes(typeof(fields[key])) || fields[key] === null) {
                if (app.fields[key] !== fields[key]) {
                    app.fields[key] = fields[key];
                    updates[key] = fields[key];
                    setTimeout(() => fieldWatchers.filter(w => w.name === key).forEach(w => w.cb(app.fields[key])));
                }
            } else {
                app.fields[key] = fields[key];
                updates[key] = fields[key];
                fieldWatchers.filter(w => w.name === key).forEach(w => w.cb(app.fields[key]));
            }
        });
        if (!holdSend && Object.keys(updates).length) {send("saveFields", updates);}
    };

    let socket = Socket("wss://form.consumergenius.com/funnel");
    socket.reconnect(true);

    const send = (action, data) => {
        if (socket.state() === 1) {
            socket.send(action, data);
        } else {
            socketCache.push({action: action, data: data});
        }
    };

    socket.on("ident", data => {
        if (data.id) {app.id = data.id;}
        if (data.fields && CoreTools.isObject(data.fields)) {updateFields(data.fields, true);}
        while (socketCache.length) {
            let sCache = socketCache.shift();
            socket.send(sCache.action, sCache.data);
        }
        hasLoaded = true;
        CoreTools.log("ident:", data);
    });

    socket.on("loadForm", data => {
        if (data.fields && CoreTools.isObject(data.fields)) {updateFields(data.fields, true);}
        if (data.documents && Array.isArray(data.documents)) {app.documents = data.documents;}
        if (data.formName) {
            app.formName = data.formName;
            if (app.affiliate && !affiliateUploaded) {
                send("affiliate", app.affiliate);
                affiliateUploaded = true;
            }
            if (data.affiliate && !app.affiliate) {
                affiliateUploaded = true;
                app.affiliate = data.affiliate;
            }
            CoreTools.state.applicantLoaded = true;
            CoreTools.emit("applicantLoaded");
        }
        CoreTools.log("applicantLoaded:", data);
    });

    socket.on("open", data => send("ident", {
        cookie: app.cookie,
        formName: app.formName,
        version: app.version
    }));
    
    socket.on("document", data => CoreTools.first(app.documents.filter(d => d.uploadId === data.uploadId))(d => Object.keys(data).forEach(key => d[key] = data[key]), () => app.documents.push(data)));
    socket.on("alert", data => alert(data.message || "Oops, some went wrong. Try refreshing your page, if the problem persists, feel free to contact our support."));
    socket.on("console", data => Array.isArray(data.message) ? console.log("Post Message", ...data.message) : console.log("Post Message", data.message));
    socket.on("updateFields", data => updateFields(data));
    socket.on("gtmEvent", data => GoogleTagManager.event(data));
    socket.on("navigate", data => data.url && setTimeout(() => window.location.href = data.url, data.delay));
    socket.on("reload", data => setTimeout(window.location.reload, data.delay));
    socket.on("upload", data => CoreTools.upload((success, results) => success && Api(data.url).json({
        uploadId: data.uploadId,
        appId: app.id,
        file: results
    }).fetch(res => CoreTools.isObject(res.data) && res.data.success && send("document", res.data)), data.type, false));
    socket.on("alive", data => {
        const isAlive = () => {
            send("alive", {});
            window.removeEventListener("keydown", isAlive);
            window.removeEventListener("mousemove", isAlive);
        };
        setTimeout(() => {
            window.addEventListener("keydown", isAlive);
            window.addEventListener("mousemove", isAlive);
        }, 3000);
    });

    SecureCookie().onReady(cookie => {
        app.cookie = cookie;
        socket.connect();
    });

    applicant = {
        on: socket.on,
        flow: () => app.formName,
        setFlow: flow => {
            if (flow && flow !== app.formName) {
                app.formName = flow;
                send("setFormName", {formName: flow});
            }
        },
        field: name => app.fields[name],
        setField: (name, value, defaultValue) => {
            const useValue = value === undefined ? (defaultValue || null) : value;
            updateFields({[name]: useValue});
        },
        fAlt: (name, def) => app.fields[name] !== undefined ? app.fields[name] : def,
        onFieldUpdate: (name, cb) => {
            const watch = {name: name, cb: cb};
            fieldWatchers.push(watch);
            return () => CoreTools.remove(fieldWatchers, watch);
        },
        valWatch: cb => {
            const watch = {cb: cb};
            validateWatchers.push(watch);
            return () => CoreTools.remove(validateWatchers, watch);
        },
        page: (name, index) => {
            if (index > app.stats.pageIndex) {
                app.stats.pageIndex = index;
                send("page", {pageIndex: index, pageName: name});
            }
        },
        validate: cb => setTimeout(() => cb(!validateWatchers.map(w => w.cb() ? true : false).includes(false))),
        fields: app.fields,
        document: fieldName => CoreTools.last(app.documents.filter(d => d.fieldName === fieldName))(d => d, () => false),
        post: (group, action, body) => setTimeout(() => send("post", {group: group, action: action, body: body}), 250),
        upload: (fieldName, accept) => send("upload", {fieldName: fieldName, accept: accept}),
        isLoaded: () => hasLoaded,
        close: () => socket.close(),
        end: () => socket.end(),
        chat: {
            messages: () => app.messages,
            on: cb => {
                const watch = {cb: cb};
                messageWatchers.push(watch);
                return () => CoreTools.remove(messageWatchers, watch);
            },
            send: msg => {
                const nMsg = {
                    name: "Me",
                    msg: msg,
                    stamp: new Date().getTime()
                };
                app.messages.push(nMsg);
                messageWatchers.forEach(w => w.cb(nMsg));
            }
        },
        affiliate: name => (CoreTools.isObject(app.affiliate) && app.affiliate[name]) || ""
    };
    return applicant;
})();
export default Applicant;