import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";

export default class CarsfastOwnRent extends GenSet {
    title = CoreTools.emit("panelTitle", "Do you Own or Rent your home?");
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <div className="day-fld-box">
                <label className="btn btn-option">
                    <AppInput.Radio name="own_or_rent" value="Own" onClick={this.onClick} />
                    <span className="btn-option-text">Own</span>
                </label>
                <label className="btn btn-option">
                    <AppInput.Radio name="own_or_rent" value="Rent" onClick={this.onClick} />
                    <span className="btn-option-text">Rent</span>
                </label>
                <label className="btn btn-option">
                    <AppInput.Radio name="own_or_rent" value="Other" onClick={this.onClick} />
                    <span className="btn-option-text">Other</span>
                </label>
            </div>
        </div>
    </div>;

}