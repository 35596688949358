import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";

export default class NhpusCredit extends GenSet {
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <fieldset className="card card-options v1">
        <div className="card-body">
            <h5 className="card-title">What is your credit score?</h5>
            <p className="card-text text-center">Most homeowners have good credit</p>
            <div className="card-fields row">
                <div className="col-12">
                    <label className="btn btn-option btn-credit credit-1">
                        <AppInput.Radio name="credit" value="Excellent" onClick={this.onClick} />
                        <span>
                            <span className="btn-option-text">Excellent</span>
                            <span className="btn-option-subtext">700 and above</span>
                        </span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option btn-credit credit-2">
                        <AppInput.Radio name="credit" value="Good" onClick={this.onClick} />
                        <span>
                            <span className="btn-option-text">Good</span>
                            <span className="btn-option-subtext">660 - 699</span>
                        </span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option btn-credit credit-3">
                        <AppInput.Radio name="credit" value="Fair" onClick={this.onClick} />
                        <span>
                            <span className="btn-option-text">Fair</span>
                            <span className="btn-option-subtext">620 - 659</span>
                        </span>
                    </label>
                </div>
                <div className="col-12">
                    <label className="btn btn-option btn-credit credit-4">
                        <AppInput.Radio name="credit" value="Poor" onClick={this.onClick} />
                        <span>
                            <span className="btn-option-text">Poor</span>
                            <span className="btn-option-subtext">619 and below</span>
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </fieldset>;

}