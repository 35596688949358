import GenSet from "../../services/GenSet";
import CoreTools from "../../services/CoreTools";
import PanelEffects from "../../directives/PanelEffects";
import Icon from "../../directives/Icon";
import CarsfastVehicleType from "./panels/CarsfastVehicleType";
import CarsfastBudget from "./panels/CarsfastBudget";
import CarsfastExistingVehicle from "./panels/CarsfastExistingVehicle";
//import CarsfastDateBirth from "./panels/CarsfastDateBirth";
import CarsfastDob from "./panels/CarsfastDob";
import CarsfastEmploymentStatus from "./panels/CarsfastEmploymentStatus";
import CarsfastMonthlyIncome from "./panels/CarsfastMonthlyIncome";
import CarsfastIncomeLength from "./panels/CarsfastIncomeLength";
import CarsfastWhereWork  from "./panels/CarsfastWhereWork";
import CarsfastOwnRent from "./panels/CarsfastOwnRent";
import CarsfastMonthlyRent from "./panels/CarsfastMonthlyRent";
import CarsfastAddress from "./panels/CarsfastAddress";
import CarsfastResidenceLength from "./panels/CarsfastResidenceLength";
import CarsfastFinalDetails from "./panels/CarsfastFinalDetails";

export default class CarsfastAltIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (CoreTools.state.appOptions.includes("nocss")) {
            this.setVal({
                cssBootstrap: true,
                cssMain: true
            });
        } else {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css", () => this.setVal({cssBootstrap: true}));
            this.importStyle("/css/CarsfastAlt.css", () => this.setVal({cssMain: true}));
            this.importStyle("/css/animate.css");
        }
        
        this.importStyle("/libs/fonts/Carsfast/fonts.css");
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider2.css");

        this.wHolder(this.applicant.on("hasBuyer", data => {
            console.log("hasBuyer", data);
            this.navigate("/thank-you/");
            if (data.isSold) {
                //Has Sold
                this.navigate(`/thank-you/id-${data.buyerId}/`);
            } else {
                //Not Sold
                this.navigate("/thank-you/");
            }
        }));
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}));
        this.wHolder(CoreTools.on("panelTitle", newTitle => this.setVal({title: newTitle})));
    };

    content = (state, props) => state.cssBootstrap && state.cssMain && <div className="container">
        <div id="appForm" className="overcard v1">
            <PanelEffects.Transition3><h5 className="card-title" key={state.title}>{state.title}</h5></PanelEffects.Transition3>
            <PanelEffects.ProgressBar
                caption="Progress: "
                barColor="#40AD87"
                style={{borderStyle: "solid", borderWidth: "1px", borderColor: "#40AD87", borderRadius: "5px"}}
            />
            
            <PanelEffects.Transition3>
                <PanelEffects.Selector
                    selector={state.hash}
                    panels={{
                        "#VehicleType":         <CarsfastVehicleType />,
                        "#Budget":              <CarsfastBudget />,
                        "#ExistingVehicle":     <CarsfastExistingVehicle />,
                        //"#DateBirth":           <CarsfastDateBirth />,
                        "#Dob":                 <CarsfastDob />,
                        "#EmploymentStatus":    <CarsfastEmploymentStatus />,
                        "#MonthlyIncome":       <CarsfastMonthlyIncome />,
                        "#IncomeLength":        <CarsfastIncomeLength />,
                        "#WhereWork":           <CarsfastWhereWork />,
                        "#OwnRent":             <CarsfastOwnRent />,
                        "#MonthlyRent":         <CarsfastMonthlyRent />,
                        "#Address":             <CarsfastAddress />,
                        "#ResidenceLength":     <CarsfastResidenceLength />,
                        "#FinalDetails":        <CarsfastFinalDetails />
                    }} 
                />
            </PanelEffects.Transition3>
            <div className="warnty-txt2"><Icon name="ribbon" /> Safe, Secure, &amp; Confidential</div>
            <div className="warnty-txt1"><Icon name="tik" /><span className="text-emphasis">15,750 car buyers</span> found the perfect car!</div>
        </div>
    </div>;
}
