//import Icon from "../../directives/Icon";

const ClrFormData = ({
   // gender: [
   //    {label: "Male", value: "male", image: <Icon name="male" style={{width: '100px'}} alt="male" />},
   //    {label: "Female", value: "female", image: <Icon name="female" style={{width: '100px'}} alt="female" />}
   // ],
   gender: ["Male", "Female"],
   finalExpense: ["$5,000 - $9,999", "$10,000 - $19,999", "$20,000 - $29,999", "$30,000 - $39,999", "$40,000 - $50,000"],
   maritalStatus: ["Married", "Single"],
   medicalConditions: ["I have no major medical problems", "Alcohol Abuse", "Anxiety", "Asthma", "Depression", "Cancer", "Diabetes", "Heart Attack", "High Blood Pressure", "Stroke"],
   // medicalConditions: [{caption: "I have no major medical problems", value:"none"}, {caption:"Alcohol Abuse", value:"alcohol_abuse"}, {caption:"Anxiety", value:"anxiety"}, {caption:"Asthma", value:"asthma"}, {caption:"Depression", value:"depression"}, {caption:"Cancer", value:"cancer"}, {caption:"Diabetes", value:"diabetes"}, {caption:"Heart Attack", value:"heart_attack"}, {caption:"High Blood Pressure", value:"high_blood_pressure"}, {caption:"Stroke", value:"stroke"},],
   insuranceCoverage: ["$1 - $250,000", "$250,001 - $500,000", "$500,001 - $750,000", "$750,001 - $1,000,000", "$1,000,001 - $1,500,000"],
   insuranceType: ["Term", "Permanent", "Not Sure"],
   residentialStatus: ["Own", "Rent"],
   province: ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland & Labrador", "Nova Scotia", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Northwest Territories", "Nunavut", "Yukon"],
   // smoke: [
   //    {label: "Smoking", value: "yes", image: <Icon name="smoking" style={{width: '100px'}} alt="smoking" />}, 
   //    {label: "Non-Smoking", value: "no", image: <Icon name="non-smoking" style={{width: '100px'}} alt="non-smoking" />}
   // ],
   yesNo: ["Yes", "No"]
});

export default ClrFormData;

