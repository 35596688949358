import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";

export default class SurexCurrentlyInsurer extends GenSet {
    continue = (state, props) => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };
    
    content = (state, props) => <SurexComponents.Card
        title="Who is your current insurer?"
    >
        <div className="form-row justify-content-center">
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="current_insurer" value="Liberty Mutual Insurance" onClick={this.continue}/>
                    <span>Liberty Mutual Insurance</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="current_insurer" value="Progressive" onClick={this.continue}/>
                    <span>Progressive</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="current_insurer" value="American Family Insurance" onClick={this.continue}/>
                    <span>American Family Insurance</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="current_insurer" value="Country Financial" onClick={this.continue}/>
                    <span>Country Financial</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="current_insurer" value="Mercury Insurance" onClick={this.continue}/>
                    <span>Mercury Insurance</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="current_insurer" value="Esurance" onClick={this.continue}/>
                    <span>Esurance</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="current_insurer" value="The General Insurance" onClick={this.continue}/>
                    <span>The General Insurance</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="current_insurer" value="Other" onClick={this.continue}/>
                    <span>Other</span>
                </label>
            </div>
        </div>
    </SurexComponents.Card>;
}
