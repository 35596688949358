import CoreTools from "../../../services/CoreTools";
import GenSet from "../../../services/GenSet";

export default class BSFlatFormProcessing extends GenSet {
    onApplicant = (state, props, applicant) => CoreTools.switch(
        [!applicant.field("first_name"),                        () => this.navigate("#Flatform")],
        [!applicant.field("last_name"),                         () => this.navigate("#Flatform")],
        [!applicant.field("phone"),                             () => this.navigate("#Flatform")],
        [!applicant.field("email"),                             () => this.navigate("#Flatform")],
        [!applicant.field("dob_day"),                           () => this.navigate("#Flatform")],
        [!applicant.field("dob_month"),                         () => this.navigate("#Flatform")],
        [!applicant.field("dob_year"),                          () => this.navigate("#Flatform")],
        [true,                                                  () => applicant.post("lifeinsurance-uk", "submit", {})]
    );

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <div className="day-fld-box">
                <h3 className="text-center">We are processing your submission...</h3>
            </div>
            <div className="row">
                <img className="col-12" src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" />
            </div>
        </div>
    </div>;
}

