import PanelEffects from "../../directives/PanelEffects";
import CoreTools from "../../services/CoreTools";
import GenSet from "../../services/GenSet";
import GoogleTagManager from "../../services/GoogleTagManager";
import FormValidate from "../../services/FormValidate";
import ClrFormData from "./ClrFormData";
import ClrGenPanel from "./panels/ClrGenPanel";
import CircleBar from "../../directives/CircleBar";

export default class ClrIndexRed extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        // this.wHolder(this.applicant.on("hasBuyer", data => {
        //     console.log("hasBuyer", data);
        //     if (data.isSold) {
        //         GoogleTagManager.event("Sold");
        //         this.navigate(`/thank-you/id-${data.buyerId}/?lid=${data.leadId}`, 500);
        //     } else {
        //         GoogleTagManager.event("Not Sold");
        //         this.navigate(`/thank-you/?lid=${data.leadId}`, 500);
        //     }
        // }));
        if (!CoreTools.state.appOptions.includes("nocss")) {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
            this.importStyle("/css/CanadianLifeRatesRed.css");
            this.importStyle("/css/animate.css");
        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider2.css");
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}, () => GoogleTagManager.event({event: "first_page", pageName: this.state.hash || "#Residence"})));
    };

    structure = {
        province: {
            title: "Where are you located?",
            fields: [
                {type: "radio", name: "province", values: ClrFormData.province},
            ],
        },
        birthdate: {
            title: "What is your birthdate?",
            fields: [
                {type: "birthdate"}
            ],
            continue: {caption: "Continue", disabled: () => !this.applicant.field("dob") },
        },
        contact: {
            titleSpecial: "true",
            fields: [
                 {type: "input", label: "Email", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail},
                 {type: "phone", label: "Phone", name: "phone", placeholder: "(123) 456 7890", onValidate: FormValidate.isPhone},
                ],
            continue: {caption: "Get My Quotes"},
            consent: "true",
        },
        gender: {
            title: "What is your gender?",
            fields: [
                {type: "radio", name: "gender", values: ClrFormData.gender},
            ],
        },
        insurance: {
            title: "Do you have active life insurance?",
            fields: [
                {type: "radio", name: "insurance_active", values: ClrFormData.yesNo},
            ],
        },
        insuranceCoverage: {
            title: "How much coverage would you like a quote on?",
            fields: [
                {type: "radio", name: "insurance_amount", values: ClrFormData.insuranceCoverage},
            ],
        },
        insuranceType: {
            title: "What type of life insurance are you looking for?",
            fields: [
                {type: "radio", name: "insurance_type", values: ClrFormData.insuranceType},
            ],
        },
        maritalStatus: {
            title: "What's your marital status?",
            fields: [
                {type: "radio", name: "marital_status", values: ClrFormData.maritalStatus},
            ],
        },
        medicalConditions: {
            title: "What medical conditions are you currently being treated and/or taking medication for?",
            fields: [
                {type: "radio", name: "medical_conditions", values: ClrFormData.medicalConditions},
            ],
        },
        name: {
            title: "What is your name?",
            fields: [
                 {type: "input", label: "First Name", name: "first_name", placeholder: "First Name (Formal)", onValidate: FormValidate.isName},
                 {type: "input", label: "Last Name", name: "last_name", placeholder: "Last Name", onValidate: FormValidate.isName},
                ],
            continue: {caption: "Continue"},
        },
        processing: {
            title: "We are processing your application.",
            processing: "ca",
            processingAnimation: 'true',
        },
        residentialStatus: {
            title: "Do you own or rent your home?",
            fields: [
                {type: "radio", name: "residential_status", values: ClrFormData.residentialStatus},
            ],
        },
        smoke: {
            title: "Have you smoked tobacco in the last 12 months?",
            fields: [
                {type: "radio", name: "smoking_status", values: ClrFormData.yesNo}, 
            ],
        },
    };
    
    panels = {
        "#Province":            <ClrGenPanel options={this.structure.province} />,
        "#Insurance":           <ClrGenPanel options={this.structure.insurance} />,
        "#MaritalStatus":       <ClrGenPanel options={this.structure.maritalStatus} />,
        "#ResidentialStatus":   <ClrGenPanel options={this.structure.residentialStatus} />,
        "#Gender":              <ClrGenPanel options={this.structure.gender} />,
        "#Smoke":               <ClrGenPanel options={this.structure.smoke} />,
        "#InsuranceType":       <ClrGenPanel options={this.structure.insuranceType} />,
        "#InsuranceCoverage":   <ClrGenPanel options={this.structure.insuranceCoverage} />,
        "#Name":                <ClrGenPanel options={this.structure.name} />,
        "#Birthdate":           <ClrGenPanel options={this.structure.birthdate} />,
        "#Contact":             <ClrGenPanel options={this.structure.contact} />,
        "#Processing":          <ClrGenPanel options={this.structure.processing} />,
    };

    content = (state, props) => <>
        <CircleBar color="#ba2b1e"/>
        <PanelEffects.Transition1>
            <div id="appForm" style={{marginTop: "15px"}} key={state.hash}>
                <div className="appForm_inner">
                    <PanelEffects.Selector
                        selector={state.hash}
                        panels={this.panels} 
                    />
                </div>
            </div>
        </PanelEffects.Transition1>
    </>;
}
