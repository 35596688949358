import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";

export default class SurexResidenceStatus extends GenSet {
    continue = (state, props) => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };
    
    content = (state, props) => <SurexComponents.Card
        title="What's your residential status?"
    >
        <div className="form-row justify-content-center">
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_status" value="Own" onClick={this.continue}/>
                    <span>Own</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_status" value="Rent" onClick={this.continue}/>
                    <span>Rent</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_status" value="LiveWithParents" onClick={this.continue}/>
                    <span>Live With Parents</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_status" value="Dormitory" onClick={this.continue}/>
                    <span>Dormitory</span>
                </label>
            </div>
            <div className="col-md-8 mb-2">
                <label className="btn btn-option">
                    <AppInput.Radio name="residence_status" value="Other" onClick={this.continue}/>
                    <span>Other</span>
                </label>
            </div>
        </div>
    </SurexComponents.Card>;
}
