import React, {useRef} from "react";

const Tray = ({openPopup,closeTray,open,chatScreen,sendMessage,detailsEntered}) => {
    const [ chatEnabled, setChatEnabled ] = React.useState(false);
    const messageInput = useRef();
    const onMessage = () => {
        if(!messageInput.current.value)return;
        sendMessage(messageInput.current.value);
        messageInput.current.value = "";
    }
    return <div className="tray" data-open={open}>
        <div className="tray_head">
            <p style={{margin:0}}>Support</p>
            <button onClick={closeTray}>✖</button>
        </div>
        <div className="tray_body" data-chat={chatEnabled}>
            { chatEnabled
                ? chatScreen()
                : <>
                    <p>Welcome to support, what would you like to do?</p>
                    <button onClick={openPopup}>Ask a question</button>
                    <button onClick={() => setChatEnabled(true)}>Chat with an agent</button>
                </>
            }
        </div>
        <div className="tray_foot">
            <input ref={messageInput} disabled={!chatEnabled || !detailsEntered} />
            <button disabled={!chatEnabled || !detailsEntered} onClick={() => onMessage()} >Send</button>
        </div>
    </div>;
}
export default Tray;