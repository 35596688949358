import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";
import FormValidate from "../../../services/FormValidate";

export default class SurexContactInfo extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    };
    
    phoneOptions = [{caption: "Mobile", value: "mobile"}, {caption: "Landline", value: "landline"}];
    phoneFormat = event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };
    
    content = (state, props) => <SurexComponents.Card
        title="You're almost done"
        text="Fill out your phone number and email address below so we can contact you with your quote."
        controls={<SurexComponents.Controls continue={this.continue} buttonText="Submit your Application" />}
    >
        <div className="form-row">
            <div className="col-md-12 mb-2">
                <AppInput.Input type="email" name="email" className="form-control text-field" placeholder="Email Address" onValidate={FormValidate.isEmail} failClass="invalid" />
            </div>
            <div className="col-md-12 mb-2">
                <AppInput.Input type="tel" name="phone" className="form-control text-field" placeholder="Mobile Phone Number" onChange={this.phoneFormat} onValidate={FormValidate.isPhone} failClass="invalid" />
            </div>
        </div>
    </SurexComponents.Card>;
}