const ConsumerGeniusFormData = {
  delivery: [{caption: "CRM", value: "crm"}, {caption: "Email", value: "email"}, {caption: "CRM/Email", value: "crm_email"}, {caption: "Other", value: "other"}],
  leadQuantity: ["25", "50", "100", "200", "250", "300+"],
  leadWhen: ["Yesterday", "Immediately", "Within The Next 2 weeks", "Within The Next 30 days", "Within The Next 90 days"],
  team: [{caption: "1-3 People", value: "1_3_people"}, {caption: "4-8 People", value: "4_8_people"}, {caption: "9+ People", value: "9_plus"}],
  yesNo: ["Yes", "No"],
  vertical: ["CA Auto Finance", "CA Life Insurance", "CA Final Expense", "CA MTG HELOC", "CA MTG Refinance", "CA Debt", "CA Personal Loans", "CA Payday Loans", "US Solar", "US Debt", "US Roofing", "US Plumbing", "US Window"],
  weekly: [{caption: "0-49 Leads", value: "0_49_leads"}, {caption: "50-99 Leads", value: "50_99_leads"}, {caption: "100-149 Leads", value: "100_149_leads"}, {caption: "150+ Leads", value: "150_plus_leads"}],
}

export default ConsumerGeniusFormData;
