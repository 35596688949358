import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";
import GoogleAddress from "../../../directives/GoogleAddress";
import OptionLists from "../../../services/OptionLists";
import Mogo from "../images/mogo-logo.png";
import DatePickerSmall from "../../../directives/DatePickerSmall";
import LoanzComponents from '../inserts/LoanzComponents'
import CsvHandler from "../../../services/CsvHandler";

let subValidate = null;
let searchTime = null;

class BankRoutingNumbers extends GenSet { 
    init = this.setVal({banks: []});

    search = (event) => {
        clearTimeout(searchTime);
        searchTime = setTimeout(() => {
            this.setVal({searchText: event.target.value});
        }, 500);
    };

    continue = () => {
        this.navigate(this.props.continue);
    };

    onMount = (state, props) => {
        CsvHandler.import('/libs/lists/usBanks.csv')(csv => {
            this.setVal({banks: csv});
        });
    };

    onApplicant = (state, props, applicant) => {
        this.setVal({state: applicant.field('state'), disabled: applicant.field('bank_name') ? false : true, searchText: applicant.field('bank_search')});
    };

    content = (state, props, applicant) => <LoanzComponents.Card
        text={"Choose from the banks in " + OptionLists.States.find(s => s.short === state.state)?.long}
        controls={<LoanzComponents.Controls continue={this.continue} buttonText={props.buttonText} disabled={state.disabled} />}
    >
        <div className="form-row">
            <div className="col-12 mb-2">
                <AppInput.Input name="bank_search" type="text" onChange={this.search} className="form-control text-field" placeholder="Search Bank Name" />
                <AppInput.Select
                    onClick={() => this.setVal({disabled: false})}
                    name="bank_aba_name"
                    className="form-control"
                    size="7"
                    options={CoreTools.orderBy(state.banks.reduce((a,c) => {if(!a.find(e => e.bank_name === c.bank_name)){a.push(c);}return a;}, []).filter(e => e.bank_state === state.state).map(e => ({bank_name: e.bank_name, bank_aba: e.bank_aba})).filter(e => (
                        !state.searchText || (
                            !state.searchText.toLowerCase().split(" ").filter(s => s.slice(0, 1) !== "!").map(s => Object.values(e).filter(v => typeof(v) === "string").join(" ").toLowerCase().includes(s)).includes(false) &&
                            !state.searchText.toLowerCase().split(" ").filter(s => s.length > 1 && s.slice(0, 1) === "!").map(s => Object.values(e).filter(v => typeof(v) === "string").join(" ").toLowerCase().includes(s.slice(1))).includes(true)
                        )
                    )).map(
                        (p) => ({caption: p.bank_name, value: p.bank_aba + ':' + p.bank_name})
                        ), 'caption')}
                />              
                <div className="options">
                </div>     
            </div>
        </div>
    </LoanzComponents.Card>;
}


class ApplicationSummary extends GenSet {
    onApplicant = (state, props, applicant) => {
        this.setVal({
            firstName: applicant.field('first_name'),
            lastName: applicant.field('last_name'),
            dob: applicant.field('dob'),
            loanAmount: applicant.field('loan_amount'),
            loanDuration: applicant.field('loan_durations_months'),
            consent: applicant.field('consent'),
        });
        CoreTools.log("Application Overview:", applicant.fields);
    };

    continue = () => this.navigate(this.props.continue);

    content = (state, props) => <div className="w-100">
        <div className="application-summary-container">
            <p className="application-summary-header">
            </p>
            <div className="application-summary-body-container">
                <h3>Your Application Summary</h3>
                <br/>
                <p>Name: {state.firstName} {state.lastName}</p>
                <p>DOB: {(state.dob || '').split('-').reverse().join('/')}</p>
                <div className="divided-container">
                    <p className="divided-container-text">Loan Amount: {state.loanAmount}</p>
                    <span className="vertical-line"></span>
                    <p className="divided-container-text">Loan Term: {state.loanDuration} Months</p>
                </div>
            </div>
        </div>
        <div className="disclaimer-text p-3">
            {/* <div className="row">
                <div className="col">
                    <p style={{fontSize: "0.9em", lineHeight: "1.5", fontWeight: "normal", marginBottom: "0px"}}> Minimum Loan Term is 1 month. Maximum Loan Term is 36 months. Representative Example: £1,000 borrowed for 18 months. Total amount repayable is: £1564.59. Interest amounts to £564.59, annual interest rate of 59.97%. <b>Representative APR: 79.5% (variable).</b></p>
                </div>
            </div> */}
            <div className="row border border-secondary">
                <div className="col p-3">
                    <label className="btn-checkbox d-inline-flex flex-row">
                        <span className="consent-checkbox d-inline-block" style={{fontSize: "0.85em", lineHeight: "1.4" }}>
                            By clicking "CONFIRM YOUR APPLICATION" below, I agree to the <a href="https://loanz.uk/terms-of-use/" target="_blank" rel="noreferrer" style={{fontSize: "0.85em"}}>Terms and Condition</a> and agree to the <a href="https://loanz.uk/privacy-policy/" target="_blank" rel="noreferrer" style={{fontSize: "0.85em"}}>Privacy Policy</a> regarding how my personal information may be used, including my personal information being sent to the lenders that partner with uk.loanz.com and credit brokers/credit providers. I also understand that a credit check may be carried out.
                        </span>
                    </label>
                </div>
            </div>
            <div className="row mt-3 border border-secondary">
                <div className="col-12 pt-2 px-2">
                    <label className="btn-checkbox mt-1">
                        <span className="consent-checkbox d-inline-block" style={{fontSize: "0.85em", lineHeight: "1.4" }}>
                            We would like to send you more information on our products and services in accordance with the <a href="https://loanz.uk/privacy-policy/" target="_blank" rel="noreferrer" style={{fontSize: "0.85em"}}>Privacy Policy</a>. You can unsubscribe at any time. If you would like to receive such communications from us please tick the boxes below:
                        </span>
                    </label>
                </div>
                <div className="col-12 pb-2 px-2">
                    <div className="row">
                        <div className="checkbox-container col">
                            <label className="btn-checkbox mt-1">
                                <AppInput.CheckBox className="consent-checkbox-item" name="cgi_sms_consent"/>
                                <b>SMS</b>
                            </label>
                        </div>
                        <div className="checkbox-container col">
                            <label className="btn-checkbox mt-1">
                                <AppInput.CheckBox className="consent-checkbox-item" name="cgi_email_consent"/>
                                <b>Email</b>
                            </label>
                        </div>
                        <div className="checkbox-container col">
                            <label className="btn-checkbox mt-1">
                                <AppInput.CheckBox className="consent-checkbox-item" name="cgi_phone_consent"/>
                                <b>Telephone</b>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3 border border-secondary" style={{display: "none" }}>
                <div className="col-12 pt-2 px-2">
                    <label className="btn-checkbox mt-1">
                        <span className="consent-checkbox d-inline-block" style={{fontSize: "0.85em", lineHeight: "1.4" }}>
                            We would also likely to send you marketing messages from Loanz's third party marketing partner T-Dot in accordance Loanz's <a href="https://loanz.uk/privacy-policy/" target="_blank" rel="noreferrer" style={{fontSize: "0.85em"}}>Privacy Policy</a> and T-Dot's <a href="https://t.uk/privacy-policy/" target="_blank" rel="noreferrer" style={{fontSize: "0.85em"}}>Privacy Policy</a>. You can unsubscribe at any time. If you would like to receive such communications from T-Dot please tick the boxes below:
                        </span>
                    </label>
                </div>
                <div className="col-12 pb-2 px-2">
                    <div className="row">
                        <div className="checkbox-container col">
                            <label className="btn-checkbox mt-1">
                                <AppInput.CheckBox className="consent-checkbox-item" name="sms_consent"/>
                                <b>SMS</b>
                            </label>
                        </div>
                        <div className="checkbox-container col">
                            <label className="btn-checkbox mt-1">
                                <AppInput.CheckBox className="consent-checkbox-item" name="email_consent"/>
                                <b>Email</b>
                            </label>
                        </div>
                        <div className="checkbox-container col">
                            <label className="btn-checkbox mt-1">
                                <AppInput.CheckBox className="consent-checkbox-item" name="phone_consent"/>
                                <b>Telephone</b>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {/* <button className={!this.state.consent ? "disabled-button mt-3" : "continue-button mt-3"} disabled={!this.state.consent} onClick={this.continue}>Confirm Your Application</button> */}
                    <button className={"continue-button mt-3"} onClick={this.continue}>Confirm Your Application</button>
                </div>
            </div>
        </div>
    </div>
}

class ApplicationSummary2 extends GenSet {
    onApplicant = (state, props, applicant) => {
        this.setVal({
            firstName: applicant.field('first_name'),
            lastName: applicant.field('last_name'),
            dob: applicant.field('dob'),
            loanAmount: applicant.field('loan_amount'),
            loanDuration: applicant.field('loan_durations_months'),
            consent: applicant.field('consent'),
        });
        CoreTools.log("Application Overview:", applicant.fields);
    };

    continue = () => this.navigate(this.props.continue);

    content = (state, props) => <div className="w-100">
        <div className="application-summary-container">
            <p className="application-summary-header">
            </p>
            <div className="application-summary-body-container">
                <h3>Your Application Summary</h3>
                <br/>
                <p>Name: {state.firstName} {state.lastName}</p>
                <p>DOB: {(state.dob || '').split('-').reverse().join('/')}</p>
                <div className="divided-container">
                    <p className="divided-container-text">Loan Amount: {state.loanAmount}</p>
                </div>
            </div>
        </div>
        <div className="disclaimer-text p-3">
            <div className="row">
                <div className="col">
                    <p style={{fontSize: "0.9em", lineHeight: "1.5", fontWeight: "normal", marginBottom: "0px"}}> Minimum Loan Term is 1 month. Maximum Loan Term is 36 months. Representative Example: £1,000 borrowed for 18 months. Total amount repayable is: £1564.59. Interest amounts to £564.59, annual interest rate of 59.97%. <b>Representative APR: 79.5% (variable).</b></p>
                </div>
            </div>
            <div className="row mt-3 border border-secondary">
                <div className="col p-3">
                    <label className="btn-checkbox d-inline-flex flex-row">
                        <span className="consent-checkbox d-inline-block" style={{fontSize: "0.85em", lineHeight: "1.4" }}>
                            By clicking "CONFIRM YOUR APPLICATION" below, I agree to the <a href="https://loanz.uk/terms-of-use/" target="_blank" rel="noreferrer" style={{fontSize: "0.85em"}}>Terms and Condition</a> and agree to the <a href="https://loanz.uk/privacy-policy/" target="_blank" rel="noreferrer" style={{fontSize: "0.85em"}}>Privacy Policy</a> regarding how my personal information may be used, including my personal information being sent to uk.loanz. I also understand that a credit check may be carried out.
                        </span>
                    </label>
                </div>
            </div>
            <div className="row mt-3 border border-secondary">
                <div className="col-12 pt-2 px-2">
                    <label className="btn-checkbox mt-1">
                        <span className="consent-checkbox d-inline-block" style={{fontSize: "0.85em", lineHeight: "1.4" }}>
                            We would like to send you more information on our products and services in accordance with the <a href="https://loanz.uk/privacy-policy/" target="_blank" rel="noreferrer" style={{fontSize: "0.85em"}}>Privacy Policy</a>. You can unsubscribe at any time. If you would like to receive such communications from us please tick the boxes below:
                        </span>
                    </label>
                </div>
                <div className="col-12 pb-2 px-2">
                    <div className="row">
                        <div className="checkbox-container col">
                            <label className="btn-checkbox mt-1">
                                <AppInput.CheckBox className="consent-checkbox-item" name="cgi_sms_consent"/>
                                <b>SMS</b>
                            </label>
                        </div>
                        <div className="checkbox-container col">
                            <label className="btn-checkbox mt-1">
                                <AppInput.CheckBox className="consent-checkbox-item" name="cgi_email_consent"/>
                                <b>Email</b>
                            </label>
                        </div>
                        <div className="checkbox-container col">
                            <label className="btn-checkbox mt-1">
                                <AppInput.CheckBox className="consent-checkbox-item" name="cgi_phone_consent"/>
                                <b>Telephone</b>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3 border border-secondary">
                <div className="col-12 pt-2 px-2">
                    <label className="btn-checkbox mt-1">
                        <span className="consent-checkbox d-inline-block" style={{fontSize: "0.85em", lineHeight: "1.4" }}>
                            We would also likely to send you marketing messages from Loanz's third party marketing partner T-Dot in accordance Loanz's <a href="https://loanz.uk/privacy-policy/" target="_blank" rel="noreferrer" style={{fontSize: "0.85em"}}>Privacy Policy</a> and T-Dot's <a href="https://t.uk/privacy-policy/" target="_blank" rel="noreferrer" style={{fontSize: "0.85em"}}>Privacy Policy</a>. You can unsubscribe at any time. If you would like to receive such communications from T-Dot please tick the boxes below:
                        </span>
                    </label>
                </div>
                <div className="col-12 pb-2 px-2">
                    <div className="row">
                        <div className="checkbox-container col">
                            <label className="btn-checkbox mt-1">
                                <AppInput.CheckBox className="consent-checkbox-item" name="sms_consent"/>
                                <b>SMS</b>
                            </label>
                        </div>
                        <div className="checkbox-container col">
                            <label className="btn-checkbox mt-1">
                                <AppInput.CheckBox className="consent-checkbox-item" name="email_consent"/>
                                <b>Email</b>
                            </label>
                        </div>
                        <div className="checkbox-container col">
                            <label className="btn-checkbox mt-1">
                                <AppInput.CheckBox className="consent-checkbox-item" name="phone_consent"/>
                                <b>Telephone</b>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {/* <button className={!this.state.consent ? "disabled-button mt-3" : "continue-button mt-3"} disabled={!this.state.consent} onClick={this.continue}>Confirm Your Application</button> */}
                    <button className={"continue-button mt-3"} onClick={this.continue}>Confirm Your Application</button>
                </div>
            </div>
        </div>
    </div>
}

class ApplicationSummary3 extends GenSet {
    onApplicant = (state, props, applicant) => {
        this.setVal({
            firstName: applicant.field('first_name'),
            lastName: applicant.field('last_name'),
            dob: applicant.field('dob'),
            loanAmount: applicant.field('loan_amount'),
            loanDuration: applicant.field('loan_durations_months'),
            consent: applicant.field('consent'),
        });
        CoreTools.log("Application Overview:", applicant.fields);
    };

    continue = () => this.navigate(this.props.continue);

    content = (state, props) => <div className="w-100">
        <div className="disclaimer-text p-3">
            <div className="row mt-3">
                <div className="col-12 mb-1">
                    {/* <p style={{fontSize: "1em", lineHeight: "1.2" }}>Your data will be used to help us ensure the accuracy of our records. It will be shared with some lenders to create an eligibility score, to save your from having to enter it again during their application process, and for any other purposes described in our privacy policy. By continuing you agree to your data being used in this way.</p> */}
                    <p style={{fontSize: "1em", lineHeight: "1.2" }}>It's important you understand the terms of our contract with you, so please read our <a href="/terms-and-conditions" target="_blank">Terms & Conditions</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a> before using this free service.</p>
                </div>
                <div className="col-12 mb-1">
                    <div className="btn-checkbox casl-checkbox">
                        <AppInput.CheckBox name="casl" onClick={v => this.setVal({casl: this.applicant.field('casl')})} />
                        <label>I accept the Consumer Genius Terms and Conditions and privacy policy</label>
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="btn-checkbox casl-checkbox">
                        <AppInput.CheckBox name="casl2" onClick={v => this.setVal({casl: this.applicant.field('casl2')})} />
                        <label>We would also like to send you useful news and offers about our services and the products and providers we compare. If you'd prefer not to receive these communications please untick this box. You will only receive communications from Consumer Genius and its subsidiaries</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {/* <button className={!this.state.consent ? "disabled-button mt-3" : "continue-button mt-3"} disabled={!this.state.consent} onClick={this.continue}>Confirm Your Application</button> */}
                    <button className={"continue-button mt-3"} onClick={this.continue}>Confirm Your Application</button>
                </div>
            </div>
        </div>
    </div>
}

class ApplicationSummaryUS extends GenSet {
    onApplicant = (state, props, applicant) => {
        this.setVal({
            firstName: applicant.field('first_name'),
            lastName: applicant.field('last_name'),
            dob: applicant.field('dob'),
            loanAmount: applicant.field('loan_amount'),
            consent: applicant.field('consent'),
        });
        CoreTools.log("Application Overview:", applicant.fields);
    };

    continue = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <div>
        <div className="application-summary-container">
            <p className="application-summary-header">
            </p>
            <div className="application-summary-body-container">
                <h3>Your Application Summary</h3>
                <br/>
                <p>Name: {state.firstName} {state.lastName}</p>
                <p>DOB: {(state.dob || '').split('-').reverse().join('/')}</p>
                <div className="divided-container">
                    <p className="divided-container-text">Loan Amount: ${state.loanAmount}</p>
                    <span className="vertical-line"></span>
                    <p className="divided-container-text">Loan Term: {state.loanDuration} Months</p>
                </div>
            </div>
        </div>
        <div className="term-text">
            <div className="col-12">
                <div className="text-left widest">
                    <div className="consent-checkbox-container mt-3">
                        <div className="checkbox-container">
                            <span style={{maxWidth: '460px'}}>Rates from 43.1% APR to 1333% APR. Minimum Loan Length is 1 month. Maximum Loan Length is 36 months. Representative Example: £1,200 borrowed for up to 75 days. Total amount repayable is £1506. Interest charged is 0.34% per day, amounting to £306, annual interest rate of 124$ (variable). <b>Representative APR: 49.7% (variable).</b></span>
                        </div>
                    </div>
                    <div className="text-left widest mt-2 mb-5">
                        <div className="consent-checkbox-container mt-5">
                            <div className="checkbox-container">
                                <label className="btn-checkbox mt-1">
                                    <span className="consent-checkbox"><input type="hidden" id="leadid_tcpa_disclosure"/>I agree to the <a id="privacy-anchor-tag" href="https://loanz.uk/terms-of-use/" target="_blank" rel="noreferrer">Terms and Condition</a> and I understand and agree to the <a id="privacy-anchor-tag" href="https://loanz.uk/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> regarding how my personal information may be used. I agree to this information being sent to the lenders that partner with uk.loanz.com and credit brokers/credit providers. </span>
                                    <AppInput.CheckBox name="consent" className="form-check-input" onClick={v => this.setVal({consent: this.applicant.field('consent')})} style={{height: "26px", width: "26px", marginLeft: "-30px", marginTop: "2px", accentColor: "navy"}} />
                                </label>
                            </div>
                        </div>
                            <div className="consent-checkbox-container">
                                <div className="checkbox-container">
                                    <label className="btn-checkbox mt-1">
                                        <span className="consent-checkbox mt-2 mb-4">I consent to receive marketing messages from ourselves and selected marketing partners as described in the Privacy Policy. You can unsubscribe at any time. By agreeing to this I consent to being contacted by:</span>
                                        <p style={{opacity: "0", height: "26px", width: "26px", marginLeft: "-30px", marginTop: "2px"}}></p>
                                    </label>
                                </div>
                            </div>
                        <div className="consent-checkbox-options-container">
                            <div className="consent-checkbox-container">
                                <div className="checkbox-container">
                                    <label className="btn-checkbox mt-1">
                                        <AppInput.CheckBox className="consent-checkbox-item" name="sms_consent"/>
                                        <b>SMS</b>
                                    </label>
                                </div>
                            </div>
                            <div className="consent-checkbox-container">
                                <div className="checkbox-container">
                                    <label className="btn-checkbox mt-1">
                                        <AppInput.CheckBox className="consent-checkbox-item" name="email_consent"/>
                                        <b>Email</b>
                                    </label>
                                </div>
                            </div>
                            <div className="consent-checkbox-container">
                                <div className="checkbox-container">
                                    <label className="btn-checkbox mt-1">
                                        <AppInput.CheckBox className="consent-checkbox-item" name="phone_consent"/>
                                        <b>Telephone</b>
                                    </label>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            <button className={!this.state.consent ? "disabled-button mt-3" : "continue-button mt-3"} disabled={!this.state.consent} onClick={this.continue}>Confirm Your Application</button>
            </div>
        </div>
    </div>
}

class LoanzAddressCA extends GenSet {
    provinceOptions = [
        {value: "", caption: "Select Province"},
        ...OptionLists.Provinces.map(ol => ({value: ol.short, caption: ol.long}))
    ];

    postalCodeFormat = event => {
        if (typeof(event.target.value) === "string") {
            const proper = event.target.value.replace(/[^a-z0-9]/gi, "").toUpperCase().substring(0, 6);
            if (event.target.value !== proper) {
                const cPos = event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = cPos;
                event.target.selectionEnd = cPos;
            }
        }
    };

    postalCodeValidate = value => (
        typeof(value) === "string" &&
        value.length === 6 &&
        CoreTools.match(value.substring(0, 1), /[A-Z]/) &&
        CoreTools.match(value.substring(1, 2), /[0-9]/) &&
        CoreTools.match(value.substring(2, 3), /[A-Z]/) &&
        CoreTools.match(value.substring(3, 4), /[0-9]/) &&
        CoreTools.match(value.substring(4, 5), /[A-Z]/) &&
        CoreTools.match(value.substring(5, 6), /[0-9]/)
    );

    onMount = (state, props) => {subValidate = () => this.state.addressSet;};
    onUnmount = () => {subValidate = null;};

    onApplicant = (state, props, applicant) => this.setVal({
        addressSet: applicant.field("street_number") && applicant.field("street_name") && applicant.field("city") && applicant.field("state") && applicant.field("postal_code") ? true : false
    });
    
    content = (state, props, applicant) => <div className="card-body-address">
        <div className="form-row text-center mt-2 mb-3">
            <div className="col-12">
                <div id="gm-autocomplete-wrap" className="form-field mb-3 address-form">                    
                    <GoogleAddress
                        country="ca"
                        className="form-control text-center google-address-input"
                        placeholder="Enter Address Here To Lookup"
                        onSelect={address => {
                            applicant.setField("google_address", address.full);
                            applicant.setField("street_number", address.number);
                            applicant.setField("street_name", address.street);
                            applicant.setField("city", address.city);
                            applicant.setField("state", address.provinceShort);
                            applicant.setField("zip_code", address.postalcode.split(" ").join("").toUpperCase());
                            this.setVal({addressSet: true}, () => console.log(state.addressSet));
                        }}
                    />
                </div>
            </div>
            <div className="col-12">
                <span onClick={() => {this.setVal({addressSet: true})}} className="manual-address">My Address Is Not Listed</span>
            </div>
        </div>
        {state.addressSet && <div className="card-fields" id="address">
            <div className="form-row">
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="street_number" placeholder="Street Number" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="street_name" placeholder="Street Name" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="unit" placeholder="Unit/Apt/Suite" className="form-control special-field" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="city" placeholder="City" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Select type="text" name="state" options={this.provinceOptions} className="form-control province-selector special-field" onValidate={v => v} failClass="invalid" />			
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="zip_code" placeholder="Postal Code" className="form-control special-field" onValidate={this.postalCodeValidate} failClass="invalid" onChange={this.postalCodeFormat} />
                </div>
            </div>
        </div>}
    </div>;
}

class LoanzAddressUK extends GenSet {
    provinceOptions = [
        {value: "", caption: "Select County"},
        ...CoreTools.orderBy(OptionLists.ProvincesUK.map(ol => ({value: ol.long, caption: ol.long})), "caption")
    ];

    postalCodeFormat = event => {
        if (typeof(event.target.value) === "string") {
            const proper = event.target.value.replace(/[^a-z0-9]/gi, "").toUpperCase().substring(0, 6);
            if (event.target.value !== proper) {
                const cPos = event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = cPos;
                event.target.selectionEnd = cPos;
            }
        }
    };

    postalCodeValidate = value => (
        typeof(value) === "string" &&
        value.length === 6 &&
        CoreTools.match(value.substring(0, 1), /[A-Z]/) &&
        CoreTools.match(value.substring(1, 2), /[0-9]/) &&
        CoreTools.match(value.substring(2, 3), /[A-Z]/) &&
        CoreTools.match(value.substring(3, 4), /[0-9]/) &&
        CoreTools.match(value.substring(4, 5), /[A-Z]/) &&
        CoreTools.match(value.substring(5, 6), /[0-9]/)
    );

    onMount = (state, props) => {subValidate = () => this.state.addressSet;};
    onUnmount = () => {subValidate = null;};

    onApplicant = (state, props, applicant) => this.setVal({
        addressSet: applicant.field("street_number") && applicant.field("street_name") && applicant.field("city") && applicant.field("state") && applicant.field("zip_code") ? true : false
    });
 
    content = (state, props, applicant) => <div className="card-body-address">
        <div className="form-row text-center mt-2 mb-3">
            <div className="col-12">
                <div id="gm-autocomplete-wrap" className="form-field mb-3 address-form">                    
                    <GoogleAddress
                        country="uk"
                        className="form-control text-center google-address-input"
                        placeholder="Enter Address Here To Lookup"
                        defaultValue={applicant.field("google_address")}
                        onSelect={address => {
                            applicant.setField("google_address", address.full);
                            applicant.setField("street_number", address.number);
                            applicant.setField("street_name", address.street);
                            applicant.setField("city", address.city);
                            let province = OptionLists.ProvincesUK.find(p => p.long.toLowerCase() === address.state.toLowerCase());
                            if (province) {applicant.setField("state", province.long);}
                            if (!province) {
                                province = OptionLists.ProvincesUK.find(p => p.long.toLowerCase().includes(address.state.toLowerCase()));
                                if (province) {applicant.setField("state", province.long);}
                            }
                            if (!province) {
                                province = OptionLists.ProvincesUK.find(p => address.state.toLowerCase().includes(p.long.toLowerCase()));
                                if (province) {applicant.setField("state", province.long);}
                            }
                            if (!province) {
                                applicant.setField("state", "");
                            }
                            applicant.setField("zip_code", address.postalcode.split(" ").join("").toUpperCase());
                            this.setVal({addressSet: true}, () => console.log(this.state.addressSet));
                        }}
                    />
                </div>
            </div>
            <div className="col-12">
                <span onClick={() => {this.setVal({addressSet: true})}} className="manual-address">My Address Is Not Listed</span>
            </div>
        </div>
        {state.addressSet && <div className="card-fields" id="address">
            <div className="form-row">
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="street_number" placeholder="Street Number" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div> 
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="street_name" placeholder="Street Name" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="unit" placeholder="Unit/Apt/Suite" className="form-control special-field" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="city" placeholder="City" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Select type="text" name="state" options={this.provinceOptions} className="form-control province-selector special-field" onValidate={v => v} failClass="invalid" />                
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="zip_code" placeholder="Postal Code" className="form-control special-field" onValidate={v => v} failClass="invalid" />
                </div>
            </div>
        </div>}
    </div>;
}

class LoanzAddressUS extends GenSet {
    stateOptions = [
        {value: "", caption: "Select State"},
        ...OptionLists.States.map(ol => ({value: ol.short, caption: ol.long}))
    ];

    zipCodeFormat = event => {
        if (typeof(event.target.value) === "string") {
            const proper = event.target.value.replace(/[^0-9]/gi, "").substring(0, 5);
            if (event.target.value !== proper) {
                const cPos = event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = cPos;
                event.target.selectionEnd = cPos;
            }
        }
    };

    zipCodeValidate = value => (
        typeof(value) === "string" &&
        value.length === 5 &&
        CoreTools.match(value.substring(0, 5), /[0-9]/g)
    );
    
    onMount = (state, props) => {subValidate = () => this.state.addressSet;};
    onUnmount = () => {subValidate = null;};

    onApplicant = (state, props, applicant) => this.setVal({
        addressSet: applicant.field("google_address_us") && applicant.field("street_number") && applicant.field("street_name") && applicant.field("city") && applicant.field("state") && applicant.field("zip_code") ? true : false
    });

    content = (state, props, applicant) => <div className="card-body-address">
        <div className="form-row text-center mt-2 mb-3">
            <div className="col-12">
                <div id="gm-autocomplete-wrap" className="form-field mb-3 address-form">                    
                    <GoogleAddress
                        country="us"
                        className="form-control text-center google-address-input"
                        placeholder="Enter Address Here To Lookup"
                        defaultValue={applicant.field("google_address_us")}
                        onSelect={address => {
                            applicant.setField("google_address_us", address.full);
                            applicant.setField("street_number", address.number);
                            applicant.setField("street_name", address.street);
                            applicant.setField("city", address.city);
                            applicant.setField("state", address.stateShort);
                            applicant.setField("zip_code", address.postalcode.split(" ").join("").toUpperCase());
                            this.setVal({addressSet: true}, () => CoreTools.log(this.state.addressSet));
                        }}
                    />
                </div>
            </div>
            <div className="col-12">
                <span onClick={() => {this.setVal({addressSet: true})}} className="manual-address">My Address Is Not Listed</span>
            </div>
        </div>
        {state.addressSet && <div className="card-fields" id="address">
            <div className="form-row">
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="street_number" placeholder="Street Number" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="street_name" placeholder="Street Name" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="unit" placeholder="Unit/Apt/Suite" className="form-control special-field" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="city" placeholder="City" onValidate={v => v} className="form-control text-field special-field" failClass="invalid" />
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Select type="text" name="state" options={this.stateOptions} className="form-control province-selector special-field" onValidate={v => v} failClass="invalid" />			
                </div>
                <div className="col-12 mb-4">
                    <AppInput.Input type="text" name="zip_code" placeholder="Zip Code" className="form-control special-field" onValidate={this.zipCodeValidate} failClass="invalid" onChange={this.zipCodeFormat} />
                </div>
            </div>
        </div>}
    </div>;
}

export default class LoanzGenPanel extends GenSet {
    continue = () => {
        if(document.getElementById("leadid_token"))
        this.applicant.setField("leadid_token", document.getElementById("leadid_token")?.value);
        this.navigate(this.props.continue);
    };
    dobContinue = () => {this.applicant.validate(isValid => {if (isValid) {this.applicant.setField("dob", [this.applicant.field("dobYear"), this.applicant.field("dobMonth"), this.applicant.field("dobDay")].join("-"));this.navigate(this.props.continue);} else {window.scrollTo(0, 0);}});};
    validate = () => this.applicant.validate(isValid => isValid && (typeof(subValidate) === "function" ? subValidate() : true) ? this.navigate(this.props.continue) : window.scrollTo(0, 0));
    setOutput = event => {if (event.target.value === "60") {this.setVal({output: event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "+"});}else {this.setVal({output: event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")});}};
    yearOptions = (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year - 15; i > year - 100; i--) {years.push(i.toString());} return years;})();
    monthOptions = (() => {let months = []; for (let i = 1; i <= 12; i++) {months.push(i);} return [{value: "", caption: "Month"}, ...months.map(m => ({value: m < 10 ? "0" + m : m.toString(), caption: m}))];})();
    dayOptions = (() => {let days = []; for (let i = 1; i <= 31; i++) {days.push(i);} return [{value: "", caption: "Day"}, ...days.map(m => ({value: m < 10 ? "0" + m : m.toString(), caption: m}))];})();

    onMount = (state, props) => {
        this.wHolder(CoreTools.on("setErrorMessage", (name, msg) => {
            const msgs = state.errorMsgs || {};
            msgs[name] = msg;
            this.setVal({errorMsgs: msgs});
        }));
    };

    radioFormat = elem => CoreTools.switch(
        [CoreTools.isObject(elem), () => ({caption: typeof(elem.caption) === "string" ? elem.caption : elem.value, value: elem.value})],
        [typeof(elem) === "string", () => ({caption: elem, value: elem})],
        [typeof(elem) === "number", () => ({caption: elem.toString(), value: elem.toString()})],
        [typeof(elem) === "boolean", () => ({caption: elem ? "Yes" : "No", value: elem ? "Yes" : "No"})],
        [true, () => ({caption: "", value: ""})]
    );

    content = (state, props) => <div className="card card-inputs last">
        <div className="card-body">
            <p className="card-title text-center">{props.options.title}</p>
            {props.options.customTitle && <p className="card-title text-center mt-4">Congratulations <b>{this.applicant.field("first_name")} {this.applicant.field("last_name")}</b>, you're almost there!</p>}
            {props.options.subtitle && <div className="form-subtext"><p className="text-center">{props.options.subtitle}</p></div>}
            {props.options.image && <img src={Mogo} className="mogo-image" alt="Mogo" />}
            {!props.options.horizontalLine && <div className="horizontal-line mt-4 mb-4"></div>}
            {Array.isArray(props.options.fields) && <div className="card-fields row">                
                {props.options.fields.map((f, fi) => CoreTools.switch(
                    f.type,
                    ["birthdate", () => <div key={fi}><div className="col-12 mb-3"><AppInput.Select name="dobDay" className="form-control text-field" options={this.dayOptions} onValidate={v => v} failClass="invalid" /></div><div className="col-12 mb-3"><AppInput.Select name="dobMonth" className="form-control text-field" options={this.monthOptions} onValidate={v => v} failClass="invalid" /></div><div className="col-12 mb-3"><AppInput.Select name="dobYear" className="form-control text-field" options={this.yearOptions} onValidate={v => v} failClass="invalid" /></div> <div className="card-footer text-center mt-4"><button onClick={this.dobContinue} className="continue-button mt-3">Continue</button></div></div>],
                    ["bouncingText", () => <div key={fi} className="bouncing-text"><p>{f.text}</p></div>],
                    ["button", () => <button key={fi} className={f.className} onClick={f.onClick}>{f.caption}</button>],
                    ["checkbox", () => <div key={fi} className="card-fields row"><div className="form-check"><label style={{cursor: "pointer"}} className="form-check-label"><AppInput.CheckBox name={f.name} className="form-check-input" style={{height: "26px", width: "26px", marginLeft: "-30px", marginTop: "2px", accentColor: "#ff02f0"}} />{f.caption}</label></div></div>],
                    ["consent", () => <div key={fi}><div><ul className="checked-list mt-2 mb-5"><li className="checked">Contact Details</li><li className="checked delay-1">SMS Verified</li><li className="checked delay-2">Residence Details</li><li className="checked delay-3">Income Details</li><li className="not-checked">Consent <small className="checked-small">(This is the consent)</small></li><li className="not-checked">Accept Offer &amp; Get Funding</li></ul></div></div>],
                    ["credit", () => (f.values || []).map((o, i) => <div className="col-12" key={fi + "_" + i}><label className="btn btn-option"><AppInput.Radio name={f.name} value={o.caption} onClick={this.continue} /><span id={o.value}>{o.caption}</span></label></div>)],
                    ["errorMsg", () => <div key={fi} className="card-fields row text-center text-danger">{state.errorMsgs && state.errorMsgs[f.name]}</div>],
                    ["expense", () => <div key={fi} className="card-fields row"><div className="col-12 adjusted-col">{f.currency && <span id="currency"><b>{f.currency}</b></span>}<AppInput.Input type="text" name={f.name} className="form-control text-field special-field phone mb-4" placeholder={f.placeholder} onValidate={f.onValidate} failClass="invalid" onChange={f.onChange} onKeyDown={f.onKeyDown} onClick={f.onClick} /></div></div>],
                    ["input", () => <div key={fi} className="card-fields row mt-1"><div className="col-12 mb-3"><AppInput.Input type={f.inputType || "text"} name={f.name} placeholder={f.placeholder} onValidate={f.onValidate} onChange={f.onChange} onKeyDown={f.onKeyDown} className="form-control text-field special-field" failClass="invalid"/>{console.log(this.applicant)}</div></div>],
                    ["mogo", () => <div key={fi}><ul className="checked-list mt-2"><li className="checked">Borrow $300-$35,000 in as little as 24 hours</li><li className="checked delay-1">Rates starting at only 9.9%</li><li className="checked delay-2">No impact to your credit score to see what you could qualify for</li><li className="checked delay-3">No obligation to take the loan if you don't like the terms</li><li className="checked delay-4">Flexible repayment terms</li></ul><div className="text-center mb-4 continue-button-container"></div></div>],
                    ["phone", () => <div key={fi} className="card-fields row"><div className="col-12 adjusted-col">{f.countryCode && <span id="country-code"><b>{f.countryCode}</b></span>}<AppInput.Input type="tel" name={f.name} className="form-control text-field special-field phone mb-4" placeholder={f.placeholder} onValidate={f.onValidate} onKeyDown={f.onKeyDown} onChange={f.onChange} failClass="invalid" /></div>{f.SMS && <div className="col-12"><div className="checkbox-container"><label className="btn-checkbox mt-1"><input className="no-text-phone-checkbox" type="checkbox" value="no-text" onChange={() => this.setVal({display: !state.display})} /><span className="no-text-phone">My phone does <b>NOT</b> support texting.</span></label></div></div>}</div>],
                    ["radio", () => (f.values || []).map(this.radioFormat).map((o, i) => <div className="col-12" key={fi + "_" + i}><label className="btn btn-option"><AppInput.Radio name={f.name} value={o.value} onClick={this.continue} /><span>{o.caption}</span></label></div>)],
                    ["select",() => <div key={fi} className="card-fields row"><div className="col-12 mt-2 mb-3"><AppInput.Select type="text" name={f.name} options={f.options} className="form-control shortened-col" onValidate={v => v} failClass="invalid" onChange={() => this.setVal({display: true})}/></div></div>],
                    ["SIN", () => <div key={fi} className="card-fields row"><div className="col-12"><div className="checkbox-container"><label className="btn-checkbox"><input className="no-text-checkbox" type="checkbox" value="no-text" onChange={() => this.setVal({display: !state.display})} /><span className="no-text">{f.text}</span></label></div>{state.display && <div className="col-12 adjusted-col"><AppInput.Input type="text" name={f.name} className="form-control text-field special-field mt-2 mb-1" placeholder="XXX-XXX-XXX" onValidate={f.onValidate} onChange={f.onChange} onKeyDown={f.onKeydown} failClass="invalid" /></div>}</div></div>],
                    ["SSN", () => <div key={fi} className="card-fields"><div className="col-12 adjusted-col"><AppInput.Input type="text" name={f.name} className="form-control text-field special-field mt-2 mb-1" placeholder="XXX-XX-XXXX" onValidate={f.onValidate} onChange={f.onChange} onKeyDown={f.onKeydown} failClass="invalid" /></div></div>],
                ))}
            </div>}
            {props.options.range && <div className="col-12 text-center"><div className="form-slider"><span className="form-value two-value"><div className="dollar"><span id="dollar-sign">{props.options.range.currency}</span>{state.output}</div></span><AppInput.Range className="mb-5" name={props.options.range.name} onValidate={props.options.range.onValidate} failClass="invalid" onChange={this.setOutput} onLoad={this.setOutput} min={props.options.range.min} max={props.options.range.max} step={props.options.range.step} defaultValue={props.options.range.defaultValue} /></div></div>}
            {props.options.address && CoreTools.switch(
                props.options.address,
                ["ca", () => <LoanzAddressCA />],
                ["uk", () => <LoanzAddressUK />],
                ["us", () => <LoanzAddressUS />],
            )}
            {props.options.datePicker && <div className="date-picker-wrap"><DatePickerSmall title={props.options.datePicker.title} name={props.options.datePicker.name} onSelect={this.continue} /></div>}
            {props.options.consent && <div className="term-text"><p className="text-center widest">By clicking "Continue", I have read and accept the <a id="privacy-anchor-tag" href="/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>. I also acknowledge having read the <a id="privacy-anchor-tag" href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and agree to the terms herein.</p></div>}
            {props.options.emailConsent && <div className="term-text"><input id="leadid_token" name="universal_leadid" type="hidden" value=""/><label className="text-center widest"><input type="hidden" id="leadid_tcpa_disclosure"/>By clicking "Continue", you agree to sharing your information with our <a href="/marketing-partners" target="_blank">marketing partners</a> and service providers and to such parties contacting you offers by artificial or prerecorded voice, autodialer, e-mail or SMS text at the number you have provided (consent to be contacted is not a condition to purchase services); even if your information appears on a our internal Do Not Call / Do Not Email List or any other do not call list including any a State or National Do Not Call Registry. You agree to Our <a href="/privacy-policy" target="_blank">Privacy Policy</a> and <a href="/terms-of-use" target="_blank">Terms of Use</a>. Standard message and data rates may apply.</label></div>}
            {props.options.bankRoutingNumbers && <BankRoutingNumbers {...this.props} />}
            {props.options.applicationSummary && <ApplicationSummary {...this.props}/> }
            {props.options.applicationSummary2 && <ApplicationSummary2 {...this.props}/> }
            {props.options.applicationSummary3 && <ApplicationSummary3 {...this.props}/> }
            {props.options.applicationSummaryUS && <ApplicationSummaryUS {...this.props}/> }
            {props.options.processingAnimation && <div className="card card-options"><div className="card-body"><div><img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{display: "block", margin: "20px auto"}} /></div></div></div>}
            {props.options.continue && <div className="continue-button mt-3" onClick={() => this.validate(props.options.continue.disabled)}>{props.options.continue.caption}</div>}
        </div>
    </div>;
}


