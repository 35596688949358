import GenSet from "../../services/GenSet";
import CoreTools from "../../services/CoreTools";
import PanelEffects from "../../directives/PanelEffects";
import RVFastDob from "./panels/RVFastDob";
import RVFastWhereWork  from "./panels/RVFastWhereWork";
import RVFastMonthlyRent from "./panels/RVFastMonthlyRent";
import RVFastFinalDetails from "./panels/RVFastFinalDetails";
import RVFastProcessing from "./panels/RVFastProcessing";
import GlobalPanels from "../../directives/GlobalPanels";
import RVFastForward from "./panels/RVFastForward";
import RVFastForwardProcessing from "./panels/RVFastForwardProcessing";

export default class RVFastIndex extends GenSet {    
    init = (() => {
        this.setVal({hash: this.location.hash});
        if (!CoreTools.state.appOptions.includes("nocss")) {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
            this.importStyle("/css/Carsfast.css");
            this.importStyle("/css/animate.css");
        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider2.css");
    })();

    onMount = (state, props) => this.onNavigate(location => this.setVal({hash: location.hash}));

    values = {
        // desired_vehicle: ["Toy-Haulers", "Fifth Wheels", "Travel Trailers", "Boats", "Side by Side", "Quads"],
        // desired_vehicle: ["RV", "Snow Mobiles", "Side by Side", "ATV"],
        desired_vehicle: ["Snow Mobiles", "Side by Side", "ATV"],
        gross_monthly_income_range: ["Under $1800", "$1800 to $2200", "$2200 to $3000", "$3000 to $4500", "Over $4500"],
        existing_vehicle: ["No", "Yes, I want to trade", "Yes, I'm keeping it"],
        employment_status: ["Employed", "Self Employed", "Retired", "Disability", "Unemployed", "Other"],
        employment_duration_range: ["Less Than 3 Months", "3 - 6 Months", "6 - 12 Months", "12 Months - 2 Years", "2 Years +"],
        residence_type: ["Own", "Rent", "Other"],
        residence_duration_range: ["Less Than 3 Months", "3 - 6 Months", "6 - 12 Months", "12 Months - 2 Years", "2 Years +"]
    };

    content = (state, props) => <div id="appForm" className="overcard v1">
        {/* <div className="card-headline">
            <small className="d-block text-center">Updated: August 10th, 2022</small>
        </div> */}
        <PanelEffects.ProgressBar
            caption="Progress: "
            barColor="#40AD87"
            offset={2}
            style={{borderStyle: "solid", borderWidth: "1px", borderColor: "#40AD87", borderRadius: "5px"}}
        />
        <PanelEffects.Transition3>
            <PanelEffects.Selector
                selector={state.hash}
                panels={{
                    "#VehicleType":         <GlobalPanels.RadioButtons title="Start by clicking on your preferred type of vehicle:" name="desired_vehicle" options={this.values.desired_vehicle} />,
                    "#DateBirth":           <RVFastDob />,
                    "#EmploymentStatus":    <GlobalPanels.RadioButtons title="What is your Employment Status?" name="employment_status" options={this.values.employment_status} />,
                    "#MonthlyIncome":       <GlobalPanels.RadioButtons title="What is your current Monthly Gross Income?" name="gross_monthly_income_range" options={this.values.gross_monthly_income_range} />,
                    "#IncomeLength":        <GlobalPanels.RadioButtons title="How long have you been earning this income?" name="employment_duration_range" options={this.values.employment_duration_range} />,
                    "#WhereWork":           <RVFastWhereWork />,
                    "#OwnRent":             <GlobalPanels.RadioButtons title="Do you Own or Rent your home?" name="residence_type" options={this.values.residence_type} />,
                    "#MonthlyRent":         <RVFastMonthlyRent />,
                    "#Address":             <GlobalPanels.Address title="What is your address?" country="ca" />,
                    "#FinalDetails":        <RVFastFinalDetails />,
                    "#Processing":          <RVFastProcessing />,
                    "#Forward":             <RVFastForward />,
                    "#ForwardProcessing":   <RVFastForwardProcessing />
                }} 
            />
        </PanelEffects.Transition3>
    </div>;
}
