import CoreTools from "./CoreTools";

let languages = [];
let language = CoreTools.getQueryParam("lang") || navigator?.languages || navigator?.language || navigator?.userLanguage;
(Array.isArray(language) ? language : [language]).filter(lang => lang && typeof(lang) === "string").map(lang => lang.split("-")[0].toLowerCase()).filter(lang => ["en", "fr"].includes(lang)).forEach(lang => !languages.includes(lang) && languages.push(lang));
if (!languages.length) {languages.push("en");}
CoreTools.log("Languages:", languages);

let langDef = false;
let current = languages[0];
const Lang = (param, sel) => {
    if (param === null) {
        if (sel && CoreTools.isObject(langDef) && Object.keys(langDef).includes(sel)) {current = sel;}
    } else if (CoreTools.isObject(param) && typeof(param.source) === "string") {
        if (langDef) {
            let params = param.source.trim().split(".");
            let value = langDef[current];
            params.forEach(p => value = value[p]);
            return value;
        }
    } else if (CoreTools.isObject(param) && typeof(param.children) === "string") {
        if (langDef) {
            let params = param.children.trim().split(".");
            let value = langDef[current];
            params.forEach(p => value = value[p]);
            return value;
        }
    } else if (typeof(param) === "string") {
        if (langDef) {return langDef[current][param];}
    } else if (CoreTools.isObject(param)) {
        langDef = param;
        if (!Object.keys(langDef).includes(current)) {current = Object.keys(langDef)[0];}
    }
};
export default Lang;