import CoreTools from "../../../services/CoreTools";
import GenSet from "../../../services/GenSet";
import FieldValidate from "../../../services/FieldValidate";

export default class RVFastProcessing extends GenSet {
    onApplicant = (state, props) => CoreTools.switch(
        [!this.applicant.field("desired_vehicle"),                      () => this.navigate("#VehicleType")],
        // [!this.applicant.field("monthly_budget_range"),              () => this.navigate("#Budget")],
        // [!this.applicant.field("existing_vehicle"),                  () => this.navigate("#ExistingVehicle")],
        [!FieldValidate.isDob(),                                        () => this.navigate("#DateBirth")],
        [!this.applicant.field("employment_status"),                    () => this.navigate("#EmploymentStatus")],
        [!this.applicant.field("gross_monthly_income_range"),           () => this.navigate("#MonthlyIncome")],
        [!this.applicant.field("employment_duration_range"),            () => this.navigate("#IncomeLength")],
        [!this.applicant.field("company_name"),                         () => this.navigate("#WhereWork")],
        [!this.applicant.field("job_title"),                            () => this.navigate("#WhereWork")],
        [!this.applicant.field("residence_type"),                       () => this.navigate("#OwnRent")],
        [!Number(this.applicant.field("monthly_residence_cost")),       () => this.navigate("#MonthlyRent")],
        [!FieldValidate.isAddress("ca"),                                () => this.navigate("#Address")],
        // [!this.applicant.field("residence_duration_range"),          () => this.navigate("#ResidenceLength")],
        [!FieldValidate.isName(),                                       () => this.navigate("#FinalDetails")],
        [!FieldValidate.isEmail(),                                      () => this.navigate("#FinalDetails")],
        [!FieldValidate.isPhone(),                                      () => this.navigate("#FinalDetails")],
        [!this.applicant.field("agreed"),                               () => this.navigate("#FinalDetails")],
        [true,                                                          () => this.applicant.post("rv-auto-ca", "submit", {source: "gen2"})]
    );
    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <h5 className="card-title">We are processing your application.</h5>
            <div>
                <img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{display: "block", margin: "20px auto"}} />
            </div>
        </div>
    </div>;
}
