import CoreTools from "../services/CoreTools";
import Applicant from "../services/Applicant";
import GenSet from "../services/GenSet";
import GoogleTagManager from "../services/GoogleTagManager";
import React from "react";

const global = {
    onPanelLoad: false
};

class ProgressBar extends GenSet {
    cRef = false;
    bRef = false;
    tRef = false;
    otRef = false;
    utRef = false;
    init = (() => {
        global.onPanelLoad = (max, value) => {
            if (this.props.offset) {max = max - this.props.offset;}
            if (value > max) {value = max;}
            const load = () => !this.cRef || !this.bRef || !this.tRef || !this.otRef || !this.utRef ? setTimeout(load) : (() => {
                this.bRef.style.width = (this.cRef.offsetWidth / (max || 1) * value) + "px";
                this.otRef.innerHTML = (this.props.caption || "") + (100 / (max || 1) * value).toFixed(0) + "%";
                this.utRef.innerHTML = (this.props.caption || "") + (100 / (max || 1) * value).toFixed(0) + "%";
                this.bRef.style.height = this.tRef.offsetHeight + "px";
                this.otRef.style.marginLeft = (this.utRef.offsetLeft - 1) + "px";
            })();
            setTimeout(load);
            this.wHolder(CoreTools.on("resize", () => load()));
        };
    })();
    content = (state, props) => <div ref={r => this.cRef = r} style={CoreTools.fuseObj(props.style, {position: props.position || "relative", float: props.float || 'none', overflow: "hidden", width: props.width, height: props.height, color: props.color})}><div>
        <div ref={r => this.bRef = r} style={{position: "absolute", color: props.textColorPrimary || "#fff", backgroundColor: props.barColor, backgroundImage: props.barImage, overflow: "hidden", zIndex: 1}}><span ref={r => this.otRef = r} style={{whiteSpace: "nowrap"}}></span></div>
        <div ref={r => this.tRef = r} style={{color:  props.textColorSecondary || "#000000", textAlign: "center"}}><span ref={r => this.utRef = r} style={{whiteSpace: "nowrap"}}></span></div>
    </div></div>;
}

class ProgressBar2 extends GenSet {
    cRef = false;
    bRef = false;
    tRef = false;
    otRef = false;
    utRef = false;
    init = (() => {
        global.onPanelLoad = (max, value) => {
            if (this.props.offset) {max = max - this.props.offset;}
            if (value > max) {value = max;}
            const load = () => !this.cRef || !this.bRef || !this.tRef || !this.otRef || !this.utRef ? setTimeout(load) : (() => {
                this.bRef.style.width = `${(this.cRef.offsetWidth / (max || 1) * value)}px`;
                this.otRef.innerHTML = (this.props.caption || "") + (100 / (max || 1) * value).toFixed(0) + "%";
                this.utRef.innerHTML = (this.props.caption || "") + (100 / (max || 1) * value).toFixed(0) + "%";
                this.bRef.style.height = this.tRef.offsetHeight + "px";
                this.otRef.style.marginLeft = (this.utRef.offsetLeft - 1) + "px";
            })();
            setTimeout(load);
            this.wHolder(CoreTools.on("resize", () => load()));
        };
    })();
    content = (state, props) => <div className="test" style={{display: "flex"}}>
    <div ref={r => this.cRef = r} style={CoreTools.fuseObj(props.style, {position: props.position || "relative", float: props.float || 'none', overflow: "hidden", width: props.width, height: props.height, color: props.color, maxWidth: props.maxWidth || "100%", flex: 11})}><div>
        <div ref={r => this.bRef = r} style={{position: "absolute", color: props.textColorPrimary || "#fff", backgroundColor: props.barColor, backgroundImage: props.barImage, overflow: "hidden", zIndex: 1, transition: "width 0.3s ease-in-out"}}><span ref={r => this.otRef = r} style={{whiteSpace: "nowrap"}}></span></div>
    </div></div>
        <div ref={r => this.tRef = r} style={{color:  props.textColorSecondary || "#000000", textAlign: "center", flex: 1}}><span ref={r => this.utRef = r} style={{whiteSpace: "nowrap"}}></span></div>
    </div>;
}

// const PanelSelector = props => {
//     if (!CoreTools.isObject(props.panels)) {return null;}
//     const panelNames = Object.keys(props.panels);
//     CoreTools.state.totalPages = panelNames.length;
//     if (!panelNames.length) {return null;}
//     const panelIndex = ((panelNames.indexOf(props.selector) + 1) || 1) - 1;
//     CoreTools.state.currentPageNumber = panelIndex;
//     const previous = panelIndex > 0 ? panelNames[panelIndex - 1] : "";
//     const next = panelIndex + 1 < panelNames.length ? panelNames[panelIndex + 1] : "";
//     if (global.onPanelLoad) {global.onPanelLoad(panelNames.length - 1, panelIndex);}
//     const Panel = props.panels[panelNames[panelIndex]];
//     Applicant().setField("page", panelNames[panelIndex]);
//     GoogleTagManager.page(panelNames[panelIndex]);
//     return <Panel continue={next} previous={previous} />;
// }

const ConditionSelector = props => {
    if (!Array.isArray(props.panels)) {return null;}
    const panels = props.panels.filter(p => Array.isArray(p) && p.length >= 2 && typeof(p[0]) === "string");
    CoreTools.state.totalPages = panels.length;
    if (!panels.length) {return null;}
    const selector = [...panels.map(p => p[0]), "#Resume", "#Quick"].includes(props.selector) ? props.selector : panels[0][0];
    let PanelSet = panels.map((p, i) => ({
        index: i,
        name: p[0],
        Elem: p[1],
        value: typeof(p[2]) === "undefined" ? true : p[2]
    })).find(e => selector === e.name || (typeof(e.value) === "function" ? !e.value(Applicant().fields || {}) : !e.value));
    if (PanelSet) {
        CoreTools.state.currentPageNumber = PanelSet.index + 1;
        const isQuick = page => selector === "#Quick" ? "#Quick" : page;
        const previous = PanelSet.index > 0 ? panels[PanelSet.index - 1][0] : "";
        const next = PanelSet.index < panels.length - 1 ? panels[PanelSet.index + 1][0] : "";
        if (global.onPanelLoad) {global.onPanelLoad(panels.length - 1, PanelSet.index);}
        Applicant().setField("page", PanelSet.name);
        GoogleTagManager.page(PanelSet.name);
        return <PanelSet.Elem continue={isQuick(next)} previous={previous} />;
    } else if (["#Resume", "#Quick"].includes(selector)) {
        CoreTools.state.currentPageNumber = panels.length;
        const Panel = panels[panels.length - 1][1];
        const previous = panels.length > 1 ? panels[panels.length - 2][0] : "";
        if (global.onPanelLoad) {global.onPanelLoad(panels.length - 1, panels.length - 1);}
        Applicant().setField("page", panels[panels.length - 1][0]);
        GoogleTagManager.page(panels[panels.length - 1][0]);
        return <Panel continue={""} previous={previous} />;
    } else {
        CoreTools.state.currentPageNumber = 1;
        const Panel = panels[0][1];
        const next = panels.length > 1 ? panels[1][0] : "";
        if (global.onPanelLoad) {global.onPanelLoad(panels.length - 1, 0);}
        Applicant().setField("page", panels[0][0]);
        GoogleTagManager.page(panels[0][0]);
        return <Panel continue={next} previous={""} />;
    }
};

const Selector = props => {
    let panels = CoreTools.switch(
        [CoreTools.isObject(props.panels), () => Object.keys(props.panels).map(key => ({name: key, panel: props.panels[key]}))],
        [Array.isArray(props.panels), () => props.panels.map(panel => CoreTools.switch(
            [CoreTools.isObject(panel), () => ({
                name: panel.name,
                panel: panel.panel,
                isValid: panel.isValid
            })],
            [Array.isArray(panel), () => ({
                name: panel[0],
                panel: panel[1],
                isValid: panel.length > 2 && panel[2]
            })],
            [true, false]
        )).filter(panel => panel)],
        [true, []]
    );
    if (!panels.length) {return null;}
    panels.forEach((panel, index) => panel.index = index);
    let panel = panels.find(panel => {
        if (!props.selector) {return true;}
        else if (props.selector === "#Quick") {
            if (panel.index === panels.length - 1) {return true;}
            if (typeof(panel.isValid) === "function" && !panel.isValid(Applicant().fields)) {return true;}
            if (panel.isValid === false) {return true;}
        }
        else if (props.selector === "#Resume") {
            if (!Applicant().field("page")) {return true;}
            if (panel.name === Applicant().field("page")) {return true;}
            if (typeof(panel.isValid) === "function" && !panel.isValid(Applicant().fields)) {return true;}
            if (panel.isValid === false) {return true;}
        }
        else if (panel.name === props.selector) {return true;}
        else if (typeof(panel.isValid) === "function" && !panel.isValid(Applicant().fields)) {return true;}
        else if (panel.isValid === false) {return true;}
        return false;
    });
    return (current => {
        CoreTools.state.currentPageNumber = current.index + 1;
        CoreTools.state.totalPages = panels.length;
        Applicant().setField("page", current.name);
        Applicant().page(current.name, current.index);
        GoogleTagManager.page(current.name);
        CoreTools.emit("pageSelect", current.name, CoreTools.state.currentPageNumber, CoreTools.state.totalPages);
        if (global.onPanelLoad) {global.onPanelLoad(panels.length - 1, current.index);}
        const currentProps = CoreTools.fuseObj(
            current.panel.props,
            {
                continue: current.index < panels.length - 1 ? panels[current.index + 1].name : null,
                previous: current.index > 0 ? panels[current.index - 1].name : null,
                index: current.index,
                pageNumber: current.index + 1,
                pageTotal: panels.length
            }
        );
        const currentPanel = React.cloneElement(current.panel, currentProps);
        if (typeof(props.onSelect) === "function") {  
            return props.onSelect({
                panel: currentPanel,
                props: currentProps,
                name: current.name,
                continue: current.index < panels.length - 1 ? panels[current.index + 1].name : null,
                previous: current.index > 0 ? panels[current.index - 1].name : null,
                index: current.index,
                pageNumber: current.index + 1,
                pageTotal: panels.length
            });
        } else {
            return currentPanel;
        }
    })(panel || panels[0]);
};

const FlowMap = props => {
    // const model = [];


    // const renderFlow = flow => {
    //     if (Array.isArray(flow)) {
    //         for (const f of flow) {
    //             if (Array.isArray(f) && f.length > 1) {
    //                 if (typeof(f[0]) === "string") {
                        
    //                     if (props.selector === f[0]) {return processFlow(f[1]);}
    //                 } else if (typeof(f[0]) === "function") {
    //                     if (f[0]()) {return processFlow(f[1]);}
    //                 } else if (f[0]) {
    //                     return processFlow(f[1]);
    //                 }
    //             }
    //         }
    //     } else if (typeof(flow) === "function") {
    //         return flow();
    //     } else {
    //         return flow;
    //     }
    // };

    // console.log("Selector:", props.selector);
    const processFlow = flow => {
        if (Array.isArray(flow)) {
            console.log("pFlow", flow);
            for (const f of flow) {
                console.log(f);
                if (Array.isArray(f) && f.length > 1) {
                    if (typeof(f[0]) === "string") {
                        if (props.selector === f[0]) {return processFlow(f[1]);}
                    } else if (typeof(f[0]) === "function") {
                        if (f[0]()) {return processFlow(f[1]);}
                    } else if (f[0]) {
                        return processFlow(f[1]);
                    }
                }
            }
        } else if (typeof(flow) === "function") {
            return flow();
        } else {
            return flow;
        }
    };
    return processFlow(props.flow || props.panels);
};

class Transition1 extends GenSet {
    key = this.props.children.key || this.props.children.props.selector || "";
    newPanel = {
        ref: false,
        children: this.props.children,
        content: <div key={this.key} ref={r => {this.newPanel.ref = r;}} className={this.props.className} id={this.props.id}>{this.props.children}</div>
    };
    state = {
        content: this.newPanel.content
    };
    onUpdate = (state, props) => {
        const key = this.props.children.key || this.props.children.props.selector || "";
        if (key !== this.key) {
            this.key = key;
            if (this.newPanel && this.newPanel.ref) {
                let oldPanel = this.newPanel.ref.cloneNode(true);
                oldPanel.style.height = this.newPanel.ref.offsetHeight + "px";
                oldPanel.style.width = this.newPanel.ref.offsetWidth + "px";
                oldPanel.style.position = "absolute";
                oldPanel.style.animation = "fadeOutSlideToLeft 0.4s ease-in-out";
                this.newPanel.ref.parentNode.insertBefore(oldPanel, this.newPanel.ref);
                setTimeout(() => {if (oldPanel) {oldPanel.remove();}}, 350);
            }
            this.newPanel = {
                ref: false,
                children: props.children,
                content: <div key={key} ref={r => this.newPanel.ref = r} className={props.className} style={{animation: "fadeInSlideFromRight 0.4s ease-in-out"}} id={props.id}>{props.children}</div>
            };
            setTimeout(() => this.setVal({content: this.newPanel.content}));
        }
    };
    content = (state, props) => state.content;
}

class Transition2 extends GenSet {
    key = this.props.children.key || this.props.children.props.selector || "";
    newPanel = {
        ref: false,
        children: this.props.children,
        content: <div key={this.key} ref={r => {this.newPanel.ref = r;}} className={this.props.className} id={this.props.id}>{this.props.children}</div>
    };
    state = {
        content: this.newPanel.content
    };
    onUpdate = (state, props) => {
        const key = this.props.children.key || this.props.children.props.selector || "";
        if (key !== this.key) {
            this.key = key;
            if (this.newPanel && this.newPanel.ref) {
                let oldPanel = this.newPanel.ref.cloneNode(true);
                oldPanel.style.height = this.newPanel.ref.offsetHeight + "px";
                oldPanel.style.width = this.newPanel.ref.offsetWidth + "px";
                oldPanel.style.position = "absolute";
                oldPanel.style.animation = "fadeOutSlideToLeft 0.3s ease-in-out";
                this.newPanel.ref.parentNode.insertBefore(oldPanel, this.newPanel.ref);
                setTimeout(() => {if (oldPanel) {oldPanel.remove();}}, 300);
            }
            this.newPanel = {
                ref: false,
                children: props.children,
                content: <div key={key} ref={r => {this.newPanel.ref = r;}} className={props.className} style={{animation: "fadeInSlideFromRight 0.3s ease-in-out"}} id={props.id}>{props.children}</div>
            };
            setTimeout(() => this.setVal({content: this.newPanel.content}));
        }
    };
    content = (state, props) => <div id={props.id} className={props.className} style={props.style}>
        <div style={{position: "relative", overflow: "hidden"}}>{state.content}</div>
    </div>;
}

class Transition3 extends GenSet {
    key = this.props.children.key || this.props.children.props.selector || "";
    newPanel = {
        ref: false,
        children: this.props.children,
        content: <div key={this.key} ref={r => {this.newPanel.ref = r;}} className={this.props.className} id={this.props.id}>{this.props.children}</div>
    };
    state = {
        content: this.newPanel.content
    };
    onUpdate = (state, props) => {
        const key = this.props.children.key || this.props.children.props.selector || "";
        if (key !== this.key) {
            this.key = key;
            if (this.newPanel && this.newPanel.ref) {
                let oldPanel = this.newPanel.ref.cloneNode(true);
                oldPanel.style.height = this.newPanel.ref.offsetHeight + "px";
                oldPanel.style.width = this.newPanel.ref.offsetWidth + "px";
                oldPanel.style.position = "absolute";
                oldPanel.style.animation = "slideToLeft 0.3s ease-in-out";
                this.newPanel.ref.parentNode.insertBefore(oldPanel, this.newPanel.ref);
                setTimeout(() => {if (oldPanel) {oldPanel.remove();}}, 300);
            }
            this.newPanel = {
                ref: false,
                children: props.children,
                content: <div key={key} ref={r => {this.newPanel.ref = r;}} className={props.className} style={{animation: "slideFromRight 0.3s ease-in-out"}} id={props.id}>{props.children}</div>
            };
            setTimeout(() => this.setVal({content: this.newPanel.content}));
        }
    };
    content = (state, props) => <div id={props.id} className={props.className} style={props.style}>
        <div style={{position: "relative", overflow: "hidden"}}>{state.content}</div>
    </div>;
}
class Transition4 extends GenSet {
    key = this.props.children.key || this.props.children.props.selector || "";
    newPanel = {
        ref: false,
        children: this.props.children,
        content: <div key={this.key} ref={r => {this.newPanel.ref = r;}} className={this.props.className} id={this.props.id}>{this.props.children}</div>
    };
    state = {
        content: this.newPanel.content
    };
    onUpdate = (state, props) => {
        const key = this.props.children.key || this.props.children.props.selector || "";
        if (key !== this.key) {
            this.key = key;
            if (this.newPanel && this.newPanel.ref) {
                let oldPanel = this.newPanel.ref.cloneNode(true);
                // oldPanel.style.height = this.newPanel.ref.offsetHeight + "px";
                // oldPanel.style.width = this.newPanel.ref.offsetWidth + "px";
                // oldPanel.style.position = "absolute";
                // oldPanel.style.animation = "dissolveOut 0.5s ease-out";
                // this.newPanel.ref.parentNode.insertBefore(oldPanel, this.newPanel.ref);
                setTimeout(() => {if (oldPanel) {oldPanel.remove();}}, 1);
            }
            this.newPanel = {
                ref: false,
                children: props.children,
                content: <div key={key} ref={r => this.newPanel.ref = r} className={props.className} style={{animation: "dissolveIn 0.6s ease-in"}} id={props.id}>{props.children}</div>
            };
            setTimeout(() => this.setVal({content: this.newPanel.content}));
        }
    };
    content = (state, props) => state.content;
}

const PanelEffects = {
    ProgressBar: ProgressBar,
    ProgressBar2: ProgressBar2,
    ConditionSelector: ConditionSelector,
    Selector: Selector,
    FlowMap: FlowMap,
    Transition1: Transition1,
    Transition2: Transition2,
    Transition3: Transition3,
    Transition4: Transition4
};

export default PanelEffects;
