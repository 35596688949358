import GenSet from '../../../services/GenSet';
import LegacyDOBPicker from '../../../directives/LegacyDOBPicker';
import LoanzFormData from '../LoanzFormData';

export default class LoanzBirthdate extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };

    onApplicant = (state, props, applicant) => {
        this.setVal({
            loanReason: applicant.field('loan_reason'),
        });
    };

    content = state => <div className='card card-options'>
        <div className='card-body'>
        <p className='card-title wide text-center mb-4'>Great, you need a loan to <span className='highlighted-text'>{LoanzFormData.us.reasons.find(f => f.value === state.loanReason)?.caption || 'deal with debt'}</span>. Let's get started.</p>
            <div className='form-subtext'>
                <p className='text-center'>What Is Your Date Of Birth?</p>
            </div>
            <div className='horizontal-line mt-1 mb-4'></div>
        </div>
        <LegacyDOBPicker onSelect={v => this.setVal({ready: v})} />

        <button className={state.ready ? 'continue-button' : 'disabled-button'} onClick={() => this.continue()} disabled={!state.ready}>
            Continue
        </button>
   </div>;
}
