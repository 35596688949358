import CoreTools from "../../../services/CoreTools";
import GenSet from "../../../services/GenSet";

export default class JugglingDebtProcessing extends GenSet {
    onApplicant = (state, props, applicant) => {
        if (applicant.flow() === "JugglingDebtCA") {
            CoreTools.switch(

                [!applicant.field("cc_debt_amount"), () => this.navigate("#CcDebtAmount")],
                [!applicant.field("unsecured_debt_amount_range"), () => this.navigate("#UnsecuredDebtAmount")],
                [!applicant.field("debt_type"), () => this.navigate("#DebtType")],
                [!applicant.field("employment_status"), () => this.navigate("#EmploymentStatus")],
                [!applicant.field("credit"), () => this.navigate("#Credit")],
                [!applicant.field("monthly_income"), () => this.navigate("#MonthlyIncome")],
                [!applicant.field("behind_payments"), () => this.navigate("#BehindPayment")],
                [!applicant.field("creditors_count_range"), () => this.navigate("#CreditorsCount")],
                [!applicant.field("residence_type"), () => this.navigate("#ResidenceType")],
                [!applicant.field("property_value_range"), () => this.navigate("#PropertyValue")],
                [!applicant.field("mortgage_balance_range"), () => this.navigate("#MortgageBalance")],
                [!applicant.field("city"), () => this.navigate("#AddressInformation")],
                [!applicant.field("state"), () => this.navigate("#AddressInformation")],
                [!applicant.field("zip_code"), () => this.navigate("#AddressInformation")],
                [!applicant.field("first_name"), () => this.navigate("#ContactInformation")],
                [!applicant.field("last_name"), () => this.navigate("#ContactInformation")],
                [!applicant.field("email"), () => this.navigate("#ContactInformation")],
                [!applicant.field("phone"), () => this.navigate("#ContactInformation")],

                [true, () => applicant.post("debt_us", "submit", {})]
            );
        } else if (applicant.flow() === "JugglingDebtUS1") {
            CoreTools.switch(

                [!applicant.field("unsecured_debt_amount_range"), () => this.navigate("#UnsecuredDebtAmount")],
                [!applicant.field("debt_type"), () => this.navigate("#DebtType")],
                [!applicant.field("credit"), () => this.navigate("#Credit")],
                [!applicant.field("monthly_income"), () => this.navigate("#MonthlyIncome")],
                [!applicant.field("residence_type"), () => this.navigate("#ResidenceType")],
                [!applicant.field("city"), () => this.navigate("#AddressInformation")],
                [!applicant.field("state"), () => this.navigate("#AddressInformation")],
                [!applicant.field("zip_code"), () => this.navigate("#AddressInformation")],
                [!applicant.field("first_name"), () => this.navigate("#ContactInformation")],
                [!applicant.field("last_name"), () => this.navigate("#ContactInformation")],
                [!applicant.field("email"), () => this.navigate("#ContactInformation")],
                [!applicant.field("phone"), () => this.navigate("#ContactInformation")],

                [true, () => applicant.post("debt_us", "submit", {})]
            );
        } else if (applicant.flow() === "JugglingDebtUS2") {
            CoreTools.switch(

                [!applicant.field("cc_debt_amount_range"), () => this.navigate("#CcDebtAmount")],
                [!applicant.field("debt_type"), () => this.navigate("#DebtType")],
                [!applicant.field("credit"), () => this.navigate("#Credit")],
                [!applicant.field("monthly_income_range"), () => this.navigate("#MonthlyIncome")],
                [!applicant.field("residence_type"), () => this.navigate("#ResidenceType")],
                [!applicant.field("first_name"), () => this.navigate("#ContactInformation")],
                [!applicant.field("last_name"), () => this.navigate("#ContactInformation")],
                [!applicant.field("email"), () => this.navigate("#ContactInformation")],
                [!applicant.field("phone"), () => this.navigate("#ContactInformation")],

                [true, () => applicant.post("debt_us", "submit", {})]
            );
        }  else if (applicant.flow() === "JugglingDebtUK1") {
            CoreTools.switch(

                [!applicant.field("cc_debt_amount"), () => this.navigate("#CcDebtAmount")],
                [!applicant.field("creditors_count_range"), () => this.navigate("#CreditorsCount")],
                [!applicant.field("debt_concerns"), () => this.navigate("#DebtConcerns")],
                [!applicant.field("home_country"), () => this.navigate("#HomeCountry")],
                [!applicant.field("residence_type"), () => this.navigate("#ResidenceType")],
                [!applicant.field("employment_status"), () => this.navigate("#EmploymentStatus")],
                [!applicant.field("monthly_income_range"), () => this.navigate("#MonthlyIncome")],
                [!applicant.field("first_name"), () => this.navigate("#ContactInformation")],
                [!applicant.field("last_name"), () => this.navigate("#ContactInformation")],
                [!applicant.field("email"), () => this.navigate("#ContactInformation")],
                [!applicant.field("phone"), () => this.navigate("#ContactInformation")],

                [true, () => applicant.post("debt_us", "submit", {})]
            );
        } 
    };
    content = (state, props) => <fieldset className="card card-options">
        <div className="card-body">
            <h5 className="card-title text-center">We are processing your application.</h5>
            <div>
                <img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{width: "300px",display: "block", margin: "20px auto"}} />
            </div>
        </div>
    </fieldset>;
}