import CoreTools from "../../../services/CoreTools";
import GenSet from "../../../services/GenSet";

export default class NhpusProcessing extends GenSet {
    onApplicant = (state, props, applicant) => {
        CoreTools.switch(
            [!applicant.field("property_type"), () => this.navigate("#PropertyType")],
            [!applicant.field("property_use"), () => this.navigate("#PropertyUse")],
            [true, () => applicant.post("homeloanscanada", "submit", {})]
        );
    };
    content = (state, props) => <fieldset className="card card-options">
        <div className="card-body">
            <h5 className="card-title">We are processing your application.</h5>
            <div>
                <img src={(CoreTools.state.isLocal ? "/" : "https://form.consumergenius.com/") + "libs/images/loading1.gif"} alt="Processing" style={{display: "block", margin: "20px auto"}} />
            </div>
        </div>
    </fieldset>;
}