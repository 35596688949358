import PanelEffects from "../../directives/PanelEffects";
import CoreTools from "../../services/CoreTools";
import GenSet from "../../services/GenSet";
import GoogleTagManager from "../../services/GoogleTagManager";
import FormValidate from "../../services/FormValidate";
import BlrFormData from "./BlrFormData";
import BlrGenPanel from "./panels/BlrGenPanel";

export default class BlrIndex extends GenSet {
    init = (() => {
        this.setVal({hash: this.location.hash});
        // this.wHolder(this.applicant.on("hasBuyer", data => {
        //     console.log("hasBuyer", data);
        //     if (data.isSold) {
        //         GoogleTagManager.event("Sold");
        //         this.navigate(`/thank-you/id-${data.buyerId}/?lid=${data.leadId}`, 500);
        //     } else {
        //         GoogleTagManager.event("Not Sold");
        //         this.navigate(`/thank-you/?lid=${data.leadId}`, 500);
        //     }
        // }));
        if (!CoreTools.state.appOptions.includes("nocss")) {
            CoreTools.importStyle("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
            this.importStyle("/css/CanadianLifeRatesV6.css");
            this.importStyle("/css/animate.css");
        }
        this.importStyle("/libs/fontawesome-free-6.0.0-web/css/all.css");
        this.importStyle("/css/rangeslider2.css");
    })();

    onMount = (state, props) => {
        this.onNavigate(location => this.setVal({hash: location.hash}, () => GoogleTagManager.event({event: "first_page", pageName: this.state.hash || "#Residence"})));
    };

    structure = {
        birthdate: {
            title: "What is your birthdate?",
            fields: [
                {type: "birthdate"}
            ],
            continue: {caption: "Continue", disabled: () => !this.applicant.field("dob") },
        },
        contact: {
            titleSpecial: "true",
            fields: [
                 {type: "input", label: "Email", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail},
                 {type: "phone", label: "Phone", name: "phone", placeholder: "(123) 456 7890", onValidate: FormValidate.isPhone},
                ],
            continue: {caption: "Get My Quotes"},
            consent: "true",
        },
        email: {
            titleSpecial: "true",
            fields: [
                 {type: "input", label: "Email", name: "email", placeholder: "Email Address", onValidate: FormValidate.isEmail},
                ],
            continue: {caption: "Get My Quotes"},
            consent: "true",
        },
        gender: {
            title: "What is your gender?",
            fields: [
                {type: "image", name: "gender", values: BlrFormData.gender},
            ],
        },
        finalExpense: {
            title: "How much coverage would you like?",
            subTitle: "It's okay to estimate",
            fields: [
                {type: "radio", name: "insurance_amount", values: BlrFormData.finalExpense},
            ],
        },
        insurance: {
            title: "How much coverage would you like?",
            subTitle: "It's okay to estimate",
            fields: [
                {type: "radio", name: "insurance_amount", values: BlrFormData.insurance},
            ],
        },
        insuranceCover: {
            title: "Which of the following would you like to cover?",
            fields: [
                {type: "radio", name: "insurance_cover", values: BlrFormData.insuranceCover},
            ],
        },
        insuranceType: {
            title: "What type of life insurance are you looking for?",
            fields: [
                {type: "radio", name: "insurance_type", values: BlrFormData.insuranceTypes},
            ],
        },
        insuranceWho: {
            title: "Who are you looking to be insured?",
            fields: [
                {type: "radio", name: "insurance_who", values: BlrFormData.insuranceWho},
            ],
        },
        name: {
            title: "What is your name?",
            fields: [
                 {type: "input", label: "First Name", name: "first_name", placeholder: "First Name (Formal)", onValidate: FormValidate.isName},
                 {type: "input", label: "Last Name", name: "last_name", placeholder: "Last Name", onValidate: FormValidate.isName},
                ],
            continue: {caption: "Continue"},
        },
        phone: {
            fields: [
                 {type: "phoneSMS" },
                ],
        },
        province: {
            title: "Where are you located?",
            fields: [
                {type: "radio", name: "province", values: BlrFormData.province},
            ],
        },
        processing: {
            title: "We are processing your application.",
            processing: "ca",
            processingAnimation: 'true',
        },
        processingFE: {
            title: "We are processing your application.",
            processing: "fe",
            processingAnimation: 'true',
        },
        smoke: {
            title: "Have you smoked tobacco in the last 12 months?",
            fields: [
                {type: "image", name: "smoking_status", values: BlrFormData.smoke}, 
            ],
        },
    };
    
    panels = {
        "#InsuranceWho":        <BlrGenPanel options={this.structure.insuranceWho} />,
        "#InsuranceCover":      <BlrGenPanel options={this.structure.insuranceCover} />,
        "#Name":                <BlrGenPanel options={this.structure.name} />,
        "#Gender":              <BlrGenPanel options={this.structure.gender} />,
        "#Smoke":               <BlrGenPanel options={this.structure.smoke} />,
        "#Insurance":           <BlrGenPanel options={this.structure.insurance} />,
        "#InsuranceType":       <BlrGenPanel options={this.structure.insuranceType} />,
        "#Province":            <BlrGenPanel options={this.structure.province} />,
        "#Birthdate":           <BlrGenPanel options={this.structure.birthdate} />,
        "#Contact":             <BlrGenPanel options={this.structure.contact} />,
        "#Processing":          <BlrGenPanel options={this.structure.processing} />,
    };

    content = (state, props) => <>
        <PanelEffects.ProgressBar
            caption=""
            barColor="#40AD87"
            className="progress-bar"
            style={{
                borderStyle: "solid",
                borderWidth: "2px", 
                borderColor: "#ffffff",
                backgroundColor: "rgb(233, 236, 239)",
                borderRadius: "5px", 
                padding: "2px", 
                fontSize: "13px",
                margin: "0 10px"
            }}
        />
        <PanelEffects.Transition1 className="panel-container">
            <div id="appForm" style={{marginTop: "15px"}} key={state.hash}>
                <div className="appForm_inner">
                    <PanelEffects.Selector
                        selector={state.hash}
                        panels={this.panels} 
                    />
                </div>
            </div>
        </PanelEffects.Transition1>
    </>;
}
