import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import Icon from "../../../directives/Icon";
import CoreTools from "../../../services/CoreTools";

export default class CarsfastVehicleType extends GenSet {
    title = CoreTools.emit("panelTitle", "Start by clicking on your preferred type of vehicle:");
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">

            <div className="icon-picker">
                <label className="btn-icon">
                    <AppInput.Radio name="vehicle_type" value="Car" onClick={this.onClick} />
                        <span>
                            <Icon name="cfCar" />
                            <span className="btn-option-text">Car</span>
                        </span>
                </label>
                <label className="btn-icon">
                    <AppInput.Radio name="vehicle_type" value="Truck" onClick={this.onClick} />
                    <span>
                        <Icon name="cfTruck" />
                        <span className="btn-option-text">Truck</span>
                    </span>
                </label>
                <label className="btn-icon">
                    <AppInput.Radio name="vehicle_type" value="Van" onClick={this.onClick} />
                    <span>
                        <Icon name="cfVan" />
                        <span className="btn-option-text">Van</span>
                    </span>
                </label>
                <label className="btn-icon">
                    <AppInput.Radio name="vehicle_type" value="SUV" onClick={this.onClick} />
                    <span>
                        <Icon name="cfSUV" />
                        <span className="btn-option-text">SUV</span>
                    </span>
                </label>
            </div>
        </div>
    </div>;

}