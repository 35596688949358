import GenSet from "../../../services/GenSet";
import CoreTools from "../../../services/CoreTools";
import GoogleAddress from "../../../directives/GoogleAddress";

export default class NhpusZipCode extends GenSet {
    continue = () => {
        this.navigate(this.props.continue);
    };

    zipCodeFormat = event => {
        if (typeof(event.target.value) === "string") {
            const proper = event.target.value.replace(/[^0-9]/gi, "").substring(0, 5);
            if (event.target.value !== proper) {
                const cPos = event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = cPos;
                event.target.selectionEnd = cPos;
            }
        }
    };

    zipCodeValidate = value => (
        typeof(value) === "string" &&
        value.length === 5 &&
        CoreTools.match(value.substring(0, 5), /[0-9]/g)
    );

    onApplicant = (state, props, applicant) => {
        if (applicant.field("city") && applicant.field("state") && applicant.field("zip_code") && applicant.field("google_zip_address")) {
            this.setVal({
                isValid: true,
                address: applicant.field("google_zip_address")
            });
        } else {
            this.setVal({
                isValid: false,
                address: ""
            });
        }
    };

    content = (state, props, applicant) => <fieldset className="card card-inputs">
        <div className="card-body">
            <h5 className="card-title">Where do you currently live?</h5>
            <p className="card-text text-center">Please enter your ZIP Code below.</p>
            <div className="card-fields" id="addressFields" >
                <div className="form-row">
                    <div className="col-12 mb-1">
                        <GoogleAddress
                            type="postal_code"
                            country="us"
                            className="form-control text-center"
                            placeholder="Zip Code"
                            defaultValue={state.address}
                            onSelect={address => {
                                applicant.setField("google_zip_address", address.full);
                                applicant.setField("city", address.city);
                                applicant.setField("state", address.stateShort);
                                applicant.setField("zip_code", address.zipcode);
                                this.setVal({isValid: true});
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="card-footer text-center mt-4">
                <button onClick={this.continue} disabled={!state.isValid} className="mx-auto btn btn-navigate">Next</button>
            </div>
        </div>
    </fieldset>;

}