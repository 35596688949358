import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";

export default class SurexLicenceNumber extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };

    licenceNumberValidation = value => value ? true : false;

    content = (state, props) => <SurexComponents.Card
        title="What's your driver's licence number?"
        controls={<SurexComponents.Controls continue={this.continue} />}
    >
        <div className="form-row">
            <div className="col-md-12 mb-2">
                <AppInput.Input
                    name="licence_number"
                    className="subForm form-control text-field"
                    onValidate={this.licenceNumberValidation}
                    failClass="invalid"
                    placeholder="Licence Number"
                />
            </div>
        </div>
    </SurexComponents.Card>;
}
