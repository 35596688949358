const RifcoFormData = {
    existingVehicle: ['Car', 'Truck', 'Van', 'SUV'],
    currentAddress: ["Less Than 3 Months", "3 - 6 Months", "6 - 12 Months", "12 Months - 2 Years", "2+ Years"],
    currentEmployment: ["3 - 6 Months", "6 - 12 Months", "12 Months - 2 Years", "2+ Years"],
    currentRate: ["Less than 15%", "More than 15% but less than 20%", "More than 20%", "I don't know"],
    employmentStatus: ["Employed", "Self Employed", "Retired", "Disability", "Other", "Unemployed"],
    income: ["$1800 to $2200", "$2200 to $3000", "$3000 to $4500", "Over $4500"],
    residenceType: ["Own", "Rent", "Other"],
    yesNo: ['Yes', 'No'],
}

export default RifcoFormData;
