import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";

export default class CarsfastResidenceLength extends GenSet {
    title = CoreTools.emit("panelTitle", "How long have you lived at your current address?");
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <div className="form-row">
                <div className="col-lg-6 mb-1">
                        <label className="btn btn-option">
                            <AppInput.Radio name="residence_length" value="Less Than 3 Months" onClick={this.onClick} />
                            <span className="btn-option-text">Less Than 3 Months</span>
                        </label>
                    </div>
                    <div className="col-lg-6 mb-1">
                        <label className="btn btn-option">
                            <AppInput.Radio name="residence_length" value="3 - 6 Months" onClick={this.onClick} />
                            <span className="btn-option-text">3 - 6 Months</span>
                        </label>
                    </div>
                    <div className="col-lg-6 mb-1">
                        <label className="btn btn-option">
                            <AppInput.Radio name="residence_length" value="6 - 12 Months" onClick={this.onClick} />
                            <span className="btn-option-text">6 - 12 Months</span>
                        </label>
                    </div>
                    <div className="col-lg-6 mb-1">
                        <label className="btn btn-option">
                            <AppInput.Radio name="residence_length" value="12 Months - 2 Years" onClick={this.onClick} />
                            <span className="btn-option-text">12 Months - 2 Years</span>
                        </label>
                    </div>
                    <div className="col-lg-6 mb-1">
                        <label className="btn btn-option">
                            <AppInput.Radio name="residence_length" value="2 Years +" onClick={this.onClick} />
                            <span className="btn-option-text">2 Years +</span>
                        </label>
                </div>
            </div>
        </div>
    </div>;

}