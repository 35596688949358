import GenSet from "../../../services/GenSet";
import Icon from "../../../directives/Icon";
import AppInput from "../../../directives/AppInput";

export default class NhpusPropertyType extends GenSet {    
    onClick = () => {
        this.navigate(this.props.continue);
    };

    content = (state, props) => <fieldset className="card card-options is--active">
        <div className="card-body">
            <p className="card-text text-center">What type of property are you buying?</p>
            <div className="card-fields row">
                <div className="icon-picker">
                    <label type="radio" className="btn-icon">
                        <AppInput.Radio name="property_type" value="Single Family" onClick={this.onClick} />
                        <span>
                            <Icon name="singlehome" />
                            Single Family
                        </span>
                    </label>
                    <label className="btn-icon">
                        <AppInput.Radio name="property_type" value="Multi-Family" onClick={this.onClick} />
                        <span>
                            <Icon name="multihome" />
                            Multi-Family
                        </span>
                    </label>
                    <label className="btn-icon">
                        <AppInput.Radio name="property_type" value="Condominium" onClick={this.onClick} />
                        <span>
                            <Icon name="condohome" />
                            Condominium
                        </span>
                    </label>
                    <label className="btn-icon">
                        <AppInput.Radio name="property_type" value="Mobile" onClick={this.onClick} />
                        <span>
                            <Icon name="mobilehome" />
                            Mobile Home
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </fieldset>;

}