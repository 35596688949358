import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import CoreTools from "../../../services/CoreTools";

export default class CarsfastDob extends GenSet {
    title = CoreTools.emit("panelTitle", "Select Your Date of Birth");
    continue = () => {
        this.applicant.validate(isValid => {
            if (isValid) {
                this.applicant.setField("dob", [this.applicant.field("dobYear"), this.applicant.field("dobMonth"), this.applicant.field("dobDay")].join("-"));
                this.navigate(this.props.continue);
            } else {window.scrollTo(0, 0);}
        });
    };

    yearOptions = (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year - 15; i > year - 100; i--) {years.push(i.toString());} return years;})();
    monthOptions = (() => {let months = []; for (let i = 1; i <= 12; i++) {months.push(i);} return [{value: "", caption: "Month"}, ...months.map(m => ({value: m < 10 ? "0" + m : m.toString(), caption: m}))];})();
    dayOptions = (() => {let days = []; for (let i = 1; i <= 31; i++) {days.push(i);} return [{value: "", caption: "Day"}, ...days.map(m => ({value: m < 10 ? "0" + m : m.toString(), caption: m}))];})();

    content = (state, props) => <div className="card card-options">
        <div className="card-body">
            <h5 className="card-title">Select Your Date of Birth</h5>
            <div className="day-fld-box col-12">
                <div className="col-12 mb-3">
                    <AppInput.Select name="dobDay" className="form-control text-field" options={this.dayOptions} onValidate={v => v} failClass="invalid" />
                </div>
                <div className="col-12 mb-3">
                    <AppInput.Select name="dobMonth" className="form-control text-field" options={this.monthOptions} onValidate={v => v} failClass="invalid" />
                </div>
                <div className="col-12 mb-3">
                    <AppInput.Select name="dobYear" className="form-control text-field" options={this.yearOptions} onValidate={v => v} failClass="invalid" />
                </div>
                <div className="card-footer text-center mt-4">
                    <button onClick={this.continue} className="mx-auto btn btn-navigate">Continue</button>
                </div>
            </div>
        </div>
    </div>;
}