import Icon from "../../../directives/Icon";

const HlFormData = ({
    ages: ["Over 65", "56 to 65", "46 to 55", "45 or under"],
    difficultSituations: ["One-on-one conversations", "Group conversations", "Restaurant or bar", "Conference, church or concert"],
    gender: [
        {label: "Male", value: "male", image: <Icon name="male-alt" style={{width: '150px'}} alt="male" />},
        {label: "Female", value: "female", image: <Icon name="female-alt" style={{width: '150px'}} alt="female" />}
     ],
    excitedFor: ["Clearly hearing friends and family", "Turning down the TV volume", "Participating in group activities", "All of the Above"],
    employmentStatus: ["Employed or Self employed", "Unemployed", "Retired", "Homemaker"],
    forWhom: ["Myself", "Someone Else"],
    lastTest: ["Within the last 12 months", "More than a year ago", "Unsure"],
    mostImportant: ["Latest high-end technology", "Best value for my budget", "Premium service"],
    militaryService: ["Active Military", "Veteran", "Federal Employee", "None of the Above"],
    insurance: ["Employer provided or private plan", "Original medicare, medigap or supplment", "Medicare Advantage", "Medicaid", "I'm not sure"],
    roadblocks: ["Not sure if I need them", "I can't afford them", "I need more information", "I've been too busy"],
    yesNo: ["Yes", "No"],
    whenImprove: ["As soon as possible", "Within the next few weeks", "Within the next few months", "I don't know"],
    whenTest: ["Within the last 12 months", "More than a year ago", "Unsure"],
    whereHear: ["Review or comparison site", "News Article", "TV", "Radio", "Social Media", "Search Engine"],
})

export default HlFormData;
