const Card = props => <fieldset className="card card-options">
    <div className="card-body">
        <div className="card-fields">
            <h5 className="card-title text-center">{props.title}</h5>
            {props.text && <p className="card-text text-center">{props.text}</p>}
            <div className="card-fields-options">
                {props.children}
            </div>
        </div>
        {props.controls}
    </div>
</fieldset>;

const Controls = props => {
    return <div className="card-controls text-center">
        { props.buttonText !== false && <button disabled={props.disabled} onClick={props.continue} className={"mx-auto btn btn-navigate " + props.buttonClass}>{props.buttonText || "Continue"}</button>}
    </div>;
}

const RenovateComponents = {
    Card: Card,
    Controls: Controls
};

export default RenovateComponents;
