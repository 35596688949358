import GenSet from "../../services/GenSet";
import CoreTools from "../../services/CoreTools";
import React from "react";
import AppInput from "../../directives/AppInput";
import GoogleAddress from "../../directives/GoogleAddress";
import OptionLists from "../../services/OptionLists";
import FormValidate from "../../services/FormValidate";

class PanelLoan extends GenSet {
    amountOptions = [
        {value: "", caption: "Please Select"},
        {value: "1", caption: "$100"},
        {value: "2", caption: "$200"},
        {value: "3", caption: "$300"},
        {value: "4", caption: "$400"},
        {value: "5", caption: "$500"},
        {value: "6", caption: "$600"},
        {value: "7", caption: "$700"},
        {value: "8", caption: "$800"},
        {value: "9", caption: "$900"},
        {value: "10", caption: "$1000"},
        {value: "11", caption: "$1100"},
        {value: "12", caption: "$1200"},
        {value: "13", caption: "$1300"},
        {value: "14", caption: "$1400"},
        {value: "15", caption: "$1500"},
        {value: "16", caption: "$1600"},
        {value: "17", caption: "$1700"},
        {value: "18", caption: "$1800"},
        {value: "19", caption: "$1900"},
        {value: "20", caption: "$2000"},
        {value: "21", caption: "$2100"},
        {value: "22", caption: "$2200"},
        {value: "23", caption: "$2300"},
        {value: "24", caption: "$2400"},
        {value: "25", caption: "$2500"},
        {value: "26", caption: "$2600"},
        {value: "27", caption: "$2700"},
        {value: "28", caption: "$2800"},
        {value: "29", caption: "$2900"},
        {value: "30", caption: "$3000"},
        {value: "31", caption: "$3100"},
        {value: "32", caption: "$3200"},
        {value: "33", caption: "$3300"},
        {value: "34", caption: "$3400"},
        {value: "35", caption: "$3500"},
        {value: "36", caption: "$3600"},
        {value: "37", caption: "$3700"},
        {value: "38", caption: "$3800"},
        {value: "39", caption: "$3900"},
        {value: "40", caption: "$4000"},
        {value: "41", caption: "$4100"},
        {value: "42", caption: "$4200"},
        {value: "43", caption: "$4300"},
        {value: "44", caption: "$4400"},
        {value: "45", caption: "$4500"},
        {value: "46", caption: "$4600"},
        {value: "47", caption: "$4700"},
        {value: "48", caption: "$4800"},
        {value: "49", caption: "$4900"},
        {value: "50", caption: "$5000 and more"},
    ];

    stateOptions = [
        {caption: "Select State", value: "", key: "blank"}, 
        {caption: "Alabama", value: "AL"},
        {caption: "Alaska", value: "AK"},
        {caption: "American Samoa", value: "AS"},
        {caption: "Arizona", value: "AZ"},
        {caption: "Arkansas", value: "AR"},
        {caption: "California", value: "CA"},
        {caption: "Colorado", value: "CO"},
        {caption: "Connecticut", value: "CT"},
        {caption: "Delaware", value: "DE"},
        {caption: "District Of Columbia", value: "DC"},
        {caption: "Federated States Of Micronesia", value: "FM"},
        {caption: "Florida", value: "FL"},
        {caption: "Georgia", value: "GA"},
        {caption: "Guam", value: "GU"},
        {caption: "Hawaii", value: "HI"},
        {caption: "Idaho", value: "ID"},
        {caption: "Illinois", value: "IL"},
        {caption: "Indiana", value: "IN"},
        {caption: "Iowa", value: "IA"},
        {caption: "Kansas", value: "KS"},
        {caption: "Kentucky", value: "KY"},
        {caption: "Louisiana", value: "LA"},
        {caption: "Maine", value: "ME"},
        {caption: "Marshall Islands", value: "MH"},
        {caption: "Maryland", value: "MD"},
        {caption: "Massachusetts", value: "MA"},
        {caption: "Michigan", value: "MI"},
        {caption: "Minnesota", value: "MN"},
        {caption: "Mississippi", value: "MS"},
        {caption: "Missouri", value: "MO"},
        {caption: "Montana", value: "MT"},
        {caption: "Nebraska", value: "NE"},
        {caption: "Nevada", value: "NV"},
        {caption: "New Hampshire", value: "NH"},
        {caption: "New Jersey", value: "NJ"},
        {caption: "New Mexico", value: "NM"},
        {caption: "New York", value: "NY"},
        {caption: "North Carolina", value: "NC"},
        {caption: "North Dakota", value: "ND"},
        {caption: "Northern Mariana Islands", value: "MP"},
        {caption: "Ohio", value: "OH"},
        {caption: "Oklahoma", value: "OK"},
        {caption: "Oregon", value: "OR"},
        {caption: "Palau", value: "PW"},
        {caption: "Pennsylvania", value: "PA"},
        {caption: "Puerto Rico", value: "PR"},
        {caption: "Rhode Island", value: "RI"},
        {caption: "South Carolina", value: "SC"},
        {caption: "South Dakota", value: "SD"},
        {caption: "Tennessee", value: "TN"},
        {caption: "Texas", value: "TX"},
        {caption: "Utah", value: "UT"},
        {caption: "Vermont", value: "VT"},
        {caption: "Virgin Islands", value: "VI"},
        {caption: "Virginia", value: "VA"},
        {caption: "Washington", value: "WA"},
        {caption: "West Virginia", value: "WV"},
        {caption: "Wisconsin", value: "WI"},
        {caption: "Wyoming", value: "WY"}
    ]

    phoneFormat = event => {
        if (typeof(event.target.value) === "string") {
            let proper = CoreTools.asPhone(event.target.value, true);
            if (event.target.value !== proper) {
                const cPos = event.target.value.length - event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = event.target.value.length - cPos;
                event.target.selectionEnd = event.target.value.length - cPos;
            }
        }
    };

    yearOptions = (() => {let year = new Date().getFullYear(); let years = [{value: "", caption: "Year"}]; for (let i = year - 17; i > year - 100; i--) {years.push(i.toString());} return years;})();
    monthOptions = (() => {let months = [{value: "", caption: "Month"}]; for (let i = 1; i <= 12; i++) {months.push(i.toString());} return months;})();
    dayOptions = (() => {let days = [{value: "", caption: "Day"}]; for (let i = 1; i <= 31; i++) {days.push(i.toString());} return days;})();
    
    errMsgHdlr = (cond, name, msg) => v => {
        console.log('v:', v.length)
        if (!cond(v)) {
            CoreTools.emit("setErrorMessage", name, msg);
        } 
        return cond(v);
    };

    content = () => <div className="form-panel">
        <div className="row">
            <div className="col">
                <span className="section-header-text">Your Personal Details</span>
            </div>
            <div className="col">
                <span className="section-header-number"><span className="section-header-number-first">1</span> of 5</span>
            </div>
        </div>
        <hr className="horizontal-line"/>
        <span className="customer-message-text">We take security very seriously. Your details will remain secure.</span>
        <div className="row">
            <div className="col col-sm-4 col-12">
                <span className="label">First Name</span>
                <div>
                    <span className="input-group">                    
                        <AppInput.Input name="first_name" placeholder="First Name" onValidate={FormValidate.isName} onChange={FormValidate.onlyLetters} failClass="invalid" />
                    </span>
                </div>
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">Last Name</span>   
                <div>
                    <span className="input-group">                    
                        <AppInput.Input name="last_name" placeholder="Last Name" onValidate={FormValidate.isName} onChange={FormValidate.onlyLetters} failClass="invalid" />
                    </span>
                </div>         
            </div>
            <div className="col col-sm-4 col-12">
                <span className=" label">Email</span>
                <div>
                    <span className="input-group">                    
                        <AppInput.Input name="email" onValidate={FormValidate.isEmail} failClass="invalid" />
                    </span>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col col-sm-4 col-12">
                <span className="label">Home Phone Number</span>
                <div>
                    <span className="input-group">                    
                        <AppInput.Input name="home_phone" onValidate={FormValidate.isPhone} placeholder="(XXX) XXX-XXXX" onChange={this.phoneFormat} onKeyDown={FormValidate.maxLength(10)} type="tel" failClass="invalid" />
                    </span>
                </div>
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">Mobile Phone Number</span>   
                <div>
                    <span className="input-group">                    
                        <AppInput.Input name="mobile_phone" onValidate={FormValidate.isPhone} placeholder="(XXX) XXX-XXXX" onChange={this.phoneFormat} onKeyDown={FormValidate.maxLength(10)} type="tel" failClass="invalid" />
                    </span>
                </div>         
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">SSN</span>   
                <div>
                    <span className="input-group">                    
                        <AppInput.Input name="ssn" placeholder="XXX-XX-XXXX" onValidate={this.errMsgHdlr(v => v.length === 11, "ssn", "Please enter a valid social security number.")} onKeyDown={FormValidate.socialSecurityNumberFormatKeyDown} onChange={FormValidate.socialSecurityNumberFormatChange} failClass="invalid" />
                    </span>
                </div>         
            </div>
        </div>
        <div className="row">
            <div className="col">
                <span className="label">Birthdate</span>
                <div className="row">
                    <div className="col col-sm-4 col-12">
                        <span className="input-group">                    
                        <AppInput.Select name="dob_month" options={this.monthOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                    <div className="col col-sm-4 col-12">
                        <span className="input-group">                    
                        <AppInput.Select name="dob_day" options={this.dayOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                    <div className="col col-sm-4 col-12">
                        <span className="input-group">                    
                        <AppInput.Select name="dob_year" options={this.yearOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col col-sm-4 col-12">
                <span className="label">Drivers License</span>
                <div>
                    <span className="input-group">                    
                        <AppInput.Input name="drivers_license" onKeyDown={FormValidate.maxLength(25)} onValidate={v => v.length >= 2 && v.length <= 25} failClass="invalid" />
                    </span>
                </div>
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">State</span>   
                <div>
                    <span className="input-group">                    
                        <AppInput.Select name="drivers_license_state" options={this.stateOptions} onValidate={v => v} failClass="invalid" />
                    </span>
                </div>         
            </div>
        </div>
    </div>;
};

class PanelAddress extends GenSet {
    states = {
        options: [
            {value: "", caption: "Select State"},
            ...OptionLists.States.map(ol => ({value: ol.short, caption: ol.long}))
        ]
    };

    zipCodeFormat = event => {
        if (typeof(event.target.value) === "string") {
            const proper = event.target.value.replace(/[^0-9]/gi, "").substring(0, 5);
            if (event.target.value !== proper) {
                const cPos = event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = cPos;
                event.target.selectionEnd = cPos;
            }
        }
    };

    setOutput = event => {if (event.target.value === "60") {this.setVal({output: event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "+"});}else {this.setVal({output: event.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")});}};

    content = (state, props, applicant) => <div className="form-panel">
        <div className="row">
            <div className="col">
                <span className="section-header-text">Your Address Details</span>
            </div>
            <div className="col">
                <span className="section-header-number"><span className="section-header-number-first">2</span> of 5</span>
            </div>
        </div>
        <hr className="horizontal-line"/>
        <span className="customer-message-text">Tell us a little about your current living situation.</span>
        <div>
            <span className="label">Search Address</span>
                <div className="input-group-large"><GoogleAddress
                country="us"
                className="custom-width"
                placeholder="Enter Address Here To Lookup"
                defaultValue={this.applicant.field("google_address_us")}
                onSelect={address => {
                    applicant.setField("google_address_us", address.full);
                    applicant.setField("street_number", address.number);
                    applicant.setField("street_name", address.street);
                    applicant.setField("city", address.city);
                    applicant.setField("state", address.stateShort);
                    applicant.setField("zip_code", address.postalcode.split(" ").join("").toUpperCase());
                    this.setVal({addressSet: true}, () => CoreTools.log(state.addressSet));
                }}
            /></div>
        </div>
        <div className="row">
            <div className="col col-sm-4 col-12">
                <span className="label">Street Number</span>
                <span className="input-group">                    
                    <AppInput.Input name="street_number" placeholder="Street Number" onValidate={v => v} failClass="invalid" />
                </span>
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">Street Name</span>   
                <span className="input-group">                    
                    <AppInput.Input name="street_name" placeholder="Street Name" onValidate={v => v} failClass="invalid" />
                </span>
            </div>   
            <div className="col col-sm-4 col-12">
                <span className="label">Unit / Apt / Suite</span>
                <span className="input-group">                    
                    <AppInput.Input name="unit" placeholder="U / A / S" />
                </span>
            </div>      
        </div>
        <div className="row">
            <div className="col col-sm-4 col-12">
                <span className="label">City</span>   
                <span className="input-group">                    
                    <AppInput.Input name="city" placeholder="City" onValidate={v => v} failClass="invalid" />
                </span>
            </div>         
            <div className="col col-sm-4 col-12">
                <span className="label">Postal Code</span>
                <span className="input-group">                    
                    <AppInput.Input name="zip_code" placeholder="Zip Code" onValidate={this.zipCodeFormat} onChange={this.zipCodeFormat} failClass="invalid" />
                </span>
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">State</span>   
                <span className="input-group">                   
                    <AppInput.Select name="state" options={this.states.options} onValidate={v => v} failClass="invalid" />
                </span>
            </div>         
        </div>
        <div className="row">
            <div className="col col-sm-4 col-12">
                <span className="label mb-2">Residential Status</span>
                <label className="btn btn-option">
                    <AppInput.Radio name="residential_status" value="own"/>
                    <span style={{maxWidth: "600px"}}>Owner</span>
                </label>
                <label className="btn btn-option">
                    <AppInput.Radio name="residential_status" value="rent"/>
                    <span style={{maxWidth: "600px"}}>Renter</span>
                </label>
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">Months At Address</span>   
                <span className="input-group">            
                    <div className="form-slider">
                        <AppInput.Range className="mb-2" name="time_at_address" min="1" max="60" step="1" defaultValue="30" onChange={this.setOutput} onLoad={this.setOutput} failClass="invalid" style={{border: "0"}}/>
                        <span className="form-value">{this.state.output} Months</span>
                    </div>        
                </span>
            </div>         
        </div>
    </div>;
};

class PanelIncome extends GenSet {
    employmentTimeOptions = [
        {value: "", caption: "Please Select"},
        {value: "3 months", caption: "< 3 Months"},
        {value: "5 months", caption: "4 - 6 Months"},
        {value: "8 months", caption: "7 - 9 Months"},
        {value: "11 months", caption: "10 - 12 Months"},
        {value: "1.5 years", caption: "1 - 2 Years"},
        {value: "2.5 years", caption: "2 - 3 Years"},
        {value: "6 years", caption: "4 - 8 Years"},
        {value: "8+ years", caption: "Over 8 Years"},
    ]

    incomeType = [
        {value: "", caption: "Please Select"},
        {caption: "Employed", value: "employed"}, 
        {caption: "Benefits", value: "benefits"}, 
        {caption: "Self-Employed", value: "self_employed"}
    ]

    payFrequencyOptions = [
        {caption: "Please Select", value: ""},
        {caption: "Weekly", value: "weekly"},
        {caption: "Bi-Weekly", value: "biweekly"}, 
        {caption: "Semi-Monthly", value: "semimonthly"}, 
        {caption: "Monthly", value: "monthly"}
    ]
    
    dayOptions = (() => {let days = [{value: "", caption: "Day"}]; for (let i = 1; i <= 31; i++) {days.push(i.toString());} return days;})();
    monthOptions = (() => {let months = [{value: "", caption: "Month"}]; for (let i = 1; i <= 12; i++) {months.push(i.toString());} return months;})();
    
    content = () => <div className="form-panel">
        <div className="row">
            <div className="col">
                <span className="section-header-text">Your Income</span>
            </div>
            <div className="col">
                <span className="section-header-number"><span className="section-header-number-first">3</span> of 5</span>
            </div>
        </div>
        <hr className="horizontal-line"/>
        <span className="customer-message-text">Tell us a little about your current living situation.</span>
        <div className="row">
            <div className="col col-sm-4 col-12">
                <span className="label">Payment Frequency</span>
                <span className="input-group">                    
                    <AppInput.Select name="loan_amount" options={this.payFrequencyOptions} onValidate={v => v} failClass="invalid" />
                </span>
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">Income Type</span>   
                <span className="input-group">                    
                    <AppInput.Select name="income_type" options={this.incomeType} onValidate={v => v} failClass="invalid" />
                </span>
            </div>     
            <div className="col col-sm-4 col-12">
                <span className="label">Monthly Income</span>   
                <span className="input-group">                    
                    <p className="currency-holder">$</p>
                    <AppInput.Input name="monthly_income" className="special-input" failClass="invalid" />
                </span>
            </div>      
        </div>
        <div className="row">
            <div className="col col-sm-6 col-12">
                <span className="label">This Pay Date</span>
                <div className="row">
                    <div className="col col-sm-6 col-6">
                        <span className="input-group">                    
                        <AppInput.Select name="this_paydate_day" options={this.dayOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                    <div className="col col-sm-6 col-6">
                        <span className="input-group">                    
                        <AppInput.Select name="this_paydate_month" options={this.monthOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                </div>
            </div>
            <div className="col col-sm-6 col-12">
                <span className="label">Next Pay Date</span>
                <div class="row">
                    <div className="col col-sm-6 col-6">
                        <span className="input-group">                    
                        <AppInput.Select name="next_paydate_day" options={this.dayOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                    <div className="col col-sm-6 col-6">
                        <span className="input-group">                    
                        <AppInput.Select name="next_paydate_month" options={this.monthOptions} onValidate={v => v} failClass="invalid" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col col-sm-4 col-12">
                <span className="label">Bank Account Type</span>
                <div>
                    <label className="btn btn-option">
                        <AppInput.Radio name="checking" value="checking"/>
                        <span>Checking</span>
                    </label>
                </div>
                <div>
                    <label className="btn btn-option">
                        <AppInput.Radio name="checking" value="saving"/>
                        <span>Saving</span>
                    </label>
                </div>
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">Payment Method</span>
                <div>
                    <label className="btn btn-option">
                        <AppInput.Radio name="direct_deposit" value="yes"/>
                        <span>Direct Deposit</span>
                    </label>
                </div>
                <div>
                    <label className="btn btn-option">
                        <AppInput.Radio name="direct_deposit" value="no"/>
                        <span>Paper Check</span>
                    </label>
                </div>
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">Active Military</span>
                <div>
                    <label className="btn btn-option">
                        <AppInput.Radio name="active_military" value="yes"/>
                        <span>Yes</span>
                    </label>
                </div>
                <div>
                    <label className="btn btn-option">
                        <AppInput.Radio name="active_military" value="no"/>
                        <span>No</span>
                    </label>
                </div>
            </div>
        </div>
        <span className="customer-message-text">We will never contact your employer. Lenders just need to understand how you generate an income.</span>
        <div className="row">
            <div className="col col-sm-4 col-12">
                <span className="label">Name of Employer</span>
                <span className="input-group">                    
                    <AppInput.Input name="employer_name" onValidate={v => v} onChange={FormValidate.onlyLetters} failClass="invalid" />
                </span>
            </div>
            <div className="col col-sm-4 col-12">
                <span className="label">Employer Phone</span>   
                <span className="input-group">                    
                    <AppInput.Input name="employer_phone" onValidate={FormValidate.isPhone} placeholder="(XXX) XXX-XXXX" failClass="invalid" onChange={this.phoneFormat} onKeyDown={FormValidate.maxLength(10)} type="tel"/>
                </span>
            </div>  
            <div className="col col-sm-4 col-12">
                <span className="label">Time At Employer</span>
                <span className="input-group">                    
                    <AppInput.Select name="employer_time" options={this.employmentTimeOptions} onValidate={v => v} failClass="invalid" />
                </span>
            </div>       
        </div>
    </div>;
};

class PanelCredit extends GenSet {
    credit = [
        {caption: "Please Select", value: ""},
        {caption: "720+ Excellent", value: 'excellent'}, 
        {caption: "719-690 Very Good", value: 'very_good'}, 
        {caption: "689-640 Good", value: 'good'}, 
        {caption: "639-560 Fair", value: 'fair'}, 
        {caption: ">559 Poor", value: 'poor'}
    ]

    content = () => <div className="form-panel">
    <div className="row">
        <div className="col">
            <span className="section-header-text">Credit</span>
        </div>
        <div className="col">
            <span className="section-header-number"><span className="section-header-number-first">4</span> of 5</span>
        </div>
    </div>
    <hr className="horizontal-line"/>
    <span className="customer-message-text">Please enter accurate information as this will improve the types of lenders that we can find for you.</span>
    <div className="row">
        <div className="col col-sm-4 col-12">
            <span className="label">Do you have $10,000+ in unsecured debt and want one of our partners to contact you?</span>
            <div>
                <label className="btn btn-option">
                    <AppInput.Radio name="unsecured_debt" value="yes"/>
                    <span>Yes</span>
                </label>
            </div>
            <div>
                <label className="btn btn-option">
                    <AppInput.Radio name="unsecured_debt" value="no"/>
                    <span>No</span>
                </label>
            </div>
        </div>
        <div className="col col-sm-4 col-12">
            <span className="label">Do you have a free and clear title to a vehicle under your name?</span>
            <div>
                <label className="btn btn-option">
                    <AppInput.Radio name="auto_title" value="yes"/>
                    <span>Yes</span>
                </label>
            </div>
            <div>
                <label className="btn btn-option">
                    <AppInput.Radio name="auto_title" value="no"/>
                    <span>No</span>
                </label>
            </div>
        </div>
        <div className="col col-sm-4 col-12">
            <span className="label">How is your overall credit?</span>
            <span className="input-group">                    
                <AppInput.Select name="credit" options={this.credit} onValidate={v => v} failClass="invalid" />
            </span>
        </div>
    </div> 
</div>;
};
// 

class PanelLast extends GenSet {
    continue = () => this.navigate("#BankAba");

    content = () => <div className="form-panel">
        <div className="section-header">
            <span className="section-header-text">The Small Print</span>
            <span className="section-header-number"><span className="section-header-number-first">5</span> of 5</span>
        </div>
        <hr className="horizontal-line"/>
        <div className="customer-message">
            <span className="customer-message-text">Please complete this final step to see your personalised loan offers.</span>
        </div>
        <div className="form-left">
            <div className="inline-display">
                <label className="btn-checkbox mt-1">
                    <AppInput.CheckBox name="consent-general" className="form-check-input" onClick={v => this.setVal({consent: this.applicant.field('consent')})} style={{height: "26px", width: "26px", marginLeft: "1px", marginTop: "2px", accentColor: "navy"}} />
                    <span className="small-print-checkbox">By clicking "Continue", I have read and accept the <a className="terms-anchor-tag" href="https://loanz.com/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>. I also acknowledge having read the <a className="terms-anchor-tag" href="https://loanz.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> and agree to the terms herein.</span>
                </label>
            </div>
            <div className="inline-display">
                <label className="btn-checkbox mt-1">
                    <AppInput.CheckBox name="consent-email" className="form-check-input" onClick={v => this.setVal({consent: this.applicant.field('consent')})} style={{height: "26px", width: "26px", marginLeft: "1px", marginTop: "2px", accentColor: "navy"}} />
                    <span className="small-print-checkbox">Please indicate if you would like to receive your credit application results by email.</span>
                </label>
            </div>
        </div>
        <div className="continue-button mt-3" onClick={() => this.continue()}>Continue</div>
    </div>;
};

export default class LoanzFlatFormUS extends GenSet {
    onMount = () => {
        if (!CoreTools.state.appOptions.includes("nocss")) {
            if (CoreTools.state.isLocal) {
                CoreTools.importStyle("/css/loanz-flatform-us.css");
            } else {
                CoreTools.importStyle("https://form.consumergenius.com/css/loanz-flatform-us.css");
            }
        }
    };

    content = state => <div className="form-body">
        <PanelLoan {...this.props}/>
        <PanelAddress {...this.props}/>
        <PanelIncome {...this.props}/>
        <PanelCredit {...this.props}/>
        <PanelLast {...this.props} />
    </div>;
}
