import GenSet from "../../../services/GenSet";
import AppInput from "../../../directives/AppInput";
import GoogleAddress from "../../../directives/GoogleAddress";
import SurexComponents from "../inserts/SurexComponents";
import CoreTools from "../../../services/CoreTools";
import OptionLists from "../../../services/OptionLists";

export default class SurexAddress extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => isValid ? this.navigate(this.props.continue) : CoreTools.centerForm());
    };

    stateOptions = [
        {value: "", caption: "Select State"},
        ...OptionLists.States.map(i => ({value: i.short, caption: i.long}))
    ];

    zipCodeFormat = event => {
        if (typeof(event.target.value) === "string") {
            const proper = event.target.value.replace(/[^0-9]/gi, "").substring(0, 5);
            if (event.target.value !== proper) {
                const cPos = event.target.selectionStart;
                event.target.value = proper;
                event.target.selectionStart = cPos;
                event.target.selectionEnd = cPos;
            }
        }
    };

    zipCodeValidate = value => (
        typeof(value) === "string" &&
        value.length === 5 &&
        CoreTools.match(value.substring(0, 5), /[0-9]/g)
    );
    
    onApplicant = (state, props, applicant) => {
        this.setVal({addressSet: applicant.field("street_number") && applicant.field("street_name") && applicant.field("city") && applicant.field("state") && applicant.field("zip_code") ? true : false});
        this.applicant.onFieldUpdate("google_address", newValue => {
            this.setVal({addressSet: applicant.field("street_number") && applicant.field("street_name") && applicant.field("city") && applicant.field("state") && applicant.field("zip_code") ? true : false});
        });
    };

    content = (state, props, applicant) => <SurexComponents.Card
        title="Where do you live?"
        text="Enter your physical address in the box below to quickly autocomplete all required fields."
        controls={<SurexComponents.Controls continue={this.continue} disabled={!this.state.addressSet} />}
    >
        <div className="form-row text-center mb-3">
            <div className="col-md-12">
                <div id="gm-autocomplete-wrap" className="form-field mb-3">                    
                    <GoogleAddress
                        country="us"
                        className="form-control text-center"
                        placeholder="Type Here to Search For Your Address"
                        defaultValue={this.applicant.field("google_address") || ""}
                        onSelect={address => {
                            applicant.setField("google_address", address.full);
                            applicant.setField("street_number", address.number);
                            applicant.setField("street_name", address.street);
                            applicant.setField("city", address.city);
                            applicant.setField("state", address.stateShort);
                            applicant.setField("zip_code", address.zipcode);
                            this.setVal({addressSet: true}, () => console.log(state.addressSet));
                        }}
                    />
                </div>
            </div>
        </div>
        { state.addressSet && <div className="form-row address-fields">
            <div className="col-md-12 mb-1">
                <AppInput.Input type="text" name="street_number" placeholder="Street Number" onValidate={v => v} className="form-control text-field" failClass="invalid" />
            </div>
            <div className="col-md-12 mb-1">
                <AppInput.Input type="text" name="street_name" placeholder="Street Name" onValidate={v => v} className="form-control text-field" failClass="invalid" />
            </div>
            <div className="col-md-12 mb-1">
                <AppInput.Input type="text" name="unit" placeholder="Unit/Apt/Suite" className="form-control text-field" />
            </div>
            <div className="col-md-12 mb-1">
                <AppInput.Input type="text" name="city" placeholder="City" onValidate={v => v} className="form-control text-field" failClass="invalid" />
            </div>
            <div className="col-md-12 mb-1">
                <AppInput.Select type="text" name="state" options={this.stateOptions} className="form-control text-field" onValidate={v => v} failClass="invalid" />			
            </div>
            <div className="col-md-12 mb-1">
                <AppInput.Input type="text" name="zip_code" placeholder="Zip Code" className="form-control text-field" onValidate={this.zipCodeValidate} failClass="invalid" onChange={this.zipCodeFormat} />
            </div>
        </div> }
    </SurexComponents.Card>;
}