import GenSet from "../../../services/GenSet";
import SurexComponents from "../inserts/SurexComponents";
import LegacyDOBPicker from '../../../directives/LegacyDOBPicker';
import CoreTools from "../../../services/CoreTools";

export default class SurexDateBirth extends GenSet {
    continue = () => {
        this.applicant.validate(isValid => {
            if (isValid) {
                this.applicant.setField("license_date", [this.applicant.field("licence_year"), this.applicant.field("licence_month"), this.applicant.field("licence_day")].join("-"));
                this.navigate(this.props.continue);
            } else {CoreTools.centerForm();}
        });
    };
    content = (state, props) => <SurexComponents.Card
        title="When was your licence issued?"
        text="Select the date your licence was issued from the dropdowns below."
        controls={<SurexComponents.Controls continue={this.continue} />}
    >
        
        <LegacyDOBPicker onSelect={v => this.setVal({ready: v})} />
    </SurexComponents.Card>;
}
